import { FC, useEffect, useState } from "react";
import { message, Select } from "antd";
import { useForm, SubmitHandler } from "react-hook-form";
import { FormButton } from "../../../../components";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../redux/store/store";
import { useNavigate } from "react-router-dom";
import { updateTalentProfile } from "../../../../redux/TalentSlice/TalentSlice";

import "../EditProfileModal.scss";

const Option = Select.Option;

interface LinkInputs {
  linkedinurl: string;
  website: string;
  githuburl: Number;
  twitterurl: string;
}

interface PasswordHidden {
  one: boolean;
  two: boolean;
}

type Props = {
  onModalClose?: () => void;
};

const ProfileLinks: FC<Props> = (props) => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const { register, handleSubmit } = useForm<LinkInputs>();
  const dispatch = useDispatch<AppDispatch>();

  const talentProfile = useSelector((state: any) => state.talent.profileInfo);
  const onSubmit: SubmitHandler<LinkInputs> = (data) => {
    setLoading(true);
    dispatch(
      updateTalentProfile({
        userId: talentProfile?.id,
        payload: { social_bio: data },
      })
    )
      .then(() => {
        setLoading(false);
        props.onModalClose?.();
      })
      .catch(() => {
        setLoading(false);
      });
    return;
  };

  useEffect(() => {
    // This code is used for rendering country flags:
    // ref: https://www.twilio.com/blog/international-phone-number-input-html-javascript

    const phoneInputField = document.getElementById("phone");
    (window as any).intlTelInput(phoneInputField);

    const element = document.getElementsByClassName("iti__flag-container")[0];
    (element as any).style.display = "none";
  }, []);

  const openFileUpload = () => {
    document.getElementById("fileUpload")?.click();
  };

  return (
    <div className="editProfile__container">
      <form onSubmit={handleSubmit(onSubmit)}>
        <label>
          <span>LinkedIn</span>
          <input
            {...register("linkedinurl")}
            defaultValue={talentProfile?.social_links?.linkedin}
            type="text"
            placeholder="Write Here"
          />
        </label>
        <label>
          <span>Portfolio or Personal Website</span>
          <input
            {...register("website")}
            defaultValue={talentProfile?.social_links?.website}
            type="text"
            placeholder="Write Here"
          />
        </label>
        <label>
          <span>Github</span>
          <input
            {...register("githuburl")}
            defaultValue={talentProfile?.social_links?.github}
            type="text"
            placeholder="Write Here"
          />
        </label>
        <label>
          <span>X (formerly known as Twitter)</span>
          <input
            {...register("twitterurl")}
            defaultValue={talentProfile?.social_links?.twitter}
            type="text"
            placeholder="Write Here"
          />
        </label>

       

        {errorMessage && <span className="errorMessage">{errorMessage}</span>}
        <FormButton text="Submit" loading={loading} />
      </form>
    </div>
  );
};

export default ProfileLinks;
