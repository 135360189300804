import React, {FC} from "react";
import {ReactComponent as Logo} from "../../assets/icons/logo.svg";

import "./PartnerResourceFooter.scss";
import {Link} from "react-router-dom";
import {ArrowRight, Facebook, Instagram} from "../../assets/icons";
import {scrollToTop} from "../../utils/scrollToTop";

const PartnerResourceFooter: FC = () => {
    const currentYear = new Date().getFullYear();
    return (
        <footer className="footer">
            <div className="footer__container">
                <main>
                    <div className="footer__content">
                        <Logo className="logo"/>
                        <div className="title">We would love to hear from you.</div>
                        <p>
                            Feel free to reach us if you want to collaborate with us, or
                            simply have a chat
                        </p>
                        <Link className="buttonWithArrow" to={"#"}>
                            Contact Us
                            <ArrowRight/>
                        </Link>
                        <span>Don't like the forms? Drop us a line via email.</span>
                        <div className="email">hello@tribaja.com</div>
                    </div>
                    <div className="footer__box">
                        <Logo className="logo"/>
                        <div className="footer__box-columns">
                            <div className="footer__box-column">
                                <span>Company</span>
                                <Link to={"/talent"}>Jobseekers</Link>
                                <Link to={"/employer"}>Employers</Link>
                                <Link to={"/partner"}>Partner</Link>
                            </div>
                            <div className="footer__box-column">
                                <span>Product</span>
                                <Link to={"#"}>Pricing</Link>
                            </div>
                        </div>
                        <div className="footer__box-socials">
                            <a
                                href="https://www.facebook.com/Tribaja.co/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Facebook/>
                            </a>
                            <a
                                href="https://www.instagram.com/tribaja.co/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Instagram/>
                            </a>
                        </div>
                        <div onClick={() => scrollToTop()} className="footer__box-top">
                            Back To The Top
                        </div>
                    </div>
                </main>
                <div className="footer__copyright">
                    © Tribaja {currentYear}. All rights reserved{" "}
                    <Link to={"https://tribaja.co/privacy-policy"}>Privacy Policy</Link>
                </div>
            </div>
        </footer>
    );
};

export default PartnerResourceFooter;