import React, { useEffect, useState } from "react";
import "./CoachPanelContainer.scss";

import { AnimatePresence, motion } from "framer-motion";
import { TailSpin } from "react-loader-spinner";
import Slider  from "react-slick";
import DashboardNavbar from "../../components/DashboardNavbar/DashboardNavbar";

import { scrollToTop } from "../../utils/scrollToTop";
import ProfileDetailCard from "../../components/ProfileDetailCard/ProfileDetailCard";

import {
  MentoredIcon,
  ProfileViewIcon,
  RequestsReceived,
  SavedItems,
} from "../../assets/icons";
import StudentCard from "../../components/StudentCard/StudentCard";
import axios from "axios";
import api from "../../utils/api";
import { getAccessToken } from "../../utils/jwtToken";

const CoachPanelContainer = () => {
  const [loading, setLoading] = useState(false);
  const [payloadData, setPayloadData] = useState<any>([]);

  const menteeReqLength = payloadData?.mentored_students?.requests?.length;
  const savedItemsQty = payloadData?.total_saved;


  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 700);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 700);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const fetchCoachPanelData = () => {
    const accessToken = getAccessToken();
    axios
      .get(`${api}/talent/coach/panel/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(function (response) {
        setLoading(false);
        setPayloadData(response.data);
      })
      .catch(function (error) {
        // Handle any errors
        setLoading(false);
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    fetchCoachPanelData();
  }, [menteeReqLength,savedItemsQty]);

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <AnimatePresence>
      {!loading ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="coachPanel__box"
        >
          <DashboardNavbar addLogo={isMobileView} />
          <main className="coachPanel">
            <motion.div className="coachPanel__heading">
              <h1>Coach Panel</h1>
            </motion.div>
            <motion.div className="coachPanel__mentor-stats">
              <h3>Coach Stats</h3>
              <div className="coachPanel__mentor-stats-statCard">
                <ProfileDetailCard
                  icon={<SavedItems />}
                  title="Saved Items"
                  content={payloadData?.total_saved}
                  background="#FDCEB5"
                />

                <ProfileDetailCard
                  icon={<RequestsReceived />}
                  title="Talent requests received"
                  content={payloadData?.talent_requests?.total}
                  background="#FEEDCC"
                />

                <ProfileDetailCard
                  icon={<MentoredIcon />}
                  title="Coached"
                  content={payloadData?.mentored_students?.total}
                  background="#91C7B1"
                />
              </div>
            </motion.div>

            <motion.div className="coachPanel__recommendations-container">
            <h3>My Students</h3>
              {payloadData?.mentored_students?.students?.length > 0 ?
                 <div className="coachPanel__recommendations-slider">
                    <Slider
                          dots={true}
                          slidesToShow={Math.min(payloadData?.mentored_students?.students?.length, 3)}
                          slidesToScroll={3}
                          autoplay={true}
                          autoplaySpeed={3000}
                          arrows={false}
                          responsive={[
                            {
                              breakpoint: 1189, // Width less than or equal to 1024px
                              settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2,
                                infinite: true,
                                dots: true,
                              }
                            },
                            {
                              breakpoint: 938, // Width less than or equal to 1024px
                              settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                infinite: true,
                                dots: true
                              }
                            },
                          ]}
                        >
                          {payloadData?.mentored_students?.students?.map(
                            (student: any) => {
                              return (
                                <StudentCard
                                  student={student}
                                  fetchCoachPanelData={fetchCoachPanelData}
                                />
                              );
                            }
                          )}
                      </Slider>
                     
                  </div> : 
                    <span>No Students Found!</span>
              }
            </motion.div>

            <motion.div className="coachPanel__info-container">
            <h3>Mentee Requests</h3>
              {payloadData?.talent_requests?.requests?.length === 0 && (
                <span>No mentee requests to show !</span>
              )}
              <div className="coachPanel__info-container-details">
                {payloadData?.talent_requests?.requests?.map((request: any) => {
                  return (
                    <StudentCard
                      student={request}
                      isRequest={true}
                      fetchCoachPanelData={fetchCoachPanelData}
                    />
                  );
                })}
              </div>
            </motion.div>
          </main>
        </motion.div>
      ) : (
        <TailSpin
          height="160"
          width="160"
          color="#FBAD84"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{
            justifySelf: "center",
            alignSelf: "center",
          }}
          wrapperClass="tail-spin"
          visible={true}
        />
      )}
    </AnimatePresence>
  );
};

export default CoachPanelContainer;
