import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";


import { TailSpin } from "react-loader-spinner";

import "./EmployerTeamManagementPage.scss";
import DashboardNavbar from "../../components/DashboardNavbar/DashboardNavbar";

import { AppDispatch } from "../../redux/store/store";

import {  getStaff } from "../../redux/EmployerSlice/EmployerSlice";
import {  Button, message } from "antd";


import StaffCard from "../../components/StaffCard/StaffCard";
import { NoJobIcon } from "../../assets/icons";
import StaffAddModal from "./StaffAddModal";


const EmployerTeamManagementPage: FC = () => {
  // States
  const [loading, setLoading] = useState<boolean>(true);

  const [openModal, setModalOpen] = useState<boolean>(false);
  
  const [staffProfileMode, setStaffProfileMode] = useState<"ADD_NEW" | "EDIT">(
      "ADD_NEW"
    );
  const { staff: staffData } = useSelector((state: any) => state.employer);
  
  // Dispatch
  const dispatch = useDispatch<AppDispatch>();

  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 700);


  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 700);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

 
  useEffect(() => {
   dispatch(getStaff()).then(()=> {
     setLoading(false);
   })

  }, [dispatch]);


 

  return (
    <>
        {openModal && (
        <StaffAddModal
          formType={staffProfileMode} 
          onModalClose={() => setModalOpen(false)}
          staffId="" 
        />
        )}
      <AnimatePresence>
      {!loading ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="employer__teamManagementPage-box"
        >
          <DashboardNavbar addLogo={isMobileView} />
          <main className="employer__teamManagementPage">
            <motion.div
              key={"dashboard-heading"}
              transition={{ duration: 0.3, delay: 0.3 }}
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              className="employer__teamManagementPage-heading"
            >
              <h1>Team Management</h1>
              <Button className="button"
                  type="primary"
                  shape="round"
                  size={"large"}
                  style={{
                    padding: "6px 44px",
                    fontSize: "14px",
                    fontWeight: 600,
                    background: "#5937CC)",
                  }}
                  onClick={() => {
                    setStaffProfileMode("ADD_NEW");
                    setModalOpen(true);
                  }}
                >
                  Add Member
                </Button>
            </motion.div>
            {staffData?.length > 0 ? (
              <motion.section
                transition={{ duration: 0.3, delay: 0.4 }}
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                className="employer__teamManagementPage-container"
              >
                  
                    {staffData?.map((elem: any) => {
                      return <StaffCard staff={elem} />;
                    })}
                  
               
              </motion.section>
            ) : (
              <motion.section
                transition={{ duration: 0.3, delay: 0.4 }}
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                className="employer__teamManagementPage"
              >
                <div className="no-job">
                  <div className="no-job__message">
                    <NoJobIcon />
                    <h1>No Staff Member</h1>
                  </div>
                </div>
              </motion.section>
            )}
          </main>
        </motion.div>
      ) : (
        <TailSpin
          height="160"
          width="160"
          color="#FBAD84"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{
            justifySelf: "center",
            alignSelf: "center",
          }}
          wrapperClass="tail-spin"
          visible={true}
        />
      )}
    </AnimatePresence>
    </>
    
  );
};

export default EmployerTeamManagementPage;
