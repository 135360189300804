import React, { FC, useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";

import "./YourCoaches.scss";
import DashboardNavbar from "../../components/DashboardNavbar/DashboardNavbar";
import CoachCard from "../../components/CoachCard/CoachCard";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store/store";
import { TailSpin } from "react-loader-spinner";
import { getTalentDashboard } from "../../redux/TalentSlice/TalentSlice";

const YourCoaches: FC = () => {
  // States
  const [loading, setLoading] = useState<boolean>(true);

  // Selectors
  const profileInfo = useSelector((state: any) => state.profile.profileInfo);
  const talentDashboard = useSelector((state: any) => state.talent.dashboard);

  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 700);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 700);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const myCoaches = talentDashboard?.coaches || [];

  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    if (!talentDashboard?.profile) dispatch(getTalentDashboard());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setLoading(true);

    if (profileInfo?.first_name && talentDashboard?.profile) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    setLoading(true);

    if (profileInfo?.first_name) {
      setLoading(false);
    }
  }, [profileInfo]);

  return (
    <AnimatePresence>
      {!loading ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="yourCoaches"
        >
          <DashboardNavbar addLogo={isMobileView}/>
          <div className="yourCoaches__heading">My Coaches</div>
          {(myCoaches ?? []).length > 0 ? (
            <div className="yourCoaches__container">
              {myCoaches.map((coach: any) => {
                return <CoachCard coach={coach} />;
              })}
            </div>
          ) : (
            <div
              style={{
                fontWeight: "400",
                fontSize: "15px",
                display: "flex",
                justifyContent: "center",
                marginTop: "40px",
              }}
            >
              <span>
                No Coaches assigned to you. View our
                <Link to="/recommendations"> Recommended Coaches </Link> for
                you.
              </span>
            </div>
          )}
        </motion.div>
      ) : (
        <TailSpin
          height="160"
          width="160"
          color="#FBAD84"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{
            justifySelf: "center",
            alignSelf: "center",
          }}
          wrapperClass="tail-spin"
          visible={true}
        />
      )}
    </AnimatePresence>
  );
};

export default YourCoaches;
