import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
// import { Pie } from "@ant-design/charts";
import { ReactComponent as MenuIcon } from "../../assets/icons/menuIcon.svg";

import { TailSpin } from "react-loader-spinner";

import "./EmployerPostedJobPage.scss";
import DashboardNavbar from "../../components/DashboardNavbar/DashboardNavbar";
import { useNavigate, useParams } from "react-router-dom";
import {
  NoJobIcon,
  OpenRolesEmployer,
  PendingReviewEmployer,
  TimeToHireEmployer,
  TotalApplicantsEmployer,
} from "../../assets/icons";
import { EmployerAnalyticCard } from "../../components";
import { AppDispatch } from "../../redux/store/store";
import { getEmployerDashboard } from "../../redux/EmployerSlice/EmployerSlice";
import { ReactComponent as EditIcon } from "../../assets/icons/editIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/deleteIcon.svg";
import { Tag, Dropdown, message } from "antd";
import PostedJobCard from "../../components/PostedJobCard/PostedJobCard";
import axios from "axios";
import { getAccessToken } from "../../utils/jwtToken";
import api from "../../utils/api";
import Slider from "react-slick";

const EmployerPostedJobPage: FC = () => {
  // States
  const [loading, setLoading] = useState<boolean>(true);

  // Dispatch
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();
  // Selectors
  const profileInfo = useSelector((state: any) => state.profile.profileInfo);
  const employerDashboard = useSelector(
    (state: any) => state.employer.dashboard,
  );

  const statusColor: any = {
    active: "#31BE00",
    open: "#31BE00",
    paused: "#FFA800",
    closed: "#EE1D52",
  };

  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 700);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 700);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // LifeCylce events
  useEffect(() => {
    setLoading(true);

    if (profileInfo?.first_name && employerDashboard?.job_statistics) {
      setLoading(false);
    }
  }, [profileInfo, employerDashboard]);

  useEffect(() => {
    dispatch(getEmployerDashboard());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const jobAnalytics =
    employerDashboard?.job_statistics?.open_roles?.analytics || [];

  const items: any = [
    // {
    //     key: "1",
    //     label: (
    //         <div style={{ display: "flex", alignItems: "center" }}>
    //             <EditIcon style={{ marginRight: "10px" }} /> Edit Details{" "}
    //         </div>
    //     ),
    // },
    {
      key: "2",
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <DeleteIcon style={{ marginRight: "10px" }} /> Delete{" "}
        </div>
      ),
    },
  ];

  const handleDeleteJob = (jobId: string) => {
    setLoading(true);
    const accessToken = getAccessToken();
    axios
      .delete(`${api}/job/manage/?job_id=${jobId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(function (response) {
        message.success("Job successfuly deleted");
        dispatch(getEmployerDashboard());
        setLoading(false);
      })
      .catch(function (error) {
        message.error("Job deletion unsuccesful");
        setLoading(false); // Handle any errors
        console.error("Error:", error);
      });
  };

  const onClickMenuItemHandler = (key: any, jobId: any) => {
    if (key === "2") {
      handleDeleteJob(jobId);
    }
  };

  return (
    <AnimatePresence>
      {!loading ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="employer__jobsPage-box"
        >
          <DashboardNavbar addLogo={isMobileView} />
          <main className="employer__jobsPage">
            <motion.div
              key={"dashboard-heading"}
              transition={{ duration: 0.3, delay: 0.3 }}
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              className="employer__jobsPage-heading"
            >
              <h1>Posted Jobs</h1>
              <button
                onClick={() => {
                  navigate("/post/job");
                }}
              >
                Post a new job
              </button>
            </motion.div>
            {jobAnalytics?.length > 0 ? (
              <motion.section
                transition={{ duration: 0.3, delay: 0.4 }}
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                className="employer__jobsPage-container"
              >
                <div className="employer__jobsPage-slider">
                  <Slider
                    slidesToShow={Math.min(jobAnalytics?.length, 3)}
                    slidesToScroll={3}
                    infinite={false}
                    autoplay={true}
                    autoplaySpeed={3000}
                    arrows={false}
                    responsive={[
                      {
                        breakpoint: 1190, // Width less than or equal to 1190px
                        settings: {
                          slidesToShow: 2,
                          slidesToScroll: 2,
                          infinite: true,
                        },
                      },
                      {
                        breakpoint: 940, // Width less than or equal to 940px
                        settings: {
                          slidesToShow: 1,
                          slidesToScroll: 1,
                          infinite: true,
                        },
                      },
                    ]}
                  >
                    {jobAnalytics?.map((elem: any) => {
                      return <PostedJobCard job={elem} />;
                    })}
                  </Slider>
                </div>
              </motion.section>
            ) : (
              <motion.section
                transition={{ duration: 0.3, delay: 0.4 }}
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                className="employer__jobsPage"
              >
                <div className="no-job">
                  <div className="no-job__message">
                    <NoJobIcon />
                    <h1>No Job Posted</h1>
                  </div>
                </div>
              </motion.section>
            )}
          </main>
        </motion.div>
      ) : (
        <TailSpin
          height="160"
          width="160"
          color="#FBAD84"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{
            justifySelf: "center",
            alignSelf: "center",
          }}
          wrapperClass="tail-spin"
          visible={true}
        />
      )}
    </AnimatePresence>
  );
};

export default EmployerPostedJobPage;
