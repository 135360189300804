import React, { useEffect, useState } from "react";
import { CheckItemIcon, PricingIcon } from "../../assets/icons";
import "./index.module.scss";
import Cookies from "js-cookie";
import axios from "axios";
import api from "../../utils/api";
import { getAccessToken } from "../../utils/jwtToken";
import { message } from "antd";
import { TailSpin } from "react-loader-spinner";
import { useSelector } from "react-redux";

interface TalentBillingCardProps {
  backgroundColor?: string;
  planName: string;
  price?: string;
  description: string;
  buttonText: string;
  subscriptionStatus: string | null;
  planId: number;
  subscriptionPlan: any;
  gridStartColumn?: string | number;
}

const TalentBillingCard: React.FC<TalentBillingCardProps> = ({
  backgroundColor = "#fff",
  planName,
  price,
  description,
  buttonText,
  subscriptionStatus,
  planId,
  subscriptionPlan,
  gridStartColumn = "auto",
}) => {
  const features = [
    { key: "access_resources", label: "Access Resources" },
    { key: "apply_jobs", label: "Apply Jobs" },
    { key: "chatbot_access", label: "Chatbot Access" },
    { key: "mentor_matches", label: "Mentor Matches" },
    { key: "recommendations", label: "Recommendations" },
    { key: "searchjobs", label: "Search Jobs" },
  ];
  const [cardWidth, setCardWidth] = useState("100%");
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1150) {
        setCardWidth("100%");
      } else if (window.innerWidth <= 1312) {
        setCardWidth("217px");
      } else if (window.innerWidth <= 1469) {
        setCardWidth("247px");
      } else if (window.innerWidth <= 1527) {
        setCardWidth("334px");
      } else {
        setCardWidth("100%");
      }
      setIsMobileView(window.innerWidth < 1150);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call once to set initial state

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const talentDashboard = useSelector((state: any) => state.talent.dashboard);
  const handleStripe = async (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    window.location.href =
      "https://billing.stripe.com/p/login/test_5kA7tMb7c5RD83C5kk";

    // try {
    //   const response = await axios.post(
    //     `${api}/main/stripe/customer_portal/talent/`,
    //     {},
    //     {
    //       headers: {
    //         Authorization: `Bearer ${getAccessToken()}`,
    //       },
    //     }
    //   );

    //   const { url } = response.data;

    //   window.location.href = url;
    // } catch (error) {
    //   message.error("Error accessing Stripe portal");
    // }
  };
  const width =
    talentDashboard?.subscription_status !== "seeker" ? "365px" : "294px";

  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1150);
  const [loader, setLoader] = useState(false);
  const [subscribed, setSubscribed] = useState(
    subscriptionStatus === "seeker" ||
      subscriptionStatus === "path" ||
      subscriptionStatus === "trailblazer"
  );
  const btnText = subscribed ? "Already Subscribed" : buttonText;

  const buttonClass =
    btnText === "Already Subscribed"
      ? "employerBilling__card-button subscribed"
      : "employerBilling__card-button";

  const isBtnEnabled = btnText === "Already Subscribed" ? true : false;

  const renderPointers = (planId: any) => {
    const plan = subscriptionPlan.find((p: any) => p.id === planId);
    if (!plan) return null;

    return (
      <div>
        {features.map((feature) =>
          plan[feature.key] && feature.key !== "job_post_count" ? (
            <div
              key={feature.key}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                padding: "5px 0",
              }}
            >
              <CheckItemIcon />
              <span>{feature.label}</span>
            </div>
          ) : feature.key === "job_post_count" && plan.id === 43 ? (
            <div
              key={feature.key}
              style={{ display: "flex", alignItems: "center", gap: "10px" }}
            >
              <CheckItemIcon />
              <span>{feature.label}: Unlimited</span>
            </div>
          ) : feature.key === "job_post_count" && plan[feature.key] ? (
            <div
              key={feature.key}
              style={{ display: "flex", alignItems: "center", gap: "10px" }}
            >
              <CheckItemIcon />
              <span>
                {feature.label}: {plan.job_post_count}
              </span>
            </div>
          ) : null
        )}
      </div>
    );
  };

  const handleGetPrice = (id: any) => {
    setLoader(true);
    if (Cookies.get("role") === "talent") {
      axios
        .post(
          `${api}/main/stripe/create_checkout/talent/`,
          {
            subscription_id: id,
          },
          {
            headers: {
              Authorization: `Bearer ${getAccessToken()}`,
            },
          }
        )
        .then((response) => {
          setLoader(false);
          window.location.href = response.data.checkout_session_url;
        })
        .catch((error) => {
          setLoader(false);
          message.error("Error sending request");
          console.error("Error sending request", error);
        });
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 1150);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className="employerBilling__card"
      style={{ backgroundColor, gridColumnStart: gridStartColumn }}
    >
      <PricingIcon />
      <div className="employerBilling__card-plan-name">{planName}</div>
      {price && (
        <div className="employerBilling__card-pricing">
          ${price} <span>/mo</span>
        </div>
      )}
      <div className="employerBilling__card-description">{description}</div>
      {isMobileView && (
        <div
          style={{
            borderTop: "1px solid rgba(153, 153, 153, 1)",
            width: "100%",
            marginTop: "15px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              gap: "10px",
              margin: "15px 0 15px 0",
            }}
          >
            {renderPointers(planId)}
          </div>
        </div>
      )}
      <button
        disabled={isBtnEnabled}
        className={buttonClass}
        onClick={() => handleGetPrice(planId)}
      >
        {!loader ? (
          btnText
        ) : (
          <TailSpin
            height="25"
            width="30"
            color="#FBAD84"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{
              justifySelf: "center",
              alignSelf: "center",
            }}
            wrapperClass="tail-spin"
            visible={true}
          />
        )}
      </button>
      {subscribed && (
        <a
          href="/"
          style={{
            marginTop: "12px",
            fontSize: "12px",
          }}
          onClick={handleStripe}
        >
          Stripe Portal
        </a>
      )}
    </div>
  );
};

export default TalentBillingCard;
