import React, { useEffect, useState, CSSProperties } from "react";
import "./ScheduleMeetingModal.scss";
import { TailSpin } from "react-loader-spinner";
import { InlineWidget } from "react-calendly";
import { backdropOverFlowHide } from "../../utils/backdropOverFlowHide";
import Button from "../Button/Button";
import ReactDOM from "react-dom";
import { ErrorCircle } from "../../assets/icons";

type Props = {
  handleOpenModal: () => void;
  coach?: any;
  dataUrl: string;
  scheduleMeetingError: boolean;
};
const ScheduleMeetingModal = (props: Props) => {
  const { coach, handleOpenModal, dataUrl, scheduleMeetingError } = props;

  const [loader, setLoader] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const defaultStyle: CSSProperties={
    height: "50vh",
    minWidth: 580
  }
  const mediaQueryStyle: CSSProperties = {
    ...defaultStyle,
    minWidth: '100%'
  };

  const divStyle = windowWidth <= 620 ? mediaQueryStyle : defaultStyle;


  useEffect(() => {
    backdropOverFlowHide();
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  return ReactDOM.createPortal(
    <>
      <div
        onClick={(e) => {
          if (e.currentTarget === e.target) {
          }
        }}
        className="scheduleMeetingModal__container"
      >
        {!loader ? (
          <div className="scheduleMeetingModal">
            <h1>Schedule a meeting</h1>
            <p>with {coach?.name}</p>
            {!scheduleMeetingError ? (
              <InlineWidget
                url={dataUrl}
                styles={divStyle}
              />
            ) : (
              <div className="scheduleMeetingModal__error-case">
                <ErrorCircle />
                <span>Your coach has not integrated its Calendly yet !</span>
              </div>
            )}
            <div onClick={handleOpenModal}>
              <Button>Cancel</Button>
            </div>
          </div>
        ) : (
          <TailSpin
            height="160"
            width="160"
            color="#FBAD84"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{
              justifySelf: "center",
              alignSelf: "center",
            }}
            wrapperClass="tail-spin"
            visible={true}
          />
        )}
      </div>
    </>,
    document.getElementById("portal")!
  );
};

export default ScheduleMeetingModal;
