import { FC, useEffect, useState } from "react";
import { Select, message } from "antd";
import { useForm, SubmitHandler, set } from "react-hook-form";
import { FormButton } from "../../../../components";
import { PasswordHide, PasswordVisible } from "../../../../assets/icons";
import { ReactComponent as SuccessTick } from "../../../../assets/icons/successTick.svg";
import { Avatar } from "antd";
import MentorImage from "../../../../assets/images/mentor.png";
// import { AntDesignOutlined, UserOutlined } from '@ant-design/icons';

import "../../../ProfileScreen/Modals/EditProfileModal.scss";
import { createStaff, getStaff } from "../../../../redux/AdminSlice/AdminSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store/store";
import { encryptPassword } from "../../../../utils/encryptPassword";

const Option = Select.Option;

interface SignUpInputs {
  name: string;
  email: string;
  password: string;
  repeatPassword: string;
}

interface PasswordHidden {
  one: boolean;
  two: boolean;
}

type Props = {
  formType: string;
  onModalClose: () => void;
};

const AdminAddForm: FC<Props> = (props) => {
  const { formType } = props;
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [submittedSuccessfully, setSubmittedSuccessfully] =
    useState<boolean>(false);

  const { register, handleSubmit } = useForm<SignUpInputs>();
  const [passwordHidden, setPasswordHidden] = useState<PasswordHidden>({
    one: true,
    two: true,
  });

  const dispatch = useDispatch<AppDispatch>();

  const onSubmit: SubmitHandler<SignUpInputs> = async (data) => {
    setLoading(true);
    setErrorMessage("");
    if (data.password !== data.repeatPassword) {
      setErrorMessage("Passwords do not match");
      setLoading(false);
      return;
    }

    const encryptedPassword = await encryptPassword(data.password);
    dispatch(
      createStaff({
        email: data.email.trim(),
        password: encryptedPassword,
        first_name: data.name.split(" ")[0],
        last_name: data.name.split(" ")?.[1] ?? "",
      }),
    ).then((res: any) => {
      if (res.type.includes("fulfilled")) {
        setSubmittedSuccessfully(true);
        dispatch(getStaff());
      } else {
        setErrorMessage(res.payload.message);
      }
      setLoading(false);
    });

    // setSubmittedSuccessfully(true);

    return;
  };
  const CrossSvg = () => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.17188 14.8299L14.8319 9.16992"
        stroke="#344054"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.8319 14.8299L9.17188 9.16992"
        stroke="#344054"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
        stroke="#344054"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );

  return (
    <>
      {submittedSuccessfully ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              width: "100%",
              cursor: "pointer",
            }}
            onClick={props.onModalClose}
          >
            <CrossSvg />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "80px 40px",
            }}
          >
            <div>
              <SuccessTick />
            </div>

            <div
              style={{
                fontWeight: 700,
                fontSize: "40px",
                color: "#1E1E2F",
                marginTop: "40px",
              }}
            >
              Admin Added Successfully
            </div>
          </div>
        </>
      ) : (
        <div className="editProfile__container">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h2
              style={{
                textAlign: "left",
                marginTop: "10px",
                fontWeight: 700,
                fontSize: "40px",
                color: "#1E1E2F",
              }}
            >
              {formType === "ADD_NEW" ? "Add New Admin" : "Edit Admin"}
            </h2>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.onModalClose();
              }}
            >
              <CrossSvg />
            </div>
          </div>
          <hr
            style={{
              color: "#999999",
              marginTop: "40px",
              marginBottom: "20px",
            }}
          />

          <div className="editProfile__container">
            <form onSubmit={handleSubmit(onSubmit)}>
              <label>
                <span>Admin Name</span>
                <input {...register("name")} required type="text" />
              </label>

              <label>
                <span>Admin Email</span>
                <input {...register("email")} required type="email" />
              </label>

              <label>
                <span>
                  Password
                  <span
                    onClick={() =>
                      setPasswordHidden({
                        ...passwordHidden,
                        one: !passwordHidden.one,
                      })
                    }
                  >
                    {passwordHidden.one ? (
                      <PasswordHide />
                    ) : (
                      <PasswordVisible />
                    )}
                  </span>
                </span>
                <input
                  required
                  {...register("password")}
                  type={passwordHidden.one ? "password" : "text"}
                />
              </label>

              {formType === "ADD_NEW" && (
                <label>
                  <span>
                    Repeat Password
                    <span
                      onClick={() =>
                        setPasswordHidden({
                          ...passwordHidden,
                          two: !passwordHidden.two,
                        })
                      }
                    >
                      {passwordHidden.two ? (
                        <PasswordHide />
                      ) : (
                        <PasswordVisible />
                      )}
                    </span>
                  </span>
                  <input
                    required
                    {...register("repeatPassword")}
                    type={passwordHidden.two ? "password" : "text"}
                  />
                </label>
              )}
              {errorMessage && (
                <span
                  style={{
                    textAlign: "start",
                  }}
                  className="errorMessage"
                >
                  {errorMessage}
                </span>
              )}
              <FormButton text="Submit" loading={Boolean(loading)} />
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminAddForm;
