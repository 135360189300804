import React from "react";
import "./PdfViewer.scss";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";

function removeQueryParams(url: string) {
  if (!url) return "";
  // Split the URL at the '?' character and take the first part (the part before the query params)
  return url.split("?")[0];
}

const PdfViewer = React.memo(({ s3Url }: any) => {
  const docs = [{ uri: removeQueryParams(s3Url) }];

  return (
    <div style={{ width: 800, height: 700, overflow: "auto" }}>
      <DocViewer
        key={s3Url}
        documents={docs}
        pluginRenderers={DocViewerRenderers}
        config={{
          header: {
            disableHeader: true,
            disableFileName: true,
            retainURLParams: false,
          },
          // You can explore other config options to further remove controls
        }}
        style={{ width: "100%", height: "100%" }}
      />
    </div>
  );
});

export default PdfViewer;
