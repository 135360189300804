import React, { FC, MouseEvent } from "react";
import { TailSpin } from "react-loader-spinner";

import "./FormButton.scss";

interface FormButtomProps {
  loading: boolean;
  text: string;
  customStyle?: object;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  disabled?:boolean
}

const FormButton: FC<FormButtomProps> = ({
  loading,
  text,
  customStyle = {},
  onClick = () => {},
    disabled
}) => {
  return (
    <button
      disabled={loading || disabled}
      style={customStyle}
      className="formButton"
      onClick={onClick}
    >
      {text}
      {loading && (
        <TailSpin
          height="16"
          width="16"
          color="white"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      )}
    </button>
  );
};

export default FormButton;
