import React, { useEffect, useState, CSSProperties } from "react";
import { AnimatePresence, motion } from "framer-motion";
import "./CalendlyContainer.scss";
import DashboardNavbar from "../../components/DashboardNavbar/DashboardNavbar";

import { TailSpin } from "react-loader-spinner";
import Button from "../../components/Button/Button";
import { message } from "antd";
import axios from "axios";
import { CalendlyLogo, SuccessIcon } from "../../assets/icons";
import { getAccessToken } from "../../utils/jwtToken";
import api from "../../utils/api";
import { useDispatch, useSelector } from "react-redux";
import { getTalentDashboard } from "../../redux/TalentSlice/TalentSlice";
import { AppDispatch } from "../../redux/store/store";

const CalendlyContainer = () => {
  const talentDashboard = useSelector((state: any) => state.talent.dashboard);
  const calendlyLink =
    "https://calendly.com/oauth/authorize?client_id=NcPh2OaBTj2BK1-2SQfp-R23wHHrWz9k500FihdIQAc&response_type=code&redirect_uri=https://app.tribaja.co/calendly";
  const currentURL = window.location.href;
  const key = currentURL.split("code=");
  const [loader, setLoader] = useState(true);
  const [btnLoader, setBtnLoader] = useState(false);
  const [isEventCreated, setIsEventCreated] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 700);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 700);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const defaultStyle1: CSSProperties = {
    textAlign: "center",
    padding: '30px'
  };

  const mediaQueryStyle1: CSSProperties = {
      ...defaultStyle1,
      padding: "20px", // Add other styles you want to change for the media query
  };
  const defaultStyle2: CSSProperties = {
    marginLeft: '90px',
    marginBottom: "20px"
   
  };

  const mediaQueryStyle2: CSSProperties = {
      ...defaultStyle2,
      marginLeft: '40px', // Add other styles you want to change for the media query
  };

  const divStyle1 = windowWidth <= 880 ? mediaQueryStyle1 : defaultStyle1;
  const divStyle2 = windowWidth <= 880 ? mediaQueryStyle2 : defaultStyle2;


  const dispatch = useDispatch<AppDispatch>();

  setTimeout(() => {
    setLoader(false);
  }, 500);

  useEffect(() => {
    if (key.length === 2) {
      const urlKey = key[1];
      const accessToken = getAccessToken();
      axios
        .post(
          `${api}/talent/calendly/auth/`,
          {
            code: urlKey,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {})
        .catch((error) => {
          console.error("Error sending request", error);
        });
    }
  }, []);

  const handleCreateCalendyEvent = () => {
    setBtnLoader(true);
    const accessToken = getAccessToken();
    axios
      .post(
        `${api}/talent/calendly/integration/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        setBtnLoader(false);
        setIsEventCreated(true);
        message.success("Event created successfully", 5);
        dispatch(getTalentDashboard());
      })
      .catch((error) => {
        setBtnLoader(false);
        message.error("Error creating event", 5);
        console.error("Error sending request", error);
      });
  };

  return (
    <AnimatePresence>
      {!loader ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="calendly"
        >
          <DashboardNavbar addLogo={isMobileView} />
          <div className="calendly__heading">Calendly Integration</div>
          <div className="calendly__container">
            {!key[1] ? (
              <div className={"calendly__sub-container"}>
                <div  style={divStyle1}>
                  <div className="logo" style={divStyle2}>
                    <CalendlyLogo />
                  </div>
                  <h2>
                    {talentDashboard?.calendly_integration
                      ? "You are already connected"
                      : "Connect with Calendly"}
                  </h2>
                  <p>
                    {talentDashboard?.calendly_integration
                      ? ""
                      : "Continuing will enable tribaja to connect to your account with calendly"}
                  </p>
                </div>
                <div>
                  {talentDashboard?.calendly_integration ? (
                    ""
                  ) : (
                    <a href={calendlyLink} target={"_blank"}>
                      <Button className={"btn-primary"}>Connect</Button>
                    </a>
                  )}
                </div>
              </div>
            ) : (
              <>
                {!isEventCreated ? (
                  <div className={"calendly__instruction"}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "15px",
                      }}
                    >
                      <SuccessIcon />
                      <h1>Authorized Successfully</h1>
                    </div>
                    <div style={{ padding: "10px" }}>
                      <ol>
                        Instructions:
                        <li>
                          Go to calendly and create an event type named
                          "tribaja".When created successfully and complete your
                          calendly integration
                        </li>
                        <li>
                          Go to calendly and create an event type named
                          "tribaja".When created successfully and complete your
                          calendly integration
                        </li>
                        <li>
                          Go to calendly and create an event type named
                          "tribaja".When created successfully and complete your
                          calendly integration
                        </li>
                      </ol>
                    </div>
                    <div>
                      {key[1] && (
                        <div
                          className={"calendly__button"}
                          onClick={handleCreateCalendyEvent}
                        >
                          {!btnLoader ? (
                            <Button className={"btn-primary"}>
                              Connect calendly event
                            </Button>
                          ) : (
                            <TailSpin
                              height="60"
                              width="60"
                              color="#FBAD84"
                              ariaLabel="tail-spin-loading"
                              radius="1"
                              wrapperStyle={{
                                justifySelf: "center",
                                alignSelf: "center",
                              }}
                              wrapperClass="tail-spin"
                              visible={true}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className={"calendly__instruction"}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "15px",
                      }}
                    >
                      <SuccessIcon />
                      <h1>Created Successfully</h1>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </motion.div>
      ) : (
        <TailSpin
          height="160"
          width="160"
          color="#FBAD84"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{
            justifySelf: "center",
            alignSelf: "center",
          }}
          wrapperClass="tail-spin"
          visible={true}
        />
      )}
    </AnimatePresence>
  );
};

export default CalendlyContainer;
