import React, { FC } from "react";
import { ReactComponent as Logo } from "../../assets/icons/tribajaLogo.svg";
import { ReactComponent as BackIcon } from "../../assets/icons/arrowLeft.svg";
import { Link, useLocation } from "react-router-dom";
import "./navBar.scss";

interface NavBarProps {
  hide?: boolean;
  back?: boolean;
  backAction?: () => void;
  disabled?: boolean;
  disableNavigation?: boolean;
}

const NavBar: FC<NavBarProps> = ({
  hide = false,
  back = false,
  backAction,
  disabled = false,
  disableNavigation = false,
}) => {
  const signUpPaths = ["/signup", "/verify-otp", "/reset-password"];

  const location = useLocation();

  return (
    <div className="navbar">
      <div className="navbar__col-1">
        <Link
          style={{
            cursor: disableNavigation ? "default" : "pointer",
          }}
          to={`${disableNavigation ? "" : "/"}`}
        >
          <Logo className="navbar__logo" />
        </Link>
      </div>
      {back && (
        <BackIcon
          onClick={!disabled ? backAction : undefined}
          className={`navbar__back-icon ${disabled ? "disabled" : ""}`}
        />
      )}
      {!hide &&
        (signUpPaths.includes(location.pathname) ? (
          <Link className="link-button" to={"/login"}>
            Login
          </Link>
        ) : (
          <Link className="link-button" to={"/signup"}>
            Signup
          </Link>
        ))}
    </div>
  );
};

export default NavBar;
