import React, { useEffect } from "react";

import "./RequestConnectModal.scss";
import { RequestModal } from "../../assets/icons";
import { CloseSquare } from "../../assets/icons";

import ReactDOM from "react-dom";
import { backdropOverFlowHide } from "../../utils/backdropOverFlowHide";
import { scrollToTop } from "../../utils/scrollToTop";

interface Props {
  handleCloseConnectRequest: () => void;
}

const RequestConnectModal = (props: Props) => {
  const { handleCloseConnectRequest } = props;

  useEffect(() => {
    scrollToTop();
    backdropOverFlowHide();
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  return ReactDOM.createPortal(
    <>
      <div
        onClick={(e) => {
          if (e.currentTarget === e.target) {
          }
        }}
        className="requestModal__container"
      >
        <div className="requestModal">
          <CloseSquare
            onClick={handleCloseConnectRequest}
            className="requestModal_closeSquareLogo"
          />
          <RequestModal />
          <h1>Your Request is on Its Way!</h1>
          <p>
            Thank you for your coach request. We will review your request and
            email you next step in the next 5 to 7 working days.
          </p>
          <button onClick={handleCloseConnectRequest}>Done</button>
        </div>
      </div>
    </>,
    document.getElementById("portal")!
  );
};

export default RequestConnectModal;
