import React, { FC, useEffect, useState } from "react";
import { Footer, FormButton, NavBar } from "../../components";

import { SubmitHandler, useForm } from "react-hook-form";
import { AppDispatch } from "../../redux/store/store";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PasswordHide, PasswordVisible, VerifiedOtp } from "../../assets/icons";
import {
  forgetPassword,
  resetPassword,
} from "../../redux/ProfileSlice/ProfileSlice";

import "./ResetPasswordScreen.scss";

interface ResetPasswordInputs {
  email: string;
  otp: number;
  password: string;
  repeatPassword: string;
}

interface PasswordHidden {
  one: boolean;
  two: boolean;
}

const ResetPasswordScreen: FC = () => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<Boolean>(false);
  const [passwordHidden, setPasswordHidden] = useState<PasswordHidden>({
    one: true,
    two: true,
  });
  const navigate = useNavigate();
  const location = useLocation();
  const param = useParams();
  console.log("params", param);
  const [step, setStep] = useState<number>(1);

  const { register, handleSubmit, reset, setValue } =
    useForm<ResetPasswordInputs>();

  const dispatch = useDispatch<AppDispatch>();
  const searchParams = new URLSearchParams(location.search);
  const emailFromQuery = searchParams.get("email");

  useEffect(() => {
    if (emailFromQuery) {
      // Decode the email from the URL and replace any spaces with '+'
      const decodedEmail = decodeURIComponent(emailFromQuery).replace(
        / /g,
        "+"
      );
      setValue("email", decodedEmail);
      handleSubmit(onSubmitEmail)();
    }
  }, [emailFromQuery, setValue, handleSubmit]);

  const onSubmitEmail: SubmitHandler<ResetPasswordInputs> = async (data) => {
    setLoading(true);
    try {
      const response = await dispatch(
        forgetPassword({ email: data.email })
      ).unwrap();
      setLoading(false);
      // Navigate to OTP step or show success message
      setStep(2);
      console.log("Email submission successful", response);
    } catch (error) {
      setLoading(false);
      setErrorMessage("Failed to send reset email. Please try again.");
    }
  };
  const onSubmitOTP: SubmitHandler<ResetPasswordInputs> = (data) => {
    setErrorMessage("");
    setStep(3);
  };

  const onSubmitPasswords: SubmitHandler<ResetPasswordInputs> = (data) => {
    setErrorMessage("");
    setLoading(true);
    if (data.password === data.repeatPassword) {
      dispatch(
        resetPassword({
          email: data.email,
          otp: data.otp,
          passwordOne: data.password,
          passwordTwo: data.repeatPassword,
        })
      ).then((res) => {
        setLoading(false);
        if (res.type.includes("rejected")) {
          const errMessage: any = Object.values(res.payload);
          if (errMessage[0][0]?.length > 2) {
            setErrorMessage(errMessage[0][0]);
          } else {
            setErrorMessage("Something went wrong, please try again.");
          }
        } else {
          setStep(4);
          // return;
        }
      });
    } else {
      setLoading(false);
      setErrorMessage("Both passwords doesn't match.");
    }
  };

  return (
    <div className="resetPassword">
      <NavBar />
      <div className="resetPassword__container">
        {step === 1 && (
          <>
            <h2>Reset Password</h2>
            <p>
              Reset Your Password and Regain Access to Your Tribaja Account. We
              will send you a confirmation email
            </p>
            <form onSubmit={handleSubmit(onSubmitEmail)}>
              <label>
                <span>Please enter your email</span>
                <input {...register("email")} required type="email" />
              </label>
              {errorMessage && (
                <span className="errorMessage">{errorMessage}</span>
              )}
              <FormButton text="Continue" loading={Boolean(loading)} />
            </form>
          </>
        )}
        {step === 2 && (
          <>
            <h2>Verification code</h2>
            <p>
              Please confirm the verification code sent to
              <span style={{ color: "purple", margin: "0 4px 0 4px" }}>
                {emailFromQuery}
              </span>
              to reset your password and regain access to Tribaja.
            </p>
            <form onSubmit={handleSubmit(onSubmitOTP)}>
              <label>
                <span>Enter your verification code</span>
                <input {...register("otp")} required type="number" />
              </label>
              {errorMessage && (
                <span className="errorMessage">{errorMessage}</span>
              )}
              <FormButton text="Continue" loading={Boolean(loading)} />
            </form>
          </>
        )}
        {step === 3 && (
          <>
            <h2>New Password</h2>
            <p>
              Connect with our community of coaches and users from 141+
              countries around the world.
            </p>
            <form onSubmit={handleSubmit(onSubmitPasswords)}>
              <label>
                <span>
                  Create a password
                  <span
                    onClick={() =>
                      setPasswordHidden({
                        one: !passwordHidden.one,
                        two: passwordHidden.two,
                      })
                    }
                  >
                    {passwordHidden.one ? (
                      <PasswordHide />
                    ) : (
                      <PasswordVisible />
                    )}
                  </span>
                </span>
                <input
                  required
                  {...register("password")}
                  type={passwordHidden.one ? "password" : "text"}
                />
              </label>
              <label>
                <span>
                  Repeat password
                  <span
                    onClick={() =>
                      setPasswordHidden({
                        one: passwordHidden.one,
                        two: !passwordHidden.two,
                      })
                    }
                  >
                    {passwordHidden.two ? (
                      <PasswordHide />
                    ) : (
                      <PasswordVisible />
                    )}
                  </span>
                </span>
                <input
                  required
                  {...register("repeatPassword")}
                  type={passwordHidden.two ? "password" : "text"}
                />
              </label>
              {errorMessage && (
                <span className="errorMessage">{errorMessage}</span>
              )}
              <FormButton text="Continue" loading={Boolean(loading)} />
              <span
                className="resend"
                onClick={() => {
                  setErrorMessage("");
                  setLoading(false);
                  reset();
                  setStep(1);
                }}
              >
                Resend OTP
              </span>
            </form>
          </>
        )}
        {step === 4 && (
          <>
            <div className="success">
              <VerifiedOtp />
              <div className="title">Password Reset Successful</div>
              <span>
                Congratulations! Your password has been successfully reset. You
                can now securely access your Tribaja account with your new
                password.
              </span>
              <button onClick={() => navigate("/login")}>Login</button>
            </div>
          </>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default ResetPasswordScreen;
