import React, { FC, useState } from "react";
import { Select } from "antd";

import "../../../ProfileScreen/Modals/EditProfileModal.scss";

import AdminAddForm from "./AdminAddForm";

const CrossSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.17188 14.8299L14.8319 9.16992"
      stroke="#344054"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.8319 14.8299L9.17188 9.16992"
      stroke="#344054"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
      stroke="#344054"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

type Props = {
  onModalClose: () => void;
  formType: "ADD_NEW" | "EDIT";
};

const AdminAddModal: FC<Props> = (props) => {
  return (
    <div className="overlay">
      <div
        className="modal"
        style={{ height: "auto", maxHeight: "1000px", overflowY: "unset" }}
      >
        <AdminAddForm
          onModalClose={props.onModalClose}
          formType={props.formType}
        />
      </div>
    </div>
  );
};

export default AdminAddModal;
