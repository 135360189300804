import React, { useEffect, useState } from "react";
import "./PdfViewerModal.scss";
import { CloseSquare, Deletion } from "../../assets/icons";
import { TailSpin } from "react-loader-spinner";
import FormButton from "../FormButton/FormButton";
import { message, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { backdropOverFlowHide } from "../../utils/backdropOverFlowHide";
import { scrollToTop } from "../../utils/scrollToTop";
import axios from "axios";
import api from "../../utils/api";
import { getAccessToken } from "../../utils/jwtToken";

import { AppDispatch } from "../../redux/store/store";
import PdfViewer from "../PdfViewer/PdfViewer";

const Option = Select.Option;

interface Props {
  handleToggleModal: () => void;
  s3Url: any;
}

interface PreferencesType {
  name: string;
  id: number;
}

const PdfViewerModal = (props: Props) => {
  const { handleToggleModal, s3Url } = props;
  // const talentId = useSelector(
  //   (state: any) => state.talent.dashboard.profile.id
  // );
  const talentPreferences = useSelector(
    (state: any) => state.talent.dashboard?.preferences?.role_type
  );
  const [selectValues, setSelectValues] = useState<number[]>([]);
  const [payloadRoleType, setPayloadRoleType] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (talentPreferences && payloadRoleType) {
      payloadRoleType.map((role: PreferencesType) => {
        if (talentPreferences.includes(role.name)) {
          setSelectValues((val) => [...val, role.id]);
        }
      });
    }
  }, [talentPreferences, payloadRoleType]);

  const dispatch = useDispatch<AppDispatch>();

  const handleOnChange = (event: any) => {
    setSelectValues(event);
  };

  const handleSubmit = () => {
    // setLoader(true);
    // const payload = {
    //   preferences: {
    //     talent: talentId,
    //     preferred_role_type: selectValues,
    //   },
    // };
    // axios
    //   .post(`${api}/talent/profile/update/`, payload, {
    //     headers: {
    //       Authorization: `Bearer ${getAccessToken()}`,
    //     },
    //   })
    //   .then((response) => {
    //     handleToggleModal();
    //     setLoader(false);
    //     dispatch(getTalentDashboard());
    //     message.success("Updated successfully");
    //   })
    //   .catch((error) => {
    //     handleToggleModal();
    //     setLoader(false);
    //     message.error("Error sending request");
    //   });
  };

  useEffect(() => {
    scrollToTop();
    backdropOverFlowHide();
    return () => {
      document.body.style.overflow = "";
    };
  });

  return (
    <div
      onClick={(e) => {
        if (e.currentTarget === e.target) {
        }
      }}
      className="pdfViewerModal__container"
    >
      <div className="pdfViewerModal">
        <CloseSquare
          onClick={handleToggleModal}
          className="pdfViewerModal_closeSquareLogo"
        />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <PdfViewer s3Url={s3Url} />
        </div>
        {/* <div>
          <FormButton
            disabled={selectValues.length === 0}
            onClick={handleSubmit}
            loading={loader}
            text={"Download"}
          />
        </div> */}
      </div>
    </div>
  );
};

export default PdfViewerModal;
