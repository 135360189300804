import React from "react";
import "./AdminMainDash.scss";
import AdminSideBar from "../../../components/AdminSideBar/AdminSideBar";
import { useParams } from "react-router-dom";
import CareerPath from "./AdminDashTabsComponents/CareerPath";
import Events from "./AdminDashTabsComponents/Events";
import AdminsPanel from "./AdminDashTabsComponents/AdminsPanel";
import UserManagement from "./AdminDashTabsComponents/UserManagement";
import MainDashboard from "./AdminDashTabsComponents/MainDashboard";
import NotFoundContainer from "../../NotFoundContainer/NotFoundContainer";
import PartnersManagement from "./AdminDashTabsComponents/PartnersManagement";
import CoachesManagement from "./AdminDashTabsComponents/CoachesManagement";
import Resources from "./AdminDashTabsComponents/Resources";

const dashboardTabsMapping = {
  dashboard: MainDashboard,
  "career-path": CareerPath,
  "user-management": UserManagement,
  events: Events,
  "admins-panel": AdminsPanel,
  "partners-management": PartnersManagement,
  "coaches-management": CoachesManagement,
  resources: Resources,
};

type DashboardTabParams = {
  dashboardTab:
    | "dashboard"
    | "career-path"
    | "user-management"
    | "events"
    | "admins-panel"
    | "partners-management"
    | "coaches-management"
    | "resources";
};

const AdminMainDash = () => {
  const { dashboardTab } = useParams<DashboardTabParams>();

  if (!dashboardTab) {
    return <NotFoundContainer admin={true} />;
  }

  const DashboardTabCompToRender = dashboardTabsMapping[dashboardTab];
  if (!DashboardTabCompToRender) {
    return <NotFoundContainer admin={true} />;
  }
  return (
    <div className="adminMainDash">
      <AdminSideBar />
      <DashboardTabCompToRender />
    </div>
  );
};

export default AdminMainDash;
