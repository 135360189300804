import React from "react";
import { PricingIcon } from "../../assets/icons";
import "./index.module.scss";

interface EmployerBillingCardProps {
  backgroundColor?: string;
  planName: string;
}

const AnnualPlanCard: React.FC<EmployerBillingCardProps> = ({
  backgroundColor = "#fff",
  planName,
}) => {
  const btnText = "Already Subscribed";

  const buttonClass =
    btnText === "Already Subscribed"
      ? "employerBilling__card-button subscribed"
      : "employerBilling__card-button";

  const isBtnEnabled = btnText === "Already Subscribed" ? true : false;

  function convertString(input: string) {
    const replacedString = input.replace(/-/g, " ");

    const capitalizedString = replacedString
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return capitalizedString;
  }

  return (
    <div
      className="employerBilling__card"
      style={{ backgroundColor, minWidth: "100%" }}
    >
      <PricingIcon />
      <div className="employerBilling__card-plan-name">
        {convertString(planName)}
      </div>

      <div className="employerBilling__card-description">
        If you have any query regarding subscription plan, contact us at
        support@tribaja.co
      </div>
      <button
        disabled={isBtnEnabled}
        className={buttonClass}
        // onClick={() => {
        //   if (buttonText === "Book a Demo") {
        //     window.location.href =
        //       "https://calendly.com/d/4fm-mkb-bg9/book-a-demo";
        //   }
        // }}
      >
        {btnText}
      </button>
    </div>
  );
};

export default AnnualPlanCard;
