import axios from "axios";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../utils/api";
import { getAccessToken } from "../../utils/jwtToken";

const Resource = () => {
  const { id, link } = useParams<{ id: string; link: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    if (link && id) redirectToProgram();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, link]);

  const redirectToProgram = async () => {
    try {
      const token = getAccessToken();
      await axios.post(
        `${api}/partners/resource/increment_click/`,
        {
          resource_id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (link) window.location.href = link;
    } catch (e) {
      navigate("/404");
    }
  };

  return <div></div>;
};

export default Resource;
