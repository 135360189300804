export const CopyinClipboard = (link: string) => {
  // Copy the link to the clipboard.
  navigator.clipboard
    .writeText(link)
    .then(() => {
      // Success message
    })
    .catch((err) => {
      // Error message
      console.error("Failed to copy: ", err);
    });

  return;
};
