import TalentProfile from "./TalentProfile";
import EmployerProfile from "./EmployerProfile";
import CoachProfile from "./CoachProfile";
import PartnerProfile from "./PartnerProfile";


export default {
  TalentProfile,
  EmployerProfile,
  CoachProfile,
  PartnerProfile, 
}