/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { RecommendedJob } from "../../components";
import axios from "axios";
import api from "../../utils/api";
import { getAccessToken } from "../../utils/jwtToken";
import { TailSpin } from "react-loader-spinner";
import { PaginationComp } from "../../components/PaginationComp/PaginationComp";

const Jobs = () => {
  const [jobs, setJobs] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchJobs = (page: number) => {
    setLoading(true);
    const accessToken = getAccessToken();
    axios
      .get(`${api}/main/list/jobs?page=${page}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(function (response) {
        setLoading(false);
        setJobs(response.data);
      })
      .catch(function (error) {
        setLoading(false); // Handle any errors
        console.error("Error:", error);
      });
  };

  const handlePagination = (p: any) => {
    fetchJobs(p);
  };

  useEffect(() => {
    fetchJobs(1);
  }, []);


  return (
    <AnimatePresence>
      {!loading ? (
        <motion.section className="viewAll__container">
          {jobs?.results?.length > 0 && (
            <div className="viewAll__recommendations-container">
              <div className="viewAll__recommendations-container-card">
                {jobs?.results?.map((job: any) => {
                  return (
                    <RecommendedJob
                      job={{
                        ...job,
                        style: { marginBottom: "40px" },
                      }}
                    />
                  );
                })}
              </div>
            </div>
          )}
          <PaginationComp
            count={jobs?.count}
            page={jobs?.page}
            onChange={handlePagination}
          />
        </motion.section>
      ) : (
        <TailSpin
          height="160"
          width="160"
          color="#FBAD84"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{
            justifySelf: "center",
            alignSelf: "center",
          }}
          wrapperClass="tail-spin"
          visible={true}
        />
      )}
    </AnimatePresence>
  );
};

export default Jobs;
