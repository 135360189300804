import React, { FC } from "react";
import { Increasing } from "../../assets/icons";

import "./EmployerAnalyticCard.scss";

interface EmployerAnalyticCardProps {
  title: string;
  icon: any;
  content: string;
  detail: string;
}

const EmployerAnalyticCard: FC<EmployerAnalyticCardProps> = ({
  title,
  icon,
  content,
  detail,
}) => {
  return (
    <div className="employerAnalyticCard">
      {icon}
      <div className="employerAnalyticCard__container">
        <span className="title">{title}</span>
        <div>{content}</div>
        <span className="detail">
          {/* <Increasing /> */}
          {detail}
        </span>
      </div>
    </div>
  );
};

export default EmployerAnalyticCard;
