import { Dropdown, MenuProps, Pagination, Select, Tag } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../redux/store/store";
import { Delete } from "../../../../assets/icons";
import { AnimatePresence, motion } from "framer-motion";
import SearchBar from "../../../../components/DashboardSearchbar/SearchBar";
import { ReactComponent as MenuIcon } from "../../../../assets/icons/menuIcon.svg";
import { TailSpin } from "react-loader-spinner";
import {
  changePartnerStatus,
  deletePartner,
  getAllPartners,
  getPartners,
} from "../../../../redux/AdminSlice/AdminSlice";
import DashboardNavbar from "../../../../components/DashboardNavbar/DashboardNavbar";
import AdminDeleteModal from "./AdminDeleteModal";
import { debounce } from "debounce";

const Option = Select.Option;
const PartnersManagement = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [allPartners, setAllPartners] = useState<any>(false);
  const [openDelModal, setDelModalOpen] = useState<string>("");

  const [search, setSearch] = useState<string>("");

  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 700);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 700);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const dispatch = useDispatch<AppDispatch>();

  const { partners } = useSelector((state: any) => state.admin);

  const tableRef = React.useRef<HTMLDivElement>(null);

  // LifeCylce events
  useEffect(() => {
    dispatch(getPartners(page)).then(() => {
      setLoading(false);
    });
  }, [page]);

  const getItems = (id: string): MenuProps["items"] => [
    {
      icon: <Delete />,
      key: "1",
      label: "Delete Partner?",
      onClick: () => {
        setDelModalOpen(id);
      },
    },
  ];

  useEffect(() => {
    if (allPartners) {
      setPage(1);
      dispatch(getAllPartners({ page, search })).then(() => {
        setLoading(false);
      });
    } else {
      setPage(1);
      dispatch(getPartners(1)).then(() => {
        setLoading(false);
      });
    }
  }, [allPartners]);

  const statusColor: any = {
    accepted: "#31BE00",
    pending: "#FFA800",
    rejected: "#EE1D52",
  };

  const debouncedDispatch = useCallback(
    debounce((searchValue: any) => {
      dispatch(getAllPartners({ page, search: searchValue }));
    }, 800),
    [dispatch, page], // Dependencies
  );
  return (
    <>
      {Boolean(openDelModal) && (
        <AdminDeleteModal
          onSubmit={() => {
            dispatch(deletePartner(openDelModal)).then(() => {
              dispatch(getAllPartners({ page, search }));
            });
            setDelModalOpen("");
          }}
          onModalClose={() => setDelModalOpen("")}
        />
      )}
      <AnimatePresence>
        {!loading ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="main__dashboard-box"
          >
            <DashboardNavbar addLogo={isMobileView} />
            <main className="main__dashboard">
              <motion.div
                key={"dashboard-heading"}
                transition={{ duration: 0.3, delay: 0.3 }}
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                className="main__dashboard-heading"
              >
                <h1>Partners Management</h1>
              </motion.div>
              <motion.section
                transition={{ duration: 0.3, delay: 0.4 }}
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                className="main__dashboard-container"
              >
                {/* Search Section */}
                <div
                  id="main__analytics--admin"
                  className="main__dashboard-container-analytics--admin"
                >
                  <div
                    className="main__dashboard-container-analytics"
                    style={{ marginTop: "20px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "70%",
                      }}
                      className="searchSection"
                    >
                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                          color: "#1E1E2F",
                          marginBottom: "10px",
                        }}
                      >
                        Search
                      </div>
                      <SearchBar
                        debouncedDispatch={debouncedDispatch}
                        disabled={!allPartners}
                        value={search}
                        page={page}
                        setSearch={setSearch}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "20%",
                      }}
                      className="filterSection"
                    >
                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                          color: "#1E1E2F",
                          marginBottom: "10px",
                        }}
                      >
                        {" "}
                        Filter{" "}
                      </div>
                      <Select
                        optionFilterProp="label"
                        showSearch={true}
                        onChange={(e) =>
                          e === "Pending"
                            ? setAllPartners(false)
                            : setAllPartners(true)
                        }
                        className="select"
                        placeholder="Select Status"
                        defaultValue="Pending"
                      >
                        {["Pending", "All"].map((state) => (
                          <Option
                            label={state}
                            value={state}
                            className="roleType__option"
                          >
                            {state}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>
                <div className="main__dashboard-recent-activities--admin">
                  <div
                    className="main__dashboard-recent-activities"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      overflow: "auto",
                      maxHeight: "600px",
                      scrollBehavior: "smooth",
                    }}
                    ref={tableRef}
                  >
                    {/* Header First Row */}
                    <div
                      style={{
                        fontSize: "13px",
                        fontWeight: "700",
                        color: "#344054",
                        display: "flex",
                        gap: 16,
                        minWidth: 800,
                        borderBottom: "1px solid #aaa",
                        paddingBottom: "8px",
                      }}
                    >
                      <div style={{ width: "100%", flex: 1 }}>
                        {" "}
                        Partner Name{" "}
                      </div>
                      <div style={{ width: "100%", flex: 1 }}> Email </div>
                      <div style={{ width: "100%", flex: 1 }}>
                        {" "}
                        Company name{" "}
                      </div>
                      <div style={{ flex: 1, width: "100%" }}>
                        {" "}
                        {allPartners ? "Status" : ""}{" "}
                      </div>
                    </div>

                    {/* <hr style={{ marginTop: "8px" }} /> */}

                    {partners.results.map((user: any, index: number) => (
                      <>
                        <div
                          style={{
                            fontSize: "12px",
                            fontWeight: "700",
                            color: "#344054",
                            display: "flex",
                            marginTop: "20px",
                            gap: 16,
                            minWidth: 800,
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              flex: 1,
                              fontWeight: "400",
                              fontSize: "12px",
                              lineHeight: "12.67px",
                              color: "#5937CC",
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            {user.first_name} {user.last_name}
                          </div>
                          <div
                            style={{
                              width: "100%",
                              flex: 1,
                              fontWeight: "400",
                              fontSize: "12px",
                              lineHeight: "12.67px",
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            {" "}
                            {user.email}
                          </div>
                          <div
                            style={{
                              width: "100%",
                              flex: 1,
                              fontWeight: "400",
                              fontSize: "12px",
                              lineHeight: "12.67px",
                              alignItems: "center",
                              display: "flex",
                              textTransform: "capitalize",
                            }}
                          >
                            {" "}
                            {user?.demographics?.company_name ?? "-"}
                          </div>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "end",
                              flex: 1,
                            }}
                          >
                            <div
                              style={{
                                marginLeft: `${!allPartners ? "auto" : 0}`,
                              }}
                            >
                              {" "}
                              {!allPartners ? (
                                <>
                                  <Tag
                                    onClick={() =>
                                      dispatch(
                                        changePartnerStatus({
                                          id: user.id,
                                          status: "rejected",
                                        }),
                                      ).then(() => dispatch(getPartners(page)))
                                    }
                                    color={"#EE1D52"}
                                    style={{
                                      width: "62px",
                                      height: "22px",
                                      borderRadius: "22px",
                                      color: "#FAF9FF",
                                      fontSize: "10px",
                                      fontWeight: "400",
                                      textAlign: "center",
                                      cursor: "pointer",
                                    }}
                                  >
                                    Reject
                                  </Tag>
                                  <Tag
                                    onClick={() =>
                                      dispatch(
                                        changePartnerStatus({
                                          id: user.id,
                                          status: "approved",
                                        }),
                                      ).then(() => dispatch(getPartners(page)))
                                    }
                                    color={"#31BE00"}
                                    style={{
                                      width: "62px",
                                      height: "22px",
                                      borderRadius: "22px",
                                      color: "#FAF9FF",
                                      fontSize: "10px",
                                      fontWeight: "400",
                                      textAlign: "center",
                                      cursor: "pointer",
                                    }}
                                  >
                                    Approve
                                  </Tag>
                                </>
                              ) : (
                                <Tag
                                  color={`${
                                    user.verification_status ===
                                    "pending-approval"
                                      ? "#FFA800"
                                      : user.verification_status === "approved"
                                        ? "#31BE00"
                                        : "#EE1D52"
                                  }`}
                                  style={{
                                    height: "22px",
                                    borderRadius: "22px",
                                    color: "#FAF9FF",
                                    fontSize: "10px",
                                    fontWeight: "400",
                                    textAlign: "center",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {user.verification_status ===
                                  "pending-approval"
                                    ? "Pending Approval"
                                    : user.verification_status === "approved"
                                      ? "Approved"
                                      : "Rejected"}
                                </Tag>
                              )}
                            </div>
                            {allPartners && (
                              <div
                                style={{
                                  marginLeft: "auto",
                                }}
                              >
                                <Dropdown
                                  menu={{ items: getItems(user.id) }}
                                  placement="bottomLeft"
                                  trigger={["click"]}
                                >
                                  <MenuIcon style={{ cursor: "pointer" }} />
                                </Dropdown>
                              </div>
                            )}
                          </div>
                        </div>

                        {index < partners.length - 1 && (
                          <hr style={{ marginTop: "20px" }} />
                        )}
                      </>
                    ))}
                  </div>
                </div>
                <Pagination
                  style={{
                    marginInline: "auto",
                    marginTop: "36px",
                  }}
                  current={partners.page}
                  total={partners.count}
                  pageSize={partners.page_size}
                  showSizeChanger={false}
                  onChange={(e) => {
                    setPage(e);
                    if (tableRef.current) {
                      tableRef.current.scrollTo(0, 0);
                    }
                  }}
                />
              </motion.section>
            </main>
          </motion.div>
        ) : (
          <div className="loader-container">
            <TailSpin
              height="160"
              width="160"
              color="#FBAD84"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{
                justifySelf: "center",
                alignSelf: "center",
              }}
              wrapperClass="tail-spin"
              visible={true}
            />
          </div>
        )}
      </AnimatePresence>
    </>
  );
};

export default PartnersManagement;
