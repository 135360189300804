import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getAccessToken } from "../../utils/jwtToken";
import api from "../../utils/api";
import { message } from "antd";

export const getPartnerDashboard = createAsyncThunk(
  "partner/getPartnerDashboard",
  async (_, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.get(`${api}/partners/dashboard/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);
export const updatePartnerProfile = createAsyncThunk(
  "partner/updatePartnerProfile",
  async (profilePayload: any, { rejectWithValue, dispatch }) => {
    console.log("payload>>>", profilePayload);

    const { userId } = profilePayload;
    try {
      const accessToken = getAccessToken();
      const response = await axios.put(
        `${api}/partners/profile/manage/`,
        profilePayload.payload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      // return response.data;
      message.success("Profile updated successfully");
      dispatch(getPartnerResource(userId));
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      message.error("Profile update failed");
      return rejectWithValue(err.response.data);
    }
  }
);



export const uploadPartnerLogo = createAsyncThunk(
  "partner/uploadPartnerLogo",
  async (_, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.post(
        `${api}/partners/file/upload/`,
        { file_type: "logo" },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const getPartnerLogo = createAsyncThunk(
  "partner/getPartnerLogo",
  async (id: any, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.get(`${api}/partners/file/logo/${id}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const addPartnerResource = createAsyncThunk(
  "partner/getPartnerDashboard",
  async (data: any, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.post(
        `${api}/partners/resource/upload/`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const getPartnerResourceTypes = createAsyncThunk(
  "partner/getPartnerResourceTypes",
  async (_, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.get(`${api}/shared/resource-type/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const getPartnerResource = createAsyncThunk(
  "partner/getPartnerResource",
  async (id: string, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.get(`${api}/partners/resource/${id}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  dashboard: null,
  profileInfo: null,
};

export const partnerSlice = createSlice({
  name: "partner",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPartnerDashboard.fulfilled, (state: any, action: any) => {
        state.dashboard = action.payload;
      })
      .addCase(getPartnerDashboard.rejected, (state: any, action: any) => {
        return action.payload;
      });

    builder
      .addCase(getPartnerResource.fulfilled, (state: any, action: any) => {
        return action.payload;
      })
      .addCase(getPartnerResource.rejected, (state: any, action: any) => {
        return action.payload;
      });
   
  },
});

// Action creators are generated for each case reducer function
// eslint-disable-next-line no-empty-pattern
export const {} = partnerSlice.actions;

export default partnerSlice.reducer;
