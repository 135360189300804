import { FC, useEffect, useState } from "react";
import { Select, Input, message } from "antd";
import { FormButton } from "../../../../components";
import { ReactComponent as SuccessTick } from "../../../../assets/icons/successTick.svg";
import "../../../ProfileScreen/Modals/EditProfileModal.scss";
import axios from "axios";
import api from "../../../../utils/api";
import { getAccessToken } from "../../../../utils/jwtToken";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store/store";
import { getEmployerAnnualPlans } from "../../../../redux/EmployerSlice/EmployerSlice";

type Props = {
  onModalClose: () => void;
};
type Plan = {
  id: number;
  type: string;
};
const AdminNewAccountModal: FC<Props> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const [plans, setPlans] = useState<Plan[]>([]); // Use the Plan type here

  useEffect(() => {
    dispatch(getEmployerAnnualPlans()).then((data: any) => {
      const formattedPlans = data.payload.map((plan: any) => ({
        id: plan.id,
        type: formatPlanType(plan.type),
      }));
      setPlans(formattedPlans);
    });
  }, [dispatch]);
  const formatPlanType = (type: string) => {
    return type
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const [submittedSuccessfully, setSubmittedSuccessfully] =
    useState<boolean>(false);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    role: "",
    password: "",
    plan_id: null,
  });

  const onSubmit = (event: any) => {
    event.preventDefault();
    if (formData.password.length < 6) {
      message.error("Password must be more than 6 characters");
      return;
    }
    setLoading(true);
    const accessToken = getAccessToken();
    axios
      .post(`${api}/main/admin/create-user/`, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        setLoading(false);
        setSubmittedSuccessfully(true);
        message.success("Account created successfully");
      })
      .catch((error) => {
        setLoading(false);
        message.error("User with this email already exists.");
      });
  };

  const CrossSvg = () => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.17188 14.8299L14.8319 9.16992"
        stroke="#344054"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8319 14.8299L9.17188 9.16992"
        stroke="#344054"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
        stroke="#344054"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (value: string) => {
    setFormData((prev) => ({ ...prev, role: value }));
  };

  const handlePlanChange = (value: any) => {
    setFormData((prev) => ({ ...prev, plan_id: value }));
  };
  //const options PLans
  // map subscription plan
  // return option. ids and labels
  //
  return (
    <>
      <div className="overlay">
        <div
          className="modal"
          style={{
            height: "auto",
            maxHeight: "1000px",
            overflowY: "unset",
            maxWidth: "600px",
            width: "100%",
          }}
        >
          {submittedSuccessfully ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  width: "100%",
                  cursor: "pointer",
                }}
                onClick={props.onModalClose}
              >
                <CrossSvg />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "80px 40px",
                }}
              >
                <div>
                  <SuccessTick />
                </div>

                <div
                  style={{
                    fontWeight: 700,
                    fontSize: "40px",
                    color: "#1E1E2F",
                    marginTop: "40px",
                  }}
                >
                  Account created successfully
                </div>
              </div>
            </>
          ) : (
            <div className="editProfile__container">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h2
                  style={{
                    textAlign: "left",
                    marginTop: "10px",
                    fontWeight: 700,
                    fontSize: "40px",
                    color: "#1E1E2F",
                  }}
                >
                  Create New Account
                </h2>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    props.onModalClose();
                  }}
                >
                  <CrossSvg />
                </div>
              </div>
              <hr
                style={{
                  color: "#999999",
                  marginTop: "40px",
                  marginBottom: "20px",
                }}
              />

              <div className="editProfile__container">
                <form onSubmit={onSubmit} style={{ gap: "0px" }}>
                  <div className="editProfile__inputContainer">
                    <label
                      className="editProfile__label"
                      style={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                        display: "block",
                        textAlign: "left",
                      }}
                    >
                      First Name
                    </label>
                    <Input
                      name="first_name"
                      value={formData.first_name}
                      onChange={handleChange}
                      style={{
                        width: "100%",
                        marginBottom: "20px",
                        borderRadius: "5px",
                        height: "40px",
                      }}
                    />
                  </div>
                  <div className="editProfile__inputContainer">
                    <label
                      className="editProfile__label"
                      style={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                        display: "block",
                        textAlign: "left",
                      }}
                    >
                      Last Name
                    </label>
                    <Input
                      name="last_name"
                      value={formData.last_name}
                      onChange={handleChange}
                      style={{
                        width: "100%",
                        marginBottom: "20px",
                        borderRadius: "5px",
                        height: "40px",
                      }}
                    />
                  </div>
                  <div className="editProfile__inputContainer">
                    <label
                      className="editProfile__label"
                      style={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                        display: "block",
                        textAlign: "left",
                      }}
                    >
                      Email
                    </label>
                    <Input
                      name="email"
                      type="email"
                      value={formData.email}
                      onChange={handleChange}
                      style={{
                        width: "100%",
                        marginBottom: "20px",
                        borderRadius: "5px",
                        height: "40px",
                      }}
                    />
                  </div>
                  <div className="editProfile__inputContainer">
                    <label
                      className="editProfile__label"
                      style={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                        display: "block",
                        textAlign: "left",
                      }}
                    >
                      Role
                    </label>
                    <Select
                      defaultValue="Select Role"
                      style={{
                        width: "100%",
                        marginBottom: "20px",
                        borderRadius: "5px",
                        height: "40px",
                        textAlign: "left",
                      }}
                      onChange={handleSelectChange}
                    >
                      <Select.Option value="talent">Talent</Select.Option>
                      <Select.Option value="employer">Employer</Select.Option>
                    </Select>
                  </div>
                  {formData.role === "employer" && (
                    <div className="editProfile__inputContainer">
                      <label
                        className="editProfile__label"
                        style={{
                          fontWeight: "bold",
                          marginBottom: "10px",
                          display: "block",
                          textAlign: "left",
                        }}
                      >
                        Select annual plan
                      </label>
                      <Select
                        defaultValue="Select Plan"
                        style={{
                          width: "100%",
                          marginBottom: "20px",
                          borderRadius: "5px",
                          height: "40px",
                          textAlign: "left",
                        }}
                        onChange={handlePlanChange}
                      >
                        {/* <Select.Option value="start">
                          Start Annual
                        </Select.Option>
                        <Select.Option value={43}>Grow Annual</Select.Option>
                        <Select.Option value="scale">
                          Scale Annual
                        </Select.Option> */}
                        {plans.map((plan) => (
                          <Select.Option key={plan.id} value={plan.id}>
                            {plan.type}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                  )}

                  <div className="editProfile__inputContainer">
                    <label
                      className="editProfile__label"
                      style={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                        display: "block",
                        textAlign: "left",
                      }}
                    >
                      Password
                    </label>
                    <Input.Password
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      style={{
                        width: "100%",
                        marginBottom: "20px",
                        borderRadius: "5px",
                        height: "40px",
                      }}
                    />
                  </div>
                  <FormButton
                    disabled={
                      !formData.first_name ||
                      !formData.last_name ||
                      !formData.email ||
                      !formData.role ||
                      formData.password.length < 6
                    }
                    text="Create Account"
                    loading={loading}
                  />
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AdminNewAccountModal;
