import { FC, useEffect, useState } from "react";
import { Button, Select, message } from "antd";
import { useForm, SubmitHandler } from "react-hook-form";
import { FormButton } from "../../../../components";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../redux/store/store";
import { useNavigate } from "react-router-dom";
import { Avatar } from "antd";
import MentorImage from "../../../../assets/images/mentor.png";
import axios from "axios";
import { getAccessToken } from "../../../../utils/jwtToken";
import api from "../../../../utils/api";
import countries from "../../../../utils/countriesList";

// import { AntDesignOutlined, UserOutlined } from '@ant-design/icons';
import {
  updateTalentProfile,
  getTalentProfilePic,
  getTalentDashboard,
  getTalentWorkLocations,
} from "../../../../redux/TalentSlice/TalentSlice";
import { Modal, Upload } from "antd";
import type { RcFile, UploadProps } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";
import { IState, State, Country, ICountry } from "country-state-city";

import "../EditProfileModal.scss";
import { Delete } from "../../../../assets/icons";
import { UploadOutlined } from "@ant-design/icons";
import styled from "styled-components";
import {
  getPartnerDashboard,
  updatePartnerProfile,
} from "../../../../redux/PartnerSlice/PartnerSlice";
import PartnerDashboard from "../../../PartnerDashboard/PartnerDashboard";

const Option = Select.Option;

const DEFAULT_ETHNICITY = "Christian";

interface SignUpInputs {
  company_name: string;
  company_description: string;
  skill_training: any;
  website: string;
  linkedinurl: string;
  twitterurl: string;
  pace_of_program: string[];
  phoneNumber: Number;
  genders: string;
  profession: string;
}

interface PasswordHidden {
  one: boolean;
  two: boolean;
}

type Props = {
  onModalClose?: () => void;
  handleDeleteAccount?: () => void;
  setUpdatePartnerProfile?: (data: any) => void | undefined;
};

interface StateOption {
  label: string;
  value: string;
}
const CustomStyledSelect = styled(Select)`
  .ant-select-arrow .anticon {
    margin-bottom: 18px !important;
  }
`;

const refineObject = (obj: any) => {
  const unwrapped: any = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      // Check if the value is an object with a 'value' key
      if (
        typeof obj[key] === "object" &&
        obj[key] !== null &&
        "value" in obj[key]
      ) {
        unwrapped[key] = obj[key].value; // Unwrap the value
      } else {
        unwrapped[key] = obj[key]; // Leave it as is
      }
    }
  }
  return unwrapped;
};

const PartnerCompanyProfile: FC<Props> = (props) => {
  const talentProfile = useSelector((state: any) => state.talent.profileInfo);
  //   const partnerProfile = useSelector((state: any) => state.partner.profileInfo);
  const talentDashboard = useSelector((state: any) => state.talent.dashboard);
  // const partnerId = partnerProfile?.id;
  const [errorMessage, setErrorMessage] = useState<string>();
  const [sharedFields, setSharedFields] = useState<any>();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [s3UploadUrl, setS3UploadUrl] = useState<string>("");
  const [key, setKey] = useState<string>("");
  const [fileId, setFileId] = useState<string>("");
  const dispatch = useDispatch<AppDispatch>();
  const [resume, setResume] = useState<any>(null);
  const [states, setStates] = useState<StateOption[]>([]);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [workLocations, setWorkLocations] = useState<any>([]);
  const [selectedWorkLocations, setSelectedWorkLocations] = useState<any>([]);
  const [fileExtension, setFileExtension] = useState<any>(null);
  const [selectedSkills, setSelectedSkills] = useState<string[]>([]);
  const [partnerProfile, setPartnerProfile] = useState<any | undefined>(
    undefined
  );
  const [selectedProgram, setSelectedProgram] = useState([]);

  const [programs] = useState([
    {
      value: "part-time",
      label: "Part Time",
    },
    {
      value: "full-time",
      label: "Full Time",
    },
    {
      value: "self-paced",
      label: "Self Paced",
    },
    {
      value: "other",
      label: "Other",
    },
  ]);
  const handleSelectChange = (selectedValues: any) => {
    setFormFields((prevFields: any) => ({
      ...prevFields,
      pace_of_program: { value: selectedValues },
    }));
  };
  useEffect(() => {
    const allCountries = Country.getAllCountries();
    setCountries(allCountries);
  }, []);

  useEffect(() => {
    dispatch(getTalentWorkLocations()).then((locations) => {
      setWorkLocations(locations.payload);
    });
  }, []);

  useEffect(() => {
    const getIdsByName = (data: any, names: any) => {
      const nameSet = new Set(names); // Convert array to Set for faster lookup
      return data
        .filter((item: any) => nameSet.has(item.name))
        .map((item: any) => item.id);
    };

    if (workLocations.length > 0 && talentProfile)
      setSelectedWorkLocations(
        getIdsByName(workLocations, talentProfile.preferences.work_mode)
      );
  }, [talentProfile, workLocations]);
  useEffect(() => {
    // setLoading(true);
    const accessToken = getAccessToken();
    axios
      .get(`${api}/partners/profile/manage/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(function (response) {
        setPartnerProfile(response.data);
      })
      .catch(function (error) {
        console.error("Error:", error);
      });
  }, []);
  const partnerId = partnerProfile;
  const handleDeleteAccount = props.handleDeleteAccount;

  const initializeFormFieldsFromProfile = (partnerProfile: any) => {
    const _formFields: any = {};

    _formFields.company_name = { value: partnerProfile?.company_name };
    _formFields.company_description = {
      value: partnerProfile?.company_description,
    };
    _formFields.payment_options = {
      value: talentProfile?.demographics?.[0]?.toLowerCase()?.includes("she")
        ? "Female"
        : "Male",
    };
    _formFields.skill_training = { value: partnerProfile?.skill_training?.[0] };

    _formFields.linkedinurl = { value: partnerProfile?.linkedinurl };
    _formFields.twitterurl = { value: partnerProfile?.twitterurl };
    _formFields.website = { value: partnerProfile?.website };
    // _formFields.pace_of_program = { value: partnerProfile?.pace_of_program };
    _formFields.pace_of_program = {
      value: partnerProfile?.pace_of_program || [],
    };

    // setFormFields(_formFields);
    return _formFields;
  };
  // const [formFields, setFormFields] = useState<any>();
  const [formFields, setFormFields] = useState<any>({
    company_name: { value: "" },
    company_description: { value: "" },
    skill_training: { value: [] },
    website: { value: "" },
    linkedinurl: { value: "" },
    twitterurl: { value: "" },
    pace_of_program: { value: [] },
  });
  const { register, handleSubmit } = useForm<SignUpInputs>();

  const onSubmit: SubmitHandler<SignUpInputs> = (data) => {
    let payload = { ...partnerProfile };
    const refinedData = refineObject(formFields);

    console.log("Selected Skills:", selectedSkills);

    setLoading(true);
    payload = { ...refinedData, skill_training: selectedSkills };

    dispatch(updatePartnerProfile({ userId: partnerId, payload }))
      .then(() => {
        console.log("Profile updated successfully.");

        axios
          .get(`${api}/partners/profile/manage/`, {
            headers: {
              Authorization: `Bearer ${getAccessToken()}`,
            },
          })
          .then(function (response) {
            setPartnerProfile(response.data);
            if (props.setUpdatePartnerProfile !== undefined)
              props.setUpdatePartnerProfile(response.data);
            console.log("Profile data fetched again:", response.data);

            dispatch(getPartnerDashboard())
              .then(() => {
                console.log("Dashboard data updated successfully.");
              })
              .catch((error) => {
                console.error("Error updating dashboard:", error);
              });
          })
          .catch(function (error) {
            console.error("Error fetching profile:", error);
          })
          .finally(() => {
            setLoading(false);
            props.onModalClose?.();
          });

        // if (resume) {
        //   getUploadUrlPromise("resume").then((res: any) => {
        //     const url = res.data.s3_params.url;
        //     const formData = new FormData();

        //     Object.keys(res.data.s3_params.fields).forEach((key: any) => {
        //       formData.append(key, res.data.s3_params.fields[key]);
        //     });

        //     // Append the file
        //     formData.append("file", resume[0].originFileObj);

        //     // Use fetch or axios to send a POST request to the S3 bucket
        //     fetch(url, {
        //       method: "POST",
        //       body: formData,
        //       headers: new Headers({
        //         "Content-Disposition": `attachment; filename="${resume[0].name}"`,
        //       }),
        //     })
        //       .then((response) => {
        //         const fileId = res.data.file_id;
        //         console.log("fileId>>>>>", fileId, res.data);
        //         axios
        //           .post(
        //             `${api}/partners/file/upload/confirm/?${fileId}`,
        //             {},
        //             {
        //               headers: {
        //                 Authorization: `Bearer ${getAccessToken()}`,
        //               },
        //             }
        //           )
        //           .then(() => {
        //             console.log("File uploaded and confirmed.");
        //           })
        //           .catch((error) => {
        //             console.error("Error confirming file upload:", error);
        //           });
        //       })
        //       .catch((error) => {
        //         console.error("Error uploading file:", error);
        //       });
        //   });
        // }
      })
      .catch((error) => {
        console.error("Error updating profile:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    const accessToken = getAccessToken();

    if (!partnerProfile) return;

    setFormFields(initializeFormFieldsFromProfile(partnerProfile));

    const internalFunc = async () => {
      const sharedFieldsValues: any = {};
      const promises: any = [];
      ["skill_training"].forEach(async () => {
        promises.push(
          axios.get(`${api}/shared/skill`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
        );
      });
      let allData = await Promise.all(promises);
      allData = allData.map((allData) => allData.data);

      setSharedFields({
        skill_trainings: allData[0],
      });
      const defaultSkills =
        partnerProfile?.skill_training.map((skill: string) => {
          const matchedSkill = allData[0].find(
            (ind: any) => ind.skill === skill
          );
          return matchedSkill?.id;
        }) || [];
      setSelectedSkills(defaultSkills);
    };
    internalFunc();
    getUploadUrlPromise("logo").then((response) => {
      const data = response.data;

      const fileId = data?.file_id;
      setFileId(fileId);
      const uploadUrl = data?.s3_params?.url;
      const key = data?.s3_params?.fields?.key;

      setKey(key);

      setS3UploadUrl(uploadUrl);
    });
  }, [partnerProfile]);

  const getUploadUrlPromise = (fileType: "logo" | "resume") =>
    axios.post(
      `${api}/partners/file/upload/`,
      {
        file_type: fileType,
      },
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    );

  const onFieldChange = (fieldKey: string, value: any) => {
    if (!value) return;
    setFormFields({ ...formFields, [fieldKey]: value });
    if (fieldKey === "skill_training") {
      setSelectedSkills(value);
    }
  };

  const handleChange: UploadProps["onChange"] = ({
    fileList: newFileList,
    file,
    event,
  }) => {
    const fileSize = file.size;
    if (fileSize && fileSize / 1024 / 1024 >= 2) {
      message.error("File must be smaller than 2MB!");
      return;
    }

    setFileList(newFileList);

    // debugger;

    const formData = new FormData();
    formData.append("key", key);
    fileList.forEach((file) => {
      formData.append("file", file as RcFile);
    });

    // debugger;

    fetch(s3UploadUrl, {
      method: "POST",
      body: formData,
    })
      .then(() => {
        // debugger;

        axios
          .post(
            `${api}/partners/file/confirm/${fileId}/`,
            {},
            { headers: { Authorization: `Bearer ${getAccessToken()}` } }
          )
          .then(() => {
            // message.success("Updated Image successfully");
            // dispatch(getTalentProfilePic(partnerId));
          });
        // })
      })
      .catch(() => {
        message.error("Upload failed.");
      })
      .finally(() => {});
  };

  const _props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList,
  };

  const resumeProps: UploadProps = {
    name: "file",
    action: `${api}/main/check/`,
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      setResume(info.fileList);
    },
  };

  const uploadButton = (
    <div
      style={{
        marginLeft: "10px",
        marginTop: "20px",
        fontWeight: 600,
        fontSize: "16px",
        fontFamily: "Space Grotesk, sans-serif",
        color: "#5937CC",
        cursor: "pointer",
      }}
      onClick={() => {
        const inputElements = document.getElementsByTagName("input");
        for (var i = 0; i < inputElements.length; i++) {
          var node = inputElements[i];

          if (node.getAttribute("type") === "file") {
            node.click();
            return;
          }
        }
      }}
    >
      Update Logo
      <div
        style={{
          fontWeight: 400,
          fontSize: "12px",
          color: "#344054",
          fontFamily: "Space Grotesk, sans-serif",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <span>Make sure the file is below 2mb</span>
      </div>
    </div>
  );

  const getIdFromName = (name: any) => {
    let field: any = null;

    Object.entries(sharedFields ?? {}).forEach((k: any, v: any) => {
      if (field) return;
      field = k[1].find((l: any) => l?.name?.includes(name));
      if (field) return;
    });

    if (field) {
      return field.id;
    }
    return null;
  };

  const getIdFromNameEthinicity = (names: string[]) => {
    let ids: number[] = [];
    // Assuming sharedFields is accessible in this scope
    Object.entries(sharedFields ?? {}).forEach(([_, values]: any) => {
      names?.forEach((name) => {
        const foundItem = values.find((item: any) => item.name === name);
        if (foundItem) {
          ids.push(foundItem.id);
        }
      });
    });

    return ids;
  };

  // function to handle country change
  const handleCountryChange = (countryName: string) => {
    // Update the formFields state with the selected country
    onFieldChange("country", countryName);

    // Find the country object by name
    const country = countries.find((c) => c.name === countryName);

    if (!country) {
      console.error("No country found for name:", countryName);
      setStates([]);
      return;
    }

    // Use the ISO code of the selected country to fetch states
    const countryCode = country.isoCode;

    try {
      const statesArray: IState[] = State.getStatesOfCountry(countryCode);

      const formattedStates: StateOption[] = statesArray.map((state) => ({
        label: state.name,
        value: state.name,
      }));

      setStates(formattedStates);
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };
  return (
    <div className="editProfile__container">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            justifyContent: !(fileList?.length || talentProfile?.s3_params)
              ? "center"
              : "unset",
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            document.getElementById("fileUpload")?.click();
          }}
        >
          {talentProfile?.s3_params && !fileList?.length && (
            <Avatar
              style={{
                width: "74px",
                height: "74px",
                border: "1px solid",
                marginTop: "10px",
              }}
              src={talentProfile?.s3_params}
            />
          )}

          <Upload
            action={s3UploadUrl}
            listType="picture-circle"
            fileList={fileList}
            beforeUpload={(file) => {
              const isLt2M = file.size / 1024 / 1024 < 2;
              if (!isLt2M) {
                // message.error("Image must be smaller than 2MB!");
                return false;
              }
              setFileList([file]);
              return false;
            }}
            onChange={handleChange}
            accept=".jpg, .jpeg, .png"
          ></Upload>
          {uploadButton}
        </div>
        <label>
          <span>Name *</span>
          <input
            {...register("company_name")}
            onChange={(e) => onFieldChange("company_name", e.target.value)}
            defaultValue={formFields?.["company_name"]?.value}
            type="text"
            required
          />
        </label>
        <label>
          <span>Description *</span>
          <input
            required
            {...register("company_description")}
            onChange={(e) =>
              onFieldChange("company_description", e.target.value)
            }
            defaultValue={formFields?.["company_description"]?.value}
            type="text"
          />
        </label>
        <label>
          <span>Pace of Program</span>
          <Select
            optionFilterProp="children"
            showSearch={true}
            value={formFields.pace_of_program?.value || []} // Ensure value is always an array
            onChange={handleSelectChange}
            className="select"
          >
            {programs.map((program) => (
              <Option key={program.value} value={program.value}>
                {program.label}
              </Option>
            ))}
          </Select>
        </label>

        <label>
          <span>Skill Training</span>
          <Select
            optionFilterProp="label"
            showSearch={true}
            key={selectedSkills?.[0]}
            defaultValue={selectedSkills}
            onChange={(value) => onFieldChange("skill_training", value)}
            className="select"
            mode="multiple"
          >
            {sharedFields?.["skill_trainings"]?.map((data: any) => (
              <Option
                label={data.skill}
                value={data.id}
                className="roleType__option"
                key={data.id}
              >
                {data.skill}
              </Option>
            ))}
          </Select>
        </label>
        <label>
          <span>Linkedin</span>
          <input
            {...register("linkedinurl")}
            onChange={(e) => onFieldChange("linkedinurl", e.target.value)}
            defaultValue={formFields?.["linkedinurl"]?.value || null}
            type="text"
          />
        </label>
        <label>
          <span>Twitter</span>
          <input
            {...register("twitterurl")}
            onChange={(e) => onFieldChange("twitterurl", e.target.value)}
            defaultValue={formFields?.["twitterurl"]?.value || null}
            type="text"
          />
        </label>
        <label>
          <span>Other</span>
          <input
            {...register("website")}
            onChange={(e) => onFieldChange("website", e.target.value)}
            defaultValue={formFields?.["website"]?.value || null}
            type="text"
          />
        </label>
        {errorMessage && <span className="errorMessage">{errorMessage}</span>}
        <FormButton text="Submit" loading={loading} />
      </form>
    </div>
  );
};

export default PartnerCompanyProfile;
