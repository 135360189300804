import React, { useCallback, useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";

import {
  getProfile,
  updateProfileState,
} from "./redux/ProfileSlice/ProfileSlice";
import { getTalentDashboard } from "./redux/TalentSlice/TalentSlice";
import { getEmployerDashboard } from "./redux/EmployerSlice/EmployerSlice";

import DashboardSidebar from "./components/DashboardSidebar/DashboardSidebar";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import { PartnerRequest } from "./components/PartnerRequest/PartnerRequest";

import {
  AdminMainDash,
  EmployerDashboard,
  TalentDashboard,
  PartnerDashboard,
  OnboardingScreen,
  SignUpScreen,
  LoginScreen,
  AdminLoginScreen,
  ResetPasswordScreen,
  OTPVerificationScreen,
  SelectProfileScreen,
  ProfileScreen,
  JobPage,
  PostJobPage,
  YourCoaches,
  TalentRecommended,
  EmployerRecommendedTalents,
  TalentExplore,
  EmployerExplore,
  TalentCareerGuide,
  TalentSavedItems,
  ViewAllScreen,
  CoachPanelContainer,
  PartnerResourceScreen,
  Resource,
} from "./containers";

import { AppDispatch } from "./redux/store/store";
import { PricingPlanScreen } from "./containers/PricingPlanScreen/PricingPlanScreen";
import CalendlyContainer from "./containers/CalendlyContainer/CalendlyContainer";
import NotFoundContainer from "./containers/NotFoundContainer/NotFoundContainer";
import { CompletePartnerScreen } from "./containers/CompletePartnerScreen/CompletePartnerScreen";
import AddResource from "./containers/AddResource/AddResource";
import ViewResource from "./containers/ViewResource/ViewResource";
import EmployerPostedJobPage from "./containers/EmployerPostedJobPage/EmployerPostedJobPage";

import ViewJobApplication from "./containers/ViewJobApplications/ViewJobApplication";
import EmployerATS from "./containers/EmployerATS/EmployerATS";
import PaymentPage from "./containers/PaymentPage/PaymentPage";
import PaymentSuccessPage from "./containers/PaymentSuccessPage/PaymentSuccesPage";
import EmployerTeamManagementPage from "./containers/EmployerTeamManagementPage/EmployerTeamManagement";
import EmployerSavedItems from "./containers/EmployerSavedItems/EmployerSavedItems";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import EmployerBillingScreen from "./containers/EmployerBillingScreen/EmployerBillingScreen";
// import PartnerProfileNew from "./containers/ProfileScreen/UserTypeProfiles/PartnerProfileNew";
import PartnerProfile from "./containers/ProfileScreen/UserTypeProfiles/PartnerProfile";
import Verification from "./containers/Verification/Verification";

interface ProfileState {
  accessToken: string | null;
  boardingCompleted: boolean;
  userRole: string;
}

const adminPaths = [
  "/dashboard",
  "/recommendations",
  "/my-coaches",
  "/explore",
  "/saved-items",
  "/employer-saved-items",
  "/career-guide",
  "/recommended-talent",
  "/calendly",
  "/billing",
  "/coach-panel",
  "/post/job",
  "/add-resource",
  "/resource",
  "/jobs-posted",
  "/ats",
  "/payment",
  "/management",
  "/employer-billing",
];

const GTMNoScript = () => (
  <noscript>
    <iframe
      src="https://www.googletagmanager.com/ns.html?id=GTM-TRDFZDSJ"
      height="0"
      width="0"
      style={{ display: "none", visibility: "hidden" }}
    ></iframe>
  </noscript>
);

const App = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();

  const { accessToken, boardingCompleted, userRole } = useSelector(
    (state: { profile: ProfileState }) => state.profile
  );

  const subscriptionPlanTalent = useSelector(
    (state: any) => state.talent?.dashboard?.subscription_status
  );
  const isTalentCoach = useSelector(
    (state: any) => state.talent?.dashboard?.is_coach
  );
  const subscriptionPlanEmployer = useSelector(
    (state: any) => state.employer?.dashboard?.subscription_status
  );

  useEffect(() => {
    const token = Cookies.get("access-token");
    if (token) {
      dispatch(getProfile()).then((res: any) => {
        if (res.payload) {
          dispatch(
            updateProfileState({
              accessToken: token,
              boardingCompleted: res.payload.on_boarding_completed,
              userRole: res.payload.role,
            })
          );
        }
      });

      if (Cookies.get("role") === "talent") {
        dispatch(getTalentDashboard());
      }
      if (Cookies.get("role") === "employer") {
        dispatch(getEmployerDashboard());
      }
    }
  }, [dispatch, accessToken]);

  // Use useCallback to avoid creating a new function on every render
  const getDashboard = useCallback(() => {
    switch (userRole) {
      case "employer":
        return <EmployerDashboard />;
      case "talent":
        return <TalentDashboard />;
      case "partner":
        return <PartnerDashboard />;
      default:
        return null;
    }
  }, [userRole]);

  const getExplore = useCallback(() => {
    switch (userRole) {
      case "employer":
        return <EmployerExplore />;
      case "talent":
        return <TalentExplore />;
      default:
        return null;
    }
  }, [userRole]);

  const isDashboardPath =
    adminPaths.includes(location.pathname) ||
    location.pathname.includes("/view-all/") ||
    location.pathname.includes("/job-application/");

  return (
    <>
      <div className={isDashboardPath ? "app" : ""}>
        {isDashboardPath &&
          !Boolean(
            location.pathname.includes(
              `${window.location.origin}/view-resource/`
            )
          ) && <DashboardSidebar />}
        <Routes>
          {/* Public Routes */}
          {(!accessToken || !boardingCompleted) && (
            <>
              <Route path="/payment" element={<PaymentPage />} />
              <Route path="/signup" element={<SignUpScreen />} />
              <Route path="/login" element={<LoginScreen />} />
              <Route path="/verification" element={<Verification />} />
              <Route path="/admin/login" element={<AdminLoginScreen />} />
              <Route path="/reset-password" element={<ResetPasswordScreen />} />
              <Route path="/linkedin" element={<LinkedInCallback />} />
              <Route
                path="/verify-otp/:email"
                element={<OTPVerificationScreen />}
              />
              <Route path="/select-profile" element={<SelectProfileScreen />} />
              <Route path="/onboarding" element={<OnboardingScreen />} />
              <Route
                path="/complete-partner"
                element={<CompletePartnerScreen />}
              />
              <Route path="/*" element={<Navigate to="/signup" />} />
            </>
          )}

          {/* Admin Routes */}

          {accessToken && userRole === "admin" && (
            <>
              <Route path="/admin/:dashboardTab" element={<AdminMainDash />} />
              <Route
                path="/admin/view-resource/:resourceId"
                element={<ViewResource />}
              />
              <Route path="/resource/:id/:link" element={<Resource />} />
              <Route path="/" element={<Navigate to="/admin/dashboard" />} />
              <Route path="*" element={<NotFoundContainer />} />
            </>
          )}

          {/* Authenticated Routes */}
          {accessToken && boardingCompleted && userRole !== "admin" && (
            <>
              <Route path="/payment" element={<PaymentPage />} />
              <Route path="/payment-success" element={<PaymentSuccessPage />} />
              <Route path="/onboarding" element={<OnboardingScreen />} />
              <Route path="/partner-approval" element={<PartnerRequest />} />
              <Route path="/dashboard" element={getDashboard()} />
              <Route path="/job/:jobId" element={<JobPage />} />
              <Route path="/post/job" element={<PostJobPage />} />
              {/* <Route path="/my-coaches" element={<YourCoaches />} /> */}
              <Route
                path="/my-coaches"
                element={
                  <ProtectedRoute
                    element={<YourCoaches />}
                    subscriptionPlan={subscriptionPlanTalent}
                    requiredPlan={["trailblazer"]}
                    isCoach={isTalentCoach ? isTalentCoach : false}
                  />
                }
              />
              <Route path="/recommendations" element={<TalentRecommended />} />
              <Route path="/partner-profile" element={<PartnerProfile />} />
              {/* <Route path="/partner-profile" element={<PartnerProfileNew />} /> */}
              <Route
                path="/recommended-talent"
                element={<EmployerRecommendedTalents />}
              />
              <Route path="/jobs-posted" element={<EmployerPostedJobPage />} />
              <Route
                path="/management"
                element={<EmployerTeamManagementPage />}
              />

              <Route
                path="/job-application/:jobId"
                element={<ViewJobApplication />}
              />
              {/* <Route
              path="/job-application/:jobId"
              element={
                <ProtectedRoute
                  element={<ViewJobApplication />}
                  subscriptionPlan={'start'}
                  requiredPlan={['start']}
                  isCoach={false}
                  isEmployer={Cookies.get('role')=== 'employer'? true : false}
                />
              }
            /> */}
              <Route path="/ats" element={<EmployerATS />} />
              <Route path="/explore" element={getExplore()} />
              {/* <Route path="/career-guide" element={<TalentCareerGuide />} /> */}
              <Route
                path="/career-guide"
                element={
                  <ProtectedRoute
                    element={<TalentCareerGuide />}
                    subscriptionPlan={subscriptionPlanTalent}
                    requiredPlan={["trailblazer", "path"]}
                    isCoach={isTalentCoach ? isTalentCoach : false}
                  />
                }
              />
              <Route path="/saved-items" element={<TalentSavedItems />} />
              <Route
                path="/employer-saved-items"
                element={<EmployerSavedItems />}
              />
              <Route path="/view-all/:type" element={<ViewAllScreen />} />
              <Route
                path="/profile/:userType/:userId"
                element={<ProfileScreen />}
              />
              {/* <Route path="/billing" element={<PricingPlanScreen />} /> */}
              <Route path="/billing" element={<EmployerBillingScreen />} />
              {/* <Route path="/calendly" element={<CalendlyContainer />} /> */}
              <Route
                path="/calendly"
                element={
                  <ProtectedRoute
                    element={<CalendlyContainer />}
                    subscriptionPlan={subscriptionPlanTalent}
                    requiredPlan={[]}
                    isCoach={isTalentCoach ? isTalentCoach : false}
                  />
                }
              />
              <Route path="/add-resource" element={<AddResource />} />
              {/* <Route path="/coach-panel" element={<CoachPanelContainer />} /> */}
              <Route
                path="/coach-panel"
                element={
                  <ProtectedRoute
                    element={<CoachPanelContainer />}
                    subscriptionPlan={subscriptionPlanTalent}
                    requiredPlan={[]}
                    isCoach={isTalentCoach ? isTalentCoach : false}
                  />
                }
              />
              <Route
                path="/view-resource/:resourceId"
                element={<ViewResource />}
              />
              <Route path="/resource/:id/:link" element={<Resource />} />
              <Route path="/" element={<Navigate to="/dashboard" />} />
              <Route path="*" element={<NotFoundContainer />} />
            </>
          )}
        </Routes>
      </div>
      <GTMNoScript />
    </>
  );
};

export default App;
