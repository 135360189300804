import React, { FC, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  Bookmark,
  Clock,
  Global,
  Pin,
  Star,
  Verified,
} from "../../assets/icons";

import "./PostedJobCard.scss";
import InitialsAvatar from "../InitialsAvatar/InitialsAvatar";
type Props = {
  job?: any;
};
const PostedJobCard: FC<Props> = (props) => {
  const { job } = props;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  return (
    <div className="postedJob" style={job?.style || {}}>
      {job?.percentage_job_match && (
        <div className="postedJob__match">
          <div className="progress">{job?.percentage_job_match || "82%"}</div>
          Job Match
        </div>
      )}
      {job?.job?.image ? (
        <img 
        src={job?.job?.image} 
        className="profile"
        alt="job" />
      ) : (
        <InitialsAvatar
          name={job?.job?.employer ? job?.job?.employer : "---"}
          customStyles={{ width: "84px", height: "84px", fontSize: "16px" }}
        />
      )}
      <div className="name">{job?.job?.employer || "------"} </div>
      <div
        className="title"
        style={{
          display: "block",
          whiteSpace: "nowrap",
          width: "200px",
          textOverflow: "ellipsis",
          overflow: "hidden",
          textAlign: "center",
        }}
      >
        {job?.job?.title || "---"}
      </div>
      <div className="line"></div>
      <div className="details">
        <div>
          <Pin />
          <span
            style={{
              display: "block",
              whiteSpace: "nowrap",
              width: "80px",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {" "}
            {job?.job?.location || "-----"}
          </span>
        </div>
        <div>
          <Global />
          <span
            style={{
              display: "block",
              whiteSpace: "nowrap",
              width: "80px",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {typeof job?.job?.mode_of_work === "string"
              ? job?.job?.mode_of_work
              : job?.job?.mode_of_work?.[0] || job?.mode_of_work?.[0] || "----"}
          </span>
        </div>
        <div>
          <Clock />
          <span style={{ maxWidth: "80px" }}>
            {job?.posted_since ||
              (job?.date_applied
                ? moment(job?.date_applied, "DD/MM/YYYY").fromNow()
                : "-- Hours Ago")}
          </span>
        </div>
      </div>

      <div className="buttons">
        <button
          disabled={!!job?.date_applied}
          onClick={() =>
            job?.job?.id && navigate(`/job-application/${job?.job?.id}`)
          }
        >
          View Applications
        </button>
        <button
          className="invert"
          onClick={() => job?.job?.id && navigate(`/job/${job?.job?.id}`)}
        >
          View Job
        </button>
      </div>
    </div>
  );
};

export default PostedJobCard;
