import { FC, useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { FormButton } from "../../../../components";
import { AppDispatch } from "../../../../redux/store/store";
import { updateEmployerProfile } from "../../../../redux/EmployerSlice/EmployerSlice";

import "../EditProfileModal.scss";
import { message } from "antd";

interface LinkInputs {
  linkedin: string;
  company_website: string;
  twitter: string;
}

interface Prop {
  onModalClose?: () => void;
}

const ProfileLinks: FC<Prop> = (props: Prop) => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const { register, handleSubmit } = useForm<LinkInputs>();
  const employerProfile = useSelector(
    (state: any) => state.employer.profileInfo
  );
  const dispatch = useDispatch<AppDispatch>();
  const addHttpsIfMissing = (url: string) => {
    if (url && !url.startsWith("https://")) {
      return `https://${url}`;
    }
    return url;
  };
  const onSubmit: SubmitHandler<LinkInputs> = (data) => {
    setLoading(true);
    data.linkedin = addHttpsIfMissing(data.linkedin);
    data.twitter = addHttpsIfMissing(data.twitter);
    data.company_website = addHttpsIfMissing(data.company_website);
    dispatch(
      updateEmployerProfile({ userId: employerProfile?.id, payload: data })
    )
      .then(() => {
        setLoading(false);
        message.success("Update successful");
        props.onModalClose?.();
      })
      .catch(() => {
        setLoading(false);
        message.error("Error updating");
        props.onModalClose?.();
      });
    return;
  };

  return (
    <div className="editProfile__container">
      <form onSubmit={handleSubmit(onSubmit)}>
        <label>
          <span>LinkedIn</span>
          <input
            {...register("linkedin")}
            type="text"
            placeholder="Write Here"
            defaultValue={employerProfile?.demographics?.links?.linkedin}
          />
        </label>
        <label>
          <span>X (formerly known as Twitter)</span>
          <input
            {...register("twitter")}
            type="text"
            placeholder="Write Here"
            defaultValue={employerProfile?.demographics?.links?.twitter}
          />
        </label>

        <label>
          <span>Others</span>
          <input
            {...register("company_website")}
            type="text"
            placeholder="Write Here"
            defaultValue={employerProfile?.demographics?.links?.website}
          />
        </label>

        {errorMessage && <span className="errorMessage">{errorMessage}</span>}
        <FormButton text="Submit" loading={loading} />
      </form>
    </div>
  );
};

export default ProfileLinks;
