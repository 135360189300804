import React, { useEffect, useState, CSSProperties } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useNavigate, useParams } from "react-router-dom";
import ProfileCover from "../../../assets/images/profileCover.png";
import MentorImage from "../../../assets/images/mentor.png";
import OurTalentPf1 from "../../../assets/images/ourTalentPf1.png";
import LifeCompanyImage from "../../../assets/images/lifeCompanyImage.png";
import JobImage from "../../../assets/images/job.png";
import { ReactComponent as LocationIcon } from "../../../assets/icons/locationIcon.svg";
import { ReactComponent as SessionIcon } from "../../../assets/icons/sessions.svg";
import { ReactComponent as ExperienceIcon } from "../../../assets/icons/experience.svg";
import SocialMediaIcons from "../../../assets/icons/SocialMediaIcons";
import { ReactComponent as VerifiedIcon } from "../../../assets/icons/verifiedIcon.svg";
import { ReactComponent as BookmarkIcon } from "../../../assets/icons/bookmark.svg";
import { ReactComponent as StarIcon } from "../../../assets/icons/starIcon.svg";
import { Avatar, Tag, Button, Tabs, Progress } from "antd";
import type { TabsProps } from "antd";
import { Link } from "react-router-dom";
import TechEventImage from "../../../assets/images/largetTechEvent.png";

import "./Profile.scss";
import { getAccessToken } from "../../../utils/jwtToken";
import axios from "axios";
import api from "../../../utils/api";
import InitialsAvatar from "../../../components/InitialsAvatar/InitialsAvatar";
import { ResourceCard } from "../../../components";
import Slider from "react-slick";
import DashboardNavbar from "../../../components/DashboardNavbar/DashboardNavbar";
import { CopyinClipboard } from "../../../utils/CopyinClipboard";
import { getProfile } from "../../../redux/ProfileSlice/ProfileSlice";
import { AppDispatch, RootState } from "../../../redux/store/store";
import PartnerResourceFooter from "../../../components/PartnerResourceFooter/PartnerResourceFooter";
import EditProfileModal from "../Modals/EditProfileModal";
import { getPartnerDashboard } from "../../../redux/PartnerSlice/PartnerSlice";

type ProfileParams = {
  userType: "talent" | "coach" | "employer" | "partner";
  userId: string;
};

type Props = {
  // typing of data provided by parent
};

type DetailObject = {
  title: string;
  desc: string;
};

const PartnerProfile: React.FC = (props: Props) => {
  const [partnerProfile, setPartnerProfile] = useState<any | undefined>(
    undefined
  );
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 700);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [customStyleForNav, setCustomStyleForNav] = useState({});
  const [openModal, setModalOpen] = useState<boolean>(false);
  const [openLeaveModal, setOpenLeaveModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [modalActiveTabTalent, setModalActiveTabTalent] = useState("");

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 700);
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleDeleteAccount = () => {
    setModalOpen(false);
    setOpenLeaveModal(true);
  };
  const handleContinueDelete = () => {
    setOpenDeleteModal(true);
    setOpenLeaveModal(false);
  };

  const handleCancelDelete = () => {
    setOpenLeaveModal(false);
    setOpenDeleteModal(false);
  };
  const handleOpenModal = () => {
    console.log("click modal");
    setModalOpen(true);
  };
  const defaultStyle: CSSProperties = {
    display: "flex",
    justifyContent: "space-between",
    marginRight: "200px",
    marginLeft: "200px",
  };
  const mediaQueryStyle: CSSProperties = {
    ...defaultStyle,
    flexDirection: "column",
    gap: "30px",
  };

  const btnStyle = windowWidth <= 520 ? mediaQueryStyle : defaultStyle;

  useEffect(() => {
    if (isMobileView) {
      setCustomStyleForNav({
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "unset",
        padding: "20px 24px",
      });
    } else {
      setCustomStyleForNav({
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "unset",
        padding: "20px 80px",
      });
    }
  }, []);

  useEffect(() => {
    // setLoading(true);
    const accessToken = getAccessToken();
    axios
      .get(`${api}/partners/profile/manage/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(function (response) {
        setPartnerProfile(response.data);
        // setCoaches(response.data);
        // setLoading(false);
      })
      .catch(function (error) {
        // Handle any errors
        // setLoading(false);
        console.error("Error:", error);
      });
  }, []);

  const resource_data = [
    {
      id: 1,
      program_title: "All About The Data ",
      program_link: "https://google.com",
      resource_type: ["Training", "Bootcamp"],
      description:
        "Aim to create new candidate pools from previously underrepresented groups in tech, and our continued efforts to support those candidates through admissions (including scholarships), classwork, and on to job hunting through mentorship and networking support  ",
    },
    {
      id: 1,
      program_title: "All About The Data ssss",
      program_link: "https://google.com",
      resource_type: ["Training", "Bootcamp"],
      description:
        "Aim to create new candidate pools from previously underrepresented groups in tech, and our continued efforts to support those candidates through admissions (including scholarships), classwork, and on to job hunting through mentorship and networking support",
    },

    {
      id: 1,
      program_title: "All About The Data ",
      program_link: "https://google.com",
      resource_type: ["Training", "Bootcamp"],
      description:
        "Aim to create new candidate pools from previously underrepresented groups in tech, and our continued efforts to support those candidates through admissions (including scholarships), classwork, and on to job hunting through mentorship and networking support",
    },
  ];

  const ReviewTile = ({ reviewObj }: any) => {
    return (
      <div
        className="job-tile"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "320px",
          marginTop: "40px",
        }}
      >
        {/* Review Paragraph */}
        <div
          style={{
            marginTop: "30px",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "30px",
            color: "#111111",
            width: "320px",
          }}
        >
          {reviewObj.reviewComment}
        </div>

        <div
          style={{
            display: "flex",
            width: "100%",
            marginTop: "40px",
            justifyContent: "space-around",
            alignItems: "center",
            marginLeft: "30px",
          }}
        >
          <Avatar
            style={{ width: "70px", height: "70px", marginTop: "30px" }}
            src={reviewObj.reviewerImage}
          />

          <div
            style={{
              display: "flex",
              fontWeight: 700,
              fontSize: "18px",
              lineHeight: "20px",
              color: "#344054",
              marginTop: "20px",
              alignItems: "baseline",
              flexDirection: "column",
              marginRight: "50px",
            }}
          >
            {reviewObj.name}
            <div
              style={{
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "20px",
                color: "#6F6C90",
                marginTop: "4px",
                alignItems: "center",
              }}
            >
              {" "}
              {reviewObj.jobTitle}{" "}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const MentorTile = ({ mentorObj }: any) => {
    return (
      <div
        className="job-tile"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "290px",
          marginTop: "40px",
        }}
      >
        {/* <div style={{display: "flex", flexDirection: "column", alignItems: 'center'}}> */}

        {/* Mentor */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            width: "100%",
            marginLeft: "-60px",
            marginTop: "12px",
          }}
        >
          <Avatar
            style={{
              width: "78px",
              height: "78px",
              border: "1px solid #FBAD84",
              marginTop: "30px",
            }}
            src={mentorObj.image}
          />

          {/* Mentor Details */}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "20px",
              marginLeft: "-50px",
            }}
          >
            <div
              style={{
                display: "flex",
                fontWeight: 600,
                fontSize: "22px",
                lineHeight: "24px",
                color: "#1E1E2F",
                marginTop: "6px",
                alignItems: "baseline",
              }}
            >
              {mentorObj.name}
              {mentorObj.tribajaVerified && (
                <VerifiedIcon style={{ marginLeft: "10px" }} />
              )}
            </div>

            <div
              style={{
                display: "flex",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "15.31px",
                color: "#344054",
                marginTop: "4px",
                alignItems: "center",
              }}
            >
              {mentorObj.jobTitle}
            </div>

            <div
              style={{
                display: "flex",
                marginTop: "6px",
                justifyContent: "space-between",
              }}
            >
              {new Array(mentorObj.starReviews).fill(1).map(() => {
                return <StarIcon />;
              })}
              <div
                style={{
                  marginLeft: "4px",
                  fontSize: "12px",
                  fontWeight: 350,
                  color: "#344054",
                }}
              >
                {" "}
                {mentorObj.starReviews + " "} reviews
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            width: "92%",
            marginTop: "40px",
          }}
        >
          <div>
            <div
              style={{
                fontWeight: 600,
                fontSize: "12px",
                lineHeight: "15.31px",
                display: "flex",
              }}
            >
              <LocationIcon style={{ marginRight: "8px" }} /> From
            </div>
            <div
              style={{
                color: "#344054",
                fontSize: "12px",
                lineHeight: "15.31px",
                fontWeight: 400,
                marginTop: "10px",
              }}
            >
              {mentorObj.location}
            </div>
          </div>

          <div>
            <div
              style={{
                fontWeight: 600,
                fontSize: "12px",
                lineHeight: "15.31px",
                display: "flex",
              }}
            >
              <ExperienceIcon style={{ marginRight: "8px" }} /> Experience
            </div>
            <div
              style={{
                color: "#344054",
                fontSize: "12px",
                lineHeight: "15.31px",
                fontWeight: 400,
                marginTop: "10px",
              }}
            >
              {mentorObj.experience}
            </div>
          </div>

          <div>
            <div
              style={{
                fontWeight: 600,
                fontSize: "12px",
                lineHeight: "15.31px",
                display: "flex",
              }}
            >
              <SessionIcon style={{ marginRight: "8px" }} /> Sessions
            </div>
            <div
              style={{
                color: "#344054",
                fontSize: "12px",
                lineHeight: "15.31px",
                fontWeight: 400,
                marginTop: "10px",
              }}
            >
              {mentorObj.sessions}
            </div>
          </div>
        </div>

        {/* Action Buttons */}

        <div style={{ display: "flex", marginTop: "20px" }}>
          <Button
            type="primary"
            shape="round"
            size={"large"}
            style={{
              width: "170px",
              padding: "6px 44px",
              fontSize: "14px",
              fontWeight: 600,
              background: "#1E1E2F",
            }}
          >
            Apply
          </Button>
          <Button
            type="primary"
            shape="round"
            size={"large"}
            style={{
              width: "170px",
              marginLeft: "20px",
              padding: "6px 44px",
              fontSize: "14px",
              fontWeight: 600,
              background: "none",
              color: "#1E1E2F",
              border: "0.5px solid",
            }}
          >
            View Job
          </Button>
        </div>
      </div>
    );
  };

  const LifeAtCompanyTile = ({ obj }: any) => {
    return (
      <div
        className="job-tile"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "294px",
          height: "238px",
        }}
      >
        <img src={LifeCompanyImage}></img>
      </div>
    );
  };

  const JobTile = ({ jobObj }: any) => {
    const industries = [
      "UI/UX",
      "Product Management",
      "Cyber",
      "Hackathon",
      "Certification",
    ];
    return (
      <div
        className="job-tile"
        style={{
          display: "flex",
          flexDirection: "column",
          height: "435px",
          width: "400px",
        }}
      >
        {/* <div style={{display: "flex", flexDirection: "column", alignItems: 'center'}}> */}

        {/* Job Corners logos */}

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "20px",
            width: "100%",
          }}
        >
          <BookmarkIcon style={{ marginRight: "20px" }} />
        </div>

        {/* JobImage */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "40px",
            marginRight: "40px",
            maxHeight: "390px",
            overflowY: "scroll",
          }}
        >
          <Avatar
            style={{
              width: "70px",
              height: "70px",
            }}
            src={jobObj.logoImage}
          />

          {/* Job Title */}

          <div
            style={{
              fontWeight: 600,
              fontSize: "24px",
              lineHeight: "24px",
              color: "#1E1E2F",
              marginTop: "12px",
            }}
          >
            {jobObj.jobTitle}
          </div>

          <div style={{ marginTop: "10px" }}>
            {industries.map((ind, index) => (
              <Tag
                color="#F2F4F7"
                style={{
                  width: "fit-content",
                  borderRadius: "16px",
                  color: "#344054",
                  padding: "4px 12px 4px 12px",
                  fontSize: "12px",
                  marginTop: "10px",
                  paddingTop: "1px",
                  paddingBottom: "2px",
                  fontWeight: 400,
                }}
              >
                {ind}
              </Tag>
            ))}
          </div>

          <div
            style={{
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "18px",
              marginTop: "20px",
            }}
          >
            Aim to create new candidate pools from previously underrepresented
            groups in tech, and our continued efforts to support those
            candidates through admissions (including scholarships), classwork,
            and on to job hunting through coaching and networking support
          </div>

          {/* Action Buttons */}

          <div
            style={{
              display: "flex",
              marginTop: "20px",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              shape="round"
              size={"large"}
              style={{
                width: "170px",
                marginLeft: "20px",
                padding: "6px 44px",
                fontSize: "14px",
                fontWeight: 600,
                background: "none",
                color: "#1E1E2F",
                border: "0.5px solid",
              }}
            >
              Learn More
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const { userId, userType } = useParams<ProfileParams>();
  const navigate = useNavigate();

  const onChange = (key: string) => {
    setSelectedTab(key);
  };

  const splitArrayToChunks = (_array: Array<object>, chunk: number) =>
    _array.reduce((all: Array<object>, one, i) => {
      const ch = Math.floor(i / chunk);
      all[ch] = [].concat((all[ch] as any) || [], one as any);
      return all;
    }, []);

  const [_width, setWidth] = useState<number | null>();
  const [selectedTab, setSelectedTab] = useState<string | null>("overview");
  const { profileInfo } = useSelector((state: any) => state.profile);
  const dispatch = useDispatch<AppDispatch>();

  const [copytoClipboard, setCopytoClipboard] = useState<boolean>(false);

  const signUpUrl = "https://app.staging.tribaja.co/signup?referral=";

  useEffect(() => {
    const _clientWidth = document.getElementById("tabs")?.clientWidth;

    if (_clientWidth) setWidth(_clientWidth);
  }, document.getElementById("tabs")?.clientWidth as any);

  useEffect(() => {
    dispatch(getProfile());
  }, []);

  const OverViewContent: React.FC = () => {
    const industries = ["UI/UX", "Product Management", "Cyber"];
    console.log("skills", partnerProfile?.skill_training);
    return (
      <>
        <div id="MainDiv" style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "500px",
              minWidth: "35%",
            }}
          >
            <div
              id="MainDivFirstHalf"
              style={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "500px",
                minWidth: "40%",
                marginTop: "12px",
              }}
            >
              <div id="About" style={{ marginBottom: "8px" }}>
                <div
                  style={{
                    fontWeight: 600,
                    fontSize: "16px",
                    lineHeight: "20.42px",
                  }}
                >
                  {" "}
                  About Company{" "}
                </div>

                <p
                  style={{
                    marginTop: "16px",
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "20.42px",
                    marginBottom: "16px",
                  }}
                >
                  {partnerProfile?.company_description || ""}
                </p>
              </div>
            </div>
            <hr className="horizontal-line-break" />

            <div
              id="MainDivFirstHalf"
              style={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "500px",
                minWidth: "40%",
                marginTop: "30px",
              }}
            >
              <div id="About" style={{ marginBottom: "50px" }}>
                <div
                  style={{
                    fontWeight: 600,
                    fontSize: "16px",
                    lineHeight: "20.42px",
                  }}
                >
                  {" "}
                  Pace of Program
                </div>

                <p
                  style={{
                    marginTop: "16px",
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "20.42px",
                    marginBottom: "16px",
                    textTransform: 'capitalize'
                  }}
                >
                  {partnerProfile?.pace_of_program.replace("-", " ") || ""}
                </p>
              </div>
            </div>
          </div>
          {/* LINE */}
          <div className="vertical-line" />

          {/*  2ND RIGHT HALF SECTION*/}

          <div
            id="MainDivSecondHalf"
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "20px",
              marginLeft: "42px",
              width: "100%",
            }}
          >
            <div id="Links">
              <div
                style={{
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "20.42px",
                  marginBottom: "16px",
                }}
              >
                Links
              </div>

              <div
                id="skills"
                style={{
                  marginTop: "10px",
                  display: "flex",
                  marginLeft: "-10px",
                }}
              >
                {!(
                  partnerProfile?.twitterurl ||
                  partnerProfile?.linkedinurl ||
                  partnerProfile?.website
                ) && (
                  <p
                    style={{
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "20.42px",
                      marginLeft: "10px",
                    }}
                  >
                    No links
                  </p>
                )}
                <div
                  id="Social Media Icons"
                  style={{
                    display: "flex",
                    marginRight: "20px",
                    marginTop: "4px",
                  }}
                >
                  {partnerProfile?.twitterurl && (
                    <a
                      href={
                        partnerProfile?.twitterurl.includes("https://")
                          ? partnerProfile?.twitterurl
                          : `https://${partnerProfile?.twitterurl}`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ marginRight: "10px" }}
                    >
                      <SocialMediaIcons.twitter />
                    </a>
                  )}
                  {partnerProfile?.linkedinurl && (
                    <a
                      href={
                        partnerProfile?.linkedinurl.includes("https://")
                          ? partnerProfile?.linkedinurl
                          : `https://${partnerProfile?.linkedinurl}`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ marginRight: "10px" }}
                    >
                      <SocialMediaIcons.linkedin />
                    </a>
                  )}
                  {partnerProfile?.website && (
                    <a
                      href={
                        partnerProfile?.website.includes("https://")
                          ? partnerProfile?.website
                          : `https://${partnerProfile?.website}`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ marginRight: "10px" }}
                    >
                      <SocialMediaIcons.website />
                    </a>
                  )}
                </div>
              </div>
            </div>

            <hr className="horizontal-line-break" />
            <div
              id="Industries"
              style={{ marginTop: "30px", marginBottom: "50px" }}
            >
              <div
                style={{
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "20.42px",
                  marginBottom: "16px",
                }}
              >
                {" "}
                Skill Training
              </div>

              {partnerProfile?.skill_training?.length > 0 ? (
                <div
                  id="skills"
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    marginLeft: "-10px",
                    maxWidth: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    {partnerProfile?.skill_training?.map(
                      (ind: any, index: number) => (
                        <Tag
                          color="#F2F4F7"
                          style={{
                            width: "fit-content",
                            borderRadius: "16px",
                            color: "#344054",
                            padding: "4px 12px 4px 12px",
                            fontSize: "14px",
                            paddingTop: "1px",
                            paddingBottom: "2px",
                            marginLeft: "10px",
                          }}
                        >
                          {ind}
                        </Tag>
                      )
                    )}
                  </div>
                </div>
              ) : (
                <span>No Skills</span>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  const isPublicView = false;

  const ReviewsContent: React.FC = () => {
    const reviews = new Array(6).fill({
      reviewerImage: MentorImage,
      name: "Adam Smith",
      jobTitle: "UI/UX Designer at LinkedIn",
      reviewComment:
        '"Thank you Tribaja for connecting me with Spotify — I was hired as a Senior Designer and so far it’s been an amazing experience!"',
    });

    return (
      <div id="MainDiv" style={{ display: "flex", marginBottom: "20px" }}>
        <div
          id="Mentor-tiles"
          className="reviews-section"
          // style={{ marginTop: "40px", justifyContent: "space-evenly" }}
        >
          {reviews.map((review) => (
            <ReviewTile reviewObj={review} />
          ))}
        </div>
      </div>
    );
  };

  const items: TabsProps["items"] = [
    {
      key: "overview",
      label: "Overview",
      children: <OverViewContent />,
      style: { fontFamily: "Space Grotesk" },
    },
  ];

  if (isPublicView)
    items.push({
      key: "reviews",
      label: "Reviews",
      children: <ReviewsContent />,
      style: { fontFamily: "Space Grotesk" },
    });

  const jobs = [
    {
      logoImage: JobImage,
      companyName: "Fluent LLC",
      companyStars: 5,
      jobTitle: "All About The Data",
      location: "Sans Francisco, CA",
      jobType: "Full Time Remote",
      updated: "17 Hours Ago",
      percentageMatch: 82,
    },
    {
      logoImage: JobImage,
      companyName: "Fluent LLC",
      companyStars: 5,
      jobTitle: "All About The Data",
      location: "Sans Francisco, CA",
      jobType: "Full Time Remote",
      updated: "17 Hours Ago",
      percentageMatch: 82,
    },
    {
      logoImage: JobImage,
      companyName: "Fluent LLC",
      companyStars: 5,
      jobTitle: "All About The Data",
      location: "Sans Francisco, CA",
      jobType: "Full Time Remote",
      updated: "17 Hours Ago",
      percentageMatch: 82,
    },
  ];

  const events = [
    {
      date: { day: 14, month: "APR" },
      image: TechEventImage,
      title: "Largest hybrid tech event on the east coast.",
      desc: "We’ll get you directly seated and inside for you to enjoy the show.",
    },
    {
      date: { day: 14, month: "APR" },
      image: TechEventImage,
      title: "Diverse tech event powered by tribaja online and on site.",
      desc: "We’ll get you directly seated and inside for you to enjoy the show.",
    },
    {
      date: { day: 14, month: "APR" },
      image: TechEventImage,
      title: "Largest hybrid tech event on the east coast. ",
      desc: "We’ll get you directly seated and inside for you to enjoy the show.",
    },
  ];

  const verified = true;
  return (
    <>
      {openModal && (
        <EditProfileModal
          setUpdatePartnerProfile={setPartnerProfile}
          formModalType={"PARTNER_FORM"}
          onModalClose={() => setModalOpen(false)}
          modalActiveTabTalent={modalActiveTabTalent}
        />
      )}
      <DashboardNavbar addLogo={true} customStyles={customStyleForNav} />
      <div className="talent-profile">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div id="CoverImageDiv">
            <img
              className="profile-banner-img"
              src={ProfileCover}
              alt="ProfileCover"
            />
          </div>

          <div id="ProfileTopSectionDiv" style={btnStyle}>
            <div className="user-avatar" style={{ display: "flex" }}>
              <div
                className="profile-image-avatar-div"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                  alignItems: "center",
                  marginTop: "-70px",
                }}
              >
                {partnerProfile?.profile_pic ? (
                  <Avatar
                    className="profile-image-avatar"
                    style={{
                      width: "200px",
                      height: "205px",
                      border: "3px solid #FBAD84",
                    }}
                    src={partnerProfile.profile_pic}
                  />
                ) : (
                  <div className="box-avatar">
                    <InitialsAvatar
                      name={partnerProfile?.company_name}
                      customStyles={{
                        fontSize: "38px",
                      }}
                    />
                  </div>
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "baseline",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    marginLeft: "20px",
                    marginTop: "20px",
                  }}
                >
                  <p className="font-class talent-profile-name">
                    {" "}
                    {partnerProfile?.company_name
                      ? partnerProfile?.company_name
                      : "---"}{" "}
                  </p>
                  <div style={{ display: "flex", alignItems: "end" }}>
                    {verified && (
                      <VerifiedIcon
                        color="#31BE00"
                        style={{ marginLeft: "10px", color: "#31BE00" }}
                      />
                    )}
                    <p
                      style={{ marginLeft: "10px", width: "80px" }}
                      className="font-class-gender"
                    >
                      {" "}
                      Tribaja Partner{" "}
                    </p>
                  </div>
                </div>
                <div style={{ display: "flex", marginTop: "10px" }}></div>
              </div>
            </div>

            <div
              // id="SocialMediaAndButtonDiv"
              style={{ display: "flex" }}
            >
              <div id="button-group">
                <Button
                  type="primary"
                  shape="round"
                  style={{
                    background: "rgba(30, 30, 47, 1)",
                    color: "rgba(242, 244, 247, 1)",
                  }}
                  size={"large"}
                  // onClick={(event) => {
                  //   event.preventDefault();

                  //   setModalOpen(true);
                  //   setModalActiveTabTalent("company-info");
                  // }}
                  onClick={handleOpenModal}
                >
                  Edit Profile
                </Button>
              </div>
            </div>
          </div>

          {/* Tabs Section */}

          <div
            className="tabs_container"
            style={{
              marginTop: "100px",
              marginLeft: "200px",
              marginRight: "200px",
            }}
          >
            <Tabs
              id="tabs"
              defaultActiveKey="overview"
              items={items}
              onChange={onChange}
            />
            {selectedTab === "overview" && (
              <hr
                className="horizontal-line-break"
                style={{ marginTop: "unset" }}
              />
            )}

            {/* Opportunities Section  */}

            {selectedTab === "overview" && (
              <div
                id="opportunities"
                style={{ marginTop: "60px", marginBottom: "75px" }}
              >
                {/* {(resource_data ?? []).length > 0 ? ( */}
                {partnerProfile?.resources.length > 0 ? (
                  <div className="talentExplore__recommendations-container">
                    <div
                      className="talentExplore__recommendations-container-title"
                      style={{ marginBottom: "24px" }}
                    >
                      <div style={{ fontSize: "24px", fontWeight: "500" }}>
                        New Resources
                      </div>
                      {/* <Link
                        to="/view-all/resource"
                        style={{ textDecoration: "underline" }}
                      >
                        See all
                      </Link> */}
                    </div>
                    <div className="talentExplore__recommendations-slider">
                      <Slider
                        dots={true}
                        slidesToShow={Math.min(resource_data.length, 3)}
                        slidesToScroll={3}
                        autoplay={true}
                        autoplaySpeed={3000}
                        arrows={false}
                        responsive={[
                          {
                            breakpoint: 1189, // Width less than or equal to 1024px
                            settings: {
                              slidesToShow: 2,
                              slidesToScroll: 2,
                              infinite: true,
                              dots: true,
                            },
                          },
                          {
                            breakpoint: 938, // Width less than or equal to 1024px
                            settings: {
                              slidesToShow: 1,
                              slidesToScroll: 1,
                              infinite: true,
                              dots: true,
                            },
                          },
                        ]}
                      >
                        {partnerProfile?.resources
                          // .slice(0, 10)
                          .map((r: any) => (
                            <ResourceCard
                              key={r.id}
                              resource={r}
                              isPartnerResource={true}
                            />
                          ))}
                      </Slider>
                    </div>
                  </div>
                ) : (
                  <div style={{ fontSize: "28px", textAlign: "center" }}>
                    No resources found!
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div style={{ marginTop: "60px" }}>
        <PartnerResourceFooter />
      </div>
    </>
  );
};

export default PartnerProfile;
