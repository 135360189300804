import React, { FC, useState } from "react";
import { RecourseImage } from "../../assets/images";
import axios from "axios";
import api from "../../utils/api";
import Cookies from "js-cookie";
import { getAccessToken } from "../../utils/jwtToken";
import { ProfileCover } from "../../assets/images";

import "./ResourceCard.scss";
import { Bookmark } from "../../assets/icons";

import { Link } from "react-router-dom";

import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store/store";
import {
  getTalentDashboard,
  getTalentDashboardRecommended,
} from "../../redux/TalentSlice/TalentSlice";

import { TailSpin } from "react-loader-spinner";

type Props = {
  resource?: any;
  notSaved?: boolean;
  isPartnerResource?: any;
};

const ResourceCard: FC<Props> = (props) => {
  const resource = props?.resource;
  const notSaved = props?.notSaved ?? false;
  const isPartnerResource = props?.isPartnerResource ?? false;

  const [loading, setLoading] = useState(false);
  const [saveToggle, setSaveToggle] = useState(resource?.is_saved);

  const dispatch = useDispatch<AppDispatch>();

  const saveResource = async () => {
    setLoading(true);
    setSaveToggle(true);
    try {
      await axios.post(
        `${api}/${Cookies.get("role")}/save/resource/${resource?.id}/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        }
      );
      if (
        window.location.pathname === "/recommendations" ||
        window.location.pathname.includes("/view-all/resource")
      ) {
        dispatch(getTalentDashboardRecommended("resources"));
      } else {
        dispatch(getTalentDashboard());
      }
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const unsaveResource = async () => {
    setLoading(true);
    setSaveToggle(false);
    try {
      await axios.delete(
        `${api}/${Cookies.get("role")}/delete/resource/${resource?.id}/`,
        {
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        }
      );
      if (window.location.pathname === "/recommendations") {
        dispatch(getTalentDashboardRecommended("resources"));
      } else {
        dispatch(getTalentDashboard());
      }
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  return (
    <>
      {
        <div className={"resource"}>
          {!notSaved && !isPartnerResource && (
            <div className="resource__bookmark">
              <Bookmark
                className={`bookmark ${saveToggle ? "bookmarkChecked" : ""}`}
                onClick={
                  !loading
                    ? saveToggle
                      ? unsaveResource
                      : saveResource
                    : () => {}
                }
              />
            </div>
          )}
          <div
            className="image"
            // style={{
            //   backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.30), rgba(0, 0, 0, 0.30)), url(${ProfileCover})`,
            // }}
          >
            <img
              src={resource?.image ? resource?.image : RecourseImage}
              className="profile-resource"
              alt="resource"
            />
          </div>
          <div className="content">
            <div className="button-container">
              {resource &&
              resource.resource_types &&
              resource.resource_types.length > 0
                ? resource.resource_types.map((type: any, index: any) => (
                    <div key={index} className="data-button">
                      {resource?.resource_types}
                    </div>
                  ))
                : null}
            </div>

            <div className="name">
              {resource?.program_title || "All About The Data"}
            </div>
            <div className="tags">
              {resource?.resource_type?.map((r: string) => (
                <span>{r}</span>
              ))}
            </div>
            <div className="description">{resource?.program_description}</div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "auto",
              }}
            >
              <Link
                to={
                  resource.status === "approved"
                    ? `/view-resource/${resource?.id}`
                    : "#"
                }
              >
                {resource.status === "approved" ? "Learn more" : "Coming soon"}
              </Link>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default ResourceCard;
