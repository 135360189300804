import React, { FC, useEffect, useState } from "react";

import {
  Available,
  Edit,
  Experience,
  // MyCoaches,
  OpenFor,
  Pin,
  ProfileViews,
  SavedJobs,
  Sent,
  SlackBtnLogo,
  SlackLogo,
} from "../../assets/icons";
import { Link, useNavigate } from "react-router-dom";
import ProfileDetailCard from "../../components/ProfileDetailCard/ProfileDetailCard";
import { useDispatch, useSelector } from "react-redux";
import DashboardNavbar from "../../components/DashboardNavbar/DashboardNavbar";
import { AnimatePresence, motion } from "framer-motion";
import { TailSpin } from "react-loader-spinner";
import { getNameInitials } from "../../utils/getNameInitials";
import Slider from "react-slick";
import "./TalentDashboard.scss";
import "../TalentRecommended/TalentRecommended.scss";
import { Avatar } from "antd";

// import "../../MainDashboard.scss";
import { AppDispatch } from "../../redux/store/store";
import { getTalentDashboard } from "../../redux/TalentSlice/TalentSlice";
import {
  RecommendedJob,
  RecommendedCompanies,
  FormButton,
} from "../../components";
import CoachCard from "../../components/CoachCard/CoachCard";
import EditPreferencesModal from "../../components/EditPreferencesModal/EditPreferencesModal";
import { getProfile } from "../../redux/ProfileSlice/ProfileSlice";

const TalentDashboard: FC = () => {
  // States
  const [loading, setLoading] = useState<boolean>(true);

  const [toggleModal, setToggleModal] = useState(false);

  // Dispatch
  const dispatch = useDispatch<AppDispatch>();

  // Selectors
  const profileInfo = useSelector((state: any) => state.profile.profileInfo);
  const talentDashboard = useSelector((state: any) => state.talent.dashboard);
  const talentProfileInfo = useSelector(
    (state: any) => state.talent.profileInfo
  );

  const roleTypesText = talentDashboard?.preferences?.role_type?.join(", ");

  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 700);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 700);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //Adjust talent profile strength according to the colors
  const talentProfileStrength = () => {
    const profileStrength = Math.floor(talentDashboard?.profile_strength);
    if (profileStrength < 50) {
      return "dashboard__info-profile-strength";
    } else {
      return "dashboard__info-profile-strength-high";
    }
  };
  const navigate = useNavigate();

  const [profileStrengthText, setProfileStrengthText] = useState("");

  const navigateToProfile = () => {
    navigate(`/profile/${profileInfo?.role}/${talentDashboard?.profile?.id}`);
  };

  const handleProfileStrength = () => {
    const profileStrength = Math.floor(talentDashboard?.profile_strength);
    if (profileStrength <= 50) {
      setProfileStrengthText("Weak");
    } else if (profileStrength > 50 && profileStrength <= 80) {
      setProfileStrengthText("Medium");
    } else {
      setProfileStrengthText("Strong");
    }
  };

  const handleToggleModal = () => {
    setToggleModal((prevState) => !prevState);
  };

  // LifeCylce events
  useEffect(() => {
    setLoading(true);
    handleProfileStrength();
    if (profileInfo?.first_name && talentDashboard?.profile) {
      setLoading(false);
    } else {
      dispatch(getProfile()).then(() => setLoading(false));
    }
  }, [talentDashboard]);

  const jobsAnalytics = talentDashboard?.jobs_applied?.jobs || [];
  const myCoaches = talentDashboard?.coaches || [];
  const followedEmployers = talentDashboard?.followed_employers || [];

  useEffect(() => {
    // Incase Talent Dashboard is not loaded
    if (!talentDashboard) dispatch(getTalentDashboard());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AnimatePresence>
      {!loading ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="dashboard__container"
        >
          <DashboardNavbar addLogo={isMobileView} />
          <main className="dashboard">
            <div className="dashboard__details">
              <motion.div
                key={"dashboard-heading"}
                transition={{ duration: 0.3, delay: 0.3 }}
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                className="dashboard__details-heading"
              >
                <h1>Welcome {profileInfo?.first_name} 👋</h1>
                <p></p>
              </motion.div>
              <motion.section
                transition={{ duration: 0.3, delay: 0.4 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="dashboard__details-container"
              >
                <div
                  className="dashboard__details-container-title"
                  style={{ marginTop: "20px" }}
                >
                  My profile
                </div>
                <main className="dashboard__details-container-box">
                  <section>
                    <span className="dashboard__details-container-box-profile">
                      {talentDashboard?.profile.image ? (
                        <Avatar
                          style={{
                            width: "83px",
                            height: "83px",
                            border: "1px solid",
                          }}
                          src={talentDashboard?.profile.image}
                        />
                      ) : (
                        <div>
                          {getNameInitials(
                            profileInfo?.first_name,
                            profileInfo?.last_name
                          )}
                        </div>
                      )}
                    </span>
                    <p className="dashboard__details-container-box-name">
                      {profileInfo?.first_name} {profileInfo?.last_name}
                    </p>
                    <p className="dashboard__details-container-box-profession">
                      {talentDashboard?.profile?.profession
                        ? talentDashboard?.profile?.profession
                        : "Profession not selected yet"}
                    </p>
                    <div className="dashboard__details-container-box-stats">
                      <span>
                        <div>
                          <Pin /> From
                        </div>
                        {/* {talentDashboard?.profile?.location ? (
                          <p>{talentDashboard?.profile?.location}</p>
                        ) : (
                          <blockquote></blockquote>
                        )} */}
                        {talentDashboard?.profile?.location ? (
                          <p>
                            {talentDashboard?.profile?.location?.city},{" "}
                            {talentDashboard?.profile?.location?.state},{" "}
                            {talentDashboard?.profile?.location?.country}
                          </p>
                        ) : (
                          <blockquote></blockquote>
                        )}
                      </span>
                      <span>
                        <div>
                          <Experience /> Experience
                        </div>
                        {talentDashboard?.profile?.experience ? (
                          <p>
                            {Math.abs(
                              Math.floor(talentDashboard?.profile?.experience)
                            ) < 1
                              ? "Less than a year"
                              : `${Math.floor(
                                  talentDashboard?.profile?.experience
                                )} ${
                                  Math.floor(
                                    talentDashboard?.profile?.experience
                                  ) === 1
                                    ? "year"
                                    : "years"
                                }`}
                          </p>
                        ) : (
                          <blockquote></blockquote>
                        )}
                      </span>

                      <span>
                        <div>
                          <OpenFor /> Open for
                        </div>
                        {talentDashboard?.preferences?.role_type?.length ? (
                          <div
                            style={{
                              display: "block",
                              whiteSpace: "nowrap",
                              width: "80px",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            {roleTypesText}
                          </div>
                        ) : (
                          <blockquote></blockquote>
                        )}
                      </span>
                    </div>
                  </section>
                  <div className="dashboard__details-container-profile">
                    <ProfileDetailCard
                      icon={<SavedJobs />}
                      title="Saved Jobs"
                      content={talentDashboard?.jobs_saved}
                      background="#FEEDCC"
                    />
                    <ProfileDetailCard
                      icon={<Sent />}
                      title="Applications sent"
                      content={talentDashboard?.jobs_applied?.total}
                      background="#91C7B1"
                    />
                    <ProfileDetailCard
                      icon={<ProfileViews />}
                      title="Profile Views"
                      content={talentDashboard?.view_count}
                      background="#FDCEB5"
                    />
                  </div>
                </main>
                {/* {(jobsAnalytics ?? []).length > 0 && ( */}
                <div
                  className="main__dashboard-recent-activities"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    overflow: "auto",
                    overflowX: "hidden",
                    maxHeight: "400px",
                    // width: "93%",
                  }}
                >
                  {/* Header First Row */}
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      color: "#1E1E2F",
                    }}
                  >
                    Applied Jobs Analytics
                  </div>
                  <div
                    style={{
                      fontSize: "12px",
                      fontWeight: "600",
                      color: "#344054",
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "14px",
                      minWidth: 800,
                      borderBottom: "1px solid #aaa",
                      paddingBottom: "8px",
                    }}
                  >
                    <div style={{ minWidth: "140px", maxWidth: "140px" }}>
                      {" "}
                      Position{" "}
                    </div>
                    <div style={{ minWidth: "140px", maxWidth: "140px" }}>
                      {" "}
                      Company Name{" "}
                    </div>
                    <div style={{ minWidth: "140px", maxWidth: "140px" }}>
                      {" "}
                      Location{" "}
                    </div>

                    <div style={{ minWidth: "140px", maxWidth: "140px" }}>
                      {" "}
                      Mode of Work{" "}
                    </div>

                    <div style={{ minWidth: "120px", maxWidth: "120px" }}>
                      {" "}
                      Date Applied{" "}
                    </div>
                  </div>

                  {/* <hr style={{ marginTop: "8px" }} /> */}

                  {jobsAnalytics?.length === 0 && (
                    <div
                      style={{
                        height: "100px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      No jobs applied yet !
                    </div>
                  )}

                  {jobsAnalytics.map((jobAnal: any, index: number) => {
                    const appliedDate = new Date(
                      jobAnal?.date_applied
                    ).toLocaleDateString();

                    return (
                      <>
                        <div
                          style={{
                            fontSize: "10px",
                            fontWeight: "400",
                            color: "#344054",
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "20px",
                            minWidth: 800,
                            borderBottom: "1px solid #aaa",
                            paddingBottom: "8px",
                          }}
                        >
                          <div
                            style={{
                              minWidth: "140px",
                              maxWidth: "140px",

                              lineHeight: "12.67px",
                            }}
                          >
                            {" "}
                            {jobAnal?.job.title}{" "}
                          </div>
                          <div
                            style={{
                              minWidth: "140px",
                              maxWidth: "140px",

                              lineHeight: "12.67px",
                            }}
                          >
                            {" "}
                            {jobAnal?.job.employer}
                          </div>

                          <div
                            style={{
                              minWidth: "140px",
                              maxWidth: "140px",

                              lineHeight: "12.67px",
                              paddingRight: "20px",
                            }}
                          >
                            {" "}
                            {jobAnal?.job.location}
                          </div>

                          <div
                            style={{
                              minWidth: "140px",
                              maxWidth: "140px",

                              lineHeight: "12.67px",
                            }}
                          >
                            {" "}
                            {jobAnal?.job.mode_of_work[0] || "--"}
                          </div>

                          <div
                            style={{
                              minWidth: "120px",
                              maxWidth: "120px",

                              lineHeight: "12.67px",
                            }}
                          >
                            {" "}
                            {appliedDate}
                          </div>
                        </div>

                        {/* {index < jobsAnalytics.length - 1 && (
                          <hr style={{ marginTop: "20px" }} />
                        )} */}
                      </>
                    );
                  })}
                </div>
              </motion.section>
            </div>
            <motion.div
              transition={{ duration: 0.3, delay: 0.5 }}
              initial={{ x: 20, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              className="dashboard__info"
            >
              <main className={talentProfileStrength()}>
                <div className="title">Your Profile Strength</div>
                <span className="status">{profileStrengthText}</span>
                <section>
                  {talentDashboard?.profile_strength
                    ? `${Math.floor(talentDashboard?.profile_strength)}%`
                    : "0%"}
                  <div className="progress-bar">
                    <span
                      style={{
                        left: `${
                          talentDashboard?.profile_strength
                            ? Math.floor(talentDashboard?.profile_strength) - 1
                            : 0
                        }%`,
                      }}
                    ></span>
                    <div
                      className="progress-bar-completion"
                      style={{
                        width: `${talentDashboard?.profile_strength || 0}%`,
                      }}
                    ></div>
                  </div>
                </section>
                <button onClick={navigateToProfile}>
                  <Edit />
                  Edit Profile
                </button>
              </main>
              <main className="dashboard__info-preferences">
                <div className="title">
                  <Available />
                  <span>
                    I am available for <br />{" "}
                    {talentDashboard?.preferences?.role_type[0] ? (
                      <p>{talentDashboard.preferences.role_type.join(", ")}</p>
                    ) : (
                      <p>--------------</p>
                      //   <blockquote></blockquote>
                    )}
                  </span>
                </div>
                <p className="content">
                  Share what career opportunities you are open to{" "}
                </p>
                <button onClick={handleToggleModal}>
                  <Edit />
                  Edit Preferences
                </button>
              </main>
              {/* for mobile view */}
              <div className="dashboard__info-mobile">
                <div className="dashboard__info-mobile-edit_preference-btn">
                  <button onClick={handleToggleModal}>Edit preferences</button>
                </div>
                {talentDashboard?.subscription_status === "trailblazer" && (
                  <div className="slack_connect_btn">
                    <div className="dashboard__info-mobile-slackBtn">
                      <SlackBtnLogo />
                      <FormButton
                        text={"Join Slack"}
                        loading={false}
                        customStyle={{
                          backgroundColor: "#FAF9FF",
                          color: "#000000",
                          marginTop: "0",
                          paddingLeft: "0",
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              {/* for mobile view */}

              {talentDashboard?.subscription_status === "trailblazer" && (
                <main className="dashboard__info-slack">
                  <SlackLogo />
                  <div className="title">
                    Join our slack <br />
                    community
                  </div>
                  <p className="content">Become a part of 10000+ Community</p>
                  <button
                    onClick={() =>
                      (window.location.href = "https://slack.tribaja.co/")
                    }
                  >
                    Join
                  </button>
                </main>
              )}
            </motion.div>
          </main>
          {(jobsAnalytics ?? []).length > 0 && (
            <div
              className="talentRecommended__recommendations-container"
              style={{ marginTop: "80px" }}
            >
              <div className="talentRecommended__recommendations-container-title">
                <div>Applied Jobs</div>
              </div>
              <div
                className="talentRecommended__recommendations-slider"
                style={{ width: "103%" }}
              >
                <Slider
                  dots={true}
                  slidesToShow={Math.min(jobsAnalytics.length, 3)}
                  slidesToScroll={3}
                  autoplay={true}
                  arrows={false}
                  responsive={[
                    {
                      breakpoint: 1189, // Width less than or equal to 1024px
                      settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        infinite: true,
                        dots: true,
                      },
                    },
                    {
                      breakpoint: 938, // Width less than or equal to 1024px
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true,
                      },
                    },
                  ]}
                >
                  {jobsAnalytics?.slice(0, 10).map((jobAnal: any) => {
                    const appliedDate = new Date(
                      jobAnal?.date_applied
                    ).toLocaleDateString();
                    return (
                      <RecommendedJob
                        job={{
                          ...jobAnal.job,
                          date_applied: appliedDate,
                          is_saved: jobAnal.is_saved,
                          style: { marginBottom: "40px" },
                        }}
                      />
                    );
                  })}
                </Slider>
              </div>
            </div>
          )}
          {(myCoaches ?? []).length > 0 && (
            <div
              className="talentRecommended__recommendations-container"
              style={{ marginTop: "80px" }}
            >
              <div className="talentRecommended__recommendations-container-title">
                <div>My Coaches</div>
              </div>
              <div
                className="talentRecommended__recommendations-slider"
                style={{ width: "103%" }}
              >
                <Slider
                  dots={true}
                  slidesToShow={Math.min(myCoaches.length, 3)}
                  slidesToScroll={3}
                  arrows={false}
                  responsive={[
                    {
                      breakpoint: 1189, // Width less than or equal to 1024px
                      settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        infinite: true,
                        dots: true,
                      },
                    },
                    {
                      breakpoint: 938, // Width less than or equal to 1024px
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true,
                      },
                    },
                  ]}
                >
                  {myCoaches?.slice(0, 10).map((coach: any) => {
                    return <CoachCard coach={coach} />;
                  })}
                </Slider>
              </div>
            </div>
          )}

          {(followedEmployers ?? []).length > 0 && (
            <div
              className="talentRecommended__recommendations-container"
              style={{ marginTop: "80px" }}
            >
              <div className="talentRecommended__recommendations-container-title">
                <div>Followed Employers</div>
              </div>
              <div
                className="talentRecommended__recommendations-slider"
                style={{ width: "103%" }}
              >
                <Slider
                  dots={true}
                  slidesToShow={Math.min(followedEmployers.length, 3)}
                  slidesToScroll={3}
                  arrows={false}
                  responsive={[
                    {
                      breakpoint: 1189, // Width less than or equal to 1024px
                      settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        infinite: true,
                        dots: true,
                      },
                    },
                    {
                      breakpoint: 938, // Width less than or equal to 1024px
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true,
                      },
                    },
                  ]}
                >
                  {followedEmployers?.slice(0, 10).map((follEmp: any) => (
                    <RecommendedCompanies
                      employer={{ ...follEmp, followed: true }}
                    />
                  ))}
                </Slider>
              </div>
            </div>
          )}
        </motion.div>
      ) : (
        <TailSpin
          height="160"
          width="160"
          color="#FBAD84"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{
            justifySelf: "center",
            alignSelf: "center",
          }}
          wrapperClass="tail-spin"
          visible={true}
        />
      )}

      {toggleModal && (
        <EditPreferencesModal handleToggleModal={handleToggleModal} />
      )}
    </AnimatePresence>
  );
};

export default TalentDashboard;
