import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

type jwtPayload = {
  token: string;
  exp: number;
};

export const setJWTToken = (
  access: string,
  refresh: string,
  role: string,
  on_boarding_completed: boolean,
) => {
  Cookies.set("access-token", access);
  Cookies.set("refresh-token", refresh);
  Cookies.set("boarding-completed", JSON.stringify(on_boarding_completed));
  Cookies.set("role", role);
};

export const getAccessToken = () => {
  const accessToken = Cookies.get("access-token") || "";
  if (!accessToken) return false;

  const decodedToken = jwtDecode<jwtPayload>(accessToken);

  // Get the current time in seconds since the Unix epoch
  const currentTime = Math.floor(Date.now() / 1000);

  // Check if the token is expired
  if (decodedToken.exp < currentTime) {
    Cookies.remove("access-token");
    window.location.href = "/login";
  } else {
    return accessToken;
  }
};

export const handleLogout = () => {
  Cookies.remove("access-token");
  Cookies.remove("refresh-token");
  Cookies.remove("boarding-completed");
  Cookies.remove("role");
  window.location.reload();
};
