import React, { FC, useEffect, useState } from "react";
import Slider from "react-slick";

import { AnimatePresence, motion } from "framer-motion";
import { getEmployerRecommended } from "../../redux/EmployerSlice/EmployerSlice";
import { AppDispatch } from "../../redux/store/store";

import { useDispatch, useSelector } from "react-redux";

import DashboardNavbar from "../../components/DashboardNavbar/DashboardNavbar";

import "./EmployerRecommendedTalents.scss";
import { Link } from "react-router-dom";
import { Premium } from "../../assets/icons";

import { TalentCard } from "../../components";

import { TailSpin } from "react-loader-spinner";

const TalentRecommended: FC = () => {
  // States
  const [loading, setLoading] = useState<boolean>(true);

  // Selectors
  const profileInfo = useSelector((state: any) => state.profile.profileInfo);
  const dashboardRecommended = useSelector(
    (state: any) => state.employer.dashboardRecommended
  );
  const dispatch = useDispatch<AppDispatch>();

  const dashRecommendedTalents = dashboardRecommended;

  useEffect(() => {
    setLoading(true);

    if (profileInfo?.first_name && dashRecommendedTalents) {
      setLoading(false);
    }
  }, [profileInfo, dashRecommendedTalents]);

  useEffect(() => {
    if (!dashRecommendedTalents) {
      dispatch(getEmployerRecommended());
    }
  }, []);

  return (
    <AnimatePresence>
      {!loading ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="talentRecommended__box"
        >
          <DashboardNavbar />
          <main className="talentRecommended">
            <motion.div className="talentRecommended__heading">
              <h1>Tribaja Recommended Talent!</h1>
            </motion.div>
            <motion.section className="talentRecommended__container">
              {(dashRecommendedTalents ?? []).length > 0 && (
                <div className="talentRecommended__recommendations-container">
                  <div className="talentRecommended__recommendations-container-title">
                    <div>
                      Recommended Talents
                      {/* <Premium /> */}
                    </div>
                  </div>
                  <div className="talentRecommended__recommendations-slider">
                    <div
                      id="MainDiv"
                      style={{ display: "flex", marginBottom: "20px" }}
                    >
                      <div
                        id="Mentor-tiles"
                        className="tiles-grid"
                        style={{
                          marginTop: "40px",
                          justifyContent: "space-evenly",
                        }}
                      >
                        {dashRecommendedTalents.map((talent: any) => (
                          <TalentCard talent={talent} />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </motion.section>
          </main>
        </motion.div>
      ) : (
        <TailSpin
          height="160"
          width="160"
          color="#FBAD84"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{
            justifySelf: "center",
            alignSelf: "center",
          }}
          wrapperClass="tail-spin"
          visible={true}
        />
      )}
    </AnimatePresence>
  );
};

export default TalentRecommended;
