import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import "./PartnerDashboard.scss";
import { useDispatch, useSelector } from "react-redux";
import ProfileDetailCard from "../../components/ProfileDetailCard/ProfileDetailCard";
import {
  AffiliateClick,
  PendingApprovals,
  Sent,
  SubmittedResource,
} from "../../assets/icons";
import { useNavigate } from "react-router-dom";
import DashboardNavbar from "../../components/DashboardNavbar/DashboardNavbar";
import { getPartnerDashboard } from "../../redux/PartnerSlice/PartnerSlice";
import { AppDispatch } from "../../redux/store/store";
import { TailSpin } from "react-loader-spinner";
import Slider from "react-slick";
import { ResourceCard } from "../../components";
import { CopyinClipboard } from "../../utils/CopyinClipboard";
const PartnerDashboard = () => {
  const [loading, setLoading] = useState(true);
  const [approvedResources, setApprovedResources] = useState([]);
  const [copytoClipboard, setCopytoClipboard] = useState<boolean>(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 700);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 700);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Selectors
  const profileInfo = useSelector((state: any) => state.profile.profileInfo);

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { dashboard } = useSelector((state: any) => state.partner);

  useEffect(() => {
    // dispatch action to fetch profile info
    dispatch(getPartnerDashboard()).then((res) => {
      if (!res.payload?.is_verified) {
        navigate("/partner-approval");
      } else {
        const approvedResourcesArray =
          res.payload?.resource_statistics?.resources.filter(
            (e: any) => e.status === "approved"
          );
        setApprovedResources(approvedResourcesArray);
      }
    });
  }, []);

  // LifeCylce events
  useEffect(() => {
    setLoading(true);

    if (profileInfo?.first_name && dashboard?.resource_statistics) {
      setLoading(false);
    }
  }, [profileInfo, dashboard]);

  const signUpUrl = "https://app.staging.tribaja.co/signup?referral=";

  return (
    <AnimatePresence>
      {!loading ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="partner__dashboard-box"
        >
          <DashboardNavbar addLogo={isMobileView} />
          <main className="partner__dashboard">
            <motion.div
              key={"dashboard-heading"}
              transition={{ duration: 0.3, delay: 0.3 }}
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              className="partner__dashboard-heading"
            >
              <h1>Welcome {profileInfo?.first_name}</h1>
              <div className="button-group">
                <button
                  onClick={() => {
                    if (!copytoClipboard) {
                      setCopytoClipboard(true);
                      CopyinClipboard(`${signUpUrl}${profileInfo?.pk}`);
                      setTimeout(() => {
                        setCopytoClipboard(false);
                      }, 5000);
                    }
                  }}
                >
                  {!copytoClipboard ? "Copy Affiliate Link" : "Copied"}
                </button>
                <button
                  className="postBtn"
                  onClick={() => navigate("/add-resource")}
                >
                  Post resource
                </button>
              </div>
            </motion.div>
            <motion.section
              transition={{ duration: 0.3, delay: 0.4 }}
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              className="partner__dashboard-container"
            >
              <div className="partner__dashboard-container-title">
                My profile
              </div>
              <div className="partner__dashboard-container-analytics">
                <ProfileDetailCard
                  icon={<SubmittedResource />}
                  title="Resources Submitted"
                  content={dashboard?.resource_statistics?.total}
                  background="#FDCEB5"
                />
                <ProfileDetailCard
                  icon={<PendingApprovals />}
                  title="Pending Approvals"
                  content={dashboard?.resource_statistics?.pending}
                  background="#FEEDCC"
                />
                <ProfileDetailCard
                  icon={<Sent />}
                  title="Posted Resources"
                  content={dashboard?.resource_statistics?.approved ?? 0}
                  background="#91C7B1"
                />
                <ProfileDetailCard
                  icon={<AffiliateClick />}
                  title="Affiliate Clicks"
                  content={dashboard?.affiliate_clicks ?? 0}
                  background="#BDAFEB"
                />
              </div>
              <div className="partner-dashboard-activities">
                <div
                  className="main__dashboard-recent-activities"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    overflow: "auto",
                    overflowX: "hidden",
                    maxHeight: "400px",
                  }}
                >
                  {/* Header First Row */}
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      color: "#1E1E2F",
                    }}
                  >
                    Stats
                  </div>
                  <div
                    style={{
                      fontSize: "12px",
                      fontWeight: "600",
                      color: "#344054",
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "14px",
                      minWidth: 800,
                      borderBottom: "1px solid #aaa",
                      paddingBottom: "8px",
                    }}
                  >
                    <div style={{ width: "100%", flex: 1 }}>
                      {" "}
                      Resource Title
                    </div>
                    {/* <div style={{ width: "100%", flex: 1 }}>
                      {" "}
                    </div> */}
                    <div
                      style={{
                        width: "100%",
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        // paddingRight:"24px"
                      }}
                    >
                      Status{" "}
                    </div>

                    <div
                      style={{
                        width: "100%",
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        // paddingRight: "34px",
                      }}
                    >
                      Links{" "}
                    </div>

                    <div
                      style={{
                        width: "100%",
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingRight: "68px",
                      }}
                    >
                      Clicks{" "}
                    </div>
                  </div>

                  {dashboard?.resource_statistics?.resources?.length === 0 && (
                    <div
                      style={{
                        height: "100px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minWidth: 800,
                      }}
                    >
                      No resources posted yet !
                    </div>
                  )}

                  {dashboard?.resource_statistics?.resources.map(
                    (res: any, index: number) => {
                      let status;
                      if (res?.status === "pending-approval") {
                        status = "Pending Approval";
                      } else if (res?.status === "approved") {
                        status = "Approved";
                      } else {
                        status = "Rejected";
                      }

                      return (
                        <>
                          <div
                            style={{
                              fontSize: "10px",
                              fontWeight: "400",
                              color: "#344054",
                              display: "flex",
                              // gap: 16,
                              gap: "80px",
                              minWidth: 800,
                              paddingBottom: "10px",
                              marginTop: "10px",
                              borderBottom: "1px solid #aaa",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                flex: 1,
                                color: "#5937CC",
                                lineHeight: "12.67px",
                                cursor: `${
                                  res.status === "approved" ? "pointer" : "auto"
                                }`,
                              }}
                              onClick={() => {
                                if (res.status === "approved")
                                  navigate(`/view-resource/${res.id}`);
                              }}
                            >
                              {" "}
                              {res?.program_title}{" "}
                            </div>
                            {/* <div
                              style={{
                                width: "100%",
                                flex: 1,
                                lineHeight: "12.67px",
                              }}
                            >
                              {" "}
                              {res?.resource_type?.[0]}
                            </div> */}

                            <div
                              style={{
                                width: "100%",
                                flex: 1,
                                lineHeight: "12.67px",
                                height: "fit-content",
                                padding: "6px 12px",
                                textAlign: "center",
                                borderRadius: 12,
                                color: "white",
                                background: `${
                                  status === "Pending Approval"
                                    ? "#FFA800"
                                    : status === "Approved"
                                    ? "#31BE00"
                                    : "#EE1D52"
                                }`,
                              }}
                            >
                              {" "}
                              {status}
                            </div>

                            <a
                              href={res?.program_link}
                              target="_blank"
                              rel="noreferrer"
                              style={{
                                width: "100%",
                                flex: 1,
                                lineHeight: "12.67px",
                                whiteSpace: "nowrap",
                                color: "#5937CC",
                              }}
                            >
                              {" "}
                              {res?.program_link.length > 35
                                ? res?.program_link.slice(0, 35) + "..."
                                : res?.program_link}
                            </a>

                            <div
                              style={{
                                width: "100%",
                                flex: 1,
                                lineHeight: "12.67px",
                              }}
                            >
                              {" "}
                              {res?.clicks ?? 0}
                            </div>
                          </div>

                          {index <
                            [
                              {
                                job: {
                                  id: "0934b10b-c16b-4aa0-b18a-cc0ea69bb365",
                                  title: "SEO",
                                  employer: "Marks & spencer",
                                  location: "USA",
                                  mode_of_work: ["On-site"],
                                },
                                date_applied: "2023-11-07T14:18:47.687027Z",
                                is_saved: true,
                              },
                            ].length -
                              1 && <hr style={{ marginTop: "20px" }} />}
                        </>
                      );
                    }
                  )}
                </div>
              </div>
              {approvedResources.length ? (
                <div className="partner__dashboard-resource">
                  <div className="partner__dashboard-resource-heading">
                    My Resources
                  </div>
                  {
                    <Slider
                      dots={false}
                      slidesToShow={Math.min(approvedResources?.length, 3)}
                      slidesToScroll={1}
                      autoplay={true}
                      autoplaySpeed={3000}
                      responsive={[
                        {
                          breakpoint: 1190, // Width less than or equal to 1190px
                          settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            infinite: true,
                          },
                        },
                        {
                          breakpoint: 940, // Width less than or equal to 940px
                          settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            infinite: true,
                          },
                        },
                      ]}
                    >
                      {approvedResources.map((r: any) => (
                        <ResourceCard resource={r} notSaved={true} />
                      ))}
                    </Slider>
                  }
                </div>
              ) : (
                ""
              )}
            </motion.section>
          </main>
        </motion.div>
      ) : (
        <TailSpin
          height="160"
          width="160"
          color="#FBAD84"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{
            justifySelf: "center",
            alignSelf: "center",
          }}
          wrapperClass="tail-spin"
          visible={true}
        />
      )}
    </AnimatePresence>
  );
};

export default PartnerDashboard;
