import React, { useState } from "react";
import { AdminFooter, FormButton, AdminNavBar, NavBar } from "../../../components";
import { Logo, PasswordHide, PasswordVisible, TribajaLogo } from "../../../assets/icons";

import { SubmitHandler, useForm } from "react-hook-form";

import "./AdminLoginScreen.scss";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { AppDispatch } from "../../../redux/store/store";
import {
  getProfile,
  loginAdmin,
  loginUser,
  updateProfileState,
} from "../../../redux/ProfileSlice/ProfileSlice";
import { setJWTToken } from "../../../utils/jwtToken";

interface LoginInputs {
  email: string;
  password: string;
}

const AdminLoginScreen = () => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<Boolean>(false);
  const [passwordHide, setPasswordHide] = useState<Boolean>(true);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { register, handleSubmit } = useForm<LoginInputs>();

  const onSubmit: SubmitHandler<LoginInputs> = (data) => {
    setErrorMessage("");
    setLoading(true);
    dispatch(loginAdmin({ ...data })).then((res) => {
      setLoading(false);
      if (res.type.includes("rejected")) {
        const errMessage: any = Object.values(res.payload);
        if (errMessage[0][0]?.length > 2) {
          setErrorMessage(errMessage[0][0]);
        } else {
          setErrorMessage("Something went wrong, please try again.");
        }
      } else {
        setJWTToken(
          res.payload.access,
          res.payload.refresh,
          res.payload.user.role,
          res.payload.user.on_boarding_completed
        );
        dispatch(
          updateProfileState({
            accessToken: res.payload.access,
            boardingCompleted: res.payload.user.on_boarding_completed,
            userRole: res.payload.user.role,
          })
        );
        dispatch(getProfile()).then((res) => {
          if (res.type.includes("rejected")) {
            setErrorMessage(res.payload.detail);
          } else {
            navigate("/admin/dashboard");
          }
        });
      }
    });
  };

  return (
    <>
      <div className="adminLogin">
        <div className="adminLogin__logo">
          <Link to="/">
            <TribajaLogo className="navbar__logo" />
          </Link>
        </div>

        <div className="adminLogin__container">
          <h2>Admin Login</h2>

          <form onSubmit={handleSubmit(onSubmit)}>
            <label>
              <span>Email</span>
              <input required {...register("email")} type="email" />
            </label>
            <label>
              <span>
                Enter Password
                <span onClick={() => setPasswordHide(!passwordHide)}>
                  {passwordHide ? <PasswordHide /> : <PasswordVisible />}
                </span>
              </span>
              <input
                required
                {...register("password")}
                type={passwordHide ? "password" : "text"}
              />
            </label>
            {errorMessage && (
              <span className="errorMessage">{errorMessage}</span>
            )}
            <FormButton text="Login" loading={Boolean(loading)} />
          </form>
        </div>
        <AdminFooter />
      </div>
    </>
  );
};

export default AdminLoginScreen;
