import { configureStore } from "@reduxjs/toolkit";
import ProfileReducer from "../ProfileSlice/ProfileSlice";
import TalentReducer from "../TalentSlice/TalentSlice";
import EmployerReducer from "../EmployerSlice/EmployerSlice";
import PartnerReducer from "../PartnerSlice/PartnerSlice";
import AdminReducer from "../AdminSlice/AdminSlice";

export const store = configureStore({
  reducer: {
    profile: ProfileReducer,
    talent: TalentReducer,
    employer: EmployerReducer,
    partner: PartnerReducer,
    admin: AdminReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
