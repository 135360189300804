import { FC, useEffect, useState } from "react";

import { useForm, SubmitHandler } from "react-hook-form";
import { FormButton } from "../../../../components";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import { getAccessToken } from "../../../../utils/jwtToken";
import api from "../../../../utils/api";

import "../ApplyJobModal.scss";
import { Button, Upload, UploadProps, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";

interface LinkInputs {
  email: string;
  phone_number: number;
  cover_letter: string;
  resume: File;
}

type Props = {
  onModalClose?: () => void;
  jobTitle: string;
  jobId: string;
};

const ApplyJobForm: FC<Props> = (props) => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const { register, handleSubmit } = useForm<LinkInputs>();

  const [resume, setResume] = useState<any>(null);

  const talentProfile = useSelector((state: any) => state.profile.profileInfo);
  const talentDashboard = useSelector((state: any) => state.talent.dashboard);

  const onSubmit: SubmitHandler<LinkInputs> = async (data) => {
    setLoading(true);
    const response = await handleApplyJob(data);
    if (resume) {
      await handleResumeUpload(response.id);
    } else {
      props.onModalClose?.();
    }
  };

  const getUploadUrlPromise = (id: string) =>
    axios.post(
      `${api}/talent/apply/job/resume_upload/`,
      {
        job_application: id,
      },
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    );

  const handleResumeUpload = async (id: any) => {
    try {
      getUploadUrlPromise(id).then((res: any) => {
        const url = res.data.s3_params.url;
        const formData = new FormData();

        Object.keys(res.data.s3_params.fields).forEach((key: any) => {
          formData.append(key, res.data.s3_params.fields[key]);
        });

        // Append the file
        formData.append("file", resume[0].originFileObj);

        // Use fetch or axios to send a POST request to the S3 bucket
        fetch(url, {
          method: "POST",
          body: formData,
        })
          .then((response) => {
            axios
              .post(
                `${api}/talent/apply/job/resume_upload/confirm/`,
                {
                  file_id: res.data.file_id,
                },
                {
                  headers: {
                    Authorization: `Bearer ${getAccessToken()}`,
                  },
                }
              )
              .then(() => {
                setLoading(false);
                message.success("Applied for job sucessfuly");
                props.onModalClose?.();
              });
          })
          .catch((error) => {
            console.error("Error:", error);
            message.success("Error occured");
            setLoading(false);
            props.onModalClose?.();
          });
      });
    } catch (err) {
      props.onModalClose?.();
      message.error(err);
      console.error("Error:", err);
    }
  };

  const handleApplyJob = async (data: any) => {
    try {
      const res = await axios.post(
        `${api}/talent/apply/job/${props.jobId}/`,
        data,
        {
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        }
      );
      return res.data;
    } catch (err) {
      props.onModalClose?.();
      message.error(err);
      console.error("Error:", err);
    }
  };

  const resumeProps: UploadProps = {
    name: "file",
    action: `${process.env.REACT_APP_API_URL ?? ""}/main/check/`,
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      setResume(info.fileList);
    },
  };

  return (
    <div className="editProfile__container">
      <form onSubmit={handleSubmit(onSubmit)}>
        <label>
          <span>Email*</span>
          <input
            {...register("email")}
            defaultValue={talentProfile?.email ?? ""}
            type="text"
            placeholder="Email.."
            required
          />
        </label>
        <label>
          <span>Contact Number*</span>
          <input
            {...register("phone_number")}
            defaultValue={talentDashboard?.phone_number}
            type="tel"
            placeholder="Write Here"
            required
          />
        </label>
        <div className="resume-box">
          <p>Resume</p>
          <Upload
            maxCount={1}
            method="POST"
            fileList={resume}
            style={{
              alignSelf: "start",
            }}
            {...resumeProps}
            accept=".doc,.pdf,.docx" // Include .docx in the accepted file types
            beforeUpload={(file) => {
              const allowedTypes = [
                "application/pdf",
                "application/msword", // For DOC files
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // For DOCX files
              ];

              if (!allowedTypes.includes(file.type)) {
                message.error("Only PDF, DOC, and DOCX files are allowed");
                return false; // Prevent the upload
              }

              return true; // Allow the upload for valid file types
            }}
          >
            <Button icon={<UploadOutlined />}>
              Click to upload / edit resume
            </Button>
          </Upload>
        </div>
        <span
          style={{
            fontSize: 14,
            color: "#bbb",
          }}
        >
          Upload your resume, otherwise your profile resume will be uploaded
        </span>
        <label style={{ width: "100%" }}>
          <span>Cover Letter</span>
          <textarea
            className="input"
            style={{ height: "100px" }}
            {...register("cover_letter")}
          />
        </label>

        {errorMessage && <span className="errorMessage">{errorMessage}</span>}
        <FormButton text="Submit" loading={Boolean(loading)} />
      </form>
    </div>
  );
};

export default ApplyJobForm;
