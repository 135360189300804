import React, { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import TechEventImage from "../../assets/images/largetTechEvent.png";

import axios from "axios";
import api from "../../utils/api";
import { getAccessToken } from "../../utils/jwtToken";

import { TailSpin } from "react-loader-spinner";
import { PaginationComp } from "../../components/PaginationComp/PaginationComp";
import { useSelector } from "react-redux";

const Event = () => {
  const [event, setEvent] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const dashboardExplore = useSelector(
    (state: any) => state.talent.dashboardExplore,
  );

  const event_data = dashboardExplore?.events_data;

  function formatDate(dateString: any) {
    const months = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    const date = new Date(dateString);
    const day = date.getDate();
    const month = months[date.getMonth()];

    return { day, month };
  }

  const EventTile = ({ event: eventObj }: any) => {
    return (
      <div
        className="job-tile"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div>
          <img
            src={eventObj?.event_image ? eventObj?.event_image : TechEventImage}
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "22px",
            }}
          >
            <div
              style={{
                fontWeight: 700,
                fontSize: "11px",
                lineHeight: "14px",
                color: "#5937CC",
                textAlign: "center",
              }}
            >
              {eventObj.event_date.month}{" "}
            </div>
            <div
              style={{
                fontWeight: 700,
                fontSize: "26px",
                lineHeight: "33px",
                color: "#000000",
              }}
            >
              {eventObj.event_date.day}{" "}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "300px",
              marginLeft: "10px",
              marginTop: "20px",
            }}
          >
            <div
              style={{ fontWeight: 700, fontSize: "16px", lineHeight: "24px" }}
            >
              {eventObj?.event_name}
            </div>

            <div
              className="event-desc"
              style={{
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "21px",
                color: "#6A6A6A",
                marginTop: "10px",
              }}
            >
              {eventObj.event_details}
            </div>
            <a
              style={{
                textDecoration: "none",
                outline: "none",
                flex: 1,
                borderRadius: "24px",
                border: "1px solid var(--Text-Primary)",
                background: "var(--Text-Primary)",
                color: "#f2f4f7",
                textAlign: "center",
                fontSize: "14px",
                fontWeight: 700,
                paddingBlock: "10px",
                marginTop: 24,
                cursor: "pointer",
              }}
              rel="noreferrer"
              target="_blank"
              href={eventObj?.event_link}
            >
              View Event
            </a>
          </div>
        </div>
      </div>
    );
  };

  const fetchEvent = (page: number) => {
    setLoading(true);
    const accessToken = getAccessToken();
    axios
      .get(`${api}/main/list/events?page=${page}&page_size=${30}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(function (response) {
        const formattedEvents = response.data?.results?.map((e: any) => {
          const formattedDate = formatDate(e.event_date);
          return { ...e, event_date: formattedDate };
        });
        setEvent(formattedEvents);
        setLoading(false);
      })
      .catch(function (error) {
        // Handle any errors
        setLoading(false);
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    fetchEvent(1);
  }, []);

  const handlePagination = (p: any) => {
    fetchEvent(p);
  };

  return (
    <AnimatePresence>
      {!loading ? (
        <motion.section className="viewAll__container">
          {event?.length > 0 && (
            <div className="viewAll__recommendations-container">
              <div className="viewAll__recommendations-container-card">
                {event?.map((e: any) => {
                  return (
                    <EventTile
                      event={{
                        ...e,
                        style: { marginBottom: "40px" },
                      }}
                    />
                  );
                })}
              </div>
            </div>
          )}
          <PaginationComp
            count={event?.count}
            page={event?.page}
            onChange={handlePagination}
          />
        </motion.section>
      ) : (
        <TailSpin
          height="160"
          width="160"
          color="#FBAD84"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{
            justifySelf: "center",
            alignSelf: "center",
          }}
          wrapperClass="tail-spin"
          visible={true}
        />
      )}
    </AnimatePresence>
  );
};

export default Event;
