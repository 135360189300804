import React, { useEffect, useState } from "react";
import "./PlanFeaturesTable.scss";
import { CheckItemIcon } from "../../assets/icons";

interface Plan {
  id: number;
  inclusion_benchmarking: boolean;
  verified_badge: boolean;
  tribaja_profile: boolean;
  anual_inclusion_awards: boolean;
  ats_integration: boolean;
  pipeline_dashboard: boolean;
  explore_page: boolean;
  social_media_features: boolean;
  slack_channel_feature: boolean;
  workshops_and_events: boolean;
  job_post_count: number;
  type: string;
  price: number;
}

interface Feature {
  key: keyof Plan;
  label: string;
}


const features: Feature[] = [
  { key: "tribaja_profile", label: "Tribaja Profile" },
  { key: "inclusion_benchmarking", label: "Inclusion Benchmarking" },
  { key: "verified_badge", label: "Verified Badge" },
  { key: "anual_inclusion_awards", label: "Annual Inclusion Awards" },
  { key: "ats_integration", label: "ATS Integration" },
  { key: "pipeline_dashboard", label: "Pipeline Dashboard" },
  { key: "explore_page", label: "Explore Page" },
  { key: "social_media_features", label: "Social Media Features" },
  { key: "slack_channel_feature", label: "Slack Channel Feature" },
  { key: "workshops_and_events", label: "Workshops and Events" },
  { key: "job_post_count", label: "Job Post" },
];

const PlanFeaturesTable = ({ subscriptionPlan }: any) => {
  const grow = {
    id: 43,
    inclusion_benchmarking: true,
    verified_badge: true,
    tribaja_profile: true,
    anual_inclusion_awards: true,
    ats_integration: true,
    pipeline_dashboard: true,
    explore_page: true,
    social_media_features: true,
    slack_channel_feature: true,
    workshops_and_events: true,
    job_post: false,
    type: "grow",
    price: 499,
  };

  const reversedSubscriptionPlan = subscriptionPlan.sort(
    (a: any, b: any) => a.id - b.id,
  );

  const plans = [...reversedSubscriptionPlan, grow];
  return (
    <div className="plan-features__container">
      <div className="plan-features__header"></div>
      {features.map((feature) => (
        <div className="plan-features__row" key={feature.key}>
          <div className="plan-features__row-feature">{feature.label}</div>
          {plans.map((plan: any) => (
            <div
              className="plan-features__row-plan"
              key={plan.id}
              style={{
                backgroundColor:
                  plan.type === "grow"
                    ? "#BDAFEB"
                    : plan.type === "scale"
                      ? "#FDCEB5"
                      : "#feedcc",
              }}
            >
              {plan[feature.key] && feature.key !== "job_post_count" ? (
                <CheckItemIcon />
              ) : feature.key === "job_post_count" && plan.id === 43 ? (
                "Unlimited"
              ) : feature.key === "job_post_count" ? (
                plan?.job_post_count
              ) : (
                ""
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default PlanFeaturesTable;







