import React from "react";

import "./AdminFooter.scss";

const AdminFooter = () => {
  return (
    <footer className="adminFooter">
      <div className="adminFooter__copyright">© 2024 Tribaja</div>
    </footer>
  );
};

export default AdminFooter;
