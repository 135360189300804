import React from 'react';
import './InitialsAvatar.scss';
import {getNameInitials} from "../../utils/getNameInitials";

interface Props {
    customStyles?: object,
    name:string
}
const InitialsAvatar = (props:Props) => {

    const {customStyles,name} = props

    const splitName =  name?.split(/\s+|-/);
    let initials = '';


    if (splitName) {
        if (splitName.length === 1) {
            // Single-word string, get the first and last letters
            initials = splitName[0][0] + splitName[0][splitName[0].length - 1];
        } else {
            // Multi-word string, get the initials of the words
            initials = getNameInitials(
                splitName?.[0],
                splitName?.[splitName.length - 1]
            );
        }
    }


    return (
        <div>
            <div className={'avatar'} style={customStyles}>
                {name!=='---'? initials: '---'}
            </div>
        </div>
    );
};

export default InitialsAvatar;