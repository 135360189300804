import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
// import { Pie } from "@ant-design/charts";
import { ReactComponent as MenuIcon } from "../../assets/icons/menuIcon.svg";

import { TailSpin } from "react-loader-spinner";

import "./ViewJobApplication.scss";
import DashboardNavbar from "../../components/DashboardNavbar/DashboardNavbar";
import { useNavigate, useParams } from "react-router-dom";
import {
  NoJobIcon,
  OpenRolesEmployer,
  PendingReviewEmployer,
  TimeToHireEmployer,
  TotalApplicantsEmployer,
} from "../../assets/icons";
import { EmployerAnalyticCard, TalentCard } from "../../components";
import { AppDispatch } from "../../redux/store/store";
import { getEmployerDashboard } from "../../redux/EmployerSlice/EmployerSlice";
import { ReactComponent as EditIcon } from "../../assets/icons/editIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/deleteIcon.svg";
import { Tag, Dropdown, message } from "antd";
import PostedJobCard from "../../components/PostedJobCard/PostedJobCard";
import axios from "axios";
import { getAccessToken } from "../../utils/jwtToken";
import api from "../../utils/api";
import { PaginationComp } from "../../components/PaginationComp/PaginationComp";

const ViewJobApplication: FC = () => {
  // States
  const [loading, setLoading] = useState<boolean>(true);
  const [applicants, setApplicants] = useState<any>([]);

  const { jobId } = useParams();

  // Dispatch
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();
  // Selectors
  const profileInfo = useSelector((state: any) => state.profile.profileInfo);
  const employerDashboard = useSelector(
    (state: any) => state.employer.dashboard,
  );

  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 700);
  const [customStyleForNav, setCustomStyleForNav] = useState({});

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 700);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isMobileView) {
      setCustomStyleForNav({
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "20px",
        padding: "20px 24px",
      });
    } else {
      setCustomStyleForNav({
        display: "flex",
        justifyContent: "end",
        marginBottom: "20px",
        padding: "20px 80px",
      });
    }
  }, []);

  // LifeCylce events
  useEffect(() => {
    setLoading(true);

    if (profileInfo?.first_name && employerDashboard?.job_statistics) {
      setLoading(false);
    }
  }, [profileInfo, employerDashboard]);

  useEffect(() => {
    setLoading(true);
    const accessToken = getAccessToken();
    axios
      .get(`${api}/job/job_applicants/?job_id=${jobId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(function (response) {
        setLoading(false);
        setApplicants(response.data);
      })
      .catch(function (error) {
        message.error("Job deletion unsuccesful");
        setLoading(false); // Handle any errors
        console.error("Error:", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchJobApplicants = (page: number) => {
    setLoading(true);
    const accessToken = getAccessToken();
    axios
      .get(`${api}/job/job_applicants/?job_id=${jobId}&&page=${page}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(function (response) {
        setLoading(false);
        setApplicants(response.data);
      })
      .catch(function (error) {
        setLoading(false); // Handle any errors
        console.error("Error:", error);
      });
  };

  const handlePagination = (p: any) => {
    fetchJobApplicants(p);
  };

  useEffect(() => {
    fetchJobApplicants(1);
  }, []);

  return (
    <AnimatePresence>
      {!loading ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="viewJobApplication__jobsPage-box"
        >
          <DashboardNavbar
            addLogo={isMobileView}
            customStyles={customStyleForNav}
          />
          <main className="viewJobApplication__jobsPage">
            <motion.div
              key={"dashboard-heading"}
              transition={{ duration: 0.3, delay: 0.3 }}
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              className="viewJobApplication__jobsPage-heading"
            >
              <h1>All Applicants</h1>
              <button
                onClick={() => {
                  navigate("/post/job");
                }}
              >
                Post a new job
              </button>
            </motion.div>
            <motion.section
              transition={{ duration: 0.3, delay: 0.4 }}
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              className="viewJobApplication__jobsPage-container"
            >
              {applicants?.results?.length > 0 ? (
                <div className="viewJobApplication__jobsPage-allApplicants">
                  {applicants?.results?.map((applicant: any) => {
                    return (
                      <TalentCard
                        resume={applicant.resume}
                        showResume={true}
                        talent={applicant.talent}
                      />
                    );
                  })}
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "100px",
                  }}
                >
                  <h1>No applicants yet!</h1>
                </div>
              )}
              {applicants?.results?.length > 0 && (
                <PaginationComp
                  count={applicants?.count}
                  page={applicants?.page}
                  onChange={handlePagination}
                />
              )}
            </motion.section>
          </main>
        </motion.div>
      ) : (
        <TailSpin
          height="160"
          width="160"
          color="#FBAD84"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{
            justifySelf: "center",
            alignSelf: "center",
          }}
          wrapperClass="tail-spin"
          visible={true}
        />
      )}
    </AnimatePresence>
  );
};

export default ViewJobApplication;
