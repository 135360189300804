import React, { FC, useEffect, useState } from "react";
import Slider from "react-slick";

import { AnimatePresence, motion } from "framer-motion";

import DashboardNavbar from "../../components/DashboardNavbar/DashboardNavbar";
import TechEventImage from "../../assets/images/largetTechEvent.png";

import "./TalentSavedItems.scss";
import { Link } from "react-router-dom";
import { Premium, Bookmark } from "../../assets/icons";
import debounce from "debounce";

import SearchBar from "../../components/ExploreSearchBar/ExploreSearchBar";
import { getTalentSavedItems } from "../../redux/TalentSlice/TalentSlice";
import {
  RecommendedCompanies,
  RecommendedJob,
  ResourceCard,
  CoachCard,
} from "../../components";

import { useDispatch, useSelector } from "react-redux";
import { TailSpin } from "react-loader-spinner";
import { Select, Tag, message } from "antd";
import { AppDispatch } from "../../redux/store/store";
import Cookies from "js-cookie";

type Search = {
  searchName: string;
  searchFilters: Array<string>;
};

const Option = Select.Option;
const TalentSavedItems: FC = () => {
  // States
  const [loading, setLoading] = useState<boolean>(true);
  const [searchString, setSearchString] = useState<string | null>(null);

  const dispatch = useDispatch<AppDispatch>();

  // Selectors
  const profileInfo = useSelector((state: any) => state.profile.profileInfo);
  const dashboardSavedItems = useSelector(
    (state: any) => state.talent.dashboardSavedItems,
  );

  const employerData = dashboardSavedItems?.saved_employers;
  const jobs = dashboardSavedItems?.saved_jobs;
  const coaches = dashboardSavedItems?.saved_coach;
  const resources = dashboardSavedItems?.saved_resources;

  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 700);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 700);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const savedSeaches =
    [
      {
        searchName: "Search Name",
        searchFilters: ["Search Filter1", "Search Filter2", "Search Filter3"],
      },
      {
        searchName: "Search Name2",
        searchFilters: [
          "Search2 Filter1",
          "Search2 Filter2",
          "Search2 Filter3",
        ],
      },
      {
        searchName: "Search Name3",
        searchFilters: [
          "Search3 Filter1",
          "Search3 Filter2",
          "Search3 Filter3",
        ],
      },
    ] || dashboardSavedItems?.saved_searches;

  useEffect(() => {
    setLoading(true);

    if (profileInfo?.first_name && dashboardSavedItems) {
      setLoading(false);
    }
  }, [profileInfo, dashboardSavedItems]);

  useEffect(() => {
    dispatch(getTalentSavedItems());
    // if (Cookies.get("role")==='talent') dispatch(getTalentSavedItems());
    // else {
    //   message.info("Not Implemented");
    // }
  }, []);

  // useEffect(() => {
  //   if (searchString) dispatch(getTalentSavedItems(searchString));
  //   else {
  //     dispatch(getTalentSavedItems("a")).then(() => setLoading(false));
  //   }
  // }, [searchString]);

  const SearchCard = ({ search }: { search: Search }) => {
    return (
      <div className="talentSaved-search-tile">
        {/* Review Paragraph */}

        <div className="talentSaved-search-name">{search?.searchName}</div>

        <div className="talentSaved-search-filter">
          {(search?.searchFilters ?? []).join(", ")}
        </div>
      </div>
    );
  };

  if (!Cookies.get("role")) {
    return null;
  }

  return (
    <AnimatePresence>
      {!loading ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="talentSaved__box"
        >
          <DashboardNavbar addLogo={isMobileView} />
          <main className="talentSaved">
            <motion.div className="talentSaved__heading">
              <h1>Saved Items</h1>
            </motion.div>
            {/* <div
              className="main__dashboard-container-analytics"
              style={{
                marginTop: "20px",
                paddingBottom: "40px",
                borderBottom: "0.5px #999999 solid",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "70%",
                }}
              >
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "#1E1E2F",
                    marginBottom: "10px",
                  }}
                >
                  Search
                </div>
                <SearchBar
                  onChange={debounce((event: any) => {
                    setSearchString(event.target.value);
                    // setLoading(true);
                  }, 600)}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "20%",
                }}
                className="filterSection"
              >
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "#1E1E2F",
                    marginBottom: "10px",
                  }}
                >
                  {" "}
                  Type{" "}
                </div>
                <Select className="select" placeholder="All">
                  {["Filter1", "Filter2", "Filter3", "Filter4"].map((state) => (
                    <Option
                      label={state}
                      value={state}
                      className="roleType__option"
                    >
                      {state}
                    </Option>
                  ))}
                </Select>
              </div>
            </div> */}

            <div
              className="main__dashboard-container-analytics"
              style={
                {
                  // marginTop: "20px",
                  // paddingBottom: "20px",
                  // borderBottom: "0.5px #999999 solid",
                }
              }
            >
              {/* Search Filters */}

              {/* <div style={{ display: "flex" }}>
                <div
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "20px",
                  }}
                >
                  {" "}
                  Search Filters:{" "}
                </div>

                <div
                  style={{
                    marginLeft: "20px",
                    display: "flex",
                    flexWrap: "wrap",
                    maxWidth: "400px",
                    gap: "10px",
                  }}
                >
                  {Array.from({ length: 4 }).map((_) => (
                    <Tag
                      color={"#EDEEF7"}
                      style={{
                        width: "fit-content",
                        height: "22px",
                        borderRadius: "22px",
                        color: "#344054",
                        fontSize: "10px",
                        fontWeight: "400",
                        textAlign: "center",
                      }}
                    >
                      Search Filters
                    </Tag>
                  ))}
                </div>
              </div> */}

              {/* Save View Saved Searches */}
              <div style={{ display: "flex", cursor: "pointer" }}>
                {/* Save this Search */}
                {/* <div
                  style={{
                    display: "flex",
                    paddingRight: "20px",
                    borderRight: "0.5px #999999 solid",
                  }}
                >
                  <Bookmark />{" "}
                  <span
                    style={{
                      marginLeft: "10px",
                      fontWeight: 400,
                      fontSize: "14px",
                    }}
                  >
                    {" "}
                    Save this Search
                  </span>
                </div> */}

                {/* View Saved Searches */}
                {/* <div
                  style={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <span
                    style={{
                      marginLeft: "10px",
                      fontWeight: 600,
                      fontSize: "14px",
                      textDecoration: "underline",
                    }}
                  >
                    {" "}
                    View Saved Search
                  </span>
                </div> */}
              </div>
            </div>

            <motion.section
              className="talentSaved__container"
              // style={{ marginTop: "40px" }}
            >
              {(jobs ?? []).length > 0 ? (
                <div className="talentSaved__recommendations-container">
                  <div className="talentSaved__recommendations-container-title">
                    <div>Saved Jobs</div>
                  </div>
                  <div className="talentSaved__recommendations-slider">
                    <Slider
                      dots={true}
                      slidesToShow={Math.min(jobs?.length, 3)}
                      slidesToScroll={1}
                      autoplay={true}
                      autoplaySpeed={3000}
                      arrows={false}
                      responsive={[
                        {
                          breakpoint: 1189, // Width less than or equal to 1024px
                          settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            infinite: true,
                            dots: true,
                          },
                        },
                        {
                          breakpoint: 938, // Width less than or equal to 1024px
                          settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            infinite: true,
                            dots: true,
                          },
                        },
                      ]}
                    >
                      {jobs?.map((job: any) => {
                        return (
                          <RecommendedJob
                            job={{
                              ...job,
                              style: { marginBottom: "40px" },
                            }}
                          />
                        );
                      })}
                    </Slider>
                  </div>
                </div>
              ) : null}

              {(coaches ?? []).length > 0 ? (
                <div
                  className="talentSaved__recommendations-container"
                  style={{ marginTop: "80px" }}
                >
                  <div className="talentSaved__recommendations-container-title">
                    <div>
                      Saved Coaches
                      <Premium />
                    </div>
                  </div>
                  <div className="talentSaved__recommendations-slider">
                    <Slider
                      dots={true}
                      slidesToShow={Math.min(coaches.length, 3)}
                      slidesToScroll={1}
                      arrows={false}
                      responsive={[
                        {
                          breakpoint: 1189, // Width less than or equal to 1024px
                          settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            infinite: true,
                            dots: true,
                          },
                        },
                        {
                          breakpoint: 938, // Width less than or equal to 1024px
                          settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            infinite: true,
                            dots: true,
                          },
                        },
                      ]}
                    >
                      {coaches.map((coach: any) => {
                        return <CoachCard coach={coach} />;
                      })}
                    </Slider>
                  </div>
                </div>
              ) : null}

              {(employerData ?? []).length > 0 ? (
                <div
                  className="talentSaved__recommendations-container"
                  style={{ marginTop: "80px" }}
                >
                  <div className="talentSaved__recommendations-container-title">
                    <div>
                      Saved Employers
                      {/* <Premium /> */}
                    </div>
                  </div>
                  <div className="talentSaved__recommendations-slider">
                    <Slider
                      dots={true}
                      slidesToShow={Math.min(employerData?.length, 3)}
                      slidesToScroll={1}
                      autoplay={true}
                      autoplaySpeed={3000}
                      arrows={false}
                      responsive={[
                        {
                          breakpoint: 1189, // Width less than or equal to 1024px
                          settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            infinite: true,
                            dots: true,
                          },
                        },
                        {
                          breakpoint: 938, // Width less than or equal to 1024px
                          settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            infinite: true,
                            dots: true,
                          },
                        },
                      ]}
                    >
                      {employerData?.map((e: any) => (
                        <RecommendedCompanies employer={e} />
                      ))}
                    </Slider>
                  </div>
                </div>
              ) : null}

              {(resources ?? []).length > 0 ? (
                <div
                  className="talentSaved__recommendations-container"
                  style={{ marginTop: "80px" }}
                >
                  <div className="talentSaved__recommendations-container-title">
                    <div>
                      Saved Resources
                      {/* <Premium /> */}
                    </div>
                  </div>
                  <div className="talentSaved__recommendations-slider">
                    <Slider
                      dots={true}
                      slidesToShow={Math.min(resources?.length, 3)}
                      slidesToScroll={1}
                      autoplay={true}
                      autoplaySpeed={3000}
                      arrows={false}
                      responsive={[
                        {
                          breakpoint: 1189, // Width less than or equal to 1024px
                          settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            infinite: true,
                            dots: true,
                          },
                        },
                        {
                          breakpoint: 938, // Width less than or equal to 1024px
                          settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            infinite: true,
                            dots: true,
                          },
                        },
                      ]}
                    >
                      {resources?.map((resource: any) => (
                        <ResourceCard resource={resource} />
                      ))}
                    </Slider>
                  </div>
                </div>
              ) : null}

              {/* {(savedSeaches ?? []).length > 0 ? (
                <div className="talentSaved__recommendations-container">
                  <div className="talentSaved__recommendations-container-title">
                    <div>Saved Searches</div>
                  </div>
                  <div className="talentSaved__recommendations-slider">
                    <Slider
                      dots={true}
                      slidesToShow={Math.min(savedSeaches?.length, 3)}
                      slidesToScroll={1}
                      autoplay={true}
                      autoplaySpeed={3000}
                    >
                      {savedSeaches?.map((search: Search) => (
                        <SearchCard search={search} />
                      ))}
                    </Slider>
                  </div>
                </div>
              ) : null} */}
            </motion.section>
          </main>
        </motion.div>
      ) : (
        <TailSpin
          height="160"
          width="160"
          color="#FBAD84"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{
            justifySelf: "center",
            alignSelf: "center",
          }}
          wrapperClass="tail-spin"
          visible={true}
        />
      )}
    </AnimatePresence>
  );
};

export default TalentSavedItems;
