import { FC, useEffect, useState, useCallback } from "react";

import { AnimatePresence, motion } from "framer-motion";

import DashboardNavbar from "../../components/DashboardNavbar/DashboardNavbar";

import "./EmployerExplore.scss";

import { Bookmark } from "../../assets/icons";
import debounce from "debounce";
import axios from "axios";
import { getAccessToken } from "../../utils/jwtToken";
import api from "../../utils/api";
import { AppDispatch } from "../../redux/store/store";

import { TalentCard } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { TailSpin } from "react-loader-spinner";
import { Select, Tag, message, Pagination } from "antd";
import { Collapse } from "antd";
import ExploreSearchBar from "../../components/ExploreSearchBar/ExploreSearchBar";
import { CloseCircleOutlined } from "@ant-design/icons";
const { Panel } = Collapse;
const Option = Select.Option;


type SavedSearch = {
  id: string;
  name_search: string;
  industry: string[];
  work_location: string[];
  schedule: string[];
  professional_level: string[];
};

const TalentRecommended: FC = () => {
  // States
  const [loading, setLoading] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string | null>(null);
  const [talent, setTalent] = useState<any>([]);

  //Set Query Params for the API
  const [queryParams, setQueryParams] = useState<any>("");
  const setqueryParams = (filter: any, type: any) => {
    let query = { ...queryParams };
    if (type === "industry") {
      setIndustry(filter ? filter : []);
      if (filter && filter.length > 0) {
        query.industries = filter;
      } else {
        delete query.industries;
      }
    }
    if (type === "workLocation") {
      setWorkLocation(filter ? filter : []);
      if (filter && filter.length > 0) {
        query.worklocation = filter;
      } else {
        delete query.worklocation;
      }
    }
    if (type === "roleType") {
      setschedule(filter ? filter : []);
      if (filter && filter.length > 0) {
        query.schedule = filter;
      } else {
        delete query.schedule;
      }
    }
    if (type === "experience") {
      setProfessionLevel(filter ? filter : []);
      if (filter && filter.length > 0) {
        query.professionallevel = filter;
      } else {
        delete query.professionallevel;
      }
    }
    setQueryParams(query);
  };
  
  // Selectors
  const profileInfo = useSelector((state: any) => state.profile.profileInfo);
  const profileDashboard = useSelector(
    (state: any) => state.employer.dashboard
  );
  const [sharedFields, setSharedFields] = useState<any>({});

  const dashboardExplore = useSelector(
    (state: any) => state.employer.dashboardExplore
  );

  const dashboardExploreTalents = dashboardExplore;
  const dispatch = useDispatch<AppDispatch>();

  const [searchSaved, setSearchSaved] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 700);
  const [savedSearchString, setSavedSearchString] = useState<SavedSearch[]>([]);
  const [industry, setIndustry] = useState([]);
  const [workLocation, setWorkLocation] = useState([]);
  const [schedule, setschedule] = useState([]);
  const [professionLevel, setProfessionLevel] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);

  const savedSearchObj = {
    industry: industry?.length > 0 ? industry?.[0] : "",
    name_search: searchString,
    professional_level: professionLevel?.[0],
    schedule: schedule?.[0],
    work_location: workLocation?.[0],
  };

  // Function to clear all filters
  const clearFilters = () => {
    // Resetting the individual filter states to their default values
    setIndustry([]);
    setWorkLocation([]);
    setschedule([]);
    setProfessionLevel([]);
    setSearchString("");

    // Resetting the query params
    setQueryParams({}); // Reset to an empty object

    // Reset pagination
    setCurrentPage(1);

    employerExploreSearch("");
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 700);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const internalFunc = async () => {
      const sharedFieldsValues: any = {};
      const promises: any = [];
      [
        "experience-level/",
        "industry",
        "work-locations/",
        "role-type/",
      ].forEach(async (elem) => {
        promises.push(
          axios.get(`${api}/shared/${elem}`, {
            headers: {
              Authorization: `Bearer ${getAccessToken()}`,
            },
          })
        );
      });
      let allData = await Promise.all(promises);
      allData = allData.map((allData) => allData.data);

      setSharedFields({
        experience: allData[0],
        industry: allData[1],
        workLocation: allData[2],
        roleType: allData[3],
      });
    };
    internalFunc();
  }, [dashboardExploreTalents]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const accessToken = getAccessToken();
  
      // Filter out empty values from queryParams
      const filteredParams = Object.fromEntries(
        Object.entries(queryParams).filter(
          ([, value]) => Array.isArray(value) ? value.length > 0 : value !== undefined && value !== null && value !== ''
        )
      ) as Record<string, string | string[]>;
  
      // Convert the filteredParams object to a URLSearchParams object
      const queryString = new URLSearchParams(
        Object.entries(filteredParams).reduce((acc, [key, value]) => {
          if (Array.isArray(value)) {
            acc[key] = value.join(',');
          } else {
            acc[key] = value;
          }
          return acc;
        }, {} as Record<string, string>)
      ).toString();
  
      try {
        const res = await axios.get(
          `${api}/recommend/search/talent?${queryString}&from=${(currentPage - 1) * 10}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setTalent(res.data);
      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [queryParams, currentPage]);
  
  

  const employerExploreSearch = (searchValue: any, size = 1) => {
    const accessToken = getAccessToken();
    axios
      .get(
        `${api}/recommend/search/talent${"?q=" + searchValue}&from=${
          (size - 1) * 10
        }`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        setTalent(res.data);
        setCurrentPage(size); // Set the current page
      });
  };

  const debouncedDispatch = useCallback(
    debounce((searchValue: any) => {
      employerExploreSearch(searchValue);
    }, 600),
    [] // Dependencies
  );

  const getEmployerSavedSearches = () => {
    const accessToken = getAccessToken();
    axios
      .get(`${api}/employer/saved/searches/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res: any) => {
        setSavedSearchString(res.data.results.map((elem: any) => elem));
      });
  };

  useEffect(() => {
    getEmployerSavedSearches();
  }, []);

  useEffect(() => {
    isSearchSaved(
      savedSearchObj,
      savedSearchString.map((elem: any) => elem)
    );
  }, [savedSearchObj]);

  const saveSearch = async () => {
    // Check if any fields are selected or if there's a search string
    if (
      !industry.length &&
      !workLocation.length &&
      !schedule.length &&
      !professionLevel.length &&
      !searchString
    ) {
      message.warning("Please select at least one filter or enter a search term before saving.");
      return;
    }
  
    try {
      await axios.post(
        `${api}/employer/saved/searches/`,
        {
          industry: industry?.length === 0 ? "" : industry,
          work_location: workLocation.length === 0 ? "" : workLocation,
          schedule: schedule.length === 0 ? "" : schedule,
          professional_level: professionLevel?.length === 0 ? "" : professionLevel,
          name_search: searchString,
          user: profileInfo?.pk,
        },
        {
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        }
      );
  
      getEmployerSavedSearches();
      message.success("Search is saved");
    } catch (error) {
      message.error(error.response.data);
    }
  };

  const unSaveSearch = async (id: string) => {
    try {
      await axios.delete(`${api}/employer/unsave_employer_search/${id}/`, {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      });
      setSavedSearchString(savedSearchString.filter((search: any) => search.id !== id));
      message.warning("Search is deleted");
    } catch (error) {
      message.error("Failed to delete search");
    }
  };
  

  const compareSearches = (obj1: any, obj2: any) => {
    return Object.keys(obj1).every(
      (key) => obj2.hasOwnProperty(key) && obj1[key] === obj2[key]
    );
  };

  const isSearchSaved = (singleObj: any, arrayOfObjs: any) => {
    setSearchSaved(
      arrayOfObjs?.some((obj: any) => compareSearches(singleObj, obj))
    );
  };

  return (
    <AnimatePresence>
      {!loading ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="employerExplore__box"
        >
          <DashboardNavbar addLogo={isMobileView} />
          <main className="employerExplore">
            <motion.div className="employerExplore__heading">
              <h1>Explore Talent</h1>
            </motion.div>
            <div className="employer-explore-searchbar">
              {/* Collapse Component for Mobile View */}
              <Collapse className="mobile-collapse">
                <Panel header="Filters" key="1">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      height: 95,
                    }}
                    className="filterSection"
                  >
                    <div
                      className="select-heading"
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        color: "#1E1E2F",
                        marginBottom: "10px",
                      }}
                    >
                      {" "}
                      Industry{" "}
                    </div>
                    <Select
                      optionFilterProp="label"
                      showSearch={true}
                      onChange={(e: any) => {
                        setqueryParams(e, "industry");
                      }}
                      className="select"
                      placeholder="All"
                      value={industry}
                      allowClear={true}
                    >
                      {(sharedFields["industry"] ?? []).map((ind: any) => (
                        <Option
                          label={ind.industry}
                          value={ind.industry}
                          className="roleType__option"
                        >
                          {ind.industry}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      height: 95,
                    }}
                    className="filterSection"
                  >
                    <div
                      className="select-heading"
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        color: "#1E1E2F",
                        marginBottom: "10px",
                      }}
                    >
                      {" "}
                      Work Location{" "}
                    </div>
                    <Select
                      optionFilterProp="label"
                      allowClear={true}
                      showSearch={true}
                      onChange={(e: any) => setqueryParams(e, "workLocation")}
                      className="select"
                      placeholder="All"
                      value={workLocation}
                    >
                      {(sharedFields["workLocation"] ?? []).map((ind: any) => (
                        <Option
                          label={ind.name}
                          value={ind.name}
                          className="roleType__option"
                        >
                          {ind.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      height: 95,
                    }}
                    className="filterSection"
                  >
                    <div
                      className="select-heading"
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        color: "#1E1E2F",
                        marginBottom: "10px",
                      }}
                    >
                      {" "}
                      Job Type{" "}
                    </div>
                    <Select
                      optionFilterProp="label"
                      allowClear={true}
                      showSearch={true}
                      onChange={(e: any) => setqueryParams(e, "roleType")}
                      className="select"
                      placeholder="All"
                      value={schedule}
                    >
                      {(sharedFields["roleType"] ?? []).map((ind: any) => (
                        <Option
                          label={ind.name}
                          value={ind.name}
                          className="roleType__option"
                        >
                          {ind.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      height: 95,
                    }}
                    className="filterSection"
                  >
                    <div
                      className="select-heading"
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        color: "#1E1E2F",
                        marginBottom: "10px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {" "}
                      Experience{" "}
                    </div>
                    <Select
                      optionFilterProp="label"
                      allowClear={true}
                      showSearch={true}
                      onChange={(e: any) => setqueryParams(e, "experience")}
                      className="select"
                      placeholder="All"
                      value={professionLevel}
                    >
                      {(sharedFields["experience"] ?? []).map((ind: any) => (
                        <Option
                          label={ind.name}
                          value={ind.name}
                          className="roleType__option"
                        >
                          {ind.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Panel>
              </Collapse>

              {/* Desktop view */}
              <div
                style={{
                  marginTop: "20px",
                  paddingBottom: "10px",
                  borderBottom: "0.5px #999999 solid",
                  gap: 40,
                }}
              >
                <div className="main__dashboard-container-analytics">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                    className="filterSection"
                  >
                    <div
                      className="select-heading"
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        color: "#1E1E2F",
                        marginBottom: "10px",
                      }}
                    >
                      {" "}
                      Industry{" "}
                    </div>
                    <Select
                      optionFilterProp="label"
                      allowClear={true}
                      showSearch={true}
                      onChange={(e: any) => {
                        setqueryParams(e, "industry");
                      }}
                      className="select"
                      placeholder="All"
                      value={industry}
                    >
                      {(sharedFields["industry"] ?? []).map((ind: any) => (
                        <Option
                          label={ind.industry}
                          value={ind.industry}
                          className="roleType__option"
                        >
                          {ind.industry}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                    className="filterSection"
                  >
                    <div
                      className="select-heading"
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        color: "#1E1E2F",
                        marginBottom: "10px",
                      }}
                    >
                      {" "}
                      Work Location{" "}
                    </div>
                    <Select
                      optionFilterProp="label"
                      allowClear={true}
                      showSearch={true}
                      onChange={(e: any) => setqueryParams(e, "workLocation")}
                      className="select"
                      placeholder="All"
                      value={workLocation}
                    >
                      {(sharedFields["workLocation"] ?? []).map((ind: any) => (
                        <Option
                          label={ind.name}
                          value={ind.name}
                          className="roleType__option"
                        >
                          {ind.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                    className="filterSection"
                  >
                    <div
                      className="select-heading"
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        color: "#1E1E2F",
                        marginBottom: "10px",
                      }}
                    >
                      {" "}
                      Job Type{" "}
                    </div>
                    <Select
                      optionFilterProp="label"
                      allowClear={true}
                      showSearch={true}
                      onChange={(e: any) => setqueryParams(e, "roleType")}
                      className="select"
                      placeholder="All"
                      value={schedule}
                    >
                      {(sharedFields["roleType"] ?? []).map((ind: any) => (
                        <Option
                          label={ind.name}
                          value={ind.name}
                          className="roleType__option"
                        >
                          {ind.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                    className="filterSection"
                  >
                    <div
                      className="select-heading"
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        color: "#1E1E2F",
                        marginBottom: "10px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {" "}
                      Experience{" "}
                    </div>
                    <Select
                      optionFilterProp="label"
                      allowClear={true}
                      showSearch={true}
                      onChange={(e: any) => setqueryParams(e, "experience")}
                      className="select"
                      placeholder="All"
                      value={professionLevel}
                    >
                      {(sharedFields["experience"] ?? []).map((ind: any) => (
                        <Option
                          label={ind.name}
                          value={ind.name}
                          className="roleType__option"
                        >
                          {ind.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        color: "#1E1E2F",
                        marginBottom: "10px",
                      }}
                    >
                      Search
                    </div>
                    <ExploreSearchBar
                      searchString={searchString}
                      onChange={(e: any) => {
                        setSearchString(e.target.value);
                        debouncedDispatch(e.target.value);
                      }}
                    />
                  </div>
                  <button
                    onClick={() => clearFilters()}
                    style={{
                      outline: "none",
                      border: "1px solid var(--Text-Primary)",
                      borderRadius: "24px",
                      width: "100%",
                      padding: "10px",
                      fontSize: "14px",
                      fontWeight: "700",
                      backgroundColor: "transparent",
                      color: "var(--Text-Primary)",
                      marginTop: "20px",
                    }}
                  >
                    Clear Filters
                  </button>
                </div>

                <div
                  style={{
                    width: "fit-content",
                    marginTop: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "8px",
                  }}
                >
                  {industry.length > 0 && (
                    <Tag
                      closeIcon={<CloseCircleOutlined />}
                      onClose={() => setqueryParams(null, "industry")}
                      color={"#EDEEF7"}
                      className="tagStyle"
                    >
                      {industry}
                    </Tag>
                  )}
                  {workLocation.length > 0 && (
                    <Tag
                      closeIcon={<CloseCircleOutlined />}
                      onClose={() => setqueryParams(null, "workLocation")}
                      color={"#EDEEF7"}
                      className="tagStyle"
                    >
                      {workLocation}
                    </Tag>
                  )}
                  {schedule.length > 0 && (
                    <Tag
                      closeIcon={<CloseCircleOutlined />}
                      onClose={() => setqueryParams(null, "roleType")}
                      color={"#EDEEF7"}
                      className="tagStyle"
                    >
                      {schedule}
                    </Tag>
                  )}
                  {professionLevel.length > 0 && (
                    <Tag
                      closeIcon={<CloseCircleOutlined />}
                      onClose={() => setqueryParams(null, "experience")}
                      color={"#EDEEF7"}
                      className="tagStyle"
                    >
                      {professionLevel}
                    </Tag>
                  )}
                  {searchString && (
                    <Tag
                      closeIcon={<CloseCircleOutlined />}
                      onClose={() => setSearchString("")}
                      color={"#EDEEF7"}
                      className="tagStyle"
                    >
                      {searchString}
                    </Tag>
                  )}
                </div>
              </div>
            </div>

            <div
              className="main__dashboard-container-analytics"
              style={{
                marginTop: "20px",
                paddingBottom: "20px",
                borderBottom: "0.5px #999999 solid",
              }}
            >
              {/* Search Filters */}

              <div style={{ display: "flex" }}>
                <div
                  className="employer-searchTags-sec"
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "20px",
                  }}
                >
                  {" "}
                  Saved Searches:{" "}
                </div>

                {savedSearchString?.length > 0 ? (
                  <div
                    style={{
                      marginLeft: "20px",
                      display: "flex",
                      flexWrap: "wrap",
                      maxWidth: "400px",
                      gap: "10px",
                    }}
                  >
                    <div className="employer-explore-searchTags">
                      {savedSearchString?.map((elem: any) => (
                        <Tag
                          onClick={() => {
                            // Set the search string
                            setSearchString(elem.name_search);
                            // Set the filters
                            setIndustry(elem.industry);
                            setWorkLocation(elem.work_location);
                            setschedule(elem.schedule);
                            setProfessionLevel(elem.professional_level);
                            // Perform the search
                            employerExploreSearch(elem.name_search);
                          }}
                          closeIcon={<CloseCircleOutlined />}
                          onClose={() => unSaveSearch(elem.id)}
                          color={"#EDEEF7"}
                          className="tagStyle"
                        >
                          {elem.name_search}
                        </Tag>
                      ))}
                    </div>
                  </div>
                ) : (
                  <span
                    style={{
                      fontSize: "12px",
                      marginTop: "4px",
                      marginLeft: "5px",
                    }}
                  >
                    No saved searches
                  </span>
                )}
              </div>

              {/* Save View Saved Searches */}
              <div style={{ display: "flex", cursor: "pointer" }}>
                {/* Save this Search */}
                <div
                  style={{
                    display: "flex",
                    paddingRight: "20px",
                  }}
                >
                  <Bookmark
                     className={`bookmark ${searchSaved ? "bookmarkChecked" : ""}`}
                     onClick={saveSearch}
                  />{" "}
                  <span
                    style={{
                      marginLeft: "10px",
                      fontWeight: 400,
                      fontSize: "14px",
                    }}
                  >
                    {" "}
                    Save this Search
                  </span>
                </div>
              </div>
            </div>
            <motion.section
              className="employerExplore__container"
              style={{ marginTop: "40px" }}
            >
              <div className="employerExplore__recommendations-container">
                <div className="employerExplore__recommendations-container-title"></div>
                <div className="employerExplore__recommendations-slider">
                  <div
                    id="MainDiv"
                    style={{ display: "flex", marginBottom: "20px" }}
                  >
                    <div
                      id="Mentor-tiles"
                      className="tiles-grid"
                      style={{
                        marginTop: "40px",
                        justifyContent: "space-evenly",
                      }}
                    >
                      {talent?.results?.map((talent: any) => (
                        <TalentCard talent={talent} />
                      ))}
                    </div>
                  </div>
                </div>
                {talent?.results?.length > 0 && (
                  <Pagination
                    style={{
                      alignSelf: "center",
                    }}
                    showSizeChanger={false}
                    current={currentPage}
                    total={talent?.total}
                    pageSize={10}
                    onChange={(page) => {
                      setCurrentPage(page);
                    }}
                  />
                )}
              </div>
            </motion.section>
          </main>
        </motion.div>
      ) : (
        <TailSpin
          height="160"
          width="160"
          color="#FBAD84"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{
            justifySelf: "center",
            alignSelf: "center",
          }}
          wrapperClass="tail-spin"
          visible={true}
        />
      )}
    </AnimatePresence>
  );
};

export default TalentRecommended;
