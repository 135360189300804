import React, { FC, useEffect, useState } from "react";
import "./RequestForDeleteModal.scss";
import { Deletion } from "../../assets/icons";
import { CloseSquare } from "../../assets/icons";
import { deleteProfile } from "../../redux/ProfileSlice/ProfileSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../../redux/store/store";
import Cookies from "js-cookie";
import { TailSpin } from "react-loader-spinner";
import { backdropOverFlowHide } from "../../utils/backdropOverFlowHide";
import { scrollToTop } from "../../utils/scrollToTop";

interface RequestForDeleteModalProps {
  handleCancelDelete: () => void;
}

const RequestForDeleteModal: FC<RequestForDeleteModalProps> = ({
  handleCancelDelete,
}) => {
  const [deleteInput, setDeleteInput] = useState("");
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { accountDeleteReason } = useSelector((state: any) => state.profile);

  const deleteBtnClassName = `${
    deleteInput === "delete" ? "delBtn" : "button2"
  }`;
  const deleteAccount = (e: any) => {
    e.preventDefault();
    setLoader(true);
    dispatch(deleteProfile(accountDeleteReason))
      .then(() => {
        setLoader(false);
        Cookies.remove("access-token");
        window.location.reload();
      })
      .catch((error) => {
        // Handle any errors that might occur during the deletion
        console.error("Deletion error:", error);
        setLoader(false);
      });
  };

  useEffect(() => {
    scrollToTop();
    backdropOverFlowHide();
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  return (
    <div
      onClick={(e) => {
        if (e.currentTarget === e.target) {
        }
      }}
      className="requestForDeleteModal__container"
    >
      {!loader ? (
        <div className="requestForDeleteModal">
          <CloseSquare
            onClick={handleCancelDelete}
            className="requestForDeleteModal_closeSquareLogo"
          />
          <Deletion
            style={{
              display: "flex",
              alignSelf: "center",
            }}
          />
          <h1>Saying Goodbye is Never Easy</h1>
          <p>
            We understand that you've made the decision to leave. Please be
            aware that once your account is deleted, all your information will
            be permanently removed.{" "}
          </p>
          <form>
            <label>To confirm the deletion, please type "delete" below. </label>
            <input
              type="text"
              placeholder="Write Here"
              onChange={(e) => setDeleteInput(e.target.value)}
              value={deleteInput}
            />

            <div>
              <button onClick={handleCancelDelete} className="button1">
                Cancel
              </button>
              <button
                disabled={deleteInput !== "delete"}
                onClick={deleteAccount}
                className={deleteBtnClassName}
              >
                Delete Account
              </button>
            </div>
          </form>
        </div>
      ) : (
        <TailSpin
          height="160"
          width="160"
          color="#FBAD84"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{
            justifySelf: "center",
            alignSelf: "center",
          }}
          wrapperClass="tail-spin"
          visible={true}
        />
      )}
    </div>
  );
};
export default RequestForDeleteModal;
