import React, { FC, useCallback, useEffect, useState } from "react";
import Slider from "react-slick";

import { AnimatePresence, motion } from "framer-motion";

import DashboardNavbar from "../../components/DashboardNavbar/DashboardNavbar";
import TechEventImage from "../../assets/images/largetTechEvent.png";

import "./TalentExplore.scss";
import { Link } from "react-router-dom";
import { Premium, Bookmark } from "../../assets/icons";
import debounce from "debounce";

import ExploreSearchBar from "../../components/ExploreSearchBar/ExploreSearchBar";
import { getTalentExplore } from "../../redux/TalentSlice/TalentSlice";
import {
  RecommendedCompanies,
  RecommendedJob,
  ResourceCard,
} from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { TailSpin } from "react-loader-spinner";
import { Select, Tag, message } from "antd";
import { AppDispatch } from "../../redux/store/store";
import axios from "axios";
import api from "../../utils/api";
import { getAccessToken } from "../../utils/jwtToken";
import { CloseCircleOutlined } from "@ant-design/icons";
import Modal from "../ProfileScreen/UserTypeProfiles/Modal";

const Option = Select.Option;
const TalentRecommended: FC = () => {
  // States
  const [loading, setLoading] = useState<boolean>(true);
  const [searchString, setSearchString] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState<any>(false);

  const dispatch = useDispatch<AppDispatch>();

  // Selectors
  const profileInfo = useSelector((state: any) => state.profile.profileInfo);
  const dashboardExplore = useSelector(
    (state: any) => state.talent.dashboardExplore
  );

  const employerData = dashboardExplore?.employer_data;
  const jobs = dashboardExplore?.jobs;
  const recentlyExploredjobs = dashboardExplore?.recently_explored?.jobs;
  const resource_data = dashboardExplore?.resource_data;
  const event_data = dashboardExplore?.events_data;
  const saved_searches = dashboardExplore?.saved_searches;

  const [saveToggle, setSaveToggle] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 700);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 700);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const debouncedDispatch = useCallback(
    debounce((searchValue: any) => {
      if (searchValue) dispatch(getTalentExplore(searchValue));
      else {
        dispatch(getTalentExplore("a")).then(() => setLoading(false));
      }
    }, 600),
    [dispatch] // Dependencies
  );

  function formatDate(dateString: any) {
    const months = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    const date = new Date(dateString);
    const day = date.getDate();
    const month = months[date.getMonth()];

    return { day, month };
  }

  const formattedEvents = event_data?.map((event: any) => {
    const formattedDate = formatDate(event.event_date);
    return { ...event, event_date: formattedDate };
  });

  // const resource_data = [
  //   {
  //     id: 1,
  //     program_title: "All About The Data ",
  //     program_link: "https://google.com",
  //     resource_type: ["Training", "Bootcamp"],
  //     description:
  //       "Aim to create new candidate pools from previously underrepresented groups in tech, and our continued efforts to support those candidates through admissions (including scholarships), classwork, and on to job hunting through mentorship and networking support  ",
  //   },
  //   {
  //     id: 1,
  //     program_title: "All About The Data ssss",
  //     program_link: "https://google.com",
  //     resource_type: ["Training", "Bootcamp"],
  //     description:
  //       "Aim to create new candidate pools from previously underrepresented groups in tech, and our continued efforts to support those candidates through admissions (including scholarships), classwork, and on to job hunting through mentorship and networking support",
  //   },

  //   {
  //     id: 1,
  //     program_title: "All About The Data ",
  //     program_link: "https://google.com",
  //     resource_type: ["Training", "Bootcamp"],
  //     description:
  //       "Aim to create new candidate pools from previously underrepresented groups in tech, and our continued efforts to support those candidates through admissions (including scholarships), classwork, and on to job hunting through mentorship and networking support",
  //   },
  // ];

  const saveSearch = async () => {
    // setLoading(true);
    setSaveToggle(true);
    try {
      await axios.post(
        `${api}/talent/save/search/`,
        {
          search: searchString,
        },
        {
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        }
      );
      // setLoading(false);
      setSaveToggle(false);
      if (searchString) dispatch(getTalentExplore(searchString));
      setSearchString("");
      message.success("Search is saved");
    } catch (error) {
      // setLoading(false);
      setSaveToggle(false);
      message.error(error.response.data);
    }
  };

  const unSaveSearch = async (searchString: string) => {
    // setLoading(true);
    setSaveToggle(false);
    try {
      await axios.delete(`${api}/talent/unsave/search/${searchString}/`, {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      });
      // setLoading(false);
      setSaveToggle(false);
      message.warning("Search is deleted");
    } catch (error) {
      // setLoading(false);
      setSaveToggle(true);
      // message.error(error.response.data);
    }
  };

  useEffect(() => {
    setLoading(true);

    if (profileInfo?.first_name && dashboardExplore) {
      setLoading(false);
    }
  }, [profileInfo, dashboardExplore]);

  useEffect(() => {
    dispatch(getTalentExplore("a"));
  }, []);

  const EventTile = ({ eventObj }: any) => {
    return (
      <div
        className="job-tile"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "390px",
          position: "relative",
        }}
      >
        <div>
          <img
            src={eventObj?.event_image ? eventObj?.event_image : TechEventImage}
            alt="tech"
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "22px",
            }}
          >
            <div
              style={{
                fontWeight: 700,
                fontSize: "11px",
                lineHeight: "14px",
                color: "#5937CC",
                textAlign: "center",
              }}
            >
              {eventObj.event_date.month}{" "}
            </div>
            <div
              style={{
                fontWeight: 700,
                fontSize: "26px",
                lineHeight: "33px",
                color: "#000000",
              }}
            >
              {eventObj.event_date.day}{" "}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "300px",
              marginLeft: "10px",
              marginTop: "20px",
            }}
          >
            <div
              style={{ fontWeight: 700, fontSize: "16px", lineHeight: "24px" }}
            >
              {eventObj.event_name}
            </div>

            <div
              className="event-desc"
              style={{
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "21px",
                color: "#6A6A6A",
                marginTop: "10px",
              }}
            >
              {eventObj.event_details}
            </div>

            <div
              onClick={() => setOpenModal(true)}
              style={{
                textDecoration: "none",
                outline: "none",
                flex: 1,
                borderRadius: "24px",
                border: "1px solid var(--Text-Primary)",
                background: "var(--Text-Primary)",
                color: "#f2f4f7",
                textAlign: "center",
                fontSize: "14px",
                fontWeight: 700,
                paddingBlock: "10px",
                marginTop: 24,
                cursor: "pointer",
              }}
              // rel="noreferrer"
              // target="_blank"
              // href={eventObj?.event_link}
              // href="coach-panel"
            >
              Show More
            </div>
          </div>
        </div>
        {openModal && <Modal eventObj={eventObj} setOpenModal={setOpenModal} />}
      </div>
    );
  };

  return (
    <AnimatePresence>
      {!loading ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="talentExplore__box"
        >
          <DashboardNavbar addLogo={isMobileView} />
          <main className="talentExplore">
            <motion.div className="talentExplore__heading">
              <h1>Popular on Tribaja</h1>
            </motion.div>
            <div
              className="main__dashboard-container-analytics"
              style={{
                marginTop: "20px",
                paddingBottom: "40px",
                borderBottom: "0.5px #999999 solid",
              }}
            >
              <div
                className="explore-searchbar"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "#1E1E2F",
                    marginBottom: "10px",
                  }}
                >
                  Search
                </div>
                <ExploreSearchBar
                  searchString={searchString}
                  
                  onChange={(e: any) => {
                    setSearchString(e.target.value);
                    debouncedDispatch(e.target.value);
                  }}
                />
              </div>

              {/* <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "20%",
                }}
                className="filterSection"
              >
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "#1E1E2F",
                    marginBottom: "10px",
                  }}
                >
                  {" "}
                  Type{" "}
                </div>
                <Select className="select" placeholder="All">
                  {["Filter1", "Filter2", "Filter3", "Filter4"].map((state) => (
                    <Option
                      label={state}
                      value={state}
                      className="roleType__option"
                    >
                      {state}
                    </Option>
                  ))}
                </Select>
              </div> */}
            </div>

            <div
              className="main__dashboard-container-analytics"
              style={{
                marginTop: "20px",
                paddingBottom: "20px",
                borderBottom: "0.5px #999999 solid",
              }}
            >
              {/* Search Filters */}

              <div className="searchTags-sec" style={{ display: "flex" }}>
                <div
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "20px",
                  }}
                >
                  {" "}
                  Saved Search Filters:{" "}
                </div>

                {saved_searches?.length > 0 ? (
                  <div className="explore-searchTags">
                    {saved_searches?.map((search: any) => (
                      <Tag
                        onClick={() => {
                          setSearchString(search);
                          dispatch(getTalentExplore(search));
                        }}
                        closeIcon={<CloseCircleOutlined />}
                        onClose={() => unSaveSearch(search)}
                        color={"#EDEEF7"}
                        style={{
                          width: "fit-content",
                          height: "22px",
                          borderRadius: "22px",
                          color: "#344054",
                          fontSize: "10px",
                          fontWeight: "400",
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                      >
                        {search}
                      </Tag>
                    ))}
                  </div>
                ) : (
                  <span
                    style={{
                      marginLeft: "10px",
                      fontSize: "12px",
                      marginTop: "2px",
                    }}
                  >
                    No saved searches
                  </span>
                )}
              </div>

              {/* Save View Saved Searches */}
              <div style={{ display: "flex", cursor: "pointer" }}>
                {/* Save this Search */}
                <div
                  style={{
                    display: "flex",
                    paddingRight: "20px",
                  }}
                >
                  <Bookmark
                    className={`bookmark ${
                      saved_searches.includes(searchString)
                        ? "bookmarkChecked"
                        : ""
                    }`}
                    onClick={saveSearch}
                  />{" "}
                  <span
                    style={{
                      marginLeft: "10px",
                      fontWeight: 400,
                      fontSize: "14px",
                    }}
                  >
                    {" "}
                    Save this Search
                  </span>
                </div>

                {/* View Saved Searches */}
                {/* <div
                  style={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <span
                    style={{
                      marginLeft: "10px",
                      fontWeight: 600,
                      fontSize: "14px",
                      textDecoration: "underline",
                    }}
                  >
                    {" "}
                    View Saved Search
                  </span>
                </div> */}
              </div>
            </div>
            <motion.section
              className="talentExplore__container"
              style={{ marginTop: "40px" }}
            >
              <div className="talentExplore__recommendations-container">
                <div className="talentExplore__recommendations-container-title">
                  <div>
                    Events
                    {/* <Premium /> */}
                  </div>
                  <Link to="/view-all/event">See all</Link>
                </div>
                {formattedEvents?.length > 0 ? (
                  <div className="talentExplore__recommendations-slider">
                    <Slider
                      dots={true}
                      slidesToShow={Math.min(formattedEvents?.length, 2)}
                      slidesToScroll={3}
                      autoplay={true}
                      autoplaySpeed={3000}
                      arrows={false}
                      responsive={[
                        {
                          breakpoint: 1189, // Width less than or equal to 1024px
                          settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            infinite: true,
                            dots: true,
                          },
                        },
                        {
                          breakpoint: 1089, // Width less than or equal to 1024px
                          settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            infinite: true,
                            dots: true,
                          },
                        },
                      ]}
                    >
                      {formattedEvents?.slice(0, 10).map((eve: any) => (
                        <EventTile eventObj={eve} />
                      ))}
                    </Slider>
                  </div>
                ) : (
                  <div style={{ fontSize: "28px", textAlign: "center" }}>
                    No events found !
                  </div>
                )}
              </div>

              {(employerData ?? []).length > 0 ? (
                <div
                  className="talentExplore__recommendations-container"
                  style={{ marginTop: "80px" }}
                >
                  <div
                    className="talentExplore__recommendations-container-title"
                    style={{ position: "relative" }}
                  >
                    <div>Employers</div>
                    <Link to="/view-all/company">See all</Link>
                  </div>
                  {employerData?.length > 0 ? (
                    <div className="talentExplore__recommendations-slider">
                      <Slider
                        dots={true}
                        slidesToShow={Math.min(employerData?.length, 3)}
                        slidesToScroll={3}
                        autoplay={true}
                        autoplaySpeed={3000}
                        arrows={false}
                        responsive={[
                          {
                            breakpoint: 1189, // Width less than or equal to 1024px
                            settings: {
                              slidesToShow: 2,
                              slidesToScroll: 2,
                              infinite: true,
                              dots: true,
                            },
                          },
                          {
                            breakpoint: 938, // Width less than or equal to 1024px
                            settings: {
                              slidesToShow: 1,
                              slidesToScroll: 1,
                              infinite: true,
                              dots: true,
                            },
                          },
                        ]}
                      >
                        {employerData?.slice(0, 10).map((e: any) => (
                          <RecommendedCompanies employer={e} />
                        ))}
                      </Slider>
                    </div>
                  ) : (
                    <div style={{ fontSize: "28px", textAlign: "center" }}>
                      No employers found !
                    </div>
                  )}
                </div>
              ) : null}

              <div className="talentExplore__recommendations-container">
                <div className="talentExplore__recommendations-container-title">
                  <div>Recommended Jobs</div>
                  <Link to="/view-all/job">See all</Link>
                </div>
                {jobs?.length > 0 ? (
                  <div className="talentExplore__recommendations-slider">
                    <Slider
                      dots={true}
                      slidesToShow={Math.min(jobs?.length, 3)}
                      slidesToScroll={3}
                      autoplay={true}
                      autoplaySpeed={3000}
                      arrows={false}
                      responsive={[
                        {
                          breakpoint: 1189, // Width less than or equal to 1024px
                          settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            infinite: true,
                            dots: true,
                          },
                        },
                        {
                          breakpoint: 938, // Width less than or equal to 1024px
                          settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            infinite: true,
                            dots: true,
                          },
                        },
                      ]}
                    >
                      {jobs?.slice(0, 10).map((job: any) => {
                        return (
                          <RecommendedJob
                            job={{
                              ...job,
                              style: { marginBottom: "40px" },
                            }}
                          />
                        );
                      })}
                    </Slider>
                  </div>
                ) : (
                  <div style={{ fontSize: "28px", textAlign: "center" }}>
                    No jobs found !
                  </div>
                )}
              </div>

              {recentlyExploredjobs?.length > 0 ? (
                <div className="talentExplore__recommendations-container">
                  <div className="talentExplore__recommendations-container-title">
                    <div>Recently Explored Jobs</div>
                    <Link to="/view-all/job">See all</Link>
                  </div>
                  {recentlyExploredjobs?.length > 0 ? (
                    <div className="talentExplore__recommendations-slider">
                      <Slider
                        dots={true}
                        slidesToShow={Math.min(recentlyExploredjobs?.length, 3)}
                        slidesToScroll={3}
                        autoplay={true}
                        autoplaySpeed={3000}
                        arrows={false}
                        responsive={[
                          {
                            breakpoint: 1189, // Width less than or equal to 1024px
                            settings: {
                              slidesToShow: 2,
                              slidesToScroll: 2,
                              infinite: true,
                              dots: true,
                            },
                          },
                          {
                            breakpoint: 938, // Width less than or equal to 1024px
                            settings: {
                              slidesToShow: 1,
                              slidesToScroll: 1,
                              infinite: true,
                              dots: true,
                            },
                          },
                        ]}
                      >
                        {recentlyExploredjobs?.slice(0, 10).map((job: any) => {
                          return (
                            <RecommendedJob
                              job={{
                                ...job,
                                style: { marginBottom: "40px" },
                              }}
                            />
                          );
                        })}
                      </Slider>
                    </div>
                  ) : (
                    <div style={{ fontSize: "28px", textAlign: "center" }}>
                      No recently explored jobs found !
                    </div>
                  )}
                </div>
              ) : null}

              {/* <div className="talentExplore__recommendations-container">
                <div className="talentExplore__recommendations-container-title">
                  <div>New</div>
                  <Link to="#">See all</Link>
                </div>
                <div className="talentExplore__recommendations-slider">
                  <Slider
                    dots={true}
                    slidesToShow={3}
                    slidesToScroll={1}
                    autoplay={true}
                    autoplaySpeed={3000}
                  >
                    <RecommendedCompanies />
                    <RecommendedCompanies />
                    <RecommendedCompanies />
                    <RecommendedCompanies />
                  </Slider>
                </div>
              </div> */}

              {(resource_data ?? []).length > 0 ? (
                <div className="talentExplore__recommendations-container">
                  <div className="talentExplore__recommendations-container-title">
                    <div>New Resources</div>
                    <Link to="/view-all/resource">See all</Link>
                  </div>
                  {resource_data?.length > 0 ? (
                    <div className="talentExplore__recommendations-slider">
                      <Slider
                        dots={true}
                        slidesToShow={Math.min(resource_data?.length, 3)}
                        slidesToScroll={3}
                        autoplay={true}
                        autoplaySpeed={3000}
                        arrows={false}
                        responsive={[
                          {
                            breakpoint: 1189, // Width less than or equal to 1024px
                            settings: {
                              slidesToShow: 2,
                              slidesToScroll: 2,
                              infinite: true,
                              dots: true,
                            },
                          },
                          {
                            breakpoint: 938, // Width less than or equal to 1024px
                            settings: {
                              slidesToShow: 1,
                              slidesToScroll: 1,
                              infinite: true,
                              dots: true,
                            },
                          },
                        ]}
                      >
                        {resource_data?.slice(0, 10).map((r: any) => (
                          <ResourceCard resource={r} />
                        ))}
                      </Slider>
                    </div>
                  ) : (
                    <div style={{ fontSize: "28px", textAlign: "center" }}>
                      No resources found !
                    </div>
                  )}
                </div>
              ) : null}
            </motion.section>
          </main>
        </motion.div>
      ) : (
        <TailSpin
          height="160"
          width="160"
          color="#FBAD84"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{
            justifySelf: "center",
            alignSelf: "center",
          }}
          wrapperClass="tail-spin"
          visible={true}
        />
      )}
    </AnimatePresence>
  );
};

export default TalentRecommended;
