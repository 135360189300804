import React, { useEffect, useRef, useState } from "react";

import { TailSpin } from "react-loader-spinner";
import { AnimatePresence, motion } from "framer-motion";
import DashboardNavbar from "../../components/DashboardNavbar/DashboardNavbar";

import "./AddResource.scss";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
import { Verified, VerifiedOtp } from "../../assets/icons";
import {
  addPartnerResource,
  getPartnerResourceTypes,
} from "../../redux/PartnerSlice/PartnerSlice";
import { AppDispatch } from "../../redux/store/store";
import { useNavigate } from "react-router-dom";

const Option = Select.Option;

const AddResource = () => {
  const [loading, setLoading] = useState(false);
  const [resourceAdded, setResourceAdded] = useState(false);
  const [resourceTypes, setResourceTypes] = useState([]);

  const [programLink, setProgramLink] = useState("");
  const [company, setCompany] = useState("");
  const [programTitle, setProgramTitle] = useState("");
  const [selectedResourceTypes, setSelectedResourceTypes] = useState([]);
  const [programDescription, setProgramDescription] = useState("");
  const [requirements, setRequirements] = useState("");
  const [perks, setPerks] = useState("");
  const [commitmentToDiversity, setCommitmentToDiversity] = useState("");
  const [cost, setCost] = useState("");
  const [paymentOptions, setPaymentOptions] = useState("");

  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 700);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 700);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let btnRef: any = useRef();

  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();

  // Selectors
  const profileInfo = useSelector((state: any) => state.profile.profileInfo);

  useEffect(() => {
    setLoading(true);

    if (profileInfo?.first_name) {
      setLoading(false);
    }
  }, [profileInfo]);

  useEffect(() => {
    dispatch(getPartnerResourceTypes()).then((data: any) =>
      setResourceTypes(data.payload),
    );
  }, []);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (btnRef.current) {
      btnRef.current.setAttribute("disabled", "disabled");
    }
    dispatch(
      addPartnerResource({
        program_link: programLink,
        company: company,
        program_title: programTitle,
        resource_type: selectedResourceTypes,
        program_description: programDescription,
        requirements: requirements,
        perks: perks,
        diversity_commitment: commitmentToDiversity,
        cost: cost,
        payment_options: paymentOptions,
      }),
    ).then(() => {
      if (btnRef.current) {
        btnRef.current.removeAttribute("disabled", "disabled");
      }
      setResourceAdded(true);
    });
  };

  return (
    <AnimatePresence>
      {!loading ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="addResource__dashboard-box"
        >
          <DashboardNavbar addLogo={isMobileView} />
          <main className="addResource__dashboard">
            <motion.div
              key={"dashboard-heading"}
              transition={{ duration: 0.3, delay: 0.3 }}
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              className="addResource__dashboard-heading"
            >
              <h1>Add a Resource</h1>
            </motion.div>
            <motion.section
              transition={{ duration: 0.3, delay: 0.4 }}
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              className="addResource__dashboard-container"
            >
              {resourceAdded && (
                <div className="addResource__dashboard-successfully">
                  <VerifiedOtp />
                  <p>Resource Added Successfully</p>
                  <span>
                    Congratulations! You've successfully added a new resource to
                    the platform. Your contribution will help the community
                    thrive and grow.{" "}
                  </span>
                  <div className="addResource__dashboard-successfully-button">
                    {/* <button>View</button> */}
                    <button onClick={() => navigate("/dashboard")}>
                      Back to Dashboard
                    </button>
                  </div>
                </div>
              )}

              {!resourceAdded && (
                <form onSubmit={handleSubmit}>
                  <div className="addResource__dashboard-container-form">
                    <label>
                      Program link*
                      <input
                        value={programLink}
                        onChange={(e) => setProgramLink(e.target.value)}
                        type="text"
                        placeholder=""
                      />
                    </label>
                    <label>
                      Company*
                      <input
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                        type="text"
                        placeholder=""
                      />
                    </label>
                    <label>
                      Program Title*
                      <input
                        value={programTitle}
                        onChange={(e) => setProgramTitle(e.target.value)}
                        type="text"
                        placeholder=""
                      />
                    </label>
                    <label>
                      Resource Type*
                      <Select
                        optionFilterProp="label"
                        showSearch={true}
                        mode="multiple"
                        className="select"
                        placeholder="Select all that applies"
                        onChange={(e) => setSelectedResourceTypes(e)}
                      >
                        {resourceTypes?.map((data: any) => (
                          <Option
                            label={data.name}
                            value={data.id}
                            className="roleType__option"
                          >
                            {data.name}
                          </Option>
                        ))}
                      </Select>
                    </label>
                    <label>
                      Program Description*
                      <textarea
                        value={programDescription}
                        onChange={(e) => setProgramDescription(e.target.value)}
                        placeholder="Any detail you want to add"
                      />
                    </label>
                    <label>
                      Requirements
                      <textarea
                        value={requirements}
                        onChange={(e) => setRequirements(e.target.value)}
                        placeholder="If applicable"
                      />
                    </label>
                    <label>
                      Perks*
                      <textarea
                        value={perks}
                        onChange={(e) => setPerks(e.target.value)}
                        placeholder="If applicable"
                      />
                    </label>
                    <label>
                      We show our commitment to diversity through?*
                      <textarea
                        value={commitmentToDiversity}
                        onChange={(e) =>
                          setCommitmentToDiversity(e.target.value)
                        }
                        placeholder="Write here"
                      />
                    </label>
                    <label>
                      Cost*
                      <input
                        value={cost}
                        onChange={(e) => setCost(e.target.value)}
                        type="number"
                        placeholder=""
                      />
                    </label>
                    <label>
                      Payment Options
                      <Select
                        optionFilterProp="label"
                        showSearch={true}
                        value={paymentOptions}
                        onChange={(e) => setPaymentOptions(e)}
                        className="select"
                        placeholder="Select all that applies"
                      >
                        {[
                          {
                            value: "free",
                            label: "Free",
                          },
                          {
                            value: "pay-in-full",
                            label: "Pay In Full",
                          },
                          {
                            value: "flexible-payment-plan",
                            label: "Flexible Payment Plan",
                          },
                          {
                            value: "income-share-agreement",
                            label: "Income Share Agreement",
                          },
                          {
                            value: "not-applicable",
                            label: "Not Applicable",
                          },
                        ]?.map((data: any) => (
                          <Option
                            label={data.label}
                            value={data.value}
                            className="roleType__option"
                          >
                            {data.label}
                          </Option>
                        ))}
                      </Select>
                    </label>
                  </div>
                  <button
                    ref={btnRef}
                    disabled={
                      !Boolean(cost) ||
                      !Boolean(commitmentToDiversity) ||
                      !Boolean(perks) ||
                      !Boolean(
                        programDescription ||
                          !Boolean(resourceTypes) ||
                          !Boolean(programTitle) ||
                          !Boolean(company),
                      )
                    }
                    type="submit"
                  >
                    Submit
                  </button>
                </form>
              )}
            </motion.section>
          </main>
        </motion.div>
      ) : (
        <TailSpin
          height="160"
          width="160"
          color="#FBAD84"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{
            justifySelf: "center",
            alignSelf: "center",
          }}
          wrapperClass="tail-spin"
          visible={true}
        />
      )}
    </AnimatePresence>
  );
};

export default AddResource;
