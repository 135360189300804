import React, {useState} from "react";
import {Bookmark, Pin, Teams, Verified} from "../../assets/icons";
import {EmployerBackground, Job, ProfileCover} from "../../assets/images";
import axios from "axios";
import {getAccessToken} from "../../utils/jwtToken";
import api from "../../utils/api";
import Cookies from "js-cookie";

import {useDispatch} from "react-redux";
import {AppDispatch} from "../../redux/store/store";

import "./VerifiedEmployer.scss";
import {
    getTalentDashboard,
    getTalentDashboardRecommended,
} from "../../redux/TalentSlice/TalentSlice";
import {TailSpin} from "react-loader-spinner";
import {log} from "console";
import InitialsAvatar from "../InitialsAvatar/InitialsAvatar";

type Props = {
    employer?: any;
};

const VerifiedEmployer = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [saveToggle, setSaveToggle] = useState(props.employer?.is_saved);
    const [isFollowed, setIsFollowed] = useState(props.employer?.is_following);
    const handleViewProfile = () => {
        window.location.href = `/profile/employer/${props?.employer?.id}`;
    };

    const dispatch = useDispatch<AppDispatch>();

    const followEmployer = async () => {
        setIsFollowed(true);
        await axios
            .post(
                `${api}/talent/follow_employer/`,
                {
                    talent: Cookies.get("id"),
                    employer: props?.employer?.id,
                },
                {
                    headers: {
                        Authorization: `Bearer ${getAccessToken()}`,
                    },
                }
            )
            .then((res) => {
                dispatch(getTalentDashboardRecommended("employers"));
                setIsFollowed(true);
            })
            .catch((err) => {
                setIsFollowed(false);
            });
    };
    const unfollowEmployer = async () => {
        setIsFollowed(false);
        await axios
            .request({
                method: "DELETE",
                url: `${api}/talent/follow_employer/`,
                data: {
                    employer_id: props?.employer?.id,
                },
                headers: {
                    Authorization: `Bearer ${getAccessToken()}`,
                },
            })
            .then((res) => {
                setIsFollowed(false);
            })
            .catch((err) => {
                setIsFollowed(true);
            });
    };
    const saveEmployer = async () => {
        setLoading(true);
        setSaveToggle(true);
        try {
            await axios.post(
                `${api}/${Cookies.get("role")}/save/employer/${props?.employer?.id}/`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${getAccessToken()}`,
                    },
                }
            );
            if (window.location.pathname === "/recommendations") {
                dispatch(getTalentDashboardRecommended("employers"));
            } else {
                dispatch(getTalentDashboard());
            }
            setLoading(false);
        } catch {
            setLoading(false);
        }
    };

    const unsaveEmployer = async () => {
        setLoading(true);
        setSaveToggle(false);
        try {
            await axios.delete(
                `${api}/${Cookies.get("role")}/delete/employer/${employer?.id}/`,
                {
                    headers: {
                        Authorization: `Bearer ${getAccessToken()}`,
                    },
                }
            );
            if (
                window.location.pathname === "/recommendations" ||
                window.location.pathname.includes("/view-all/coach")
            ) {
                dispatch(getTalentDashboardRecommended("employers"));
            } else {
                dispatch(getTalentDashboard());
            }
            setLoading(false);
        } catch {
            setLoading(false);
        }
    };

    const {employer} = props;

    return (
        <>
            <div className={"verifiedEmployer"} style={employer?.style || {}}>
                <div className="verifiedEmployer__bookmark">
                    <Bookmark
                        className={`bookmark ${saveToggle ? "bookmarkChecked" : ""}`}
                        onClick={
                            !loading
                                ? saveToggle
                                    ? unsaveEmployer
                                    : saveEmployer
                                : () => {
                                }
                        }
                    />
                </div>
                {employer?.images?.profile_image ? (
                    <img
                        className="profile"
                        src={employer?.images?.profile_image}
                        alt=""
                    />
                ) : (
                    <InitialsAvatar
                        name={employer?.company_name ? employer?.company_name : "---"}
                        customStyles={{
                            width: "86px",
                            height: "86px",
                            position: "absolute",
                            top: "50px",
                            left: "30px",
                            backgroundColor: "#fbad84",
                            fontSize: "22px",
                        }}
                    />
                )}
                <div
                    className="image"
                    style={{
                        backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.30), rgba(0, 0, 0, 0.30)), url(${ProfileCover})`,
                    }}
                ></div>
                <div className="verifiedEmployer__content">
                    <div className="name" style={{alignItems: "baseline"}}>
                        <div
                            style={{
                                display: "block",
                                whiteSpace: "nowrap",
                                width: "190px",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                            }}
                        >
                            {employer?.company_name || "------"}
                        </div>
                        {" "}
                        {employer?.is_verified ? (
                            <div style={{display: "flex"}}>
                                <Verified/>{" "}
                                <span style={{marginLeft: "6px"}}>Tribaja Verified</span>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>

                    <div className="tags">
                        {(employer?.industries ?? [])
                            .map((ind: any) => ind.industry.toUpperCase())
                            .join(" • ")}
                    </div>
                    <div className="details">
            <span>
              <Pin/> {employer?.location || "------"}
            </span>
                        <span>
              <Teams/> {(employer?.company_size ?? employer?.comapny_size) ?? "Unknown"} Employees
            </span>
                    </div>
                    <div className="buttons">
                        <button onClick={isFollowed ? unfollowEmployer : followEmployer}>
                            {isFollowed ? "Unfollow" : "Follow"}
                        </button>
                        <button className="invert" onClick={handleViewProfile}>
                            View Profile
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default VerifiedEmployer;
