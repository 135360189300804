import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Onboarding from "../../assets/images/Onboarding.png";
import OnboardingUser from "../../assets/images/onboardingUser.png";

import { ReactComponent as ArrowRight } from "../../assets/icons/arrowRight.svg";
import "./OnboardingScreen.scss";

import { Link, useNavigate } from "react-router-dom";
import NavBar from "../../components/NavBar/NavBar";
import Button from "../../components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store/store";
import {
  getTalentOnboardingQuestions,
  postTalentOnboardingQuestions,
} from "../../redux/TalentSlice/TalentSlice";
import Cookies from "js-cookie";
import {
  getEmployerOnboardingQuestions,
  postEmployerOnboardingQuestions,
} from "../../redux/EmployerSlice/EmployerSlice";
import {
  Explorer,
  Strategist,
  TechEnthusiast,
  Trailblazer,
} from "../../assets/images";

import { updateBoardingCompletion } from "../../redux/ProfileSlice/ProfileSlice";
import {
  BasicInclusivePractices,
  DevelopingInclusivePractices,
  ExemplaryInclusivePractices,
  OpportunityForImprovement,
} from "../../assets/icons";
import { TailSpin } from "react-loader-spinner";

const avatarImages: any = {
  "The Strategist": <Strategist />,
  "The Explorer": <Explorer />,
  "The Seeker": <TechEnthusiast />,
  "The Trailblazer": <Trailblazer />,
  "Exemplary Inclusive Practices": <ExemplaryInclusivePractices />,
  "Developing Inclusive Practices": <DevelopingInclusivePractices />,
  "Basic Inclusive Practices": <BasicInclusivePractices />,
  "Oppurtunity for Improvement": <OpportunityForImprovement />,
};

const OnboardingScreen = () => {
  const [activeStep, setActiveStep] = useState<number>(1);
  const [questions, setQuestions] = useState<any>([]);
  const [answers, setAnswers] = useState<any>([]);
  const [result, setResult] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const btnOptionStyles = {
    borderRadius: "12px",
    width: "100%",
    padding: "16px 10px",
  };

  const profileInfo = useSelector((state: any) => state.profile.profileInfo);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const role = Cookies.get("role");

  useEffect(() => {
    const fetchQuestions = (action: any) => {
      dispatch(action).then((res: any) => {
        if (res.type.includes("rejected")) navigate("/login");
        else setQuestions(res.payload);
      });
    };

    switch (role) {
      case "talent":
        fetchQuestions(getTalentOnboardingQuestions());
        break;
      case "employer":
        fetchQuestions(getEmployerOnboardingQuestions());
        break;
      default:
        break;
    }
  }, [dispatch, navigate, role]);

  useEffect(() => {
    if (profileInfo?.on_boarding_completed) {
      dispatch(updateBoardingCompletion());
      Cookies.set("boarding-completed", "true");
      navigate("/dashboard");
    }
  }, [profileInfo]);

  const handleAnswer = (data: any) => {
    if (answers.length + 1 === questions.length) {
      setLoading(true);
      dispatch(updateBoardingCompletion());
      Cookies.set("boarding-completed", "true");

      if (role === "talent") {
        dispatch(
          postTalentOnboardingQuestions([
            ...answers,
            {
              question: data.question,
              answer: data.id,
              user: profileInfo.pk,
            },
          ]),
        ).then((res) => {
          if (res.payload?.avatar) {
            setResult(res.payload);
            setActiveStep(3);
          }
        });
      }
      if (role === "employer") {
        dispatch(
          postEmployerOnboardingQuestions([
            ...answers,
            {
              question: data.question,
              answer: data.id,
              user: profileInfo.pk,
            },
          ]),
        ).then((res) => {
          if (res.payload?.avatar) {
            setResult(res.payload);
            setActiveStep(3);
          }
        });
      }
    } else {
      setAnswers((answers: any) => [
        ...answers,
        {
          question: data.question,
          answer: data.id,
          user: profileInfo.pk,
        },
      ]);
    }
  };

  const getPercentageCompletion = () => {
    return (answers.length / questions.length) * 100;
  };

  return (
    <div className="onboarding-container">
      <NavBar
        hide={true}
        back={activeStep === 2 ? true : false}
        disabled={answers.length === 0 || loading}
        disableNavigation={true}
        backAction={() => {
          if (activeStep === 2) {
            setAnswers(answers.slice(0, -1));
          }
        }}
      />
      <div className="onboarding">
        {activeStep === 1 && (
          <div className="onboarding-section">
            <img src={Onboarding} height={670} width={506} alt="Onboarding" />
            <div className="onboarding-details-container">
              <div className="onboarding-description-container">
                <div className="onboarding-description">
                  ”As an Afro-Latina and single mother, I struggled to make ends
                  meet. I had a obtained my bachelors degree and worked a
                  corporate job but it wasn’t enough.
                </div>
                <div className="onboarding-description">
                  I faced barriers and bias trying to climb the ladder and so I
                  set out to make a career change. Transitioning into the tech
                  industry changed my life and now I help others to do the
                  same.”
                </div>
              </div>

              <div className="sector-details-container">
                <div className="sector-details">
                  <div className="salary-in-dollars">$67k</div>
                  <div className="designation-salary">Non-tech salary</div>
                </div>

                <div className="sector-details">
                  <div className="salary-in-dollars">$115k</div>
                  <div className="designation-salary">Tech salary</div>
                </div>
              </div>

              <Link
                onClick={() => setActiveStep(2)}
                className="buttonWithArrow"
                to={"#"}
              >
                Get started for free
                <ArrowRight />
              </Link>
            </div>
          </div>
        )}

        {activeStep === 2 && (
          <div className="talent-question-container">
            <div className="talent-question-section">
              <div className="progressive-bar-width">
                <div className="w3-light-grey w3-round-xlarge">
                  <div
                    style={{
                      width: `${getPercentageCompletion()}%`,
                    }}
                    className="w3-container w3-blue w3-round-xlarge"
                  />
                </div>
              </div>

              <div className="profile-info-container">
                <div className="profile-picture-info-container">
                  <div className="profile-container">
                    <img
                      src={OnboardingUser}
                      alt="Onboarding user"
                      className="profile-picture"
                    />
                  </div>
                  <div className="profile-username">Sabrina</div>
                </div>

                <div className="info-container">
                  <div className="info-description">
                    {!answers.length &&
                      "Welcome! My name is Sabrina and I will be your career guide. "}
                    {questions[answers.length].question[0].question}
                  </div>
                </div>
              </div>

              <div className="btn-options">
                {!loading ? (
                  questions[answers.length].answers.map((data: any) => (
                    <div onClick={() => handleAnswer(data)}>
                      <Button type={"INVERTED"} style={btnOptionStyles}>
                        {data.answer}
                      </Button>
                    </div>
                  ))
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <TailSpin
                      height="160"
                      width="160"
                      color="#FBAD84"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{
                        justifySelf: "center",
                        alignSelf: "center",
                        display: "flex",
                      }}
                      wrapperClass="onboarding-spinner"
                      visible={true}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {activeStep === 3 && (
          <div className="talent-question-container">
            <div className="progressive-bar-width">
              <div className="w3-light-grey">
                <div className="w3-blue explorer-mainbar-percentage" />
              </div>
            </div>

            {/* <Explorer className="explorer-img-margin"  /> */}
            {avatarImages[result?.avatar ?? 0]}

            <div className="the-explorer">
              {result?.avatar ?? "Employer onboarding WIP"}
            </div>

            <div className="profile-info-container">
              <div className="profile-picture-info-container">
                <div className="profile-container">
                  <img
                    src={OnboardingUser}
                    alt="Onboarding user"
                    className="profile-picture"
                  />
                </div>
                <div className="profile-username">Sabrina</div>
              </div>

              <div className="info-container">
                <div className="info-section">
                  {/* <div className="info-header">Best Course of Action:</div> */}
                  <div className="info-description">
                    {result?.recommendation}
                  </div>
                </div>
              </div>
            </div>

            <div
              onClick={() => navigate("/dashboard")}
              className="dashboard-btn"
            >
              <Button>Go to Dashboard</Button>
            </div>
          </div>
        )}
      </div>

      <Footer />
    </div>
  );
};

export default OnboardingScreen;
