import React, { FC, useCallback, useEffect, useState } from "react";

import { AnimatePresence, motion } from "framer-motion";
import { Pagination, Select } from "antd";
import { Dropdown, Tag, Avatar } from "antd";
import type { MenuProps } from "antd";

import { TailSpin } from "react-loader-spinner";

import AdminNavBar from "../../../../components/AdminNavBar/AdminNavBar";
import { ReactComponent as MenuIcon } from "../../../../assets/icons/menuIcon.svg";

import { ReactComponent as EditIcon } from "../../../../assets/icons/editIcon.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/deleteIcon.svg";
import { ReactComponent as PreviewEye } from "../../../../assets/icons/previewEye.svg";

import MentorImage from "../../../../assets/images/mentor.png";

import SearchBar from "../../../../components/DashboardSearchbar/SearchBar";
import "./MainDashboard.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../redux/store/store";
import {
  deleteUserStaff,
  getUserStaff,
} from "../../../../redux/AdminSlice/AdminSlice";
import { Delete, Edit } from "../../../../assets/icons";
import DashboardNavbar from "../../../../components/DashboardNavbar/DashboardNavbar";
import AdminDeleteModal from "./AdminDeleteModal";
import { debounce } from "debounce";
import AdminEditSubcriptionModal from "./AdminEditSubcriptionModal";
import AdminNewAccountModal from "./AdminNewAccountModal";

const Option = Select.Option;
const MainDashboard: FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [openDelModal, setDelModalOpen] = useState<string>("");
  const [createNewAccountModal, setCreateNewAccountModal] =
    useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<string>("");
  const [editSubscriptionModal, setEditSubscriptionModal] =
    useState<boolean>(false);
  const [search, setSearch] = useState<string>("");

  const [filter, setFilter] = useState<string>("All");

  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 700);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 700);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const dispatch = useDispatch<AppDispatch>();

  const { userStaff } = useSelector((state: any) => state.admin);

  const tableRef = React.useRef<HTMLDivElement>(null);

  // LifeCylce events
  useEffect(() => {
    dispatch(getUserStaff({ page, search, is_active: filter })).then(() => {
      setLoading(false);
    });
  }, [page, filter]);

  const getItems = (id: string, role: string): any =>
    [
      role === "employer" && {
        icon: <Edit />,
        key: "2",
        label: "Edit Annual Subscription",
        onClick: () => {
          setSelectedUserId(id);
          setEditSubscriptionModal(true);
        },
      },
      {
        icon: <Delete />,
        key: "1",
        label: "Delete User",
        onClick: () => {
          setDelModalOpen(id);
        },
      },
    ].filter(Boolean); // Filter out any false or null values

  const debouncedDispatch = useCallback(
    debounce((searchValue: any) => {
      dispatch(getUserStaff({ page, search: searchValue }));
    }, 800),
    [dispatch, page], // Dependencies
  );

  return (
    <>
      {Boolean(openDelModal) && (
        <AdminDeleteModal
          onSubmit={() => {
            dispatch(deleteUserStaff(openDelModal)).then(() => {
              dispatch(getUserStaff({ page, search }));
            });
            setDelModalOpen("");
          }}
          onModalClose={() => setDelModalOpen("")}
        />
      )}
      {editSubscriptionModal && (
        <AdminEditSubcriptionModal
          employerId={selectedUserId}
          onModalClose={() => setEditSubscriptionModal(false)}
        />
      )}
      {createNewAccountModal && (
        <AdminNewAccountModal
          onModalClose={() => setCreateNewAccountModal(false)}
        />
      )}
      <AnimatePresence>
        {!loading ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="main__dashboard-box"
          >
            <DashboardNavbar addLogo={isMobileView} />
            <main className="main__dashboard">
              <motion.div
                key={"dashboard-heading"}
                transition={{ duration: 0.3, delay: 0.3 }}
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                className="main__dashboard-heading"
              >
                <h1>User Management</h1>
                <button
                  onClick={() => {
                    setCreateNewAccountModal(true);
                  }}
                >
                  Create New Account
                </button>
              </motion.div>
              <motion.section
                transition={{ duration: 0.3, delay: 0.4 }}
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                className="main__dashboard-container"
              >
                {/* Search Section */}
                <div
                  className="main__dashboard-container-analytics"
                  style={{ marginTop: "20px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "70%",
                    }}
                    className="searchSection"
                  >
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        color: "#1E1E2F",
                        marginBottom: "10px",
                      }}
                    >
                      Search
                    </div>
                    <SearchBar
                      debouncedDispatch={debouncedDispatch}
                      disabled={false}
                      value={search}
                      page={page}
                      setSearch={setSearch}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "20%",
                    }}
                    className="filterSection"
                  >
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        color: "#1E1E2F",
                        marginBottom: "10px",
                      }}
                    >
                      {" "}
                      Filter{" "}
                    </div>
                    <Select
                      optionFilterProp="label"
                      showSearch={true}
                      onChange={(e) => setFilter(e)}
                      className="select"
                      placeholder="Select Status"
                      defaultValue="All"
                      value={filter}
                    >
                      {["All", "Active", "Inactive"].map((state) => (
                        <Option
                          label={state}
                          value={state}
                          className="roleType__option"
                        >
                          {state}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
                <div
                  className="main__dashboard-recent-activities"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    overflow: "auto",
                    maxHeight: "600px",
                    scrollBehavior: "smooth",
                  }}
                  ref={tableRef}
                >
                  {/* Header First Row */}
                  <div
                    style={{
                      fontSize: "13px",
                      fontWeight: "700",
                      color: "#344054",
                      display: "flex",
                      gap: 16,
                      // justifyContent: "space-between",
                      minWidth: 800,
                      borderBottom: "1px solid #aaa",
                      paddingBottom: "8px",
                    }}
                  >
                    <div style={{ width: "100%", flex: 1 }}> User Name </div>
                    <div style={{ width: "100%", flex: 1 }}> User Type </div>
                    <div style={{ width: "100%", flex: 1 }}> Email </div>

                    <div style={{ width: "100%", flex: 1 }}> Subscription </div>

                    <div style={{ width: "100%", flex: 1 }}> Status </div>
                  </div>

                  {userStaff.results.map((user: any, index: number) => (
                    <>
                      <div
                        style={{
                          fontSize: "12px",
                          fontWeight: "700",
                          color: "#344054",
                          display: "flex",
                          gap: 20,
                          // justifyContent: "space-between",
                          marginTop: "20px",
                          minWidth: 800,
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            flex: 1,
                            fontWeight: "400",
                            fontSize: "12px",
                            lineHeight: "12.67px",
                            color: "#5937CC",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          {user.first_name} {user.last_name}
                        </div>
                        <div
                          style={{
                            width: "100%",
                            flex: 1,
                            fontWeight: "400",
                            fontSize: "12px",
                            lineHeight: "12.67px",
                            alignItems: "center",
                            display: "flex",
                            textTransform: "capitalize",
                          }}
                        >
                          {" "}
                          {user.role}
                        </div>
                        <div
                          style={{
                            width: "100%",
                            flex: 1,
                            fontWeight: "400",
                            fontSize: "12px",
                            lineHeight: "12.67px",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          {" "}
                          {user.email}
                        </div>

                        <div
                          style={{
                            width: "100%",
                            flex: 1,
                            fontWeight: "400",
                            fontSize: "12px",
                            lineHeight: "12.67px",
                            alignItems: "center",
                            display: "flex",
                            textTransform: "capitalize",
                          }}
                        >
                          {" "}
                          {user?.subscription_status ?? "-"}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flex: "1",
                          }}
                        >
                          <div style={{ width: "100%", flex: 1 }}>
                            {" "}
                            <Tag
                              color={user?.is_active ? "#31BE00" : "#EE1D52"}
                              style={{
                                width: "62px",
                                height: "22px",
                                borderRadius: "22px",
                                color: "#FAF9FF",
                                fontSize: "10px",
                                fontWeight: "400",
                                textAlign: "center",
                              }}
                            >
                              {user?.is_active ? "Active" : "Not Active"}{" "}
                            </Tag>
                          </div>
                          <div>
                            <Dropdown
                              menu={{ items: getItems(user.id, user.role) }}
                              placement="bottomLeft"
                              trigger={["click"]}
                            >
                              <MenuIcon style={{ cursor: "pointer" }} />
                            </Dropdown>
                          </div>
                        </div>
                      </div>

                      {index < userStaff.length - 1 && (
                        <hr style={{ marginTop: "20px" }} />
                      )}
                    </>
                  ))}
                </div>
                <Pagination
                  style={{
                    marginInline: "auto",
                    marginTop: "36px",
                  }}
                  current={userStaff.page}
                  total={userStaff.count}
                  pageSize={userStaff.page_size}
                  showSizeChanger={false}
                  onChange={(e) => {
                    setPage(e);
                    if (tableRef.current) {
                      tableRef.current.scrollTo(0, 0);
                    }
                  }}
                />
              </motion.section>
            </main>
          </motion.div>
        ) : (
          <div className="loader-container">
            <TailSpin
              height="160"
              width="160"
              color="#FBAD84"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{
                justifySelf: "center",
                alignSelf: "center",
              }}
              wrapperClass="tail-spin"
              visible={true}
            />
          </div>
        )}
      </AnimatePresence>
    </>
  );
};

export default MainDashboard;
