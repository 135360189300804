import { FC, useEffect, useState } from "react";
import { Select, message } from "antd";
import { useSelector } from "react-redux";
import { useForm, SubmitHandler } from "react-hook-form";
import { FormButton } from "../../../../components";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store/store";
import { updateEmployerProfile } from "../../../../redux/EmployerSlice/EmployerSlice";
import axios from "axios";
import api from "../../../../utils/api";
import "../EditProfileModal.scss";
import { getAccessToken } from "../../../../utils/jwtToken";

const Option = Select.Option;

interface SignUpInputs {
  inclusive_space_matters: string;
  diversity_commitment: string;
  why_inclusion: string;
  description: string;
  number_of_female_employees: number;
  number_of_male_employees: number;
  number_of_non_binary_employees: number;
  company_size: string;
  company_highlights: string[];
}

type Props = {
  onModalClose?: () => void;
};

const EditProfileModal: FC<Props> = (props) => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [sharedFields, setSharedFields] = useState<any>();
  const [formFields, setFormFields] = useState<any>();
  const [highlights, setHighlights] = useState<any[]>([]);
  const [selectedHighlights, setSelectedHighlights] = useState<string[]>([]);
  const [selectedIndustryIds, setSelectedIndustryIds] = useState<number[]>([]);
  const [selectedKeyBenefits, setSelectedKeyBenefits] = useState<string[]>([]);

  const employerProfile = useSelector(
    (state: any) => state.employer.profileInfo
  );
  const { register, handleSubmit } = useForm<SignUpInputs>();
  const dispatch = useDispatch<AppDispatch>();

  const onFieldChange = (fieldKey: string, value: any) => {
    setFormFields({ ...formFields, [fieldKey]: value });
    if (fieldKey === "company_highlights") {
      setSelectedHighlights(value);
    }
    if (fieldKey === "industries") {
      setSelectedIndustryIds(value);
    }
    if (fieldKey === "key_benefits") {
      setSelectedKeyBenefits(value);
    }
  };

  useEffect(() => {
    const fetchSharedFields = async () => {
      try {
        const promises: any = [];
        ["benefits/", "industry", "highlights/"].forEach((endpoint) => {
          promises.push(
            axios.get(`${api}/shared/${endpoint}`, {
              headers: {
                Authorization: `Bearer ${getAccessToken()}`,
              },
            })
          );
        });
        let allData = await Promise.all(promises);
        allData = allData.map((data) => data.data);

        setSharedFields({
          benefits: allData[0],
          industry: allData[1],
          highlights: allData[2],
          stages_of_org: [
            "Corporation",
            "Early Stage",
            "Established",
            "Growth Stage",
            "Non profit",
            "Other",
            "Pre-Launch",
          ],
        });

        const defaultIndustryIds =
          employerProfile?.industries?.industries.map((industry: string) => {
            const matchedIndustry = allData[1].find(
              (ind: any) => ind.industry === industry
            );
            return matchedIndustry?.id;
          }) || [];
        const defaultHighlights =
          employerProfile?.demographics?.demographics?.company_highlights.map(
            (highlight: string) => {
              const matchedHighlight = allData[2].find(
                (ind: any) => ind.highlight_options === highlight
              );
              return matchedHighlight?.id;
            }
          ) || [];
        const defaultKeyBenefits =
          employerProfile?.demographics?.demographics?.company_benefits.map(
            (benefit: string) => {
              const matchedBenefit = allData[0].find(
                (ben: any) => ben.benefit_options === benefit
              );
              return matchedBenefit?.id;
            }
          ) || [];

        setSelectedIndustryIds(defaultIndustryIds);
        setSelectedHighlights(defaultHighlights);
        setSelectedKeyBenefits(defaultKeyBenefits);

        // setSelectedHighlights(
        //   employerProfile?.demographics?.demographics?.company_highlights || []
        // );
      } catch (error) {
        console.error("Error fetching shared fields:", error);
      }
    };

    fetchSharedFields();
  }, [employerProfile]);

  const onSubmit: SubmitHandler<SignUpInputs> = (data: any) => {
    setLoading(true);
    const payload: any = {};
    const _data = { ...data, ...formFields };

    // Filter out null values
    Object.keys(_data).forEach((key) => {
      if (_data[key] !== null) {
        payload[key] = _data[key];
      }
    });

    dispatch(updateEmployerProfile({ userId: employerProfile?.id, payload }))
      .then(() => {
        setLoading(false);
        message.success("Update successful");
        props.onModalClose?.();
      })
      .catch(() => {
        setLoading(false);
        message.error("Error updating");
        props.onModalClose?.();
      });
  };

  return (
    <>
      <div className="editProfile__container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <label>
            <span>Why Creating an Inclusive Workplace Matters?</span>

            <textarea
              className="input"
              style={{ height: "80px" }}
              {...register("inclusive_space_matters")}
              placeholder="Write Here"
              defaultValue={
                employerProfile?.demographics?.demographics
                  ?.inclusion_description || null
              }
            />
          </label>

          <label>
            <span>Company Description *</span>
            <textarea
              className="input"
              style={{ height: "80px" }}
              {...register("description")}
              placeholder="Write Here"
              defaultValue={
                employerProfile?.demographics?.demographics
                  ?.company_description || null
              }
              required
            />
          </label>

          <label>
            <span>Commitment to diversity *</span>
            <textarea
              className="input"
              style={{ height: "80px" }}
              {...register("diversity_commitment")}
              // type="text"
              placeholder="Write Here"
              defaultValue={
                employerProfile?.demographics?.demographics
                  ?.diversity_commitment || null
              }
              required
            />
          </label>

          <label>
            <label>
              <span>Inclusion *</span>
              <textarea
                className="input"
                style={{ height: "80px" }}
                {...register("why_inclusion")}
                // type="text"
                placeholder="Write Here"
                defaultValue={
                  employerProfile?.demographics?.demographics?.why_inclusion ||
                  null
                }
                required
              />
            </label>
          </label>

          <label>
            <span>Industry</span>
            <Select
              optionFilterProp="label"
              showSearch={true}
              onChange={(e: any) => onFieldChange("industries", e)}
              className="select"
              placeholder="Select"
              mode="multiple"
              key={selectedIndustryIds?.[0]}
              defaultValue={selectedIndustryIds}
            >
              {sharedFields?.["industry"]?.map((industry: any) => (
                <Option
                  key={industry.id}
                  label={industry.industry}
                  value={industry.id}
                  className="roleType__option"
                >
                  {industry.industry}
                </Option>
              ))}
            </Select>
          </label>
          <label>
            <span>Stage Of Organization</span>
            <Select
              optionFilterProp="label"
              showSearch={true}
              onChange={(e: any) => onFieldChange("stage_of_organization", e)}
              className="select"
              placeholder="Select"
              defaultValue={
                employerProfile?.demographics?.demographics
                  ?.stage_of_organization || null
              }
            >
              {sharedFields?.["stages_of_org"]?.map((stage: string) => (
                <Option
                  label={stage}
                  value={stage}
                  className="roleType__option"
                >
                  {stage}
                </Option>
              ))}
            </Select>
          </label>

          <label>
            <span>Number of Males</span>
            <input
              // style={{ height: "80px" }}
              {...register("number_of_male_employees")}
              type="number"
              placeholder="Write Here"
              defaultValue={
                employerProfile?.demographics?.demographics
                  ?.number_of_male_employees || null
              }
            />
          </label>

          <label>
            <span>Number of Females</span>
            <input
              // style={{ height: "80px" }}
              {...register("number_of_female_employees")}
              defaultValue={
                employerProfile?.demographics?.demographics
                  ?.number_of_female_employess || null
              }
              type="number"
              placeholder="Write Here"
            />
          </label>

          <label>
            <span>Number of Non-binary</span>
            <input
              // style={{ height: "80px" }}
              {...register("number_of_non_binary_employees")}
              type="number"
              placeholder="Write Here"
              defaultValue={
                employerProfile?.demographics?.demographics
                  ?.number_of_non_binary_employees || null
              }
            />
          </label>

          <label>
            <span>Company Size</span>
            <input
              {...register("company_size")}
              defaultValue={
                employerProfile?.demographics?.demographics?.company_size ||
                null
              }
              type="text"
              placeholder="Enter company size, e.g. 10-100, 100-200"
            />
          </label>

          <label>
            <span>Company Highlights</span>
            <Select
              optionFilterProp="label"
              showSearch={true}
              onChange={(e: any) => onFieldChange("company_highlights", e)}
              className="select"
              placeholder="Select Highlights"
              mode="multiple"
              key={selectedHighlights?.[0]}
              defaultValue={selectedHighlights}
            >
              {sharedFields?.["highlights"]?.map((highlight: any) => (
                <Option
                  key={highlight.id}
                  label={highlight.highlight_options}
                  value={highlight.id}
                  className="roleType__option"
                >
                  {highlight.highlight_options}
                </Option>
              ))}
            </Select>
          </label>

          {/* <label>
            <span>Key Benefits</span>
            <Select
              optionFilterProp="label"
              showSearch={true}
              onChange={(e: any) => onFieldChange("key_benefits", e)}
              className="select"
              placeholder="Select Multiple"
              mode="multiple"
              defaultValue={
                employerProfile?.demographics?.demographics?.company_benefits.map(
                  (elem: string) => elem
                ) || null
              }
            >
              {sharedFields?.["benefits"]?.map((ben: any) => (
                <Option
                  label={ben.benefit_options}
                  value={ben.id}
                  className="roleType__option"
                >
                  {ben.benefit_options}
                </Option>
              ))}
            </Select>
          </label> */}
          <label>
            <span>Key Benefits</span>
            <Select
              optionFilterProp="label"
              showSearch={true}
              onChange={(e: any) => onFieldChange("key_benefits", e)}
              className="select"
              placeholder="Select Multiple"
              mode="multiple"
              key={selectedKeyBenefits?.[0]}
              defaultValue={selectedKeyBenefits}
            >
              {sharedFields?.["benefits"]?.map((ben: any) => (
                <Option
                  key={ben.id}
                  label={ben.benefit_options}
                  value={ben.id}
                  className="roleType__option"
                >
                  {ben.benefit_options}
                </Option>
              ))}
            </Select>
          </label>
          {errorMessage && <span className="errorMessage">{errorMessage}</span>}
          <FormButton text="Submit" loading={Boolean(loading)} />
        </form>
      </div>
    </>
  );
};

export default EditProfileModal;
