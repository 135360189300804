import React from "react";

import { NotFound } from "../../assets/icons";
import "./NotFoundContainer.scss";
import { useNavigate } from "react-router-dom";

interface Props {
  admin?: boolean;
}

const NotFoundContainer: React.FC<Props> = ({ admin = false }) => {
  const navigate = useNavigate();

  return (
    <div className="notFoundContainer">
      <NotFound />
      <p>We seem to have run into a bit of a problem with this page.</p>
      <button
        onClick={() => navigate(admin ? `/admin/dashboard` : "/dashboard")}
      >
        Back to Home
      </button>
    </div>
  );
};

export default NotFoundContainer;
