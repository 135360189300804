import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { store } from "./redux/store/store";
import { Provider } from "react-redux";

import App from "./App";

import { ConfigProvider } from "antd";
import { GoogleOAuthProvider } from "@react-oauth/google";

import "@cyntler/react-doc-viewer/dist/index.css";
import "./index.scss";
import { SnackbarProvider } from "notistack";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  // <React.StrictMode>
  <>
    <ConfigProvider theme={{ token: { colorPrimary: "#5937CC" } }}>
      <Provider store={store}>
        <GoogleOAuthProvider
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ""}
        >
          <SnackbarProvider
            autoHideDuration={5000}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </SnackbarProvider>
        </GoogleOAuthProvider>
      </Provider>
    </ConfigProvider>
  </>,
  // </React.StrictMode>
);
