import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { FormButton, RecommendedJob } from "../../components";
import { useSelector } from "react-redux";
import axios from "axios";
import api from "../../utils/api";
import { getAccessToken } from "../../utils/jwtToken";
import { TailSpin } from "react-loader-spinner";
import { PaginationComp } from "../../components/PaginationComp/PaginationComp";
import { Link, useNavigate } from "react-router-dom";

import "./PaymentSuccessPage.scss"
import { SuccessIcon } from "../../assets/icons";

const PaymentSuccessPage = () => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate()


  return (
    <AnimatePresence>
      {!loading ? (
        <motion.section className="paymentSuccess__container">
          <div className="paymentSuccess-content">
            <SuccessIcon/>
            <h1>Payment Successful</h1>
            <span>Your job will be live in few minutes</span>
              <FormButton onClick={()=>navigate('/dashboard')} text="Go to dashboard" loading={false} customStyle={{width:'20%'}} />
          </div>
        </motion.section>
      ) : (
        <TailSpin
          height="160"
          width="160"
          color="#FBAD84"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{
            justifySelf: "center",
            alignSelf: "center",
          }}
          wrapperClass="tail-spin"
          visible={true}
        />
      )}
    </AnimatePresence>
  );
};

export default PaymentSuccessPage;
