import CryptoJS, { AES } from "crypto-js";

export async function encryptPassword(password: string) {
  const secretKeyHex = process.env.REACT_APP_SALT ?? "";
  // const secretKey = EncHex.parse(secretKeyHex);
  const secretKey = CryptoJS.enc.Hex.parse(secretKeyHex);

  const ciphertext = AES.encrypt(password, secretKey, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  }).toString();

  return ciphertext;
}
