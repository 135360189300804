import { FC, useEffect, useState } from "react";
import { Select, message } from "antd";
import { useForm, SubmitHandler } from "react-hook-form";
import { FormButton } from "../../../../components";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../redux/store/store";
import { useNavigate } from "react-router-dom";
import { Avatar } from "antd";
import MentorImage from "../../../../assets/images/mentor.png";
import countries from "../../../../utils/countriesList";

import {
  getEmployerDashboard,
  getEmployerLogoPic,
  updateEmployerProfile,
} from "../../../../redux/EmployerSlice/EmployerSlice";
import axios from "axios";
import api from "../../../../utils/api";

import "../EditProfileModal.scss";
import { getAccessToken } from "../../../../utils/jwtToken";
import type { UploadFile } from "antd/es/upload/interface";
import { Upload } from "antd";
import type { RcFile, UploadProps } from "antd/es/upload";
import { Delete } from "../../../../assets/icons";

const Option = Select.Option;

interface SignUpInputs {
  company_name: string;
  phone: number;
  newCompReg: string;
  company_website: Number;
  account_manager_email: string;
}

interface PasswordHidden {
  one: boolean;
  two: boolean;
}

type Props = {
  onModalClose?: () => void;
  handleDeleteAccount?: () => void;
};

const EditProfileModal: FC<Props> = (props) => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [sharedFields, setSharedFields] = useState<any>();
  const [formFields, setFormFields] = useState<any>({});
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [s3UploadUrl, setS3UploadUrl] = useState<string>("");
  const [key, setKey] = useState<string>("");
  const [fileId, setFileId] = useState<string>("");
  const employerProfile = useSelector(
    (state: any) => state.employer.profileInfo
  );
  const employerDashboard = useSelector(
    (state: any) => state.employer.dashboard
  );
  const dispatch = useDispatch<AppDispatch>();

  const handleDeleteAccount = props.handleDeleteAccount;

  const { register, handleSubmit } = useForm<SignUpInputs>();
  //   const dispatch = useDispatch<AppDispatch>();
  //   const navigate = useNavigate();

  const onSubmit: SubmitHandler<SignUpInputs> = (data) => {
    setLoading(true);

    const location_of_work = `${formFields.country}, ${formFields.state}, ${formFields.city}`;
    const payload = { ...data, location_of_work: location_of_work };
    dispatch(getEmployerDashboard());
    dispatch(updateEmployerProfile({ userId: employerProfile?.id, payload }))
      .then(() => {
        setLoading(false);
        message.success("Update successful");
        props.onModalClose?.();
      })
      .catch(() => {
        setLoading(false);
        message.error("Error updating");
        props.onModalClose?.();
      });
    return;
  };

  useEffect(() => {
    // This code is used for rendering country flags:
    // ref: https://www.twilio.com/blog/international-phone-number-input-html-javascript

    const phoneInputField = document.getElementById("phone");
    (window as any).intlTelInput(phoneInputField);
    const internalFunc = async () => {
      const promises: any = [];
      ["city", "state"].forEach(async (elem) => {
        promises.push(
          axios.get(`${api}/shared/${elem}/`, {
            headers: {
              Authorization: `Bearer ${getAccessToken()}`,
            },
          })
        );
      });
      let allData = await Promise.all(promises);
      allData = allData.map((allData) => allData.data);

      setSharedFields({
        city: allData[0],
        state: allData[1],
      });
    };
    internalFunc();
    getUploadUrlPromise("logo").then((response) => {
      const data = response.data;

      const fileId = data?.file_id;
      const uploadUrl = data?.s3_params?.url;
      const key = data?.s3_params?.fields?.key;
      setKey(key);
      setFileId(fileId);

      setS3UploadUrl(uploadUrl);
    });
  }, []);

  const getUploadUrlPromise = (fileType: "logo") =>
    axios.post(
      `${api}/employer/file/upload/`,
      {
        file_type: fileType,
      },
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    );
  const onFieldChange = (fieldKey: string, value: string | number) => {
    if (!value) return;
    const fieldToSetInState = { [fieldKey]: value };
    console.log(fieldToSetInState, formFields);
    setFormFields({ ...formFields, ...fieldToSetInState });
  };
  const handleChange: UploadProps["onChange"] = ({
    fileList: newFileList,
    file,
    event,
  }) => {
    setFileList(newFileList);

    const formData = new FormData();
    formData.append("key", key);
    fileList.forEach((file) => {
      formData.append("file", file as RcFile);
    });

    fetch(s3UploadUrl, {
      method: "POST",
      body: formData,
    })
      .then(() => {
        axios
          .post(
            `${api}/employer/file/upload/confirm/`,
            {
              id: fileId,
            },
            {
              headers: {
                Authorization: `Bearer ${getAccessToken()}`,
              },
            }
          )
          .then(() => {
            message.success("Updated Image successfully");
            dispatch(getEmployerLogoPic(employerProfile?.id));
          });
      })
      .catch(() => {
        message.error("upload failed.");
      })
      .finally(() => {});
  };

  useEffect(() => {
    if (employerProfile.demographics) {
      setFormFields({
        ...formFields,
        city:
          employerProfile?.demographics?.demographics?.location_of_work
            ?.split(", ")[2]
            .trim() || "",
        country:
          employerProfile?.demographics?.demographics?.location_of_work
            ?.split(", ")[0]
            .trim() || "",
        state:
          employerProfile?.demographics?.demographics?.location_of_work
            ?.split(", ")[1]
            .trim() || "",
      });
    }
  }, [employerProfile]);

  const uploadButton = (
    <div
      style={{
        marginLeft: "10px",
        marginTop: "20px",
        fontWeight: 600,
        fontSize: "16px",
        fontFamily: "Poppins",
        color: "#5937CC",
        cursor: "pointer",
      }}
      onClick={() => {
        const inputElements = document.getElementsByTagName("input");
        for (var i = 0; i < inputElements.length; i++) {
          var node = inputElements[i];

          if (node.getAttribute("type") == "file") {
            node.click();
            return;
          }
        }
      }}
    >
      Update Logo
      <div style={{ fontWeight: 300, fontSize: "10px", color: "#344054" }}>
        Make sure the file is below 2mb
      </div>
    </div>
  );

  return (
    <div className="editProfile__container">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            justifyContent: !(fileList?.length || employerProfile?.s3_params)
              ? "center"
              : "unset",
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            document.getElementById("fileUpload")?.click();
          }}
        >
          {employerProfile?.s3_params && !fileList?.length && (
            <Avatar
              style={{
                width: "74px",
                height: "74px",
                border: "1px solid",
                marginTop: "10px",
              }}
              src={employerProfile?.s3_params}
            />
          )}

          <Upload
            action={s3UploadUrl}
            listType="picture-circle"
            fileList={fileList}
            onChange={handleChange}
            beforeUpload={(file) => {
              // Check if the file size exceeds 2MB
              const isLt2M = file.size / 1024 / 1024 < 2;
              if (!isLt2M) {
                // Display an error message if the file size exceeds 2MB
                message.error("Image must be smaller than 2MB!");
                return false;
              }
              setFileList([file]);
              return false;
            }}
            accept=".jpg, .jpeg, .png"
          ></Upload>
          {uploadButton}
        </div>
        <label>
          <span>Registered Company Name</span>
          <input
            {...register("company_name")}
            defaultValue={employerProfile?.company_name || null}
            type="text"
          />
        </label>

        <label>
          <span>Country </span>
          <Select
            optionFilterProp="label"
            showSearch={true}
            defaultValue={
              employerProfile?.demographics?.demographics?.location_of_work
                ?.split(",")[0]
                .trim() || null
            }
            onChange={(value: any) => onFieldChange("country", value)}
            className="select"
            placeholder="Select"
          >
            {countries.map((country) => (
              <Option
                label={country}
                value={country}
                className="roleType__option"
              >
                {country}
              </Option>
            ))}
          </Select>
        </label>

        <label>
          <span>State</span>
          <input
            onChange={(e) => onFieldChange("state", e.target.value)}
            defaultValue={
              employerProfile?.demographics?.demographics?.location_of_work
                ?.split(",")[1]
                .trim() || null
            }
            type="text"
          />
          {/* <Select
            defaultValue={formFields["state"]?.value || null}
            onChange={(value) => onFieldChange("state", value)}
            className="select"
            placeholder="Select"
          >
            {sharedFields?.["state"]?.map((data: any) => (
              <Option
                label={data.name}
                value={data.name}
                className="roleType__option"
              >
                {data.name}
              </Option>
            ))}
          </Select> */}
        </label>

        <label>
          <span>City</span>
          <input
            onChange={(e) => onFieldChange("city", e.target.value)}
            defaultValue={
              employerProfile?.demographics?.demographics?.location_of_work
                ?.split(",")[2]
                .trim() || null
            }
            type="text"
          />
          {/* <Select
            defaultValue={formFields["city"]?.value || null}
            onChange={(value) => onFieldChange("city", value)}
            className="select"
            placeholder="Select"
          >
            {sharedFields?.["city"]?.map((data: any) => (
              <Option
                label={data.name}
                value={data.name}
                className="roleType__option"
              >
                {data.name}
              </Option>
            ))}
          </Select> */}
        </label>

        <label>
          <span>Phone number </span>
          <input
            style={{ width: "100%" }}
            {...register("phone")}
            type="tel"
            id="phone"
            defaultValue={employerDashboard?.phone_number || null}
            onKeyDown={(e) => {
              if (
                !/^\d$/.test(e.key) &&
                e.key !== "Backspace" &&
                e.key !== "Delete" &&
                e.key !== "ArrowLeft" &&
                e.key !== "ArrowRight"
              ) {
                e.preventDefault();
              }
            }}
          />
        </label>

        <label>
          <span>Company Website</span>
          <input
            {...register("company_website")}
            type="text"
            defaultValue={
              employerProfile?.demographics?.demographics?.website_url || null
            }
            placeholder="Write Here"
          />
        </label>

        <label>
          <span>Account manager Email</span>
          <input
            {...register("account_manager_email")}
            type="text"
            placeholder="Write Here"
          />
        </label>
        <main
          style={{
            textAlign: "start",
            display: "flex",
            alignItems: "center",
            gap: 8,
            cursor: "pointer",
            marginTop: 12,
            fontSize: 16,
          }}
          className="delete-account"
          onClick={handleDeleteAccount}
        >
          <Delete />
          Delete account
        </main>
        {errorMessage && <span className="errorMessage">{errorMessage}</span>}
        <FormButton text="Submit" loading={Boolean(loading)} />
      </form>
    </div>
  );
};

export default EditProfileModal;
