import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import DashboardNavbar from "../../components/DashboardNavbar/DashboardNavbar";
import "./EmployerBillingScreen.scss";
import { HrSeparator } from "../../assets/images";
import { PricingIcon } from "../../assets/icons";
import EmployerBillingCard from "../../components/EmployerBillingCard/EmployerBillingCard";
import PlanFeaturesTable from "../../components/PlanFeaturesTable/PlanFeaturesTable";
import Cookies from "js-cookie";
import axios from "axios";
import api from "../../utils/api";
import { getAccessToken } from "../../utils/jwtToken";
import { useSelector } from "react-redux";
import TalentBillingCard from "../../components/TalentBillingCard/TalentBillingCard";
import TalentPlanFeaturesTable from "../../components/TalentPlanFeaturesTable/TalentPlanFeaturesTable";
import AnnualPlanCard from "../../components/AnnualPlanCard/AnnualPlanCard";

const EmployerBillingScreen = () => {
  const employerDashboard = useSelector(
    (state: any) => state.employer.dashboard
  );
  const talentDashboard = useSelector((state: any) => state.talent.dashboard);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 700);
  const [showPlanFeaturesTable, setShowPlanFeaturesTable] = useState(
    window.innerWidth > 1150
  );
  const [subscriptionPlan, setSubscriptionPlan] = useState<any>([]);
  useEffect(() => {
    if (Cookies.get("role") === "talent") {
      axios
        .get(`${api}/main/stripe/talent/subscription/`, {
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        })
        .then((response) => {
          setSubscriptionPlan(response.data);
        })
        .catch((error) => {
          console.error("Error sending request", error);
        });
    }
    if (Cookies.get("role") === "employer") {
      axios
        .get(`${api}/main/stripe/employer/subscription/`, {
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        })
        .then((response) => {
          setSubscriptionPlan(response.data);
        })
        .catch((error) => {
          console.error("Error sending request", error);
        });
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 700);
      setShowPlanFeaturesTable(window.innerWidth > 1150);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const annualClassName = employerDashboard?.subscription_status?.includes(
    "annual"
  )
    ? "block"
    : "flex";
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className=""
      >
        <div className="employerBilling">
          <DashboardNavbar addLogo={isMobileView} />
          <h1>Choose the Pricing Plan</h1>
          <HrSeparator />
          <div
            // style={{ display: annualClassName }}
            className={`employerBilling__container  ${
              !employerDashboard?.subscription_status?.includes("annual")
                ? "not-annual"
                : ""
            } ${
              !(talentDashboard?.subscription_status === "seeker") &&
              !employerDashboard
                ? "seeker"
                : ""
            }`}
          >
            {Cookies.get("role") === "employer" ? (
              <>
                {!employerDashboard?.subscription_status?.includes("annual") ? (
                  <>
                    <EmployerBillingCard
                      planId={subscriptionPlan[0]?.id}
                      backgroundColor="#feedcc"
                      planName="Start"
                      price={subscriptionPlan[0]?.price}
                      description="For anyone to get started"
                      buttonText="Get started"
                      subscriptionStatus={
                        employerDashboard?.subscription_status === "start"
                          ? employerDashboard?.subscription_status
                          : null
                      }
                      subscriptionPlan={subscriptionPlan}
                      gridColumnStart={2}
                    />
                    <EmployerBillingCard
                      planId={subscriptionPlan[1]?.id}
                      backgroundColor="#FDCEB5"
                      planName="Scale"
                      price={subscriptionPlan[1]?.price}
                      description="For anyone to get started"
                      buttonText="Get started"
                      subscriptionStatus={
                        employerDashboard?.subscription_status === "scale"
                          ? employerDashboard?.subscription_status
                          : null
                      }
                      subscriptionPlan={subscriptionPlan}
                    />
                    <EmployerBillingCard
                      planId={43}
                      backgroundColor="#BDAFEB"
                      planName="Grow"
                      description="For customized and annual plans, book a demo with us!"
                      buttonText="Book a Demo"
                      subscriptionStatus={
                        employerDashboard?.subscription_status === "grow"
                          ? employerDashboard?.subscription_status
                          : null
                      }
                      subscriptionPlan={subscriptionPlan}
                    />
                  </>
                ) : (
                  <AnnualPlanCard
                    backgroundColor="#BDAFEB"
                    planName={employerDashboard?.subscription_status}
                  />
                )}
              </>
            ) : (
              <>
                <div
                  style={{
                    paddingInline: 20,
                    width: 200,
                  }}
                ></div>
                {talentDashboard?.subscription_status === "seeker" && (
                  <TalentBillingCard
                    planId={subscriptionPlan[0]?.id}
                    backgroundColor="#feedcc"
                    planName="Seeker"
                    price={subscriptionPlan[0]?.price}
                    description="For anyone to get started"
                    buttonText="Get started"
                    subscriptionStatus={
                      talentDashboard?.subscription_status === "seeker"
                        ? talentDashboard?.subscription_status
                        : null
                    }
                    subscriptionPlan={subscriptionPlan}
                  />
                )}
                <TalentBillingCard
                  planId={subscriptionPlan[1]?.id}
                  backgroundColor="#FDCEB5"
                  planName="Path"
                  price={subscriptionPlan[1]?.price}
                  description="For anyone to get started"
                  buttonText="Get started"
                  subscriptionStatus={
                    talentDashboard?.subscription_status === "path"
                      ? talentDashboard?.subscription_status
                      : null
                  }
                  subscriptionPlan={subscriptionPlan}
                  gridStartColumn={
                    !(talentDashboard?.subscription_status === "seeker")
                      ? 2
                      : "auto"
                  }
                />
                <TalentBillingCard
                  planId={subscriptionPlan[2]?.id}
                  backgroundColor="#BDAFEB"
                  planName="Trailblazer"
                  price={subscriptionPlan[2]?.price}
                  description="For customized and annual plans, book a demo with us!"
                  buttonText="Get started"
                  subscriptionStatus={
                    talentDashboard?.subscription_status === "trailblazer"
                      ? talentDashboard?.subscription_status
                      : null
                  }
                  subscriptionPlan={subscriptionPlan}
                />
              </>
            )}
          </div>
          {showPlanFeaturesTable &&
            (Cookies.get("role") === "employer" ? (
              employerDashboard?.subscription_status?.includes(
                "annual"
              ) ? null : (
                <PlanFeaturesTable subscriptionPlan={subscriptionPlan} />
              )
            ) : (
              <TalentPlanFeaturesTable subscriptionPlan={subscriptionPlan} />
            ))}
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default EmployerBillingScreen;
