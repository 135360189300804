import React, {FC, useEffect, useState} from "react";
import {useForm, SubmitHandler} from "react-hook-form";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import ImgCrop from 'antd-img-crop';
import {Upload, UploadFile, message} from 'antd';

import {AnimatePresence, motion} from "framer-motion";
import {TailSpin} from "react-loader-spinner";

import {EventPosted} from "../../../../components/index";
import {AppDispatch} from "../../../../redux/store/store";
import FormButton from "../../../../components/FormButton/FormButton";
import {AdminPostEvents} from "../../../../redux/AdminSlice/AdminSlice";
// import api from "../../utils/api";

import "./MainDashboard.scss";
import "./EventForm.scss";
import DashboardNavbar from "../../../../components/DashboardNavbar/DashboardNavbar";

interface EventInputs {
    event_name: string;
    event_link: string;
    event_date: number;
    event_details: string;
}

const MainDashboard: FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [addEventScreen, setAddEventScreen] = useState<boolean>(false);
    const {register, handleSubmit} = useForm<EventInputs>();
    const [fileList, setFileList] = useState<UploadFile[]>([]);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 700);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
        setIsMobileView(window.innerWidth < 700);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const defaultStyle = {
        display: "flex",
        justifyContent: "space-between"
    };

    const defaultStyle1 = {
        width: '45%'
    };

    const mediaQueryStyle = {
        ...defaultStyle,
        gap: '10px',
        flexDirection: windowWidth <= 980 ? 'column' : 'row'
    };

    const mediaQueryStyle1 = {
        ...defaultStyle1,
        width: windowWidth <= 980 ? '100%' : '45%'
    };

    const divStyle = windowWidth <= 1422 ? mediaQueryStyle : defaultStyle;
    const divStyle1 = windowWidth <= 1422 ? mediaQueryStyle1 : defaultStyle1;

    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const onSubmit: SubmitHandler<EventInputs> = (data, e) => {
        if (e) {
            e.preventDefault();
        }
        setErrorMessage("");
        setLoading(true);
        dispatch(AdminPostEvents({...data})).then((res) => {
            setLoading(false);
            if (res.type.includes("rejected")) {
                const errMessage: any = Object.values(res.payload);
                if (errMessage[0][0].length > 2) {
                    setErrorMessage(errMessage[0][0]);
                } else {
                    setErrorMessage("Something went wrong, please try again.");
                }
            } else {
                setAddEventScreen(true);
            }
        });
    };

    const handleUploadChange = ({fileList: newFileList}: { fileList: UploadFile[] }) => {
        setFileList(newFileList);
    };

    const beforeUpload = (file: UploadFile) => {
        console.log(file)
        if (!file || !file.size) {
            return false
        }
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG files!');
        }
        const isLt2M = file?.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must be smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };

    if (addEventScreen) {
        return <EventPosted setScreen={setAddEventScreen}/>;
    }

    return (
        <AnimatePresence>
            {!loading ? (
                <motion.div
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    className="main__dashboard-box"
                >
                    <DashboardNavbar addLogo={isMobileView}/>
                    <main className="main__dashboard">
                        <motion.div
                            key={"dashboard-heading"}
                            transition={{duration: 0.3, delay: 0.3}}
                            initial={{y: -50, opacity: 0}}
                            animate={{y: 0, opacity: 1}}
                            className="main__dashboard-heading"
                        >
                            <h1>Post Events</h1>
                        </motion.div>
                        <motion.section
                            transition={{duration: 0.3, delay: 0.4}}
                            initial={{y: 50, opacity: 0}}
                            animate={{y: 0, opacity: 1}}
                            className="eventForm__container"
                        >
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div style={divStyle}>
                                    <label style={divStyle1}>
                                        <span>Event Name</span>
                                        <input {...register("event_name")} type="text" required/>
                                    </label>

                                    <label style={divStyle1}>
                                        <span>Link to Event</span>
                                        <input {...register("event_link")} type="text" required/>
                                    </label>
                                </div>

                                <div style={divStyle}>
                                    <label style={divStyle1}>
                                        <span> Event Date </span>
                                        <input {...register("event_date")} type="date" required/>
                                    </label>

                                    <label style={divStyle1}>
                                        <span>Event Flyer </span>
                                        <ImgCrop aspect={2 / 1}>
                                            <Upload
                                                action={`${process.env.REACT_APP_API_URL}/main/check/`}
                                                listType="picture-card"
                                                fileList={fileList}
                                                // onChange={handleUploadChange}
                                                // beforeUpload={beforeUpload}
                                                onChange={(info: any) => {
                                                    const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB in bytes

                                                    if (info.file.size > maxSizeInBytes) {
                                                        // Display an error message or take appropriate action
                                                        message.error("File size exceeds 2 MB limit");
                                                        return false; // Prevent the file from being uploaded
                                                    } else {
                                                        handleUploadChange(info);
                                                    }
                                                }}
                                                accept=".jpg,.jpeg,.png"
                                            >
                                                {fileList.length < 1 && 'Upload'}
                                            </Upload>
                                        </ImgCrop>
                                        <span className="formatCaption">
                      Supported formats: jpg, jpeg, png
                    </span>
                                    </label>
                                </div>
                                <div style={divStyle}>
                                    <label style={{width: "100%"}}>
                                        <span>Event Details</span>
                                        <textarea
                                            className="input"
                                            style={{height: "100px"}}
                                            {...register("event_details")}
                                            required
                                        />
                                    </label>
                                </div>
                                {errorMessage && (
                                    <span className="errorMessage">{errorMessage}</span>
                                )}
                                <div style={{display: "flex", justifyContent: "center"}}>
                                    <FormButton
                                        text="Post event"
                                        loading={Boolean(loading)}
                                        customStyle={{
                                            width: "500px",
                                        }}
                                    />
                                </div>
                            </form>
                        </motion.section>
                    </main>
                </motion.div>
            ) : (
                <div className="loader-container">
                    <TailSpin
                        height="160"
                        width="160"
                        color="#FBAD84"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{
                            justifySelf: "center",
                            alignSelf: "center",
                        }}
                        wrapperClass="tail-spin"
                        visible={true}
                    />
                </div>
            )}
        </AnimatePresence>
    );
};

export default MainDashboard;
