import React, { FC, useState } from "react";
import { Footer, FormButton, NavBar } from "../../components";
import { SelectProfile } from "../../assets/icons";

import "./SelectProfileScreen.scss";
import { Select } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../../redux/store/store";
import {
  getProfile,
  resetUserRole,
  updateProfileState,
} from "../../redux/ProfileSlice/ProfileSlice";
import { setJWTToken } from "../../utils/jwtToken";

const Option = Select.Option;

const SelectProfileScreen: FC = () => {
  const [type, setType] = useState<string>("");

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<Boolean>(false);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const onSubmit = (e: any) => {
    e.preventDefault();
    setErrorMessage("");
    setLoading(true);
    if (type) {
      dispatch(resetUserRole(type)).then((res) => {
        setLoading(false);
        if (res.type.includes("rejected")) {
          const errMessage: any = Object.values(res.payload);
          if (errMessage[0][0]?.length > 2) {
            setErrorMessage(errMessage[0][0]);
          } else {
            setErrorMessage("Something went wrong, please try again.");
          }
        } else {
          setJWTToken(
            res.payload.token.access,
            res.payload.token.refresh,
            res.payload.token.user.role,
            res.payload.token.user.on_boarding_completed,
          );

          dispatch(
            updateProfileState({
              accessToken: res.payload.token.access,
              boardingCompleted: res.payload.token.user.on_boarding_completed,
              userRole: res.payload.token.user.role,
            }),
          );

          dispatch(getProfile()).then((res) => {
            if (
              !res.payload.on_boarding_completed &&
              res.payload.role === "partner"
            ) {
              navigate(`/complete-partner`);
            } else if (!res.payload.on_boarding_completed) {
              navigate(`/onboarding`);
            } else {
              navigate(`/dashboard`);
            }
          });
        }
      });
    } else {
      setLoading(false);
      setErrorMessage("Select the role you want continue as.");
    }
  };

  return (
    <div className="selectProfile">
      <NavBar />
      <div className="selectProfile__container">
        <SelectProfile />
        <h2>Select your profile</h2>
        <form onSubmit={onSubmit}>
          <label>
            <span>I want to continue as</span>
            <Select
              optionFilterProp="label"
              showSearch={true}
              onChange={(e: any) => setType(e)}
              className="select"
            >
              <Option
                label="Talent"
                value={"talent"}
                className="roleType__option"
              >
                Talent <span>For tech enthusiasts exploring new opportunities.</span>
              </Option>
              <Option
                label="Employer"
                value={"employer"}
                className="roleType__option"
              >
                Employer{" "}
                <span>
                  For companies seeking diverse tech talent to enhance their teams.
                </span>
              </Option>
              <Option
                label="Partner"
                value="partner"
                className="roleType__option"
              >
                Partner
                <span>
                  For programs looking to increase visibility to diverse audiences.
                </span>
              </Option>
            </Select>
          </label>
          {errorMessage && <span className="errorMessage">{errorMessage}</span>}
          <FormButton text="Continue" loading={Boolean(loading)} />
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default SelectProfileScreen;
