import { FC, useEffect, useState } from "react";
import { Button, Select, message } from "antd";
import { useForm, SubmitHandler } from "react-hook-form";
import { FormButton } from "../../../../components";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../redux/store/store";
import { useNavigate } from "react-router-dom";
import { Avatar } from "antd";
import MentorImage from "../../../../assets/images/mentor.png";
import axios from "axios";
import { getAccessToken } from "../../../../utils/jwtToken";
import api from "../../../../utils/api";
import countries from "../../../../utils/countriesList";

// import { AntDesignOutlined, UserOutlined } from '@ant-design/icons';
import {
  updateTalentProfile,
  getTalentProfilePic,
  getTalentDashboard,
  getTalentWorkLocations,
} from "../../../../redux/TalentSlice/TalentSlice";
import { Modal, Upload } from "antd";
import type { RcFile, UploadProps } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";
import { IState, State, Country, ICountry } from "country-state-city";

import "../EditProfileModal.scss";
import { Delete } from "../../../../assets/icons";
import { UploadOutlined } from "@ant-design/icons";
import styled from "styled-components";

const Option = Select.Option;

const DEFAULT_ETHNICITY = "Christian";

interface SignUpInputs {
  full_name: string;
  bio: string;
  phoneNumber: Number;
  genders: string;
  profession: string;
}

interface PasswordHidden {
  one: boolean;
  two: boolean;
}

type Props = {
  onModalClose?: () => void;
  handleDeleteAccount?: () => void;
};

interface StateOption {
  label: string;
  value: string;
}
const CustomStyledSelect = styled(Select)`
  .ant-select-arrow .anticon {
    margin-bottom: 18px !important;
  }
`;
const EditProfileModal: FC<Props> = (props) => {
  const talentProfile = useSelector((state: any) => state.talent.profileInfo);
  const talentDashboard = useSelector((state: any) => state.talent.dashboard);
  const talentId = talentProfile?.id;
  const [errorMessage, setErrorMessage] = useState<string>();
  const [sharedFields, setSharedFields] = useState<any>();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [s3UploadUrl, setS3UploadUrl] = useState<string>("");
  const [key, setKey] = useState<string>("");
  const [fileId, setFileId] = useState<string>("");
  const dispatch = useDispatch<AppDispatch>();
  const [resume, setResume] = useState<any>(null);
  const [states, setStates] = useState<StateOption[]>([]);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [workLocations, setWorkLocations] = useState<any>([]);
  const [selectedWorkLocations, setSelectedWorkLocations] = useState<any>([]);
  const [fileExtension, setFileExtension] = useState<any>(null);
  useEffect(() => {
    const allCountries = Country.getAllCountries();
    setCountries(allCountries);
  }, []);

  useEffect(() => {
    dispatch(getTalentWorkLocations()).then((locations) => {
      setWorkLocations(locations.payload);
    });
  }, []);

  useEffect(() => {
    const getIdsByName = (data: any, names: any) => {
      const nameSet = new Set(names); // Convert array to Set for faster lookup
      return data
        .filter((item: any) => nameSet.has(item.name))
        .map((item: any) => item.id);
    };

    if (workLocations.length > 0 && talentProfile)
      setSelectedWorkLocations(
        getIdsByName(workLocations, talentProfile.preferences.work_mode)
      );
  }, [talentProfile, workLocations]);

  const handleDeleteAccount = props.handleDeleteAccount;

  const handleUpdateWorkLocations = async () => {
    const payload = {
      preferences: {
        talent: talentId,
        preferred_work_location: selectedWorkLocations,
      },
    };
    axios
      .post(`${api}/talent/profile/update/`, payload, {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      })
      .then((response) => {})
      .catch((error) => {});
  };

  const initializeFormFieldsFromProfile = (
    talentProfile: any,
    talentDashboard: any
  ) => {
    const _formFields: any = {};

    _formFields.full_name = { value: talentProfile?.full_name };
    _formFields.profession = { value: talentProfile?.profession };
    _formFields.genders = {
      value: talentProfile?.demographics?.[0]?.toLowerCase()?.includes("she")
        ? "Female"
        : "Male",
    };
    _formFields.pronouns = { value: talentProfile?.demographics?.[0] };
    _formFields.ethnicity = {
      value: talentDashboard?.ethnicity || null,
    };

    _formFields.country = {
      value: talentDashboard?.profile?.location?.country || null,
    };

    _formFields.state = {
      value: talentDashboard?.profile?.location?.state || null,
    };

    _formFields.city = {
      value: talentDashboard?.profile?.location?.city || null,
    };
    _formFields.bio = {
      value: talentProfile?.bio || null,
    };

    _formFields.phone_number = {
      value: talentDashboard?.phone_number || null,
    };

    // setFormFields(_formFields);
    return _formFields;
  };
  const [formFields, setFormFields] = useState<any>(
    initializeFormFieldsFromProfile(talentProfile, talentDashboard)
  );

  const { register, handleSubmit } = useForm<SignUpInputs>();

  const onSubmit: SubmitHandler<SignUpInputs> = (data) => {
    const getFirstName = (fullName: string) =>
      fullName && fullName.split(" ")[0];

    const getLastName = (fullName: string) => {
      if (fullName) {
        const splittedName = fullName.split(" ");
        if (splittedName.length > 1) {
          const lastName = splittedName.slice(1);
          return lastName.join(" ");
        }
      }
      return "";
    };
    const payload: any = {};
    const formFieldKeys = Object.entries(formFields);
    const dirtyChangedFields = formFieldKeys.filter(
      ([key, value]: any) => value?.dirty
    );

    dirtyChangedFields.forEach(([key, value]: any) => {
      const demographicsUpdated = ["genders", "ethnicity", "pronouns"].includes(
        key
      );
      const talentLocationUpdated = ["city", "country", "state"].includes(key);

      if (talentLocationUpdated)
        payload["talent_location"] = payload["talent_location"] || {
          city: formFields["city"]?.value || "",
          country: formFields["country"]?.value || "",
          state: formFields["state"]?.value || "",
        };

      if (demographicsUpdated || formFields["ethnicity"].length > 0)
        payload["demographics"] = payload["demographics"] || {
          genders: formFields["genders"]?.value
            ? [getIdFromName(formFields["genders"]?.value)]
            : [1],
          ethnicity: formFields["ethnicity"]?.value
            ? formFields["ethnicity"]?.value
            : [1],
          pronouns: formFields["pronouns"]?.value
            ? [getIdFromName(formFields["pronouns"]?.value)]
            : [1],
        };
      const _value = value.value;
      if (key === "full_name") {
        payload["name"] = {
          first_name: getFirstName(_value || ""),
          last_name: getLastName(_value || ""),
        };
      }
      if (key === "profession") {
        payload["profession"] = _value;
      }
      if (key === "genders") {
        payload["demographics"]["genders"] = [getIdFromName(_value)];
        payload["demographics"]["talent"] = talentId;
      }

      if (key === "ethnicity") {
        payload["demographics"]["ethnicity"] = _value;
        payload["demographics"]["talent"] = talentId;
      }

      if (key === "pronouns") {
        payload["demographics"]["pronouns"] = [getIdFromName(_value)];
        payload["demographics"]["talent"] = talentId;
      }

      if (key === "state") {
        payload["talent_location"]["state"] = _value;
        payload["talent_location"]["talent"] = talentId;
      }

      if (key === "city") {
        payload["talent_location"]["city"] = _value;
        payload["talent_location"]["talent"] = talentId;
      }
      if (key === "country") {
        payload["talent_location"]["country"] = _value;
        payload["talent_location"]["talent"] = talentId;
      }

      if (key === "phone") {
        payload["phone"] = _value;
      }

      if (key === "bio") {
        payload["social_bio"] = {};
        payload["social_bio"]["talent"] = talentId;
        payload["social_bio"]["bio"] = _value;
      }
    });
    setLoading(true);
    handleUpdateWorkLocations();
    dispatch(updateTalentProfile({ userId: talentId, payload }))
      .then(() => {
        dispatch(getTalentDashboard());
        if (resume) {
          getUploadUrlPromise("resume").then((res: any) => {
            const url = res.data.s3_params.url;
            const formData = new FormData();

            Object.keys(res.data.s3_params.fields).forEach((key: any) => {
              formData.append(key, res.data.s3_params.fields[key]);
            });

            // Append the file
            formData.append("file", resume[0].originFileObj);

            // Use fetch or axios to send a POST request to the S3 bucket
            fetch(url, {
              method: "POST",
              body: formData,
              headers: new Headers({
                "Content-Disposition": `attachment; filename="${resume[0].name}"`,
              }),
            })
              .then((response) => {
                axios
                  .post(
                    `${api}/talent/file/upload/confirm/`,
                    {
                      id: res.data.file_id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${getAccessToken()}`,
                      },
                    }
                  )
                  .then(() => {
                    setLoading(false);
                    props.onModalClose?.();
                  });
              })
              .catch((error) => {
                console.error("Error:", error);
                setLoading(false);
                props.onModalClose?.();
              });
          });
        } else {
          setLoading(false);
          props.onModalClose?.();
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    // This code is used for rendering country flags:
    // ref: https://www.twilio.com/blog/international-phone-number-input-html-javascript

    const phoneInputField = document.getElementById("phone");
    (window as any).intlTelInput(phoneInputField);

    // const element = document.getElementsByClassName("iti__flag-container")[0];
    // (element as any).style.display = "none";

    const accessToken = getAccessToken();

    const internalFunc = async () => {
      const sharedFieldsValues: any = {};
      const promises: any = [];
      ["gender", "pronoun", "city", "state", "ethnicity"].forEach(
        async (elem) => {
          promises.push(
            axios.get(`${api}/shared/${elem}/`, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            })
          );
        }
      );
      let allData = await Promise.all(promises);
      allData = allData.map((allData) => allData.data);

      setSharedFields({
        genders: allData[0],
        pronouns: allData[1],
        city: allData[2],
        state: allData[3],
        ethnicity: allData[4],
      });
      console.log("Pronouns:", allData[1]);
    };
    internalFunc();
  }, []);

  const getUploadUrlPromise = (fileType: "profile" | "resume") => {
    let contentType = "application/pdf"; // Default binary content-type
    if (fileExtension === ".pdf") {
      contentType = "application/pdf";
    } else if (fileExtension === ".doc" || fileExtension === "docx") {
      contentType = "application/docx";
    }
    return axios.post(
      `${api}/talent/file/upload/`,
      {
        file_type: fileType,
        file_extension: fileExtension,
      },
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
          // "Content-Type": contentType || '.pdf',
        },
      }
    );
  };
  console.log("extension", fileExtension);

  const onFieldChange = (fieldKey: string, value: string | number) => {
    if (!value) return;
    const fieldToSetInState = { [fieldKey]: { value, dirty: true } };
    setFormFields({ ...formFields, ...fieldToSetInState });
  };

  const handleChange: UploadProps["onChange"] = async ({
    fileList: newFileList,
    file,
    event,
  }) => {

    let url: any;
    let imageId: any;
    let imageKey: any;

   await getUploadUrlPromise("profile").then((response) => {
      const data = response.data;

      imageId = data?.file_id;
      url = data?.s3_params?.url;
      imageKey = data?.s3_params?.fields?.key;

    });



    setFileList(newFileList);
    const fileSize = file.size;

    if (fileSize && fileSize / 1024 / 1024 >= 2) {
      return;
    }

    const formData = new FormData();
    formData.append("key", imageKey);
    fileList.forEach((file) => {
      formData.append("file", file as RcFile);
    });

    // You can use any AJAX library you like
    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then(() => {
        axios
          .post(
            `${api}/talent/file/upload/confirm/`,
            {
              id: imageId,
            },
            {
              headers: {
                Authorization: `Bearer ${getAccessToken()}`,
              },
            }
          )
          .then(() => {
            message.success("Updated Image successfully");
            dispatch(getTalentProfilePic(talentId));
          });
      })
      .catch(() => {
        message.error("upload failed.");
      })
      .finally(() => {});
  };

  const _props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList,
  };

  const resumeProps: UploadProps = {
    name: "file",
    action: `${api}/main/check/`,
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      setResume(info.fileList);
    },
  };

  const uploadButton = (
    <div
      style={{
        marginLeft: "10px",
        marginTop: "20px",
        fontWeight: 600,
        fontSize: "16px",
        fontFamily: "Space Grotesk, sans-serif",
        color: "#5937CC",
        cursor: "pointer",
      }}
      onClick={() => {
        const inputElements = document.getElementsByTagName("input");
        for (var i = 0; i < inputElements.length; i++) {
          var node = inputElements[i];

          if (node.getAttribute("type") === "file") {
            node.click();
            return;
          }
        }
      }}
    >
      Update Profile Image
      <div
        style={{
          fontWeight: 400,
          fontSize: "12px",
          color: "#344054",
          fontFamily: "Space Grotesk, sans-serif",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <span>Make sure the file is below 2mb</span>
        <span>Supported formats: jpg, jpeg, png</span>
      </div>
    </div>
  );

  const getIdFromName = (name: any) => {
    let field: any = null;

    Object.entries(sharedFields ?? {}).forEach((k: any, v: any) => {
      if (field) return;
      field = k[1].find((l: any) => l?.name?.includes(name));
      if (field) return;
    });

    if (field) {
      return field.id;
    }
    return null;
  };

  const getIdFromNameEthinicity = (names: string[]) => {
    let ids: number[] = [];
    // Assuming sharedFields is accessible in this scope
    Object.entries(sharedFields ?? {}).forEach(([_, values]: any) => {
      names?.forEach((name) => {
        const foundItem = values.find((item: any) => item.name === name);
        if (foundItem) {
          ids.push(foundItem.id);
        }
      });
    });

    return ids;
  };

  // function to handle country change
  const handleCountryChange = (countryName: string) => {
    // Update the formFields state with the selected country
    onFieldChange("country", countryName);

    // Find the country object by name
    const country = countries.find((c) => c.name === countryName);

    if (!country) {
      console.error("No country found for name:", countryName);
      setStates([]);
      return;
    }

    // Use the ISO code of the selected country to fetch states
    const countryCode = country.isoCode;


    try {
      const statesArray: IState[] = State.getStatesOfCountry(countryCode);

      const formattedStates: StateOption[] = statesArray.map((state) => ({
        label: state.isoCode,
        value: state.name,
      }));

      setStates(formattedStates);
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

useEffect(()=> {
  if(talentDashboard.profile?.location?.country && countries.length > 0){
    handleCountryChange(talentDashboard.profile?.location?.country)
  }
}, [talentDashboard, countries])


  return (
    <div className="editProfile__container">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            justifyContent: !(fileList?.length || talentProfile?.s3_params)
              ? "center"
              : "unset",
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            document.getElementById("fileUpload")?.click();
          }}
        >
          {talentProfile?.s3_params && !fileList?.length && (
            <Avatar
              style={{
                width: "74px",
                height: "74px",
                border: "1px solid",
                marginTop: "10px",
              }}
              src={talentProfile?.s3_params}
            />
          )}

          <Upload
            action={s3UploadUrl}
            listType="picture-circle"
            fileList={fileList}
            beforeUpload={(file) => {
              // Check if the file size exceeds 2MB
              const isLt2M = file.size / 1024 / 1024 < 2;
              if (!isLt2M) {
                // Display an error message if the file size exceeds 2MB
                message.error("Image must be smaller than 2MB!");
                return false;
              }
              setFileExtension(`.doc`);
              setFileList([file]);
              return false;
            }}
            onChange={handleChange}
            accept=".jpg, .jpeg, .png"
          ></Upload>
          {uploadButton}
        </div>

        <label>
          <span>Your Full Name</span>
          <input
            {...register("full_name")}
            onChange={(e) => onFieldChange("full_name", e.target.value)}
            defaultValue={formFields["full_name"]?.value}
            type="text"
          />
        </label>

        <label>
          <span>Your Profession</span>
          <input
            {...register("profession")}
            onChange={(e) => onFieldChange("profession", e.target.value)}
            defaultValue={formFields["profession"]?.value}
            type="text"
          />
        </label>

        <label>
          <span>Your pronouns</span>
          <Select
            optionFilterProp="label"
            showSearch={true}
            value={formFields["pronoun"]?.value || null}
            onChange={(value) => onFieldChange("pronoun", value)}
            className="select"
          >
            {sharedFields?.["pronouns"]?.map((data: any) => (
              <Option
                label={data.name}
                value={data.name}
                className="roleType__option"
                key={data.name}
              >
                {data.name}
              </Option>
            ))}
          </Select>
        </label>
       

        <label>
          <span>What is your ethnicity? (internal use only) </span>
          <Select
            optionFilterProp="label"
            showSearch={true}
            defaultValue={formFields["ethnicity"]?.value || null}
            onChange={(value) => onFieldChange("ethnicity", value)}
            className="custom-select"
            placeholder="This info will not be shared with employers"
            mode="multiple"
          >
            {sharedFields?.["ethnicity"]?.map((data: any) => (
              <Option
                label={data.name}
                value={data.id}
                className="roleType__option"
                key={data.name}
              >
                {data.name}
              </Option>
            ))}
          </Select>
        </label>

        <label>
          <span>Country</span>
          <Select
            optionFilterProp="value"
            showSearch={true}
            value={formFields["country"]?.value || null}
            onChange={handleCountryChange}
            className="select"
            placeholder="Select Country"
          >
            {countries.map((country) => (
              <Option
                key={country.isoCode}
                value={country.name}
                className="roleType__option"
              >
                {country.name}
              </Option>
            ))}
          </Select>
        </label>

        <label>
          <span>State</span>
          <Select
            optionFilterProp="value"
            showSearch={true}
            onChange={(value) => onFieldChange("state", value)}
            className="select"
            placeholder="Select State"
            value={formFields["state"]?.value || null}
          >
            {states.map((state) => (
              <Option
                key={state.value}
                value={state.value}
                className="roleType__option"
              >
                {state.label}
              </Option>
            ))}
          </Select>
        </label>

        <label>
          <span>City</span>
          <input
            onChange={(e) => onFieldChange("city", e.target.value)}
            defaultValue={formFields["city"]?.value || null}
            type="text"
          />
        </label>

        
        <label>
          <span>Phone number</span>
          <input
            style={{ width: "100%" }}
            type="tel"
            id="phone"
            name="phone"
            defaultValue={formFields["phone_number"]?.value || null}
            onChange={(e) => onFieldChange("phone", e.target.value)}
            onKeyDown={(e) => {
              if (
                !/^\d$/.test(e.key) &&
                e.key !== "Backspace" &&
                e.key !== "Delete" &&
                e.key !== "ArrowLeft" &&
                e.key !== "ArrowRight"
              ) {
                e.preventDefault();
              }
            }}
          />
        </label>

        <label>
          <span>Job Type</span>
          <Select
            optionFilterProp="label"
            showSearch={true}
            value={selectedWorkLocations}
            onChange={(value) => {
              setSelectedWorkLocations(value);
            }}
            className="select"
            placeholder="Select your work preference."
            mode="multiple"
          >
            {workLocations?.map((data: any) => (
              <Option
                label={data.name}
                value={data.id}
                className="roleType__option"
                key={data.name}
              >
                {data.name}
              </Option>
            ))}
          </Select>
        </label>

        <label>
          <span>About</span>
          <input
            style={{ height: "80px" }}
            {...register("bio")}
            type="text"
            placeholder="Write Here"
            onChange={(e) => onFieldChange("bio", e.target.value)}
            defaultValue={formFields["bio"]?.value}
          />
        </label>

        <div className="resume-box">
          <p>Resume</p>
          <Upload
            maxCount={1}
            method="POST"
            fileList={resume}
            style={{
              alignSelf: "start",
            }}
            {...resumeProps}
            accept=".doc,.pdf,.docx"
            beforeUpload={(file) => {
              const allowedTypes = [
                "application/pdf",
                "application/msword",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              ];

              setFileExtension(`.${file.name.split(".").pop()}`);


              if (!allowedTypes.includes(file.type)) {
                message.error("Only PDF, DOC, and DOCX files are allowed");
                return false;
              }

              return true;
            }}
          >
            <Button icon={<UploadOutlined />}>
              Click to upload / edit resume
            </Button>
          </Upload>
        </div>

        <main
          style={{
            textAlign: "start",
            display: "flex",
            alignItems: "center",
            gap: 8,
            cursor: "pointer",
            marginTop: 12,
            fontSize: 16,
          }}
          className="delete-account"
          onClick={handleDeleteAccount}
        >
          <Delete />
          Delete account
        </main>

        {errorMessage && <span className="errorMessage">{errorMessage}</span>}
        <FormButton text="Submit" loading={loading} />
      </form>
    </div>
  );
};

export default EditProfileModal;
