import React from "react";

import "./SearchBar.scss";

const SearchBar = (props: any) => {
  return (
    <div className="search-container">
      <div
        className={`search-box-container ${props?.disabled ? "disabled" : ""}`}
      >
        <span>&#128269;</span>
        <input
          disabled={props?.disabled}
          value={props?.value}
          onChange={(e: any) => {
            props.setSearch(e.target.value);
            props.debouncedDispatch(e.target.value);
          }}
          type="text"
          className="search-box"
          placeholder="Search..."
        />
      </div>
    </div>
  );
};

export default SearchBar;
