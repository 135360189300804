import React, { FC, useEffect, useState } from "react";
import Slider from "react-slick";

import { AnimatePresence, motion } from "framer-motion";
import { getTalentDashboardRecommended } from "../../redux/TalentSlice/TalentSlice";
import { AppDispatch } from "../../redux/store/store";

import { useDispatch, useSelector } from "react-redux";

import DashboardNavbar from "../../components/DashboardNavbar/DashboardNavbar";

import "./TalentRecommended.scss";
import { Link } from "react-router-dom";
import { Premium } from "../../assets/icons";
import CoachCard from "../../components/CoachCard/CoachCard";
import {
  RecommendedCompanies,
  RecommendedJob,
  ResourceCard,
} from "../../components";

import { TailSpin } from "react-loader-spinner";

const TalentRecommended: FC = () => {
  // States
  const [loading, setLoading] = useState<boolean>(true);

  // Selectors
  const profileInfo = useSelector((state: any) => state.profile.profileInfo);
  const dashboardRecommended = useSelector(
    (state: any) => state.talent.dashboardRecommended
  );
  const dispatch = useDispatch<AppDispatch>();

  const recommendedCoaches = dashboardRecommended?.coaches;
  const recommendedEmployers = dashboardRecommended?.employers?.employers;
  const recommendedJobs = dashboardRecommended?.jobs?.jobs;
  const resource_data = dashboardRecommended?.resources;

  const recommendationsLoaded =
    recommendedCoaches && recommendedEmployers && recommendedJobs;

  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 700);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 700);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setLoading(true);

    if (profileInfo?.first_name && recommendationsLoaded) {
      setLoading(false);
    }
  }, [profileInfo, recommendationsLoaded]);

  useEffect(() => {
    if (!recommendationsLoaded) {
      dispatch(getTalentDashboardRecommended("coaches"));
      dispatch(getTalentDashboardRecommended("employers"));
      dispatch(getTalentDashboardRecommended("jobs"));
      dispatch(getTalentDashboardRecommended("resources"));
    }
  }, []);

  return (
    <AnimatePresence>
      {!loading ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="talentRecommended__box"
        >
          <DashboardNavbar addLogo={isMobileView} />
          <main className="talentRecommended">
            <motion.div className="talentRecommended__heading">
              <h1>Discover Our Top Recommendations for You!</h1>
            </motion.div>
            <motion.section className="talentRecommended__container">
              {(recommendedCoaches ?? []).length > 0 && (
                <div className="talentRecommended__recommendations-container">
                  <div className="talentRecommended__recommendations-container-title">
                    <div>
                      Recommended Coaches
                      <Premium />
                    </div>
                    <Link to="/view-all/coach">See all</Link>
                  </div>
                  <div className="talentRecommended__recommendations-slider">
                    <Slider
                      slidesToShow={Math.min(
                        recommendedCoaches?.length || 0,
                        3
                      )}
                      dots
                      slidesToScroll={3}
                      autoplay={true}
                      autoplaySpeed={3000}
                      arrows={false}
                      responsive={[
                        {
                          breakpoint: 1289, // Width less than or equal to 1024px
                          settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            infinite: true,
                            dots: true,
                          },
                        },
                        {
                          breakpoint: 1089, // Width less than or equal to 1024px
                          settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            infinite: true,
                            dots: true,
                          },
                        },
                      ]}
                    >
                      {recommendedCoaches?.slice(0, 10).map((coach: any) => {
                        return (
                          <CoachCard
                            coach={{
                              ...coach,
                              style: { marginBottom: "40px" },
                            }}
                          />
                        );
                      })}
                    </Slider>
                  </div>
                </div>
              )}
              {(recommendedJobs ?? []).length > 0 && (
                <div className="talentRecommended__recommendations-container">
                  <div className="talentRecommended__recommendations-container-title">
                    <div>Recommended Jobs</div>
                    <Link to="/view-all/job">See all</Link>
                  </div>
                  <div className="talentRecommended__recommendations-slider">
                    <Slider
                      dots={true}
                      slidesToShow={Math.min(recommendedJobs?.length, 3)}
                      slidesToScroll={3}
                      autoplay={true}
                      autoplaySpeed={3000}
                      arrows={false}
                      responsive={[
                        {
                          breakpoint: 1189, // Width less than or equal to 1024px
                          settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            infinite: true,
                            dots: true,
                          },
                        },
                        {
                          breakpoint: 938, // Width less than or equal to 1024px
                          settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            infinite: true,
                            dots: true,
                          },
                        },
                      ]}
                    >
                      {recommendedJobs?.slice(0, 10).map((job: any) => {
                        return (
                          <RecommendedJob
                            job={{
                              ...job,
                              style: { marginBottom: "40px" },
                            }}
                          />
                        );
                      })}
                    </Slider>
                  </div>
                </div>
              )}
              {(recommendedEmployers ?? []).length > 0 && (
                <div className="talentRecommended__recommendations-container">
                  <div className="talentRecommended__recommendations-container-title">
                    <div>Recommended Companies</div>
                    <Link to="/view-all/company">See all</Link>
                  </div>
                  <div className="talentRecommended__recommendations-slider">
                    <Slider
                      dots={true}
                      slidesToShow={Math.min(recommendedEmployers?.length, 3)}
                      slidesToScroll={3}
                      arrows={false}
                      responsive={[
                        {
                          breakpoint: 1622, // Width less than or equal to 1024px
                          settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            infinite: true,
                            dots: true,
                          },
                        },
                        {
                          breakpoint: 1138, // Width less than or equal to 1024px
                          settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            infinite: true,
                            dots: true,
                          },
                        },
                      ]}
                    >
                      {recommendedEmployers
                        ?.slice(0, 10)
                        .map((follEmp: any) => (
                          <RecommendedCompanies
                            employer={{
                              ...follEmp,
                              style: { marginBottom: "40px" },
                            }}
                          />
                        ))}
                    </Slider>
                  </div>
                </div>
              )}
              {(resource_data ?? []).length > 0 ? (
                <div className="talentRecommended__recommendations-container">
                  <div className="talentRecommended__recommendations-container-title">
                    <div>Recommended Resources</div>
                    <Link to="/view-all/resource">See all</Link>
                  </div>
                  <div className="talentRecommended__recommendations-slider">
                    <Slider
                      dots={true}
                      slidesToShow={Math.min(resource_data?.length, 3)}
                      slidesToScroll={3}
                      autoplay={true}
                      autoplaySpeed={3000}
                      arrows={false}
                      responsive={[
                        {
                          breakpoint: 1189, // Width less than or equal to 1024px
                          settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            infinite: true,
                            dots: true,
                          },
                        },
                        {
                          breakpoint: 938, // Width less than or equal to 1024px
                          settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            infinite: true,
                            dots: true,
                          },
                        },
                      ]}
                    >
                      {resource_data?.slice(0, 10).map((r: any) => (
                        <ResourceCard resource={r} />
                      ))}
                    </Slider>
                  </div>
                </div>
              ) : null}
            </motion.section>
          </main>
        </motion.div>
      ) : (
        <TailSpin
          height="160"
          width="160"
          color="#FBAD84"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{
            justifySelf: "center",
            alignSelf: "center",
          }}
          wrapperClass="tail-spin"
          visible={true}
        />
      )}
    </AnimatePresence>
  );
};

export default TalentRecommended;
