import { useDispatch } from "react-redux";
import { AppDispatch } from "../redux/store/store";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import {
  googleAuthLogin,
  updateProfileState,
} from "../redux/ProfileSlice/ProfileSlice";
import { setJWTToken } from "../utils/jwtToken";
import { useSnackbar } from "notistack";

export const useGoogleAuth = () => {
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleGoogleAuth = useGoogleLogin({
    flow: "auth-code",
    onSuccess: async (codeResponse: any) => {
      dispatch(googleAuthLogin(codeResponse.code)).then((res) => {
        if (res.type.includes("rejected")) {
          enqueueSnackbar(res.payload.detail, {
            variant: "error",
          });
        } else {
          setJWTToken(
            res.payload.access,
            res.payload.refresh,
            res.payload.user.role,
            res.payload.user.on_boarding_completed,
          );
          dispatch(
            updateProfileState({
              accessToken: res.payload.access,
              boardingCompleted: res.payload.user.on_boarding_completed,
              userRole: res.payload.user.role,
            }),
          );
          if (!res.payload.user.on_boarding_completed) {
            navigate(`/select-profile`);
          } else {
            navigate(`/dashboard`);
          }
        }
      });
    },
    onError: async (errorResponse: any) => {
      // Consider adding more robust error handling here
    },
  });

  return handleGoogleAuth;
};
