import React, { FC } from "react";

import "./ProfileDetailCard.scss";
interface ProfileDetailCardProps {
  icon: any;
  title: string;
  content: number;
  background: string;
}

const ProfileDetailCard: FC<ProfileDetailCardProps> = ({
  icon,
  title,
  content,
  background,
}) => {
  return (
    <div
      style={{
        background: background,
      }}
      className="profileDetailCard"
    >
      <span>{icon}</span>
      <div className="detail">
        {title}
        <p>{content}</p>
      </div>
    </div>
  );
};

export default ProfileDetailCard;
