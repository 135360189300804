import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { message, Select } from "antd";
import { useForm, SubmitHandler } from "react-hook-form";
import { FormButton } from "../../../../components";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store/store";
import { useNavigate } from "react-router-dom";
import { getAccessToken } from "../../../../utils/jwtToken";
import { updateTalentProfile } from "../../../../redux/TalentSlice/TalentSlice";
import axios from "axios";
import api from "../../../../utils/api";

import "../EditProfileModal.scss";

const Option = Select.Option;

interface SignUpInputs {
  institute: string;
  degree: string;
  startYear: string;
  endYear: string;
  techList: string;
  company: string;
  role: string;
  name: string;
  credentials: string;
  cert_institute: string;
  companyName: string;
  startDate: string;
  endDate: string;
  experiences: Array<ExperienceObject>;
}

interface PasswordHidden {
  one: boolean;
  two: boolean;
}

type Props = {
  onModalClose?: () => void;
};

type EducationObject = {
  institute: string;
  degree: string;
  startYear: string;
  endYear: string;
};

type ExperienceObject = {
  id?: number;
  companyName: string;
  role: string;
  startDate: string;
  endDate: string;
};

type CertificateObject = {
  name: string;
  cert_institute: string;
  credentials: string;
};

const EditProfileModal: FC<Props> = (props) => {
  const [sharedFields, setSharedFields] = useState<any>();
  const talentProfile = useSelector((state: any) => state.talent.profileInfo);
  const onFieldChange = (fieldKey: string, value: string | number | object) => {
    if (!value) return;
    const fieldToSetInState = { [fieldKey]: { value, dirty: true } };

    setFormFields({ ...formFields, ...fieldToSetInState });
  };

  const dispatch = useDispatch<AppDispatch>();

  const initializeFormFieldsFromProfile = (talentProfile: any) => {
    const _formFields: any = {};

    _formFields.passion_industry = {
      value: talentProfile?.preferences?.industries?.map((ind: string) => ({
        value: ind,
      })),
    };

    _formFields.experience_level = {
      value: talentProfile?.preferences?.experience_level,
    };

    _formFields.education_level = {
      value: talentProfile?.preferences?.education_level,
    };

    _formFields.talent_skills = {
      value: talentProfile?.talent_skills?.map((ind: any) => ({
        value: ind.skill,
      })),
    };

    _formFields.talent_education = {
      value: talentProfile?.talent_education?.map((ind: any) => ({
        value: ind,
      })),
    };

    return _formFields;
  };
  const [formFields, setFormFields] = useState<any>(
    initializeFormFieldsFromProfile(talentProfile)
  );

  const formFieldKeys = Object.entries(formFields);
  const dirtyChangedFields = formFieldKeys.filter(
    ([key, value]: any) => value?.dirty
  );

  const talentId = talentProfile?.id;
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [educations, setEducations] = useState<Array<EducationObject>>([
    {
      institute: "",
      degree: "",
      startYear: "",
      endYear: "",
    },
  ]);

  const [experiences, setExperiences] = useState<Array<ExperienceObject>>([
    {
      companyName: "",
      role: "",
      startDate: "",
      endDate: "",
    },
  ]);

  const [certificates, setCertificates] = useState<Array<CertificateObject>>([
    {
      name: "",
      cert_institute: "",
      credentials: "",
    },
  ]);
  const [loading, setLoading] = useState<boolean>(false);

  const { register, handleSubmit, setValue } = useForm<SignUpInputs>();
  useEffect(() => {
    // Retrieve and set the persisted techList value
    const persistedTechList = localStorage.getItem("techList");
    if (persistedTechList) {
      setValue("techList", persistedTechList);
    }
    const persistedInstitute = localStorage.getItem("institute");
    if (persistedInstitute) {
      setValue("institute", persistedInstitute);
    }

    // Retrieve and set the persisted degree value
    const persistedDegree = localStorage.getItem("degree");
    if (persistedDegree) {
      setValue("degree", persistedDegree);
    }
  }, [setValue]);
  const onSubmit: SubmitHandler<SignUpInputs> = (data) => {
    const payload: any = {};

    if (data.institute && data.degree && data.startYear && data.endYear) {
      payload["talent_education"] = educations.map(() => ({
        school: data.institute,
        degree: data.degree,
        start_date: data.startYear,
        end_date: data.endYear,
        talent: talentId,
      }));
    }
    if (data.techList) {
      localStorage.setItem("techList", data.techList);
    }
    if (data.institute) {
      localStorage.setItem("institute", data.institute);
    }
    if (data.degree) {
      localStorage.setItem("degree", data.degree);
    }
    if (data.cert_institute && data.name && data.credentials) {
      payload["talent_certifications"] = certificates.map((cert) => ({
        institute: data.cert_institute,
        certification_name: data.name,
        credentials: data.credentials,
        talent: talentId,
      }));
    }

    if (
      data.experiences.length > 0 &&
      data.experiences?.[0]?.companyName &&
      data.experiences?.[0]?.role &&
      data.experiences?.[0]?.startDate &&
      data.experiences?.[0]?.endDate
    ) {
      const jobExperiencesPayload = data.experiences.map((exp) => {
        const experienceData: any = {
          company: exp.companyName,
          title: exp.role,
          start_date: exp.startDate,
          end_date: exp.endDate,
          talent: talentProfile.id,
        };

        if (exp.id && exp.id !== undefined) {
          experienceData.id = exp.id; // Only include id if it exists
        }

        return experienceData;
      });

      payload["job_experiences"] = jobExperiencesPayload;
    }

    const formFieldKeys = Object.entries(formFields);
    const dirtyChangedFields = formFieldKeys.filter(
      ([key, value]: any) => value?.dirty
    );

    dirtyChangedFields.forEach(([key, field]: any) => {
      if (key === "talent_skills") {
        payload["talent_skills"] = (field?.value ?? []).map((v: any) => ({
          talent: talentId,
          skill: v.id,
        }));
      }

      if (key === "passion_industry") {
        payload["preferences"] = {
          ...(payload["preferences"] || { talent: talentId }),

          [key]: (field?.value ?? []).map((v: any) => v.id),
        };
      }

      if (["experience_level", "education_level"].includes(key)) {
        key = key === "education_level" ? "highest_level_education" : key;
        const _value = field?.value?.id;

        payload["preferences"] = {
          ...(payload["preferences"] || { talent: talentId }),

          [key]: _value,
        };
      }
    });

    setLoading(true);
    dispatch(updateTalentProfile({ userId: talentId, payload }))
      .then(() => {
        setLoading(false);
        props.onModalClose?.();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (talentProfile.talent_job_experiences.length > 0) {
      setExperiences(talentProfile.talent_job_experiences);
    }
  }, [talentProfile]);

  useEffect(() => {
    if (
      talentProfile.talent_job_experiences &&
      talentProfile.talent_job_experiences.length > 0
    ) {
      setExperiences(talentProfile.talent_job_experiences);

      talentProfile.talent_job_experiences.forEach(
        (exp: any, index: number) => {
          setValue(`experiences.${index}.id`, exp.id);
          setValue(`experiences.${index}.companyName`, exp.company);
          setValue(`experiences.${index}.role`, exp.title);
          setValue(`experiences.${index}.startDate`, exp.start_date);
          setValue(`experiences.${index}.endDate`, exp.end_date);
        }
      );
    }
  }, [talentProfile, setValue]);

  useEffect(() => {
    // This code is used for rendering country flags:
    // ref: https://www.twilio.com/blog/international-phone-number-input-html-javascript

    // const phoneInputField = document.getElementById("phone");
    // (window as any).intlTelInput(phoneInputField);

    // const element = document.getElementsByClassName("iti__flag-container")[0];
    // (element as any).style.display = "none";

    const accessToken = getAccessToken();

    const internalFunc = async () => {
      const sharedFieldsValues: any = {};
      const promises: any = [];
      ["skill", "experience-level/", "industry", "education-level/"].forEach(
        async (elem) => {
          promises.push(
            axios.get(`${api}/shared/${elem}`, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            })
          );
        }
      );
      let allData = await Promise.all(promises);
      allData = allData.map((allData) => allData.data);

      setSharedFields({
        talent_skills: allData[0],
        experience_level: allData[1],
        passion_industry: allData[2],
        education_level: allData[3],
        // city: allData[2],
        // state: allData[3],
        // ethnicity: allData[4],
      });
    };
    internalFunc();
  }, []);

  const PlusIcon = () => (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 11H15M11 15V7M8 21H14C19 21 21 19 21 14V8C21 3 19 1 14 1H8C3 1 1 3 1 8V14C1 19 3 21 8 21Z"
        stroke="#5937CC"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );

  const addExperience = () => {
    setExperiences([
      ...experiences,
      {
        id: undefined,
        companyName: "",
        role: "",
        startDate: "",
        endDate: "",
      },
    ]);
  };

  const EducationForm = () => {
    return (
      <>
        {educations.map((edu: any, index: any) => (
          <>
            <label>
              <span>Institute Name</span>
              <input
                defaultValue={edu.institute}
                // defaultValue={
                //   formFields["talent_education"]?.value?.map((v: any) => v.value) || []
                // }
                {...register("institute")}
                type="text"
              />
            </label>

            <label>
              <span>Degree Name</span>
              <input
                defaultValue={edu.degree}
                {...register("degree")}
                type="text"
              />
            </label>

            <label>
              <span>Start Year</span>
              <input
                defaultValue={edu.startYear}
                {...register("startYear")}
                type="date"
                placeholder="YYYY-MM-DD"
                pattern="^\d{4}-\d{2}-\d{2}$"
              />
            </label>

            <label>
              <span>End Year</span>
              <input
                defaultValue={edu.endYear}
                {...register("endYear")}
                type="date"
                placeholder="YYYY-MM-DD"
                pattern="^\d{4}-\d{2}-\d{2}$"
              />
            </label>
          </>
        ))}
        <div
          style={{ display: "flex", cursor: "pointer" }}
          onClick={() => {
            // add new education
            setEducations([
              ...educations,
              {
                institute: "",
                degree: "",
                startYear: "",
                endYear: "",
              },
            ]);
          }}
        >
          <PlusIcon />{" "}
          <span
            style={{
              marginLeft: "10px",
              fontWeight: 400,
              fontSize: "16px",
              color: "#5937CC",
            }}
          >
            {" "}
            Add More Education
          </span>
        </div>
      </>
    );
  };

  const ExperienceForm = () => {
    return (
      <>
        {experiences.map((exp, index) => (
          <>
            <label>
              <span>Company Name</span>
              <input
                defaultValue={exp.companyName}
                {...register(`experiences.${index}.companyName` as const)}
                type="text"
                placeholder="Company Name"
              />
            </label>

            <label>
              <span>Role</span>
              <input
                defaultValue={exp.role}
                {...register(`experiences.${index}.role` as const)}
                type="text"
                placeholder="Role Title"
              />
            </label>

            <label>
              <span>Start Date</span>
              <input
                defaultValue={exp.startDate}
                {...register(`experiences.${index}.startDate` as const)}
                type="date"
                placeholder="YYYY-MM-DD"
              />
            </label>

            <label>
              <span>End Date</span>
              <input
                defaultValue={exp.endDate}
                {...register(`experiences.${index}.endDate` as const)}
                type="date"
                placeholder="YYYY-MM-DD"
              />
            </label>
          </>
        ))}
        <div
          style={{ display: "flex", cursor: "pointer" }}
          onClick={addExperience}
        >
          <PlusIcon />{" "}
          <span
            style={{
              marginLeft: "10px",
              fontWeight: 400,
              fontSize: "16px",
              color: "#5937CC",
            }}
          >
            Add More Experience
          </span>
        </div>
      </>
    );
  };

  const CertificateForm = () => {
    return (
      <>
        {certificates.map((cert) => (
          <>
            <label>
              <span>Certificate Name</span>
              <input
                defaultValue={cert.name}
                {...register("name")}
                name="name"
                type="text"
              />
            </label>

            <label>
              <span>Institute</span>
              <input
                defaultValue={cert.cert_institute}
                {...register("cert_institute")}
                name="cert_institute"
                type="text"
              />
            </label>

            <label>
              <span>Certificate Link</span>
              <input
                defaultValue={cert.credentials}
                {...register("credentials")}
                type="text"
                name="credentials"
              />
            </label>
          </>
        ))}
        <div
          style={{ display: "flex", cursor: "pointer" }}
          onClick={() => {
            // add new education
            setCertificates([
              ...certificates,
              {
                cert_institute: "",
                name: "",
                credentials: "",
              },
            ]);
          }}
        >
          <PlusIcon />{" "}
          <span
            style={{
              marginLeft: "10px",
              fontWeight: 400,
              fontSize: "16px",
              color: "#5937CC",
            }}
          >
            {" "}
            Add More Certificate
          </span>
        </div>
      </>
    );
  };

  return (
    <div className="editProfile__container">
      <form onSubmit={handleSubmit(onSubmit)}>
        <label>
          <span>What’s your skills? </span>
          <Select
            optionFilterProp="value"
            showSearch={true}
            defaultValue={
              formFields["talent_skills"]?.value?.map((v: any) => v.value) || []
            }
            onChange={(value, details) =>
              onFieldChange(
                "talent_skills",
                details?.map((d: any) => ({ id: d.id, value: d.value }))
              )
            }
            className="select"
            placeholder="Select all that applies"
            mode="multiple"
          >
            {sharedFields?.["talent_skills"]?.map((skill: any) => (
              <Option
                key={skill.id}
                label={skill.id}
                value={skill.skill}
                id={skill.id}
                className="roleType__option"
              >
                {skill.skill}
              </Option>
            ))}
          </Select>
        </label>
        <label>
          <span>My experience level in tech is? </span>
          <Select
            optionFilterProp="value"
            showSearch={true}
            className="select"
            placeholder="Select"
            defaultValue={formFields["experience_level"]?.value || null}
            onChange={(_, details: any) =>
              onFieldChange("experience_level", {
                id: details.id,
                value: details.value,
              })
            }
          >
            {sharedFields?.["experience_level"]?.map((skill: any) => (
              <Option
                key={skill.id}
                label={skill.id}
                value={skill.name}
                id={skill.id}
                className="roleType__option"
              >
                {skill.name}
              </Option>
            ))}
          </Select>
        </label>
        <label>
          <span>My passion industry is? </span>
          <Select
            optionFilterProp="value"
            showSearch={true}
            className="select"
            placeholder="Select"
            defaultValue={
              formFields["passion_industry"]?.value?.map((v: any) => v.value) ||
              []
            }
            onChange={(value, details) =>
              onFieldChange(
                "passion_industry",
                details?.map((d: any) => ({ id: d.id, value: d.value }))
              )
            }
            mode="multiple"
          >
            {sharedFields?.["passion_industry"]?.map((skill: any) => (
              <Option
                key={skill.id}
                label={skill.id}
                value={skill.industry}
                id={skill.id}
                className="roleType__option"
              >
                {skill.industry}
              </Option>
            ))}
          </Select>
        </label>

        <label>
          <span>Please list any technologies if not listed</span>
          <input
            style={{ height: "80px" }}
            {...register("techList")}
            type="text"
            placeholder="Write Here"
          />
        </label>

        <label>
          <span>My Education level is? </span>
          <Select
            optionFilterProp="value"
            showSearch={true}
            className="select"
            placeholder="Select"
            defaultValue={formFields["education_level"]?.value || null}
            onChange={(_, details: any) =>
              onFieldChange("education_level", {
                id: details.id,
                value: details.value,
              })
            }
          >
            {sharedFields?.["education_level"]?.map((skill: any) => (
              <Option
                key={skill.id}
                label={skill.id}
                value={skill.name}
                id={skill.id}
                className="roleType__option"
              >
                {skill.name}
              </Option>
            ))}
          </Select>
        </label>

        {/* Education Form */}

        <EducationForm />

        {/* Experience Form */}

        <ExperienceForm />

        {/* Certificate Form */}
        <CertificateForm />
        {errorMessage && <span className="errorMessage">{errorMessage}</span>}
        <FormButton text="Submit" loading={loading} />
      </form>
    </div>
  );
};

export default EditProfileModal;
