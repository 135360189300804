import React, { FC, useEffect } from "react";
import { RequestApproved } from "../../assets/icons";
import "./PartnerRequest.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../../redux/store/store";
import { getPartnerDashboard } from "../../redux/PartnerSlice/PartnerSlice";
import { handleLogout } from "../../utils/jwtToken";

export const PartnerRequest: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    // dispatch action to fetch profile info
    dispatch(getPartnerDashboard()).then((res) => {
      if (res.payload?.is_verified) {
        navigate("/dashboard");
      }
    });
  }, []);

  return (
    <div className="partnerRequest">
      <RequestApproved />
      <h1>Partner Request Received</h1>
      <p>
        We have received your partner request and our team will get back to you
        in next 5-10 business days{" "}
      </p>
      <button
        onClick={() => {
          handleLogout();
        }}
      >
        Login with different account
      </button>
    </div>
  );
};
