import { createPortal } from "react-dom";
import TechEventImage from "../../../assets/images/largetTechEvent.png";

interface EventObj {
  event_image: string;
  event_date: {
    month: string;
    day: string;
  };
  event_name: string;
  event_details: string;
  event_link: any;
}

interface ModalProps {
  eventObj: EventObj;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const Modal: React.FC<ModalProps> = ({ eventObj, setOpenModal }) => {
  console.log("eventObj", eventObj);
  return createPortal(
    <>
      {/* <div style={{ maxWidth: "650px", width: "100%", margin: "auto" }}> */}
      <div className="modal-overlay">
        <div className="modal-content">
          <button className="close-button" onClick={() => setOpenModal(false)}>
            &times;
          </button>
          <div>
            <img
              src={
                eventObj?.event_image ? eventObj?.event_image : TechEventImage
              }
              alt="tech"
              style={{ width: "100%", borderRadius: "10px" }}
            />
          </div>
          <div style={{ padding: "20px" }}>
            <div
              style={{ fontWeight: 700, fontSize: "16px", lineHeight: "24px" }}
            >
              {eventObj.event_name}
            </div>{" "}
            <div
              style={{
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "21px",
                color: "#6A6A6A",
                marginTop: "10px",
              }}
            >
              {eventObj.event_details}
            </div>
          </div>
          <div
            style={{
              marginBottom: "24px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <a
              style={{
                textDecoration: "none",
                outline: "none",
                flex: 1,
                borderRadius: "24px",
                border: "1px solid var(--Text-Primary)",
                background: "var(--Text-Primary)",
                color: "#f2f4f7",
                textAlign: "center",
                fontSize: "14px",
                fontWeight: 700,
                paddingBlock: "10px",
                marginTop: 24,
                cursor: "pointer",
              }}
              href={eventObj?.event_link}
              target="_blank"
              rel="noopener noreferrer"
            >
              View Event
            </a>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>,
    document.getElementById("portal")!
  );
};

export default Modal;
