import React, { FC, useState } from "react";

import "../../../ProfileScreen/Modals/EditProfileModal.scss";
import { ReactComponent as DeleteConfirm } from "../../../../assets/icons/deleteConfirm.svg";

import { Button } from "antd";

const CrossSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.17188 14.8299L14.8319 9.16992"
      stroke="#344054"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.8319 14.8299L9.17188 9.16992"
      stroke="#344054"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
      stroke="#344054"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

type Props = {
  onModalClose: () => void;
  onSubmit: () => void;
};

const AdminDeleteModal: FC<Props> = (props) => {
  const [textValue, setTextValue] = useState<string>();
  return (
    <div className="overlay">
      <div
        className="modal"
        style={{
          height: "auto",
          maxHeight: "1000px",
          overflowY: "unset",
          width: "fit-content",
        }}
      >
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              width: "100%",
              cursor: "pointer",
            }}
            onClick={props.onModalClose}
          >
            <CrossSvg />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              padding: "80px 40px",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <DeleteConfirm />
            </div>

            <div
              style={{
                fontWeight: 700,
                fontSize: "40px",
                color: "#1E1E2F",
                marginTop: "40px",
              }}
              className="heading"
            >
              Delete Account
            </div>

            <div
              style={{
                fontWeight: 400,
                fontSize: "14px",
                color: "#344054",
                lineHeight: "17.8px",
                width: "576px",
                marginTop: "20px",
                textAlign: "justify",
                // marginTop: "40px",
              }}
              className="para"
            >
              Deleting an account will result in the removal of all associated
              privileges and access. This action cannot be undone. Please
              confirm the deletion below
            </div>

            <div
              style={{
                fontWeight: 400,
                fontSize: "14px",
                color: "#344054",
                lineHeight: "17.8px",
                marginTop: "20px",
              }}
              className="para-2"
            >
              To confirm the deletion, please type "delete" below.
            </div>
            <div
              className="editProfile__container"
              style={{ marginTop: "20px", padding: "unset" }}
            >
              <input
                placeholder="Write Here"
                type="text"
                onChange={(e) => setTextValue(e.target?.value)}
              />
            </div>
            <div id="Button Section" className="btn" style={{ marginTop: "30px" }}>
              <Button
                type="primary"
                shape="round"
                size={"large"}
                style={{
                  padding: "6px 44px",
                  fontSize: "14px",
                  fontWeight: 600,
                  background: "#1E1E2F",
                }}
                onClick={props.onModalClose}
              >
                Cancel
              </Button>

              <Button
                type="primary"
                shape="round"
                size={"large"}
                disabled={textValue?.toLowerCase() !== "delete"}
                style={{
                  marginLeft: "20px",
                  padding: "6px 44px",
                  fontSize: "14px",
                  fontWeight: 600,
                  background: "none",
                  color: textValue?.toLowerCase() === "delete" ? "#1E1E2F" : "",
                  border: "0.5px solid",
                }}
                className="btn-large"
                onClick={props.onSubmit}
              >
                Delete Account
              </Button>
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default AdminDeleteModal;
