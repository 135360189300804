import React, { useState } from "react";

import "./TalentCard.scss";
import { Coach } from "../../assets/images";
import {
  Bookmark,
  Experience,
  Pin,
  Star,
  Verified,
  OpenFor,
  BestMatch,
} from "../../assets/icons";
import axios from "axios";
import { getAccessToken } from "../../utils/jwtToken";
import api from "../../utils/api";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import InitialsAvatar from "../InitialsAvatar/InitialsAvatar";

type Props = {
  talent?: any;
  resume?: string;
  showResume?: boolean;
};
const CoachCard = (props: Props) => {
  const { talent: coach } = props;
  const resume = props.resume || false;
  const showResume = props.showResume || false;

  const [saveToggle, setSaveToggle] = useState(coach?.is_saved);
  const [loading, setLoading] = useState<boolean>(false);
  // Not coming from backend
  const isBestMatch = coach?.is_best_match || true;

  const [saved, setSaved] = useState<boolean>(false);
  const navigate = useNavigate();

  const saveTalent = async () => {
    setLoading(true);
    setSaveToggle(true);
    try {
      await axios.post(
        `${api}/talent/save/talent/${coach?.id}/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        }
      );
      setSaved((prevState) => !prevState);
    } catch {}
  };

  const unsaveTalent = async () => {
    setLoading(true);
    setSaveToggle(false);
    try {
      await axios.delete(`${api}/talent/delete/talent/${coach?.id}/`, {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      });
      setLoading(false);
      setSaved((prevState) => !prevState);
    } catch {
      setLoading(false);
    }
  };

  const formatLocation = (location: any) => {
    const { city = "", state = "", country = "" } = location || {};
    return [city, state, country].filter((part) => part).join(", ");
  };

  return (
    <div className="talentCard" style={coach?.style || {}}>
      <Bookmark
        className={`bookmark ${saveToggle ? "bookmarkChecked" : ""}`}
        onClick={!loading ? (saveToggle ? unsaveTalent : saveTalent) : () => {}}
      />
      <div className="talentCard__details">
        {/* Image Not coming from backend */}
        {coach?.profile_image || coach?.image ? (
          <div
            style={{
              backgroundImage: `url(${coach?.profile_image || coach?.image})`,
              minWidth: 60,
              height: 60,
              backgroundSize: "cover",
              backgroundPosition: "top",
              backgroundRepeat: "no-repeat",
              borderRadius: "50%",
            }}
          ></div>
        ) : (
          <InitialsAvatar
            name={coach?.name}
            customStyles={{ width: "62px", height: "62px" }}
          />
        )}

        <div className="talentCard__details-container">
          <div className="name">
            {coach?.name || "---"}
            {coach?.is_verified ? <Verified /> : null}
          </div>
          <p className="profession">{coach?.profession || "----"}</p>
          <div className="reviews" style={{ marginTop: "4px" }}>
            {coach?.is_best_match ? <BestMatch /> : null}
          </div>
        </div>
      </div>
      <div className="talentCard__stats">
        <section>
          <div className="talentCard__stats-label">
            <Pin /> From
          </div>
          <span
            style={{
              display: "block",
              whiteSpace: "nowrap",
              width: "80px",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {coach?.location ? formatLocation(coach.location) : "------"}
          </span>
        </section>
        <section>
          <div className="talentCard__stats-label">
            <Experience /> Experience
          </div>
          {coach?.experience_level?.length > 0
            ? coach?.experience_level
            : "------"}
        </section>
        <section>
          <div className="talentCard__stats-label">
            <OpenFor /> Open for
          </div>
          <span
            style={{
              display: "block",
              whiteSpace: "nowrap",
              width: "80px",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {coach?.work_best_on?.length > 0
              ? coach?.work_best_on?.join(", ")
              : "---"}
          </span>
        </section>
      </div>
      <div className="talentCard__buttons">
        {/* {showResume && (
          <button
            onClick={() => {
              if (resume) {
                const url = resume;
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "file");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }
            }}
            disabled={!resume}
          >
            View Resume
          </button>
        )} */}
        {showResume && (
          <button
            onClick={() => {
              if (resume) {
                const url = resume;
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "resume.pdf"); // Set the file name with a .pdf extension
                link.setAttribute("type", "application/pdf"); // Ensure the MIME type is PDF
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }
            }}
            disabled={!resume}
          >
            View Resume
          </button>
        )}

        <button
          onClick={() => {
            navigate(`/profile/talent/${coach?.id}`);
          }}
        >
          View Profile
        </button>
      </div>
    </div>
  );
};

export default CoachCard;
