import React, { useState } from "react";

import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store/store";

import { useNavigate, useParams } from "react-router-dom";
import UserTypeProfiles from "./UserTypeProfiles";
// import { useGoogleLogin } from "@react-oauth/google";

import "./ProfileScreen.scss";

const userTypeProfileMap = {
  talent: UserTypeProfiles.TalentProfile,
  coach: UserTypeProfiles.CoachProfile,
  employer: UserTypeProfiles.EmployerProfile,
  partner: UserTypeProfiles.PartnerProfile,
};

type ProfileParams = {
  userType: "talent" | "coach" | "employer" | "partner";
  userId: string;
};

const ProfileScreen: React.FC = () => {
  const { userId, userType } = useParams<ProfileParams>();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  if (!userType) return null;

  const UserTypeProfileToRender = userTypeProfileMap[userType];
  if (!UserTypeProfileToRender) return null;

  return (
    <div className="profile">
      <UserTypeProfileToRender />
    </div>
  );
};

export default ProfileScreen;
