import React, { useEffect, useState } from "react";

import moment from "moment";

import { useParams } from "react-router-dom";

import { ReactComponent as BagIcon } from "../../assets/icons/bagIcon.svg";

import { Tag } from "antd";
import Slider from "react-slick";

import { message } from "antd";
import { Clock, Pin, Verified } from "../../assets/icons";
import { Job } from "../../assets/images";
import { RecommendedJob } from "../../components";
import DashboardNavbar from "../../components/DashboardNavbar/DashboardNavbar";
import api from "../../utils/api";
import { getAccessToken } from "../../utils/jwtToken";

import ApplyJobModal from "./Modals/ApplyJobModal";

// import { useGoogleLogin } from "@react-oauth/google";

import "./JobPage.scss";

import axios from "axios";
import Cookies from "js-cookie";
import { TailSpin } from "react-loader-spinner";
import InitialsAvatar from "../../components/InitialsAvatar/InitialsAvatar";
import { stripHtml } from "../../utils/stripHTML";

type JobParams = {
  jobId: string;
};

type Props = {
  // typing of data provided by parent
};

const JobPage: React.FC = () => {
  const [jobData, setJobData] = useState<any>(null);

  const [saved, setSaved] = useState<boolean>(false);
  const [isApplied, setIsApplied] = useState<boolean>(jobData?.is_applied);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [openModal, setModalOpen] = useState<boolean>(false);

  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 700);
  const [customStyleForNav, setCustomStyleForNav] = useState({});

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 700);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isMobileView) {
      setCustomStyleForNav({
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "unset",
        padding: "20px 35px",
        maxWidth: "1800px",
      });
    } else {
      setCustomStyleForNav({
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "unset",
        padding: "20px 150px",
        maxWidth: "1800px",
      });
    }
  }, []);

  const { jobId } = useParams<JobParams>();

  const saveJob = async () => {
    setSaveLoading(true);
    await axios.post(
      `${api}/${Cookies.get("role")}/save/job/${jobId}/`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    );
    setSaved(true);
    message.success("Job Saved Successfully");
    setSaveLoading(false);
  };

  const unsaveJob = async () => {
    setSaveLoading(true);
    await axios.delete(`${api}/${Cookies.get("role")}/delete/job/${jobId}/`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    setSaved(false);
    message.success("Job unsaved Successfully");
    setSaveLoading(false);
  };

  useEffect(() => {
    const responsePromise = axios.get(`${api}/job/${jobId}/`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    responsePromise.then((response: any) => {
      setJobData(response.data);
      setSaved(response.data?.is_saved);
    });
  }, [openModal]);

  const [_width, setWidth] = useState<number | null>();
  const [selectedTab, setSelectedTab] = useState<string | null>("overview");

  // const job: any = {};

  const role = Cookies.get("role");

  return (
    <>
      <DashboardNavbar addLogo={true} customStyles={customStyleForNav} />
      {openModal && (
        <ApplyJobModal
          onModalClose={() => setModalOpen(false)}
          jobTitle={jobData?.title}
          jobId={jobId as string}
        />
      )}
      {jobData ? (
        <div className="job_container">
          {/* // Section 1 */}
          <div className="job_container_section-1">
            {jobData?.employer?.images?.profile_image ? (
              <img
                src={
                  jobData?.employer?.images?.profile_image
                    ? jobData?.employer?.images?.profile_image
                    : Job
                }
                className="profile"
                alt="job"
                style={{ width: "80px", height: "80px" }}
              />
            ) : (
              <InitialsAvatar
                name={
                  jobData?.employer?.company_name === null
                    ? "---"
                    : jobData?.employer?.company_name
                }
                customStyles={{
                  width: "115px",
                  height: "115px",
                  fontSize: "32px",
                }}
              />
            )}
            <div className="job_container_title">
              {jobData?.title || "Software Engineer"}
            </div>
            <div className="job_container_name">
              <div style={{ display: "flex", gap: "6px" }}>
                {jobData?.employer?.company_name || "------"}{" "}
                <div
                  style={{ display: "flex", alignItems: "center", gap: "4px" }}
                >
                  <Verified />{" "}
                  <span className="job_container_tribaja">
                    Tribaja Verified
                  </span>
                </div>
              </div>

              <div
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
              >
                <Pin />
                <span> {jobData?.location || "-------"}</span>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
              >
                <Clock />
                <span>
                  {jobData?.posted_since
                    ? moment(
                        new Date(jobData?.posted_since).toLocaleDateString()
                      ).fromNow()
                    : "-- Hours Ago"}
                </span>
              </div>
            </div>
            {role !== "employer" ? (
              <div className="job_container_buttons">
                <button
                  disabled={jobData?.is_applied}
                  onClick={() => !jobData?.is_applied && setModalOpen(true)}
                >
                  {jobData?.is_applied ? "Applied" : "Apply"}
                </button>
                <button
                  className="invert"
                  onClick={!saved ? saveJob : unsaveJob}
                >
                  {saveLoading ? (
                    <TailSpin
                      height="16"
                      width="16"
                      color="#fdceb5"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  ) : (
                    `${!saved ? "Save job" : "Unsave job"}`
                  )}
                </button>
              </div>
            ) : null}
          </div>

          {/* // Section 2 */}
          <div className="job_container_section-2">
            <div
              className="job_container_card"
              style={{ background: "#FEEDCC" }}
            >
              <span>Job Type</span>
              <span style={{ fontWeight: 600, fontSize: "16px" }}>
                {jobData?.employment_type && jobData.employment_type.length > 0
                  ? jobData.employment_type[0].name
                  : "No job type specified"}
              </span>
            </div>

            <div
              className="job_container_card"
              style={{ background: "#FDCEB5" }}
            >
              <span>Salary</span>
              <span style={{ fontWeight: 600, fontSize: "16px" }}>
                {jobData?.salary_range?.from
                  ? `$${jobData?.salary_range?.from?.toLocaleString()} - $${jobData?.salary_range?.to?.toLocaleString()} annually`
                  : "----"}
              </span>
            </div>

            <div
              className="job_container_card"
              style={{ background: "#BDAFEB" }}
            >
              <span>Experience</span>
              <span style={{ fontWeight: 600, fontSize: "16px" }}>
                {jobData?.seniority_level && jobData.seniority_level.length > 0
                  ? jobData.seniority_level[0].name
                  : "No experience specified"}
              </span>
            </div>

            <div
              className="job_container_card"
              style={{ background: "#91C7B1" }}
            >
              <span>Industries</span>
              <span style={{ fontWeight: 600, fontSize: "16px" }}>
                {jobData?.industry && jobData.industry.length > 0
                  ? jobData.industry[0].industry
                  : "No industry specified"}
              </span>
            </div>
          </div>

          {/* // Section 3 */}
          <div className="job_container_section-3">
            <div className="job_container_section-3-left-half">
              <div
                style={{ fontWeight: 600, fontSize: "16px", color: "#000000" }}
              >
                Job Requirements
              </div>
              <p
                style={{
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "28px",
                  color: "#1E1E2F",
                  marginTop: "20px",
                }}
              >
                {jobData?.job_description ? (
                  stripHtml(jobData?.job_description)
                ) : (
                  <span>No job description</span>
                )}
              </p>
            </div>

            <div className="job_container_section-3-vertical-line" />

            <div className="job_container_section-3-right-half">
              <div className="skills">
                <div
                  style={{
                    fontWeight: 600,
                    fontSize: "16px",
                    color: "#000000",
                  }}
                >
                  Skills
                </div>
                <div style={{ display: "flex", gap: "10px" }}>
                  {jobData?.skills?.length > 0
                    ? jobData?.skills.map((skill: any) => (
                        <Tag
                          color="#F2F4F7"
                          style={{
                            width: "fit-content",
                            borderRadius: "12px",
                            color: "#344054",
                            padding: "4px 12px 4px 12px",
                            fontSize: "14px",
                            marginTop: "10px",
                            paddingTop: "1px",
                            paddingBottom: "2px",
                            fontWeight: 400,
                          }}
                        >
                          {skill.skill}
                        </Tag>
                      ))
                    : "No skills added"}
                </div>
              </div>

              <div className="skills" style={{ marginTop: "40px" }}>
                <div
                  style={{
                    fontWeight: 600,
                    fontSize: "16px",
                    color: "#000000",
                  }}
                >
                  {/* This will be dynamic */}
                  Working at{" "}
                  {jobData?.employer?.company_name
                    ? jobData?.employer?.company_name
                    : ""}
                </div>
                <div
                  className="skills-sec"
                  style={{ display: "flex", gap: "40px", marginTop: "20px" }}
                >
                  <div
                    className="job_container_card"
                    style={{ background: "#FDCEB5" }}
                  >
                    <span
                      style={{
                        fontWeight: 600,
                        fontSize: "16px",
                        display: "flex",
                        gap: "8px",
                      }}
                    >
                      <BagIcon />{" "}
                      {jobData?.diversity
                        ? jobData?.diversity?.number_of_female_employees +
                          jobData?.diversity?.number_of_male_employees +
                          jobData?.diversity?.number_of_non_binary_employees
                        : "--"}
                    </span>
                    <span> Number of employees </span>
                  </div>

                  <div
                    className="job_container_card"
                    style={{ background: "#BDAFEB" }}
                  >
                    <span
                      style={{
                        fontWeight: 600,
                        fontSize: "16px",
                        display: "flex",
                        gap: "8px",
                      }}
                    >
                      <BagIcon />{" "}
                      {jobData?.diversity
                        ? `${
                            jobData?.diversity?.number_of_male_employees || 0
                          } : ${
                            jobData?.diversity?.number_of_female_employees || 0
                          } : ${
                            jobData?.diversity
                              ?.number_of_non_binary_employees || 0
                          }`
                        : // ? calculateRatio(
                          //     jobData?.diversity?.number_of_male_employees || 0,
                          //     jobData?.diversity?.number_of_female_employees || 0,
                          //     jobData?.diversity?.number_of_non_binary_employees || 0
                          //   )
                          "-:-"}
                    </span>
                    <span> Gender diversity (male:female:non-binary) </span>
                  </div>
                </div>
              </div>

              <div
                className="skills"
                style={{ marginTop: "40px", borderBottom: "unset" }}
              >
                <div
                  style={{
                    fontWeight: 600,
                    fontSize: "16px",
                    color: "#000000",
                  }}
                >
                  {/* This will be dynamic */}
                  Inclusive Benefits
                </div>
                <div
                  style={{
                    display: "flex",
                    marginTop: "20px",
                    flexWrap: "wrap",
                  }}
                >
                  {jobData?.inclusive_benefits?.length > 0 ? (
                    jobData?.inclusive_benefits.map((ben: any) => (
                      <Tag
                        color="#EDEEF7"
                        style={{
                          width: "fit-content",
                          borderRadius: "16px",
                          color: "#344054",
                          padding: "8px",
                          fontSize: "14px",
                          marginTop: "20px",
                          marginLeft: "10px",
                        }}
                      >
                        {ben.benefit_options}
                      </Tag>
                    ))
                  ) : (
                    <Tag
                      color="#EDEEF7"
                      style={{
                        width: "fit-content",
                        borderRadius: "16px",
                        color: "#344054",
                        padding: "8px",
                        fontSize: "14px",
                        marginTop: "20px",
                        marginLeft: "10px",
                      }}
                    >
                      No benefits added
                    </Tag>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className="job_container_section-4"
            style={{ marginTop: "40px" }}
          >
            {(jobData?.similar_jobs ?? []).length > 0 && (
              <div className="talentRecommended__recommendations-container">
                <div className="talentRecommended__recommendations-container-title">
                  <div>Similar Positions</div>
                </div>
                <div
                  className="talentRecommended__recommendations-slider"
                  style={{ marginTop: "20px" }}
                >
                  <Slider
                    dots={true}
                    slidesToShow={Math.min(jobData?.similar_jobs?.length, 3)}
                    slidesToScroll={1}
                    infinite={false}
                    autoplay={true}
                    autoplaySpeed={3000}
                    arrows={false}
                    responsive={[
                      {
                        breakpoint: 1189, // Width less than or equal to 1024px
                        settings: {
                          slidesToShow: 2,
                          slidesToScroll: 2,
                          infinite: true,
                          dots: true,
                        },
                      },
                      {
                        breakpoint: 938, // Width less than or equal to 1024px
                        settings: {
                          slidesToShow: 1,
                          slidesToScroll: 1,
                          infinite: true,
                          dots: true,
                        },
                      },
                    ]}
                  >
                    {jobData?.similar_jobs?.slice(0, 7).map((job: any) => {
                      return (
                        <RecommendedJob
                          job={{
                            ...job,
                            style: { width: "420px" },
                          }}
                        />
                      );
                    })}
                  </Slider>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <TailSpin
          height="160"
          width="160"
          color="#FBAD84"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{
            justifySelf: "center",
            alignSelf: "center",
          }}
          wrapperClass="profile-tail-spin"
          visible={true}
        />
      )}
    </>
  );
};

export default JobPage;
