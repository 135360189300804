import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import { useNavigate, useParams } from "react-router-dom";
import ProfileCover from "../../../assets/images/profileCover.png";
import MentorImage from "../../../assets/images/mentor.png";
import OurTalentPf1 from "../../../assets/images/ourTalentPf1.png";
import JobImage from "../../../assets/images/job.png";
import { ReactComponent as LocationIcon } from "../../../assets/icons/locationIcon.svg";
import { ReactComponent as SessionIcon } from "../../../assets/icons/sessions.svg";
import { ReactComponent as ExperienceIcon } from "../../../assets/icons/experience.svg";
import { ReactComponent as Stats } from "../../../assets/icons/stats.svg";
// import { ReactComponent as JobImage } from "../../../assets/images/jobExampleImage.svg";
import SocialMediaIcons from "../../../assets/icons/SocialMediaIcons";
import { ReactComponent as BagIcon } from "../../../assets/icons/bagIcon.svg";
import { ReactComponent as VerifiedIcon } from "../../../assets/icons/verifiedIcon.svg";
import { ReactComponent as BookmarkIcon } from "../../../assets/icons/bookmark.svg";
import { ReactComponent as StarIcon } from "../../../assets/icons/starIcon.svg";
import { AntDesignOutlined } from "@ant-design/icons";
import { Avatar, Tag, Button, Tabs, Progress } from "antd";
import type { TabsProps } from "antd";
import { Link, useLocation } from "react-router-dom";
import TechEventImage from "../../../assets/images/largetTechEvent.png";
import EditProfileModal from "../Modals/EditProfileModal";

// import { useGoogleLogin } from "@react-oauth/google";

import "./Profile.scss";
import RequestForDeleteModal from "../../../components/RequestForDeleteModal/RequestForDeleteModal";
import RequestForLeaveModal from "../../../components/RequestForLeaveModal/RequestForLeaveModal";

type ProfileParams = {
  userType: "talent" | "coach" | "employer" | "partner";
  userId: string;
};

type Props = {
  // typing of data provided by parent
};

type DetailObject = {
  title: string;
  desc: string;
};
const TalentProfile: React.FC = (props: Props) => {
  const ReviewTile = ({ reviewObj }: any) => {
    return (
      <div
        className="job-tile"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "320px",
          marginTop: "40px",
        }}
      >
        {/* Review Paragraph */}
        <div
          style={{
            marginTop: "30px",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "30px",
            color: "#111111",
            width: "320px",
          }}
        >
          {reviewObj.reviewComment}
        </div>

        <div
          style={{
            display: "flex",
            width: "100%",
            marginTop: "40px",
            justifyContent: "space-around",
            alignItems: "center",
            marginLeft: "30px",
          }}
        >
          <Avatar
            style={{ width: "70px", height: "70px", marginTop: "30px" }}
            src={reviewObj.reviewerImage}
          />

          <div
            style={{
              display: "flex",
              fontWeight: 700,
              fontSize: "18px",
              lineHeight: "20px",
              color: "#344054",
              marginTop: "20px",
              alignItems: "baseline",
              flexDirection: "column",
              marginRight: "50px",
            }}
          >
            {reviewObj.name}
            <div
              style={{
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "20px",
                color: "#6F6C90",
                marginTop: "4px",
                alignItems: "center",
              }}
            >
              {" "}
              {reviewObj.jobTitle}{" "}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const MentorTile = ({ mentorObj }: any) => {
    return (
      <div
        className="job-tile"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "290px",
          marginTop: "40px",
        }}
      >
        {/* <div style={{display: "flex", flexDirection: "column", alignItems: 'center'}}> */}

        {/* Mentor */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            width: "100%",
            marginLeft: "-60px",
            marginTop: "12px",
          }}
        >
          <Avatar
            style={{
              width: "78px",
              height: "78px",
              border: "1px solid #FBAD84",
              marginTop: "30px",
            }}
            src={mentorObj.image}
          />

          {/* Mentor Details */}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "20px",
              marginLeft: "-50px",
            }}
          >
            <div
              style={{
                display: "flex",
                fontWeight: 600,
                fontSize: "22px",
                lineHeight: "24px",
                color: "#1E1E2F",
                marginTop: "6px",
                alignItems: "baseline",
              }}
            >
              {mentorObj.name}
              {mentorObj.tribajaVerified && (
                <VerifiedIcon style={{ marginLeft: "10px" }} />
              )}
            </div>

            <div
              style={{
                display: "flex",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "15.31px",
                color: "#344054",
                marginTop: "4px",
                alignItems: "center",
              }}
            >
              {mentorObj.jobTitle}
            </div>

            <div
              style={{
                display: "flex",
                marginTop: "6px",
                justifyContent: "space-between",
              }}
            >
              {new Array(mentorObj.starReviews).fill(1).map(() => {
                return <StarIcon />;
              })}
              <div
                style={{
                  marginLeft: "4px",
                  fontSize: "12px",
                  fontWeight: 350,
                  color: "#344054",
                }}
              >
                {" "}
                {mentorObj.starReviews + " "} reviews
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            width: "92%",
            marginTop: "40px",
          }}
        >
          <div>
            <div
              style={{
                fontWeight: 600,
                fontSize: "12px",
                lineHeight: "15.31px",
                display: "flex",
              }}
            >
              <LocationIcon style={{ marginRight: "8px" }} /> From
            </div>
            <div
              style={{
                color: "#344054",
                fontSize: "12px",
                lineHeight: "15.31px",
                fontWeight: 400,
                marginTop: "10px",
              }}
            >
              {mentorObj.location}
            </div>
          </div>

          <div>
            <div
              style={{
                fontWeight: 600,
                fontSize: "12px",
                lineHeight: "15.31px",
                display: "flex",
              }}
            >
              <ExperienceIcon style={{ marginRight: "8px" }} /> Experience
            </div>
            <div
              style={{
                color: "#344054",
                fontSize: "12px",
                lineHeight: "15.31px",
                fontWeight: 400,
                marginTop: "10px",
              }}
            >
              {mentorObj.experience}
            </div>
          </div>

          <div>
            <div
              style={{
                fontWeight: 600,
                fontSize: "12px",
                lineHeight: "15.31px",
                display: "flex",
              }}
            >
              <SessionIcon style={{ marginRight: "8px" }} /> Sessions
            </div>
            <div
              style={{
                color: "#344054",
                fontSize: "12px",
                lineHeight: "15.31px",
                fontWeight: 400,
                marginTop: "10px",
              }}
            >
              {mentorObj.sessions}
            </div>
          </div>
        </div>

        {/* Action Buttons */}

        <div style={{ display: "flex", marginTop: "20px" }}>
          <Button
            type="primary"
            shape="round"
            size={"large"}
            style={{
              width: "170px",
              padding: "6px 44px",
              fontSize: "14px",
              fontWeight: 600,
              background: "#1E1E2F",
            }}
          >
            Apply
          </Button>
          <Button
            type="primary"
            shape="round"
            size={"large"}
            style={{
              width: "170px",
              marginLeft: "20px",
              padding: "6px 44px",
              fontSize: "14px",
              fontWeight: 600,
              background: "none",
              color: "#1E1E2F",
              border: "0.5px solid",
            }}
          >
            View Job
          </Button>
        </div>
      </div>
    );
  };

  const EventTile = ({ eventObj }: any) => {
    return (
      <div
        className="job-tile"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* <div style={{display: "flex", flexDirection: "column", alignItems: 'center'}}> */}

        {/* Event Cover Image */}

        <div>
          <img src={eventObj.image} />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "22px",
            }}
          >
            <div
              style={{
                fontWeight: 700,
                fontSize: "11px",
                lineHeight: "14px",
                color: "#5937CC",
                textAlign: "center",
              }}
            >
              {eventObj.date.month}{" "}
            </div>
            <div
              style={{
                fontWeight: 700,
                fontSize: "26px",
                lineHeight: "33px",
                color: "#000000",
              }}
            >
              {eventObj.date.day}{" "}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "300px",
              marginLeft: "10px",
              marginTop: "20px",
            }}
          >
            <div
              style={{ fontWeight: 700, fontSize: "16px", lineHeight: "24px" }}
            >
              {eventObj.title}
            </div>

            <div
              style={{
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "21px",
                color: "#6A6A6A",
                marginTop: "10px",
              }}
            >
              {eventObj.desc}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const JobTile = ({ jobObj }: any) => {
    // const jobs = [{
    // "logoImage": JobImage, "companyName": "Fluent LLC", companyStars:"5", jobTile: "Software Engineer",
    // location:"Sans Francisco, CA", jobType:"Full Time Remote", updated: "17 Hours Ago", percentageMatch}]
    return (
      <div
        className="job-tile"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* <div style={{display: "flex", flexDirection: "column", alignItems: 'center'}}> */}

        {/* Job Corners logos */}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
            width: "100%",
          }}
        >
          <Progress
            type="circle"
            percent={jobObj.percentageMatch}
            size={40}
            style={{ marginLeft: "10px" }}
            strokeColor="#5937CC"
          />

          <BookmarkIcon style={{ marginRight: "20px" }} />
        </div>

        {/* JobImage */}
        <Avatar
          style={{
            width: "78px",
            height: "78px",
            border: "1px solid #FBAD84",
            marginTop: "30px",
          }}
          src={jobObj.logoImage}
        />

        {/* Company Name */}

        <div
          style={{
            display: "flex",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "20.42px",
            color: "#344054",
            marginTop: "4px",
            alignItems: "center",
          }}
        >
          {jobObj.companyName}
          <VerifiedIcon style={{ marginLeft: "10px" }} />
        </div>

        {/* JobStars */}

        <div
          style={{
            display: "flex",
            marginTop: "6px",
            width: "100px",
            justifyContent: "space-between",
          }}
        >
          {new Array(jobObj.companyStars).fill(1).map(() => {
            return <StarIcon />;
          })}
        </div>

        {/* Job Title */}

        <div
          style={{
            fontWeight: 600,
            fontSize: "22px",
            lineHeight: "24px",
            color: "#1E1E2F",
            marginTop: "6px",
          }}
        >
          {jobObj.jobTitle}
        </div>

        <hr style={{ marginTop: "20px", width: "85%" }} />

        {/* location and minor details */}

        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            width: "92%",
            marginTop: "6px",
          }}
        >
          <div
            style={{
              color: "#344054",
              fontSize: "12px",
              lineHeight: "15.31px",
              fontWeight: 400,
              display: "flex",
            }}
          >
            <LocationIcon style={{ marginRight: "2px" }} /> {jobObj.location}
          </div>

          <div
            style={{
              color: "#344054",
              fontSize: "12px",
              lineHeight: "15.31px",
              fontWeight: 400,
              display: "flex",
            }}
          >
            <LocationIcon style={{ marginRight: "2px" }} /> {jobObj.jobType}
          </div>

          <div
            style={{
              color: "#344054",
              fontSize: "12px",
              lineHeight: "15.31px",
              fontWeight: 400,
              display: "flex",
            }}
          >
            <LocationIcon style={{ marginRight: "2px" }} /> {jobObj.updated}
          </div>
        </div>

        {/* Action Buttons */}

        <div style={{ display: "flex", marginTop: "20px" }}>
          <Button
            type="primary"
            shape="round"
            size={"large"}
            style={{
              width: "170px",
              padding: "6px 44px",
              fontSize: "14px",
              fontWeight: 600,
              background: "#1E1E2F",
            }}
          >
            Apply
          </Button>
          <Button
            type="primary"
            shape="round"
            size={"large"}
            style={{
              width: "170px",
              marginLeft: "20px",
              padding: "6px 44px",
              fontSize: "14px",
              fontWeight: 600,
              background: "none",
              color: "#1E1E2F",
              border: "0.5px solid",
            }}
          >
            View Job
          </Button>
        </div>

        {/* </div> */}
      </div>
    );
  };

  const { userId, userType } = useParams<ProfileParams>();
  const navigate = useNavigate();

  const onChange = (key: string) => {
    setSelectedTab(key);
  };

  const splitArrayToChunks = (_array: Array<object>, chunk: number) =>
    _array.reduce((all: Array<object>, one, i) => {
      const ch = Math.floor(i / chunk);
      all[ch] = [].concat((all[ch] as any) || [], one as any);
      return all;
    }, []);

  const [_width, setWidth] = useState<number | null>();
  const [selectedTab, setSelectedTab] = useState<string | null>("overview");
  const [openModal, setModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const _clientWidth = document.getElementById("tabs")?.clientWidth;

    if (_clientWidth) setWidth(_clientWidth);
  }, document.getElementById("tabs")?.clientWidth as any);

  const MentorContent: React.FC = () => {
    const mentors = new Array(6).fill({
      image: MentorImage,
      name: "Jason Doe",
      jobTitle: "UI/UX Designer at LinkedIn",
      tribajaVerified: true,
      starReviews: 5,
      location: "Sans Francisco, CA",
      experience: "10 Years",
      sessions: "30 sessions",
    });

    return (
      <div id="MainDiv" style={{ display: "flex", marginBottom: "20px" }}>
        <div
          id="Mentor-tiles"
          className="tiles-grid"
          style={{ marginTop: "40px" }}
        >
          {mentors.map((mentor) => (
            <MentorTile mentorObj={mentor} />
          ))}
        </div>
      </div>
    );
  };

  const ReviewsContent: React.FC = () => {
    const reviews = new Array(6).fill({
      reviewerImage: MentorImage,
      name: "Adam Smith",
      jobTitle: "UI/UX Designer at LinkedIn",
      reviewComment:
        '"Thank you Tribaja for connecting me with Spotify — I was hired as a Senior Designer and so far it’s been an amazing experience!"',
    });

    return (
      <div id="MainDiv" style={{ display: "flex", marginBottom: "20px" }}>
        <div
          id="Mentor-tiles"
          className="tiles-grid"
          style={{ marginTop: "40px" }}
        >
          {/* {mentors.map(mentor => 
      <MentorTile mentorObj={mentor} />
    )} */}

          {reviews.map((review) => (
            <ReviewTile reviewObj={review} />
          ))}
        </div>
      </div>
    );
  };

  const OverViewContent: React.FC = () => {
    const skillsList = new Array(7).fill("Skill");
    const industries = new Array(5).fill("Industry");
    const experties = new Array(5).fill("Industry");

    const communityStats = [
      { mentoringTime: "20 Hours", sessionsCompleted: "30" },
    ];

    const certifications = splitArrayToChunks(
      new Array(6).fill({ title: "Udemy", desc: "UI/UX course by Chris Doe" }),
      2
    ) as Array<Array<DetailObject>>;
    const openTo = splitArrayToChunks(
      [
        {
          title: "Full Time Roles",
          desc: "Starting a new role in full time capacity",
        },
        { title: "Prefer mods of work", desc: "Remote, Hybrid" },
      ],
      2
    ) as Array<Array<DetailObject>>;
    const experiences = splitArrayToChunks(
      [
        { title: "Apple", desc: "UI/UX Desiner (April 2020- Present)" },
        { title: "Microsoft", desc: "Senior Reach Engineer" },
      ],
      2
    ) as Array<Array<DetailObject>>;
    const education = splitArrayToChunks(
      [{ title: "XYZ University, LA", desc: "BS in Graphic Design 2015-2019" }],
      2
    ) as Array<Array<DetailObject>>;

    return (
      <div id="MainDiv" style={{ display: "flex" }}>
        <div
          id="MainDivFirstHalf"
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "700px",
            marginTop: "12px",
          }}
        >
          <div id="About">
            <div
              style={{
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "20.42px",
              }}
            >
              {" "}
              About{" "}
            </div>

            <p
              style={{
                marginTop: "16px",
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "20.42px",
              }}
            >
              Hey all :) Happy to be here to seek advice from the best of the
              minds :) Being a singer, artist, guitar player, athlete, martial
              arts and voice over artist by hobby, as a UX Researcher I am
              passionate about blending business goals with user. My research
              experience lies in Fashion, E- commerce, NBFC and Automotive
              domain, worked in tools such as usertesting.com, Hotjar, Miro,
              Survey Monkey, MS Office, Illustrator and Figma.
            </p>
          </div>

          <hr className="horizontal-line-break" />

          <div id="Open to" style={{ marginTop: "40px" }}>
            <div
              style={{
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "20.42px",
              }}
            >
              {" "}
              Open to{" "}
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              {openTo.map((_openTo) => (
                <div style={{ display: "flex", marginTop: "30px" }}>
                  {_openTo.map((_open, i) => (
                    <div
                      style={{
                        fontWeight: 600,
                        fontSize: "16px",
                        lineHeight: "20.42px",
                        minWidth: i === 0 ? "268px" : "unset",
                        marginLeft: i > 0 ? "100px" : "unset",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <BagIcon />
                        <div style={{ marginLeft: "10px" }}>{_open.title}</div>
                      </div>

                      <p style={{ fontSize: "14px", fontWeight: "300" }}>
                        {" "}
                        {_open.desc}
                      </p>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>

          <hr className="horizontal-line-break" />

          <div id="Experience" style={{ marginTop: "40px" }}>
            <div
              style={{
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "20.42px",
              }}
            >
              {" "}
              Experience{" "}
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              {experiences.map((_exp) => (
                <div style={{ display: "flex", marginTop: "30px" }}>
                  {_exp.map((_ex, i) => (
                    <div
                      style={{
                        fontWeight: 600,
                        fontSize: "16px",
                        lineHeight: "20.42px",
                        minWidth: i === 0 ? "268px" : "unset",
                        marginLeft: i > 0 ? "100px" : "unset",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <BagIcon />
                        <div style={{ marginLeft: "10px" }}>{_ex.title}</div>
                      </div>

                      <p style={{ fontSize: "14px", fontWeight: "300" }}>
                        {" "}
                        {_ex.desc}
                      </p>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>

          <hr className="horizontal-line-break" />

          <div
            id="Education"
            style={{ marginTop: "40px", marginBottom: "40px" }}
          >
            <div
              style={{
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "20.42px",
              }}
            >
              {" "}
              Education{" "}
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {education.map((_edu) => (
                <div style={{ display: "flex", marginTop: "30px" }}>
                  {_edu.map((_ed, i) => (
                    <div
                      style={{
                        fontWeight: 600,
                        fontSize: "16px",
                        lineHeight: "20.42px",
                        minWidth: i === 0 ? "268px" : "unset",
                        marginLeft: i > 0 ? "100px" : "unset",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <BagIcon />
                        <div style={{ marginLeft: "10px" }}>{_ed.title}</div>
                      </div>

                      <p style={{ fontSize: "14px", fontWeight: "300" }}>
                        {" "}
                        {_ed.desc}
                      </p>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* LINE */}
        <div className="vertical-line" />

        {/*  2ND RIGHT HALF SECTION*/}

        <div
          id="MainDivSecondHalf"
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "40px",
            marginLeft: "42px",
          }}
        >
          <div id="Community-Stats">
            <div
              style={{
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "20.42px",
              }}
            >
              {" "}
              Community Stats{" "}
            </div>

            {/* <p style={{ marginTop: "16px", fontWeight: 400, fontSize: "16px", lineHeight: "20.42px" }}>
       Hey all :) Happy to be here to seek advice from the best of the minds :) Being a singer, artist, guitar player, athlete, martial arts and voice over artist by hobby, as a UX Researcher I am passionate about blending business goals with user. My research experience lies in Fashion, E- commerce, NBFC and Automotive domain, worked in tools such as usertesting.com, Hotjar, Miro, Survey Monkey, MS Office, Illustrator and Figma.
       </p>  */}
            <div
              id="comm-stats"
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "left",
              }}
            >
              {communityStats.map((stats, index) => (
                <>
                  {stats.mentoringTime && (
                    <div style={{ display: "flex" }}>
                      <Stats />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginLeft: "10px",
                        }}
                      >
                        <div style={{ fontSize: "16px", fontWeight: 500 }}>
                          {" "}
                          {stats.mentoringTime}{" "}
                        </div>
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: 400,
                            color: "#999999",
                          }}
                        >
                          {" "}
                          Total mentoring time{" "}
                        </div>
                      </div>
                    </div>
                  )}

                  {stats.sessionsCompleted && (
                    <div style={{ display: "flex", marginLeft: "40px" }}>
                      <Stats />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginLeft: "10px",
                        }}
                      >
                        <div style={{ fontSize: "16px", fontWeight: 500 }}>
                          {" "}
                          {stats.sessionsCompleted}{" "}
                        </div>
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: 400,
                            color: "#999999",
                          }}
                        >
                          {" "}
                          Sessions Completed{" "}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ))}
            </div>
          </div>

          <hr
            className="horizontal-line-break"
            style={{ width: _width ? _width / 2 - 2 + "px" : "unset" }}
          />

          <div id="Skills" style={{ marginTop: "40px" }}>
            <div
              style={{
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "20.42px",
              }}
            >
              {" "}
              Skills{" "}
            </div>

            {/* <p style={{ marginTop: "16px", fontWeight: 400, fontSize: "16px", lineHeight: "20.42px" }}>
       Hey all :) Happy to be here to seek advice from the best of the minds :) Being a singer, artist, guitar player, athlete, martial arts and voice over artist by hobby, as a UX Researcher I am passionate about blending business goals with user. My research experience lies in Fashion, E- commerce, NBFC and Automotive domain, worked in tools such as usertesting.com, Hotjar, Miro, Survey Monkey, MS Office, Illustrator and Figma.
       </p>  */}
            <div
              id="skills"
              style={{
                marginTop: "10px",
                display: "flex",
                marginLeft: "-10px",
              }}
            >
              <div>
                {skillsList.map((skill, index) => (
                  <Tag
                    color="#BDAFEB"
                    style={{
                      width: "fit-content",
                      borderRadius: "12px",
                      color: "#344054",
                      padding: "4px 12px 4px 12px",
                      fontSize: "14px",
                      marginTop: "10px",
                      paddingTop: "1px",
                      paddingBottom: "2px",
                      marginLeft: "10px",
                    }}
                  >
                    {skill}
                  </Tag>
                ))}
              </div>
            </div>
          </div>

          <hr
            className="horizontal-line-break"
            style={{ width: _width ? _width / 2 - 2 + "px" : "unset" }}
          />

          <div id="Industries" style={{ marginTop: "40px" }}>
            <div
              style={{
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "20.42px",
              }}
            >
              {" "}
              Industries{" "}
            </div>

            {/* <p style={{ marginTop: "16px", fontWeight: 400, fontSize: "16px", lineHeight: "20.42px" }}>
       Hey all :) Happy to be here to seek advice from the best of the minds :) Being a singer, artist, guitar player, athlete, martial arts and voice over artist by hobby, as a UX Researcher I am passionate about blending business goals with user. My research experience lies in Fashion, E- commerce, NBFC and Automotive domain, worked in tools such as usertesting.com, Hotjar, Miro, Survey Monkey, MS Office, Illustrator and Figma.
       </p>  */}
            <div
              id="skills"
              style={{
                marginTop: "10px",
                display: "flex",
                marginLeft: "-10px",
              }}
            >
              <div>
                {industries.map((ind, index) => (
                  <Tag
                    color="#91C7B1"
                    style={{
                      width: "fit-content",
                      borderRadius: "16px",
                      color: "#344054",
                      padding: "4px 12px 4px 12px",
                      fontSize: "14px",
                      marginTop: "10px",
                      paddingTop: "1px",
                      paddingBottom: "2px",
                      marginLeft: "10px",
                    }}
                  >
                    {ind}
                  </Tag>
                ))}
              </div>
            </div>
          </div>

          <hr
            className="horizontal-line-break"
            style={{ width: _width ? _width / 2 - 2 + "px" : "unset" }}
          />

          <div id="Experties" style={{ marginTop: "40px" }}>
            <div
              style={{
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "20.42px",
              }}
            >
              {" "}
              Experties{" "}
            </div>

            {/* <p style={{ marginTop: "16px", fontWeight: 400, fontSize: "16px", lineHeight: "20.42px" }}>
       Hey all :) Happy to be here to seek advice from the best of the minds :) Being a singer, artist, guitar player, athlete, martial arts and voice over artist by hobby, as a UX Researcher I am passionate about blending business goals with user. My research experience lies in Fashion, E- commerce, NBFC and Automotive domain, worked in tools such as usertesting.com, Hotjar, Miro, Survey Monkey, MS Office, Illustrator and Figma.
       </p>  */}
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                marginLeft: "-10px",
              }}
            >
              <div>
                {experties.map((exp, index) => (
                  <Tag
                    color="#FDCEB5"
                    style={{
                      width: "fit-content",
                      borderRadius: "16px",
                      color: "#344054",
                      padding: "4px 12px 4px 12px",
                      fontSize: "14px",
                      marginTop: "10px",
                      paddingTop: "1px",
                      paddingBottom: "2px",
                      marginLeft: "10px",
                    }}
                  >
                    {exp}
                  </Tag>
                ))}
              </div>
            </div>
          </div>

          <hr
            className="horizontal-line-break"
            style={{ width: _width ? _width / 2 - 2 + "px" : "unset" }}
          />

          <div id="Certifications" style={{ marginTop: "40px" }}>
            <div
              style={{
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "20.42px",
              }}
            >
              {" "}
              Certifications{" "}
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "80px",
              }}
            >
              {certifications.map((_cert) => (
                <div style={{ display: "flex", marginTop: "30px" }}>
                  {_cert.map((_cer, i) => (
                    <div
                      style={{
                        fontWeight: 600,
                        fontSize: "16px",
                        lineHeight: "20.42px",
                        minWidth: i === 0 ? "268px" : "unset",
                        marginLeft: i > 0 ? "100px" : "unset",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <BagIcon />
                        <div style={{ marginLeft: "10px" }}>{_cer.title}</div>
                      </div>

                      <p style={{ fontSize: "14px", fontWeight: "300" }}>
                        {" "}
                        {_cer.desc}
                      </p>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const isPublicView = false;

  const items: TabsProps["items"] = [
    {
      key: "overview",
      label: "Overview",
      children: <OverViewContent />,
      style: { fontFamily: "Space Grotesk" },
    },
    {
      key: "coaches",
      label: isPublicView ? "Coaches" : "My Coaches",
      children: <MentorContent />,
      style: { fontFamily: "Space Grotesk" },
    },

    {
      key: "reviews",
      label: "Reviews",
      children: <ReviewsContent />,
      style: { fontFamily: "Space Grotesk" },
    },
  ];

  const jobs = [
    {
      logoImage: JobImage,
      companyName: "Fluent LLC",
      companyStars: 5,
      jobTitle: "Software Engineer",
      location: "Sans Francisco, CA",
      jobType: "Full Time Remote",
      updated: "17 Hours Ago",
      percentageMatch: 82,
    },
    {
      logoImage: JobImage,
      companyName: "Fluent LLC",
      companyStars: 5,
      jobTitle: "Software Engineer",
      location: "Sans Francisco, CA",
      jobType: "Full Time Remote",
      updated: "17 Hours Ago",
      percentageMatch: 82,
    },
    {
      logoImage: JobImage,
      companyName: "Fluent LLC",
      companyStars: 5,
      jobTitle: "Software Engineer",
      location: "Sans Francisco, CA",
      jobType: "Full Time Remote",
      updated: "17 Hours Ago",
      percentageMatch: 82,
    },
  ];

  const events = [
    {
      date: { day: 14, month: "APR" },
      image: TechEventImage,
      title: "Largest hybrid tech event on the east coast.",
      desc: "We’ll get you directly seated and inside for you to enjoy the show.",
    },
    {
      date: { day: 14, month: "APR" },
      image: TechEventImage,
      title: "Diverse tech event powered by tribaja online and on site.",
      desc: "We’ll get you directly seated and inside for you to enjoy the show.",
    },
    {
      date: { day: 14, month: "APR" },
      image: TechEventImage,
      title: "Largest hybrid tech event on the east coast. ",
      desc: "We’ll get you directly seated and inside for you to enjoy the show.",
    },
  ];

  const [openLeaveModal, setOpenLeaveModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleDeleteAccount = () => {
    setModalOpen(false);
    setOpenLeaveModal(true);
  };
  const handleContinueDelete = () => {
    setOpenDeleteModal(true);
    setOpenLeaveModal(false);
  };

  const handleCancelDelete = () => {
    setOpenLeaveModal(false);
    setOpenDeleteModal(false);
  };

  return (
    <>
      {openDeleteModal && (
        <RequestForDeleteModal handleCancelDelete={handleCancelDelete} />
      )}
      {openLeaveModal && (
        <RequestForLeaveModal
          handleCancelDelete={handleCancelDelete}
          handleContinueDelete={handleContinueDelete}
        />
      )}
      {openModal && (
        <EditProfileModal
          handleDeleteAccount={handleDeleteAccount}
          formModalType={"TALENT_FORM"}
          onModalClose={() => setModalOpen(false)}
        />
      )}
      <div className="talent-profile" style={{ marginBottom: "20px" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div id="CoverImageDiv">
            <img src={ProfileCover} alt="ProfileCover" />
          </div>

          <div
            id="ProfileTopSectionDiv"
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginRight: "200px",
              marginLeft: "200px",
            }}
          >
            <div style={{ display: "flex" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                  alignItems: "center",
                  marginTop: "-70px",
                }}
              >
                <Avatar
                  style={{
                    width: "200px",
                    height: "205px",
                    border: "3px solid #FBAD84",
                  }}
                  src={OurTalentPf1}
                />
                <Tag
                  color="#5937CC"
                  style={{
                    width: "fit-content",
                    borderRadius: "12px",
                    color: "#FAF9FF",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    marginTop: "-10px",
                    paddingTop: "1px",
                    paddingBottom: "2px",
                    marginLeft: "10px",
                  }}
                >
                  {" "}
                  {!isPublicView
                    ? "Looking for work"
                    : "Available for Session"}{" "}
                </Tag>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "baseline",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    marginLeft: "20px",
                    marginTop: "20px",
                  }}
                >
                  <p className="font-class"> Alpha Junkie </p>
                  <p
                    style={{ marginLeft: "10px", marginTop: "20px" }}
                    className="font-class-gender"
                  >
                    {" "}
                    She/Her{" "}
                  </p>
                </div>

                <div style={{ display: "flex", marginTop: "10px" }}>
                  <LocationIcon />{" "}
                  <p style={{ marginLeft: "10px" }} className="location-state">
                    California, USA
                  </p>
                </div>

                <div className="job-label" style={{ marginTop: "10px" }}>
                  <Tag
                    color="#FEEDCC"
                    style={{
                      borderRadius: "12px",
                      color: "#1E1E2F",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    {" "}
                    UI/UX DESIGNER{" "}
                  </Tag>
                </div>
              </div>
            </div>

            <div
              id="SocialMediaAndButtonDiv"
              style={{ display: "flex", marginTop: "40px" }}
            >
              {selectedTab === "overview" && (
                <div
                  id="Social Media Icons"
                  style={{
                    display: "flex",
                    marginRight: "20px",
                    marginTop: "4px",
                  }}
                >
                  <Link to={"/https://github.com"}>
                    <SocialMediaIcons.githubIcon
                      style={{ marginRight: "10px" }}
                    />
                  </Link>
                  <Link to={"/https://twitter.com"}>
                    <SocialMediaIcons.twitter style={{ marginRight: "10px" }} />
                  </Link>
                  <Link to="https://linkedin.com">
                    <SocialMediaIcons.linkedin
                      style={{ marginRight: "10px" }}
                    />
                  </Link>
                  <Link to="https://randomwebsite.com">
                    <SocialMediaIcons.website style={{ marginRight: "10px" }} />
                  </Link>
                </div>
              )}

              <div id="Button Section">
                {selectedTab === "overview" && !isPublicView && (
                  <Button
                    type="primary"
                    shape="round"
                    size={"large"}
                    style={{
                      padding: "6px 44px",
                      fontSize: "14px",
                      fontWeight: 600,
                      background: "#1E1E2F",
                    }}
                  >
                    Public view
                  </Button>
                )}

                {isPublicView && (
                  <Button
                    type="primary"
                    shape="round"
                    size={"large"}
                    style={{
                      padding: "6px 44px",
                      fontSize: "14px",
                      fontWeight: 600,
                      background: "#1E1E2F",
                    }}
                  >
                    Connect
                  </Button>
                )}
                {!isPublicView && (
                  <Button
                    type="primary"
                    shape="round"
                    size={"large"}
                    style={{
                      marginLeft: "20px",
                      padding: "6px 44px",
                      fontSize: "14px",
                      fontWeight: 600,
                      background: "none",
                      color: "#1E1E2F",
                      border: "0.5px solid",
                    }}
                    onClick={() => setModalOpen(true)}
                  >
                    Edit Profile
                  </Button>
                )}
              </div>
            </div>
          </div>

          {/* Tabs Section */}

          <div
            style={{
              marginTop: "100px",
              marginLeft: "200px",
              marginRight: "200px",
            }}
          >
            <Tabs
              id="tabs"
              defaultActiveKey="overview"
              items={items}
              onChange={onChange}
            />
            {selectedTab === "overview" && (
              <hr
                className="horizontal-line-break"
                style={{ marginTop: "unset" }}
              />
            )}

            {/* Opportunities Section  */}

            {selectedTab === "overview" && !isPublicView && (
              <div
                id="opportunities"
                style={{ marginTop: "100px", marginBottom: "20px" }}
              >
                <div
                  style={{
                    fontWeight: 700,
                    fontSize: "32px",
                    lineHeight: "20.42px",
                  }}
                >
                  {" "}
                  Opportunities and Resources that Match My Profile{" "}
                </div>

                {/* Jobs */}
                <div id="job" style={{ marginTop: "50px" }}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      style={{
                        fontWeight: 600,
                        fontSize: "24px",
                        lineHeight: "20.42px",
                        marginBottom: "40px",
                      }}
                    >
                      {" "}
                      Jobs{" "}
                    </div>
                    <a
                      href={"/"}
                      style={{
                        fontWeight: 500,
                        fontSize: "16px",
                        lineHeight: "20.42px",
                        marginBottom: "40px",
                        color: "#5937CC",
                        textDecoration: "underline",
                      }}
                    >
                      {" "}
                      See All{" "}
                    </a>
                  </div>

                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {jobs.map((job) => (
                      <JobTile jobObj={job} />
                    ))}
                  </div>
                </div>

                {/* Events */}
                <div id="events" style={{ marginTop: "100px" }}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      style={{
                        fontWeight: 600,
                        fontSize: "24px",
                        lineHeight: "20.42px",
                        marginBottom: "40px",
                      }}
                    >
                      {" "}
                      Events{" "}
                    </div>
                    <a
                      href={"/"}
                      style={{
                        fontWeight: 500,
                        fontSize: "16px",
                        lineHeight: "20.42px",
                        marginBottom: "40px",
                        color: "#5937CC",
                        textDecoration: "underline",
                      }}
                    >
                      {" "}
                      See All{" "}
                    </a>
                  </div>

                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {events.map((eve) => (
                      <EventTile eventObj={eve} />
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TalentProfile;
