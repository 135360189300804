import React, { FC, useCallback, useEffect, useState } from "react";

import { AnimatePresence, motion } from "framer-motion";
import { Pagination, Select } from "antd";
import { Dropdown, Tag } from "antd";
import type { MenuProps } from "antd";

import { TailSpin } from "react-loader-spinner";

import { ReactComponent as MenuIcon } from "../../../../assets/icons/menuIcon.svg";

import SearchBar from "../../../../components/DashboardSearchbar/SearchBar";
// import "../../../../EditProfileModal.scss";

import "./MainDashboard.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../redux/store/store";
import {
  deleteCareerPath,
  getCareerPath,
} from "../../../../redux/AdminSlice/AdminSlice";
import { Delete } from "../../../../assets/icons";
import DashboardNavbar from "../../../../components/DashboardNavbar/DashboardNavbar";
import AdminDeleteModal from "./AdminDeleteModal";
import { debounce } from "debounce";

const Option = Select.Option;
const MainDashboard: FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [openDelModal, setDelModalOpen] = useState<boolean | string>(false);
  const [page, setPage] = useState<number>(1);
  const dispatch = useDispatch<AppDispatch>();
  const [search, setSearch] = useState<string>("");


  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 700);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 700);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { careerPathway } = useSelector((state: any) => state.admin);

  const tableRef = React.useRef<HTMLDivElement>(null);

  // LifeCylce events
  useEffect(() => {
    dispatch(getCareerPath({page, search})).then(() => {
      setLoading(false);
    });
  }, [page]);

  const getItems = (id: string): MenuProps["items"] => [
    {
      icon: <Delete />,
      key: "1",
      label: "Delete Career Path",
      onClick: () => {
        setDelModalOpen(id);
      },
    },
  ];

  const debouncedDispatch = useCallback(
    debounce((searchValue: any) => {
      dispatch(getCareerPath({ page, search: searchValue }));
    }, 800),
    [dispatch, page] // Dependencies
  );

  return (
    <>
      {Boolean(openDelModal) && (
        <AdminDeleteModal
          onSubmit={() => {
            dispatch(deleteCareerPath(openDelModal)).then(() => {
              dispatch(getCareerPath({page, search}));
            });
            setDelModalOpen(false);
          }}
          onModalClose={() => setDelModalOpen(false)}
        />
      )}
      <AnimatePresence>
        {!loading ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="main__dashboard-box"
          >
            <DashboardNavbar addLogo={isMobileView} />
            <main className="main__dashboard">
              <motion.div
                key={"dashboard-heading"}
                transition={{ duration: 0.3, delay: 0.3 }}
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                className="main__dashboard-heading"
              >
                <h1>Career Path Management</h1>
              </motion.div>
              <motion.section
                transition={{ duration: 0.3, delay: 0.4 }}
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                className="main__dashboard-container"
              >
                {/* Search Section */}
                <div
                  className="main__dashboard-container-analytics"
                  style={{ marginTop: "20px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        color: "#1E1E2F",
                        marginBottom: "10px",
                      }}
                    >
                      Search
                    </div>
                    <SearchBar
                      debouncedDispatch={debouncedDispatch}
                      disabled={false}
                      value={search}
                      page={page}
                      setSearch={setSearch}
                    />
                  </div>
                </div>
               <div className="main__dashboard-recent-activities--admin">
                  <div
                      className="main__dashboard-recent-activities"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        overflow: "auto",
                        maxHeight: "600px",
                        scrollBehavior: "smooth",
                      }}
                      ref={tableRef}
                    >
                      {/* Header First Row */}
                      <div
                        style={{
                          fontSize: "13px",
                          fontWeight: "700",
                          color: "#344054",
                          display: "flex",
                          justifyContent: "space-between",
                          minWidth: 800,
                          borderBottom: '1px solid #aaa',
                          paddingBottom: '8px'
                        }}
                      >
                        <div style={{ minWidth: "148px", flex: 1 }}>
                          {" "}
                          Career Path{" "}
                        </div>
                        <div style={{ minWidth: "148px", flex: 2 }}> Skills </div>
                        <div style={{ minWidth: "164px", flex: 2 }}>
                          {" "}
                          Description{" "}
                        </div>
                      </div>

                      {careerPathway.results.map((career: any, index: number) => (
                        <>
                          <div
                            style={{
                              fontSize: "12px",
                              fontWeight: "700",
                              color: "#344054",
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "20px",
                              gap: "24px",
                              minWidth:800
                            }}
                          >
                            <div
                              style={{
                                minWidth: "148px",
                                fontWeight: "400",
                                fontSize: "12px",
                                lineHeight: "12.67px",
                                color: "#5937CC",
                                flex: 1,
                              }}
                            >
                              {" "}
                              {career.career_name}{" "}
                            </div>
                            <div
                              style={{
                                minWidth: "148px",
                                fontWeight: "400",
                                fontSize: "12px",
                                lineHeight: 1.5,
                                flex: 2,
                              }}
                            >
                              {" "}
                              {career?.skills_required.join(",    ")}{" "}
                            </div>
                            <div
                              style={{
                                minWidth: "148px",
                                fontWeight: "400",
                                fontSize: "12px",
                                lineHeight: "12.67px",
                                flex: 2,
                              }}
                            >
                              {" "}
                              {career?.description}
                            </div>
                            <div style={{ display: "flex" }}>
                              {/* <div style={{ minWidth: "148px", maxWidth: "288px" }}>
                              {" "}
                              <Tag
                                color={statusColor[career.status?.toLowerCase()]}
                                style={{
                                  width: "62px",
                                  height: "22px",
                                  borderRadius: "22px",
                                  color: "#FAF9FF",
                                  fontSize: "10px",
                                  fontWeight: "400",
                                  textAlign: "center",
                                }}
                              >
                                {career.status}{" "}
                              </Tag>
                            </div> */}
                              <div>
                                <Dropdown
                                  menu={{ items: getItems(career.id) }}
                                  placement="bottomLeft"
                                  trigger={["click"]}
                                >
                                  <MenuIcon style={{ cursor: "pointer" }} />
                                </Dropdown>
                              </div>
                            </div>
                          </div>

                          {index < careerPathway.length - 1 && (
                            <hr style={{ marginTop: "20px" }} />
                          )}
                        </>
                      ))}
                    </div>

               </div>
              </motion.section>
              <Pagination
                style={{
                  marginInline: "auto",
                  marginTop: "36px",
                }}
                current={careerPathway.page}
                total={careerPathway.count}
                pageSize={careerPathway.page_size}
                showSizeChanger={false}
                onChange={(e) => {
                  setPage(e);
                  if (tableRef.current) {
                    tableRef.current.scrollTo(0, 0);
                  }
                }}
              />
            </main>
          </motion.div>
        ) : (
          <div className="loader-container">
            <TailSpin
            height="160"
            width="160"
            color="#FBAD84"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{
              justifySelf: "center",
              alignSelf: "center",
            }}
            wrapperClass="tail-spin"
            visible={true}
          />
        </div>
        )}
      </AnimatePresence>
    </>
  );
};

export default MainDashboard;
