import React, { useState } from "react";

import "./CoachCard.scss";
import { Coach } from "../../assets/images";
import { Bookmark, Experience, Pin, Star, Verified } from "../../assets/icons";
import axios from "axios";
import { getAccessToken } from "../../utils/jwtToken";
import api from "../../utils/api";
import Cookies from "js-cookie";
import ScheduleMeetingModal from "../ScheduleMeetingModal/ScheduleMeetingModal";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store/store";
import {
  getTalentDashboard,
  getTalentDashboardRecommended,
} from "../../redux/TalentSlice/TalentSlice";
import { TailSpin } from "react-loader-spinner";
import { message } from "antd";
import InitialsAvatar from "../InitialsAvatar/InitialsAvatar";
import RequestConnectModal from "../RequestConnectModal/RequestConnectModal";

type Props = {
  coach?: any;
};
const CoachCard = (props: Props) => {
  const { coach } = props;

  const dispatch = useDispatch<AppDispatch>();

  // Initial Saved Status Not coming from backend
  const [loading, setLoading] = useState<boolean>(false);
  const [saveToggle, setSaveToggle] = useState(coach?.is_saved);

  const [openModal, setOpenModal] = useState(false);
  const [dataUrl, setDataUrl] = useState("");
  const [scheduleMeetingError, setScheduleMeetingError] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [connectModal, setConnectModal] = useState(false);

  const talentDashboard = useSelector((state: any) => state.talent.dashboard);

  const buttonText =
    window.location.pathname === "/my-coaches" ? "Schedule Meeting" : "Book Me";

  const handleOpenModal = () => {
    setOpenModal((prevState) => !prevState);
  };

  const handleScheduleMeeting = () => {
    const id = coach?.id;
    const url = `${api}/talent/get_schedule_url?coach_id=${id}`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      })
      .then((response) => {
        // Handle the response data here.
        setDataUrl(response.data.schedule_url);
      })
      .catch((error) => {
        // Handle any errors.
        setScheduleMeetingError(true);
        console.error("Error:", error);
      });
    handleOpenModal();
    window.scroll(0, 0);
  };
  const handleViewProfile = () => {
    window.location.href = `/profile/talent/${coach?.id}`;
  };

  const saveCoach = async () => {
    setLoading(true);
    setSaveToggle(true);
    try {
      await axios.post(
        `${api}/${Cookies.get("role")}/save/coach/${coach?.id}/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        },
      );
      if (
        window.location.pathname === "/recommendations" ||
        window.location.pathname.includes("/view-all/coach")
      ) {
        dispatch(getTalentDashboardRecommended("coaches"));
      } else {
        dispatch(getTalentDashboard());
      }
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const unsaveCoach = async () => {
    setLoading(true);
    setSaveToggle(false);
    try {
      await axios.delete(
        `${api}/${Cookies.get("role")}/delete/coach/${coach?.id}/`,
        {
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        },
      );
      if (
        window.location.pathname === "/recommendations" ||
        window.location.pathname.includes("/view-all/coach")
      ) {
        dispatch(getTalentDashboardRecommended("coaches"));
      } else {
        dispatch(getTalentDashboard());
      }
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const handleConnect = async () => {
    setSpinner(true);
    try {
      await axios.post(
        `${api}/${Cookies.get("role")}/send_request/`,
        {
          coach: coach?.id,
        },
        {
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        },
      );
      // setLoading(true);
      setConnectModal(true);
      setSpinner(false);
    } catch {
      message.error("Request already sent");
      // setLoading(false);
      setConnectModal(false);
      setSpinner(false);
    }
  };

  const handleCloseConnectRequest = () => {
    setConnectModal((prevState) => !prevState);
  };

  return (
    <>
      {!openModal ? (
        <div className={"coachCard"} style={coach?.style || {}}>
          <Bookmark
            className={`bookmark ${saveToggle ? "bookmarkChecked" : ""}`}
            onClick={
              !loading ? (saveToggle ? unsaveCoach : saveCoach) : () => {}
            }
          />
          <div className="coachCard__details">
            {coach?.image ? (
              <img src={coach?.image} alt="Jason Doe - Tribja Coach" />
            ) : (
              <InitialsAvatar
                name={coach?.name}
                customStyles={{ width: "62px", height: "62px" }}
              />
            )}
            <div className="coachCard__details-container">
              <div className="name">
                {coach?.name || "John Doe"}
                {coach?.is_verifed ? <Verified /> : ""}
              </div>
              <p className="profession">
                {coach?.latest_job?.title || "UI UX Designer"} at{" "}
                {coach?.latest_job?.company || "Linkedin"}
              </p>
              <div className="reviews" style={{ marginTop: "4px" }}>
                {Array.from({ length: coach?.rating || 1 }).map((_) => (
                  <Star />
                ))}
                {coach?.number_of_reviews || 5} reviews
              </div>
            </div>
          </div>
          <div className="coachCard__stats">
            <section>
              <div className="coachCard__stats-label">
                <Pin /> From
              </div>
              <span
                style={{
                  display: "block",
                  whiteSpace: "nowrap",
                  width: "80px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {coach?.location
                  ? `${coach?.location?.city}, ${coach?.location.state}, ${coach?.location?.country}`
                  : "---------"}
              </span>
            </section>
            <section>
              <div className="coachCard__stats-label">
                <Experience /> Experience
              </div>
              {coach?.experience
                ? Math.abs(Math.floor(coach?.experience)) + " Years"
                : "------"}
            </section>
            <section>
              <div className="coachCard__stats-label">
                <Pin /> Sessions
              </div>
              {coach?.sessions
                ? Math.floor(coach?.sessions) + " Sessions"
                : "------"}
            </section>
          </div>
          <div className="coachCard__buttons">
            <button
              onClick={
                talentDashboard?.subscription_status === "trailblazer" &&
                window.location.pathname === "/my-coaches"
                  ? handleScheduleMeeting
                  : handleConnect
              }
            >
              {!spinner ? (
                buttonText
              ) : (
                <TailSpin
                  height="25"
                  width="30"
                  color="white"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{
                    justifySelf: "center",
                    alignSelf: "center",
                  }}
                  wrapperClass="tail-spin"
                  visible={true}
                />
              )}
            </button>
            <button onClick={handleViewProfile}>View Profile</button>
          </div>
        </div>
      ) : (
        <ScheduleMeetingModal
          coach={coach}
          handleOpenModal={handleOpenModal}
          dataUrl={dataUrl}
          scheduleMeetingError={scheduleMeetingError}
        />
      )}

      {connectModal && (
        <RequestConnectModal
          handleCloseConnectRequest={handleCloseConnectRequest}
        />
      )}
    </>
  );
};

export default CoachCard;
