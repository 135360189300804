import React, { useEffect, useState } from "react";
import "./ReviewModal.scss";
import { TailSpin } from "react-loader-spinner";
import { InlineWidget } from "react-calendly";
import { backdropOverFlowHide } from "../../utils/backdropOverFlowHide";
import Button from "../Button/Button";
import ReactDOM from "react-dom";
import { CloseSquare, ErrorCircle } from "../../assets/icons";
import axios from "axios";
import api from "../../utils/api";
import { getAccessToken } from "../../utils/jwtToken";
import { Rate, message } from "antd";
import FormButton from "../FormButton/FormButton";
import { AppDispatch } from "../../redux/store/store";
import { useDispatch } from "react-redux";
import {
    getEmployerPublicProfile,
  } from "../../redux/EmployerSlice/EmployerSlice";

  import {
    getTalentPublicProfile,
  } from "../../redux/TalentSlice/TalentSlice";
import { scrollToTop } from "../../utils/scrollToTop";

 type Props = {
    handleReviewModal: () => void,
    employerId?:string;
    coachId?:string;
    talentId?:string
}

const ReviewModal = (props:Props) => {
  
    const {handleReviewModal,employerId,coachId,talentId} = props
    const dispatch = useDispatch<AppDispatch>();

  const [loader, setLoader] = useState(false);
  const [reviewDesc, setReviewDesc] = useState('');
  const [rating, setRating] = useState(0);
  const [loading,setLoading] = useState(false)

  const handleReview = async () => {
    // setSaveToggle(true);
    setLoading(true)
    if( reviewDesc === '' && rating === 0 ){
       
        message.error('review and rating must be filled')
        handleReviewModal();
        
    } else {
      if(employerId){
        try {
          await axios.post(
            `${api}/talent/rating/`,
            {
              employer:employerId,
              review:reviewDesc,
              rating:rating,
            },
            {
              headers: {
                Authorization: `Bearer ${getAccessToken()}`,
              },
            }
          ); 
          setLoading(false);
          dispatch(getEmployerPublicProfile(employerId));
          handleReviewModal();
          message.success('Review is posted')
        } catch(error) {
          setLoading(false);
          if(error.response.status === 500){
            message.error('Review must be 255 characters long')
            handleReviewModal();
          }else{
            message.error(error.response.data)
            handleReviewModal();
          }
        }
      }
      else if(coachId){
  
        try {
          await axios.post(
            `${api}/talent/review/coach/`,
            {
              "talent":talentId ,
              "coach":coachId,
              review:reviewDesc,
              rating:rating,
            },
            {
              headers: {
                Authorization: `Bearer ${getAccessToken()}`,
              },
            }
          ); 
          setLoading(false);
          // setSaveToggle(true);
          dispatch(getTalentPublicProfile(coachId));
          handleReviewModal();
        //   dispatch(getEmployerReviews(employerId))
          message.success('Review is posted')
        } catch(error) {
          setLoading(false);
          if(error.response.status === 500){
            message.error('Review must be 255 characters long')
            handleReviewModal();
          }else{
            message.error(error.response.data)
            handleReviewModal();
          }
        }
      }

    }
  }

  useEffect(() => {
    scrollToTop();
    backdropOverFlowHide();
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  return ReactDOM.createPortal(
    <>
      <div
        onClick={(e) => {
          if (e.currentTarget === e.target) {
          }
        }}
        className="reviewModal__container"
      >
        
        {!loader ? (
          <div className="reviewModal">
            <CloseSquare
            onClick={handleReviewModal}
            className="requestForDeleteModal_closeSquareLogo"
          />
            <h1>Tell us about your experience</h1>
            <Rate  onChange={(value)=>setRating(value)} value={rating} />
            <p>Leave your review</p>
            <form>
            <label>Write your review: </label>
            <textarea

              placeholder="Write Here"
              onChange={(e) => setReviewDesc(e.target.value)}
              value={reviewDesc}
            />
          </form>
             
            <div>
              <FormButton  text={'Submit'} loading={loading} onClick={handleReview} customStyle={{width:'250px'}} />
            </div>
          </div>
        ) : (
          <TailSpin
            height="160"
            width="160"
            color="#FBAD84"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{
              justifySelf: "center",
              alignSelf: "center",
            }}
            wrapperClass="tail-spin"
            visible={true}
          />
        )}
      </div>
    </>,
    document.getElementById("portal")!
  );
};

export default ReviewModal;
