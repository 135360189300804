import { ReactComponent as githubIcon } from "./github.svg";
import { ReactComponent as linkedin } from "./linkedin.svg";
import { ReactComponent as twitter } from "./twitter.svg";
import { ReactComponent as website } from "./website.svg";


export default {
  githubIcon,
  linkedin,
  twitter,
  website,
};
