import React, { useEffect, useState } from "react";
import "./EditPreferencesModal.scss";
import { CloseSquare, Deletion } from "../../assets/icons";
import { TailSpin } from "react-loader-spinner";
import SelectInput from "../SelectInput/SelectInput";
import FormButton from "../FormButton/FormButton";
import { message, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { backdropOverFlowHide } from "../../utils/backdropOverFlowHide";
import { scrollToTop } from "../../utils/scrollToTop";
import axios from "axios";
import api from "../../utils/api";
import { getAccessToken } from "../../utils/jwtToken";
import {
  getTalentDashboard,
  getTalentPublicProfile,
  updateTalentProfile,
} from "../../redux/TalentSlice/TalentSlice";
import { AppDispatch } from "../../redux/store/store";

const Option = Select.Option;

interface Props {
  handleToggleModal: () => void;
}

interface PreferencesType {
  name: string;
  id: number;
}

const EditPreferencesModal = (props: Props) => {
  const { handleToggleModal } = props;
  const talentId = useSelector(
    (state: any) => state.talent.dashboard.profile.id,
  );
  const talentPreferences = useSelector(
    (state: any) => state.talent.dashboard.preferences.role_type,
  );
  const [selectValues, setSelectValues] = useState<number[]>([]);
  const [payloadRoleType, setPayloadRoleType] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (talentPreferences && payloadRoleType) {
      payloadRoleType.map((role: PreferencesType) => {
        if (talentPreferences.includes(role.name)) {
          setSelectValues((val) => [...val, role.id]);
        }
      });
    }
  }, [talentPreferences, payloadRoleType]);

  const dispatch = useDispatch<AppDispatch>();

  const handleOnChange = (event: any) => {
    setSelectValues(event);
  };

  const handleSubmit = () => {
    setLoader(true);
    const payload = {
      preferences: {
        talent: talentId,
        preferred_role_type: selectValues,
      },
    };
    axios
      .post(`${api}/talent/profile/update/`, payload, {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      })
      .then((response) => {
        handleToggleModal();
        setLoader(false);
        dispatch(getTalentDashboard());
        message.success("Updated successfully");
      })
      .catch((error) => {
        handleToggleModal();
        setLoader(false);
        message.error("Error sending request");
      });
  };

  useEffect(() => {
    axios
      .get(`${api}/shared/role-type/`, {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      })
      .then((response) => {
        setPayloadRoleType(response.data);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    scrollToTop();
    backdropOverFlowHide();
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  return (
    <div
      onClick={(e) => {
        if (e.currentTarget === e.target) {
        }
      }}
      className="editPreferencesModal__container"
    >
      <div className="editPreferencesModal">
        <CloseSquare
          onClick={handleToggleModal}
          className="editPreferencesModal_closeSquareLogo"
        />
        <h1>I am available for</h1>
        <form>
          <label>
            <span>Select multiple </span>
            <Select
              optionFilterProp="label"
              showSearch={true}
              onChange={(e) => handleOnChange(e)}
              className="select"
              placeholder="Select all that applies"
              mode="multiple"
              value={selectValues}
            >
              {payloadRoleType?.map((elem: any) => {
                return (
                  <Option
                    key={elem.id}
                    label={elem.name}
                    value={elem.id}
                    id={elem.id}
                    className="roleType__option"
                  >
                    {elem.name}
                  </Option>
                );
              })}
            </Select>
          </label>
        </form>
        <div>
          <FormButton
            disabled={selectValues.length === 0}
            onClick={handleSubmit}
            loading={loader}
            text={"Update"}
          />
        </div>
      </div>
    </div>
  );
};

export default EditPreferencesModal;
