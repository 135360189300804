import React, { FC } from "react";

import { RequestApproved } from "../../assets/icons";
import { useNavigate } from "react-router-dom";
import "./EventPostedModal.scss";

interface EventPostedInputs {
  setScreen: (val: boolean) => void;
}

export const EventPosted: FC<EventPostedInputs> = ({ setScreen }) => {
  const navigate = useNavigate();
  const handleButton = () => {
    navigate("/admin/dashboard");
    setScreen(false);
  };

  return (
    <div className="eventPosted">
      <RequestApproved />
      <h1>Events Posted Successfully</h1>
      <div onClick={handleButton}>
        <button>Back To Dashboard</button>
      </div>
    </div>
  );
};
