import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Premium } from "../../assets/icons";
import { useSelector } from "react-redux";
import { RecommendedCompanies } from "../../components";
import axios from "axios";
import api from "../../utils/api";
import { getAccessToken } from "../../utils/jwtToken";
import { PaginationComp } from "../../components/PaginationComp/PaginationComp";
import { TailSpin } from "react-loader-spinner";

const Company = () => {
  const [company, setCompany] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const fetchCompany = (page: number) => {
    setLoading(true);
    const accessToken = getAccessToken();
    axios
      .get(`${api}/main/list/employers?page=${page}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(function (response) {
        setCompany(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        // Handle any errors
        setLoading(false);
        console.error("Error:", error);
      });
  };

  const handlePagination = (p: any) => {
    fetchCompany(p);
  };

  useEffect(() => {
    fetchCompany(1);
  }, []);

  return (
    <AnimatePresence>
      {!loading ? (
        <motion.section className="viewAll__container">
          {company?.results?.length > 0 && (
            <div className="viewAll__recommendations-container">
              <div className="viewAll__recommendations-container-card">
                {company?.results
                  ?.filter((employer: any) => employer?.company_name !== null)
                  .map((employer: any) => {
                    return <RecommendedCompanies employer={employer} />;
                  })}
              </div>
            </div>
          )}
          <PaginationComp
            count={company?.count}
            page={company?.page}
            onChange={handlePagination}
          />
        </motion.section>
      ) : (
        <TailSpin
          height="160"
          width="160"
          color="#FBAD84"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{
            justifySelf: "center",
            alignSelf: "center",
          }}
          wrapperClass="tail-spin"
          visible={true}
        />
      )}
    </AnimatePresence>
  );
};

export default Company;
