/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState } from "react";
import { Footer, FormButton, NavBar } from "../../components";
import { VerifiedOtp, VerifyEmail } from "../../assets/icons";

import "./OTPVerificationScreen.scss";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resendOtp, verifyOtp } from "../../redux/ProfileSlice/ProfileSlice";
import { AppDispatch } from "../../redux/store/store";
import { useTimer } from "react-timer-hook";

interface OTPInput {
  otp: number;
}

const OTPVerificationScreen: FC = () => {
  const time = new Date();
  time.setSeconds(time.getSeconds() + 120);

  const [loading, setLoading] = useState<Boolean>(false);
  const [errorMessage, setErrorMessage] = useState<String>("");

  const { seconds, minutes, restart } = useTimer({ expiryTimestamp: time });

  const { register, handleSubmit } = useForm<OTPInput>();

  const [verified, setVerified] = useState<Boolean>(false);

  const params = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();

  const email: string | undefined = params.email;

  const onSubmit: SubmitHandler<OTPInput> = (data) => {
    setErrorMessage("");
    setLoading(true);
    dispatch(verifyOtp({ ...data, email })).then((res) => {
      setLoading(false);
      if (res.type.includes("rejected")) {
        const errMessage: any = Object.values(res.payload);
        if (errMessage[0][0]?.length > 2) {
          setErrorMessage(errMessage[0][0]);
        } else {
          setErrorMessage("OTP is incorrect.");
        }
        return;
      } else {
        setVerified(true);
      }
    });
  };

  const handleResendOtp = () => {
    if (seconds === 0 && minutes === 0) {
      setLoading(true);
      setErrorMessage("");
      dispatch(resendOtp({ email })).then((res) => {
        setLoading(false);
        if (!res.type.includes("rejected")) {
          const time = new Date();
          time.setSeconds(time.getSeconds() + 120);
          restart(time);
          setErrorMessage("New OTP sent successfully.");
        } else {
          setErrorMessage("Something went wrong, please try again.");
        }
      });
    } else {
      setErrorMessage("You can only send OTP after 2 minutes.");
    }
  };

  return (
    <div className="OTPVerfication">
      <NavBar />
      <div className="OTPVerfication__container">
        {!verified ? (
          <>
            <VerifyEmail />
            <h2>Verify your email</h2>
            <p>
              To maintain a trusted and safe community, we’ve sent a verification code to{" "}
              {email} via email. Please enter it below. You’ll only to complete this verification process once.
            </p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <label>
                <span>
                  Enter your verification code
                  <div>
                    0{minutes}:{seconds >= 10 ? seconds : `0${seconds}`}
                  </div>
                </span>
                <input required type="number" {...register("otp")} />
              </label>
              {errorMessage && (
                <span
                  style={{
                    color: errorMessage.includes("New OTP")
                      ? "green"
                      : "#ee1d52",
                  }}
                  className="errorMessage"
                >
                  {errorMessage}
                </span>
              )}

              <FormButton text="Verify my email" loading={Boolean(loading)} />
              <p>
                Lost your OTP? {" "}
                <a href="#" onClick={handleResendOtp}>
                  Resend email
                </a>
              </p>
            </form>
          </>
        ) : (
          <div className="success">
            <VerifiedOtp />
            <div className="title">Verified</div>
            <span>
              Congratulations and welcome to Tribaja! Your email is verified and
              now you can login to continue using our services.
            </span>
            <button onClick={() => navigate("/login")}>Login</button>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default OTPVerificationScreen;
