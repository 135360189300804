import React, { useEffect } from "react";

import "./ReasonForDeclining.scss";
import { DecliningModal } from '../../assets/icons';
import { CloseSquare } from "../../assets/icons";

import ReactDOM from "react-dom";
import { scrollToTop } from "../../utils/scrollToTop";
import { TailSpin } from "react-loader-spinner";
import { backdropOverFlowHide } from "../../utils/backdropOverFlowHide";


interface Props {
  handleRejectRequest: () => void,
  handleReasonText: (e: any) => void,
  reasonText: string,
  handleRejectModal: () => void,
  spinner: boolean
}

const ReasonForDecliningModal = (props: Props) => {

  const { handleRejectRequest, handleReasonText, reasonText, handleRejectModal, spinner } = props



  useEffect(() => {
    scrollToTop();
    backdropOverFlowHide();

    return () => {
      document.body.style.overflow = '';
    }
  }, [])

  return ReactDOM.createPortal(
    <>
      <div
        onClick={(e) => {
          if (e.currentTarget === e.target) {

          }
        }}
        className="reasonForDecliningModal__container"
      >
        <div className="reasonForDecliningModal">
          <CloseSquare onClick={handleRejectModal} className="reasonForDecliningModal_closeSquareLogo" />
          <DecliningModal />
          <h1> Reasons for Declining?</h1>
          <p>Your feedback is truly valuable. If you're declining a mentee request, could you kindly provide the reason? Your insights help us enhance the coach experience. Just choose a reason from the dropdown and share your thoughts. Your contribution to our community is highly appreciated!</p>
          <form>
            <label>Reason</label>
            <input
              type='text'
              value={reasonText}
              placeholder="Reason Here"
              onChange={handleReasonText}
            />
          </form>
          <button className={!reasonText ? 'disabled' : ''} disabled={reasonText === '' ? true : false} onClick={handleRejectRequest}>
            {!spinner ? "Submit" :
              <TailSpin
                height="20"
                width="20"
                color="white"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{
                  justifySelf: "center",
                  alignSelf: "center",
                }}
                wrapperClass="tail-spin"
                visible={true}
              />
            }

          </button>

        </div>
      </div>
    </>,
    document.getElementById("portal")!
  );
};

export default ReasonForDecliningModal;