import React, { CSSProperties, FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import { Button, Dropdown } from "antd";
import type { MenuProps } from "antd";

import { TailSpin } from "react-loader-spinner";

import "./MainDashboard.scss";
import AdminNavBar from "../../../../components/AdminNavBar/AdminNavBar";
import { ReactComponent as MenuIcon } from "../../../../assets/icons/menuIcon.svg";
import { Active, Registered } from "../../../../assets/icons";
import { ReactComponent as FilterLines } from "../../../../assets/icons/filterLines.svg";
import { EmployerAnalyticCard } from "../../../../components";
import { AppDispatch } from "../../../../redux/store/store";
import DashboardNavbar from "../../../../components/DashboardNavbar/DashboardNavbar";
const MainDashboard: FC = () => {
  const [loading, setLoading] = useState<boolean>(true);

  const { dashboard } = useSelector((state: any) => state.admin);
  const { profileInfo } = useSelector((state: any) => state.profile);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 700);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 700);
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const defaultStyle: CSSProperties={
    marginTop: '20px',
    flexWrap: 'wrap',
    width: '65%'
  }
  const mediaQueryStyle: CSSProperties = {
    ...defaultStyle,
    width:'100%'
  };

  const divStyle = windowWidth <= 1260 ? mediaQueryStyle : defaultStyle;

  // LifeCylce events
  useEffect(() => {
    if (dashboard?.length !== 0 && profileInfo?.length !== 0) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [dashboard]);

  const recentActivites = [
    "John Doe registered as a new user",
    "User feedback received regarding recent employee.",
    'Mary Johnsons role changed from "Data Analyst" to "Data Scientist."',
    "John Doe registered as a new user",
    "User feedback received regarding recent employee.",
    'Mary Johnsons role changed from "Data Analyst" to "Data Scientist."',
    "John Doe registered as a new user",
  ];

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: "Option 1",
    },
    {
      key: "2",
      label: "Option 2",
    },
    {
      key: "3",
      label: "Option 2",
    },
  ];

  return (
    <AnimatePresence>
      {!loading ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="main__dashboard-box"
        >
          <DashboardNavbar addLogo={isMobileView} />
          <main className="main__dashboard">
            <motion.div
              key={"dashboard-heading"}
              transition={{ duration: 0.3, delay: 0.3 }}
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              className="main__dashboard-heading"
            >
              <h1>Welcome {profileInfo?.first_name}</h1>
            </motion.div>
            <motion.section
              transition={{ duration: 0.3, delay: 0.4 }}
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              className="main__dashboard-container"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  color: "#1E1E2F",
                  fontSize: "12px",
                  fontWeight: 400,
                  alignItems: "center",
                }}
              >
                {" "}
                <FilterLines style={{ marginRight: "8px" }} /> Yearly{" "}
              </div>
              <div
                className="main__dashboard-container-analytics"
                style={divStyle}
              >
                <EmployerAnalyticCard
                  icon={
                    <Registered className="main__dashboard-container-analytics-purple" />
                  }
                  title="Registered Employers"
                  content={dashboard?.total_employers}
                  detail=""
                />
                <EmployerAnalyticCard
                  icon={
                    <Registered className="main__dashboard-container-analytics-lGreen" />
                  }
                  title="Registered Talents"
                  content={dashboard?.total_talent}
                  detail=""
                />
                <EmployerAnalyticCard
                  icon={
                    <Registered className="main__dashboard-container-analytics-lPeach" />
                  }
                  title="Registered Partners"
                  content={dashboard?.total_partners}
                  detail=""
                />
                <EmployerAnalyticCard
                  icon={
                    <Active className="main__dashboard-container-analytics-pruple" />
                  }
                  title="Active Employers"
                  content={dashboard?.active_employer}
                  detail=""
                />
                <EmployerAnalyticCard
                  icon={
                    <Active className="main__dashboard-container-analytics-lGreen" />
                  }
                  title="Active Talents"
                  content={dashboard?.active_talent}
                  detail=""
                />
                <EmployerAnalyticCard
                  icon={
                    <Active className="main__dashboard-container-analytics-lPeach" />
                  }
                  title="Active Partners"
                  content={dashboard?.active_partners}
                  detail=""
                />
              </div>
              {/* <div className="main__dashboard-recent-activities--admin">
                <div className="main__dashboard-recent-activities">
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      color: "#1E1E2F",
                    }}
                  >
                    Recent Activities
                  </div>

                  <div className="main__dashboard-recent-activities-container">
                    {recentActivites.map((r, index) => (
                      <div
                        style={{
                          color: "#344054",
                          fontWeight: "400",
                          fontSize: "11px",
                          lineHeight: "12.75px",
                          marginTop: "20px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "baseline",
                          }}
                        >
                          <div>{r}</div>
                          <div>
                            <Dropdown
                              menu={{ items }}
                              placement="bottomLeft"
                              trigger={["click"]}
                            >
                              <MenuIcon style={{ cursor: "pointer" }} />
                            </Dropdown>
                          </div>
                        </div>
                        {index < recentActivites.length - 1 && (
                          <hr style={{ marginTop: "20px" }} />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div> */}
            </motion.section>
          </main>
        </motion.div>
      ) : (
        <div className="loader-container">
          <TailSpin
          height="160"
          width="160"
          color="#FBAD84"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{
            justifySelf: "center",
            alignSelf: "center",
          }}
          wrapperClass="tail-spin"
          visible={true}
        />
        </div>
      )}
    </AnimatePresence>
  );
};

export default MainDashboard;
