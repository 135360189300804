import PersonalProfileForm from "./PersonalProfileForm";
import ExperienceEducationForm from "./ExperienceEducationForm";
import ProfileLinks from "./ProfileLinks";
import CompanyProfileForm from "./CompanyProfileForm";
import CompanyExperienceEducationForm from "./CompanyExperienceEducationForm";
import CompanyProfileLinks from "./CompanyProfileLinks";
import PartnerCompanyProfile from "./PartnerCompanyForm";

export default {
  PersonalProfileForm,
  ExperienceEducationForm,
  ProfileLinks,
  CompanyProfileForm,
  CompanyExperienceEducationForm,
  CompanyProfileLinks,
  PartnerCompanyProfile,
};
