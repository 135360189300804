import React, { FC, useState, useEffect, useRef } from "react";
import axios from "axios";
import { Select, Upload, message } from "antd";
import api from "../../utils/api";
import { getAccessToken } from "../../utils/jwtToken";
import { useForm, SubmitHandler } from "react-hook-form";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store/store";
import { useNavigate } from "react-router-dom";

import "./CompletePartnerScreen.scss";
import Cookies from "js-cookie";
import { updateBoardingCompletion } from "../../redux/ProfileSlice/ProfileSlice";
import { uploadPartnerLogo } from "../../redux/PartnerSlice/PartnerSlice";
import ImgCrop from "antd-img-crop";

const Option = Select.Option;

export const CompletePartnerScreen: FC = () => {
  const [sharedSkills, setSharedSkills] = useState([]);
  const [payments] = useState([
    {
      value: "free",
      label: "Free",
    },
    {
      value: "pay-in-full",
      label: "Pay In Full",
    },
    {
      value: "flexible-payment-plan",
      label: "Flexible Payment Plan",
    },
    {
      value: "income-share-agreement",
      label: "Income Share Agreement",
    },
    {
      value: "not-applicable",
      label: "Not Applicable",
    },
  ]);
  const [programs] = useState([
    {
      value: "part-time",
      label: "Part Time",
    },
    {
      value: "full-time",
      label: "Full Time",
    },
    {
      value: "self-paced",
      label: "Self Paced",
    },
    {
      value: "other",
      label: "Other",
    },
  ]);
  const [scholarShip] = useState([
    {
      value: true,
      label: "Yes",
    },
    {
      value: false,
      label: "No",
    },
  ]);
  const [selectedSkill, setSelectedSkill] = useState([]);
  const [selectedProgram, setSelectedPrograms] = useState(null);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [selectedScholorship, setSelectedScholorship] = useState(null);
  const [profilePic, setProfilePic] = useState<any>();

  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { register, handleSubmit, watch } = useForm<any>();
  const profilePicFile = watch("profilePic");
  const beforeUpload = (file: any) => {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must be smaller than 2MB!");
    }
    return isLt2M;
  };
  // useEffect(() => {
  //   register("profilePic", { required: true });
  // }, [register]);
  let btnRef: any = useRef();

  const onSubmit: SubmitHandler<any> = async (data) => {
    if (!profilePic || !profilePic.length) {
      message.error("Please upload a company logo.");
      return;
    }
    const token = getAccessToken();
    setErrorMessage("");
    if (btnRef.current) {
      btnRef.current.setAttribute("disabled", "disabled");
    }
    try {
      if (
        selectedScholorship !== null &&
        selectedSkill.length &&
        selectedProgram &&
        selectedPayment
      ) {
        await axios.put(
          `${api}/partners/profile/manage/`,
          {
            website: data.other,
            githuburl: data.github,
            linkedinurl: data.linkedIn,
            twitterurl: data.twitter,
            facebookurl: data.facebook,
            instagramurl: data.instagram,
            company_name: data.companyName,
            company_description: data.companyDescription,
            pace_of_program: selectedProgram,
            program_cost: data.programCost,
            length_of_program: data.lengthOfProgram,
            scholarships: selectedScholorship,
            skill_training: selectedSkill,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (profilePic.length) {
          dispatch(uploadPartnerLogo()).then((data) => {
            const url = data.payload.s3_params.url;
            const formData = new FormData();

            Object.keys(data.payload.s3_params.fields).forEach((key: any) => {
              formData.append(key, data.payload.s3_params.fields[key]);
            });

            // Append the file
            formData.append("file", profilePic[0].originFileObj);

            // Use fetch or axios to send a POST request to the S3 bucket
            fetch(url, {
              method: "POST",
              body: formData,
            })
              .then((response) => {
                axios
                  .post(
                    `${api}/partners/file/confirm/${data.payload.file_id}/`,
                    {},
                    {
                      headers: {
                        Authorization: `Bearer ${getAccessToken()}`,
                      },
                    }
                  )
                  .then(() => {
                    if (btnRef.current) {
                      btnRef.current.removeAttribute("disabled", "disabled");
                    }
                    dispatch(updateBoardingCompletion());

                    Cookies.set("boarding-completed", "true");

                    navigate("/partner-approval");
                  });
              })
              .catch((error) => {
                console.error("Error:", error);
                if (btnRef.current) {
                  btnRef.current.removeAttribute("disabled", "disabled");
                }
              });
          });
        } else {
          dispatch(updateBoardingCompletion());

          Cookies.set("boarding-completed", "true");

          navigate("/partner-approval");
        }
      } else {
        if (btnRef.current) {
          btnRef.current.removeAttribute("disabled", "disabled");
        }
        setErrorMessage("Please fill all required fields");
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getSharedSkills();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSharedSkills = async () => {
    try {
      const accessToken = getAccessToken();
      const res = await axios.get(`${api}/shared/skill`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setSharedSkills(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  const handleFileChange = (info: any) => {
    setProfilePic(info.fileList);

    if (info.file.status === "done") {
      message.success("Upload successful");
    } else if (info.file.status === "error") {
      message.error("Upload failed");
    }
  };
  // const uploadButton = (
  //   <div
  //     className="uploadButton"
  //     onClick={() => {
  //       const inputElements = document.getElementsByTagName("input");
  //       for (var i = 0; i < inputElements.length; i++) {
  //         var node = inputElements[i];

  //         if (node.getAttribute("type") === "file") {
  //           node.click();
  //           return;
  //         }
  //       }
  //     }}
  //   >
  //     Upload Logo
  //     <div style={{ fontWeight: 300, fontSize: "10px", color: "#344054" }}>
  //       Make sure the file is below 2mb
  //     </div>
  //   </div>
  // );

  return (
    <>
      <div className="completePartnerScreen">
        <h1>Complete Partner Profile</h1>
        <p>
          Tribaja is your partner in building exceptional teams. With a focus on
          diversity and expertise, we provide a streamlined platform for
          employers to connect with top-tier tech talent.
        </p>
        <div className="formWrapper">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div
              className="logo"
              
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                document.getElementById("fileUpload")?.click();
              }}
            >
              <ImgCrop
                aspect={1}
                beforeCrop={(info) => {
                  console.log("Before Crop Info:", info);

                  const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB in bytes

                  if (info.size > maxSizeInBytes) {
                    message.error("File size exceeds 2 MB limit");
                    return false; // Prevent the crop (and implicitly the upload)
                  }
                  return true;
                }}
              >
                <Upload
                  action={`${api}/main/check/`}
                  listType="picture-card"
                  fileList={profilePic}
                  onChange={handleFileChange}
                  accept=".jpg,.jpeg,.png"
                  method="POST"
                  maxCount={1}
                  beforeUpload={(file) => {
                    const maxSizeInBytes = 2 * 1024 * 1024;

                    if (file.size > maxSizeInBytes) {
                      message.error("File size exceeds 2 MB limit");
                      return Upload.LIST_IGNORE; 
                    }
                    return true; 
                  }}
                  // beforeUpload={beforeUpload}
                >
                  {(!profilePic || profilePic.length < 1) && (
                    <div className="uploadButton">
                      Upload Logo
                      <div
                        style={{
                          fontWeight: 300,
                          fontSize: "10px",
                          color: "#344054",
                        }}
                      >
                        Make sure the file is below 2mb
                      </div>
                    </div>
                  )}
                </Upload>
              </ImgCrop>
            </div>
            <label>
              <span>Company Name *</span>
              <input
                type="text"
                {...register("companyName", {
                  required: "Company Name is required",
                })}
                required
              />
            </label>
            <label>
              <span>Company Description *</span>
              <textarea
                placeholder="Write here"
                {...register("companyDescription", {
                  required: "Company Description is required",
                })}
                required
              />
            </label>
            <label>
              <span>Skill Training *</span>
              <Select
                optionFilterProp="label"
                showSearch={true}
                mode="multiple"
                onChange={(value) => setSelectedSkill(value)}
                className="select"
                placeholder="Select all that applies"
              >
                {sharedSkills?.map((data: any) => (
                  <Option
                    label={data.skill}
                    value={data.id}
                    className="roleType__option"
                  >
                    {data.skill}
                  </Option>
                ))}
              </Select>
            </label>

            <label>
              <span>Pace of program offerd (if applicable) *</span>
              <Select
                optionFilterProp="label"
                showSearch={true}
                onChange={(value) => setSelectedPrograms(value)}
                className="select"
                placeholder="Select all that applies"
              >
                {programs?.map((data: any) => (
                  <Option
                    label={data.label}
                    value={data.value}
                    className="roleType__option"
                  >
                    {data.label}
                  </Option>
                ))}
              </Select>
            </label>
            <label>
              <span>Length of program *</span>
              <input type="text" {...register("lengthOfProgram")} required />
            </label>
            <label>
              <span>Program cost *</span>
              <input
                required
                type="number"
                placeholder="0000$"
                {...register("programCost")}
              />
            </label>
            <label>
              <span>Payment options *</span>
              <Select
                optionFilterProp="label"
                showSearch={true}
                onChange={(value) => setSelectedPayment(value)}
                className="select"
                placeholder="Select all that applies"
              >
                {payments?.map((data: any) => (
                  <Option
                    label={data.label}
                    value={data.value}
                    className="roleType__option"
                  >
                    {data.label}
                  </Option>
                ))}
              </Select>
            </label>

            <label>
              <span>Scholarships for under supported professionals *</span>
              <Select
                optionFilterProp="label"
                showSearch={true}
                onChange={(value) => setSelectedScholorship(value)}
                className="select"
                placeholder="Select"
              >
                {scholarShip?.map((data: any) => (
                  <Option
                    label={data.label}
                    value={data.value}
                    className="roleType__option"
                  >
                    {data.label}
                  </Option>
                ))}
              </Select>
            </label>

            <label>
              <span>Facebook</span>
              <input
                type="text"
                placeholder="Write here"
                {...register("facebook")}
              />
            </label>
            <label>
              <span>Instagram</span>
              <input
                type="text"
                placeholder="Write here"
                {...register("instagram")}
              />
            </label>
            <label>
              <span>LinkedIn</span>
              <input
                type="text"
                placeholder="Write here"
                {...register("linkedIn")}
              />
            </label>
            <label>
              <span>X (formally Twitter)</span>
              <input
                type="text"
                {...register("twitter")}
                placeholder="Write here"
              />
            </label>
            <label>
              <span>Other</span>
              <input
                type="text"
                {...register("other")}
                placeholder="Write here"
              />
            </label>
            {errorMessage && (
              <span
                style={{
                  color: "red",
                }}
                className="error-message"
              >
                {errorMessage}
              </span>
            )}
            <button ref={btnRef} type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
};
