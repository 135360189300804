/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from "react";
import { Footer, FormButton, NavBar } from "../../components";
import { VerifiedOtp, VerifyEmail } from "../../assets/icons";

import "./Verification.scss";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getProfile, resendOtp, updateProfileState, verifyMFA, verifyOtp } from "../../redux/ProfileSlice/ProfileSlice";
import { AppDispatch } from "../../redux/store/store";
import { useTimer } from "react-timer-hook";
import { setJWTToken } from "../../utils/jwtToken";

interface OTPInput {
  otp: number;
}

const Verification: FC = () => {
  const time = new Date();
  time.setSeconds(time.getSeconds() + 120);

  const [loading, setLoading] = useState<Boolean>(false);
  const [errorMessage, setErrorMessage] = useState<String>("");

  const { seconds, minutes, restart } = useTimer({ expiryTimestamp: time });

  const { register, handleSubmit } = useForm<OTPInput>();

  const [verified, setVerified] = useState<Boolean>(false);

  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { email } = location.state || {};

  const dispatch = useDispatch<AppDispatch>();


  useEffect(()=> {
    if(!email){
        navigate('/login')
    }
  }, [email])

  const onSubmit: SubmitHandler<OTPInput> = (data) => {
    setErrorMessage("");
    setLoading(true);
    dispatch(verifyMFA({ ...data, email })).then((res) => {
      setLoading(false);
      if (res.type.includes("rejected")) {
        const errMessage: any = Object.values(res.payload);
        if (errMessage[0][0]?.length > 2) {
          setErrorMessage(errMessage[0][0]);
        } else {
          setErrorMessage("OTP is incorrect.");
        }
        return;
      } else {
        setJWTToken(
            res.payload.access_token,
            res.payload.refresh_token,
            res.payload.user.role,
            res.payload.user.on_boarding_completed,
          );
  
          dispatch(
            updateProfileState({
              accessToken: res.payload.access_token,
              boardingCompleted: res.payload.user.on_boarding_completed,
              userRole: res.payload.user.role,
            }),
          );
  
          dispatch(getProfile());
          if (
            !res.payload.user.on_boarding_completed &&
            res.payload.user.role === "partner"
          ) {
            navigate(`/complete-partner`);
          } else if (!res.payload.user.on_boarding_completed) {
            navigate(`/onboarding`);
          } else {
            navigate(`/dashboard`);
          }
      }
    });
  };






  return (
    <div className="Verification">
      <NavBar hide={true} />
      <div className="Verification__container">
            <VerifyEmail />
            <h2>Verify Your Identity</h2>
            <p>
              To keep your account secure, we've sent a multi-factor authentication (MFA) code to {email} via email. Please enter the code below to complete the login process. This additional step helps ensure your account’s safety.

            </p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <label>
                <span>
                Enter your MFA code
                  <div>
                    0{minutes}:{seconds >= 10 ? seconds : `0${seconds}`}
                  </div>
                </span>
                <input required type="number" {...register("otp")} />
              </label>
              {errorMessage && (
                <span
                  style={{
                    color: errorMessage.includes("New OTP")
                      ? "green"
                      : "#ee1d52",
                  }}
                  className="errorMessage"
                >
                  {errorMessage}
                </span>
              )}

              <FormButton text="Verify and Login" loading={Boolean(loading)} />
            </form>
      </div>
      <Footer />
    </div>
  );
};

export default Verification;
