import React, {useEffect, useState, useCallback} from "react";
import "./ViewAllScreen.scss";
import {AnimatePresence, motion} from "framer-motion";
import {TailSpin} from "react-loader-spinner";
import {useParams} from "react-router-dom";
import DashboardNavbar from "../../components/DashboardNavbar/DashboardNavbar";
import Jobs from "./Jobs";
import Company from "./Company";
import Resource from "./Resource";
import Coach from "./Coach";
import Event from "./Event";
import debounce from "debounce";
import {Collapse, Pagination, Select, Tag, message} from "antd";
import {PaginationComp} from "../../components/PaginationComp/PaginationComp";
import {scrollToTop} from "../../utils/scrollToTop";
import ExploreSearchBar from "../../components/ExploreSearchBar/ExploreSearchBar";
import {CloseCircleOutlined} from "@ant-design/icons";
import {Bookmark} from "../../assets/icons";
import {useSelector} from "react-redux";
import axios from "axios";
import api from "../../utils/api";
import {getAccessToken} from "../../utils/jwtToken";
import {RecommendedJob} from "../../components";

const ViewAllScreen = () => {
    const {Panel} = Collapse;
    const Option = Select.Option;

    const dashboardExplore = useSelector(
        (state: any) => state.employer.dashboardExplore
    );

    const dashboardExploreTalents = dashboardExplore;

    const [searchString, setSearchString] = useState<string | null>(null);
    const [sharedFields, setSharedFields] = useState<any>({});
    const [searchSaved, setSearchSaved] = useState(false);
    const [savedSearchString, setSavedSearchString] = useState<[]>([]);
    const [workLocation, setWorkLocation] = useState([]);
    const [Skill, setSkill] = useState([]);
    const [JobType, setJobType] = useState([]);
    const [isFilterApplied, setIsFilterApplied] = useState(false);

    const [loading, setLoading] = useState<boolean>(false);
    const [talent, setTalent] = useState<any>([]);

    const [currentPage, setCurrentPage] = useState(1);

    //Set Query Params for the API
    const [queryParams, setQueryParams] = useState<any>("");
    const setqueryParams = (filter: any, type: any) => {
        let query = {...queryParams}; // Clone the current query parameters
        let filterApplied = false;

        if (type === "location") {
            setWorkLocation(filter ? filter : []);
            query.location = filter ? filter : [];
            filterApplied = filterApplied || !!filter;
        }
        if (type === "skills") {
            setSkill(filter ? filter : []);
            query.skills = filter ? filter : [];
            filterApplied = filterApplied || !!filter;
        }
        if (type === "job_type") {
            setJobType(filter ? filter : []);
            query.job_type = filter ? filter : [];
            filterApplied = filterApplied || !!filter;
        }

        setIsFilterApplied(filterApplied);
        setQueryParams(query);
    };

    const {type = "job"} = useParams();

    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 700);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth < 700);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const currentViewType: any = {
        job: {
            title: "Jobs",
            component: (
                <Jobs/>
            ),
        },
        company: {
            title: "Companies",
            component: <Company/>,
        },
        resource: {
            title: "Resources",
            component: <Resource/>,
        },
        coach: {
            title: "Coaches",
            component: <Coach/>,
        },
        event: {
            title: "Events",
            component: <Event/>,
        },
    };

    const savedSearchObj = {
        search: searchString,
        skill: Skill?.[0],
        JobType: JobType?.[0],
        work_location: workLocation?.[0],
    };

    // Function to clear all filters
    const clearFilters = () => {
        // Resetting the individual filter states to their default values
        setWorkLocation([]);
        setSkill([]);
        setJobType([]);
        setSearchString("");
        // Reset pagination
        setCurrentPage(1);
    };

    useEffect(() => {
        scrollToTop();
    }, []);

    useEffect(() => {
        const internalFunc = async () => {
            const promises: any = [];
            ["skill", "work-locations/", "role-type/"].forEach(async (elem) => {
                promises.push(
                    axios.get(`${api}/shared/${elem}`, {
                        headers: {
                            Authorization: `Bearer ${getAccessToken()}`,
                        },
                    })
                );
            });
            let allData = await Promise.all(promises);
            allData = allData.map((allData) => allData.data);

            setSharedFields({
                skill: allData[0],
                workLocation: allData[1],
                roleType: allData[2],
            });
        };
        internalFunc();
    }, [dashboardExploreTalents]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const accessToken = getAccessToken();
            const queryString = new URLSearchParams(queryParams).toString(); // Construct the query string from the object
            try {
                const res = await axios.get(
                    `${api}/recommend/search/job?${queryString}&from=${
                        (currentPage - 1) * 10
                    }`,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );
                setTalent(res.data);
            } catch (error) {
                console.error("Error fetching data", error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [queryParams, currentPage]);

    const talentJobSearch = (searchValue: any, size = 1) => {
        const accessToken = getAccessToken();
        axios
            .get(
                `${api}/recommend/explore/${"?q=" + searchValue}&from=${
                    (size - 1) * 10
                }`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            )
            .then((res) => {
                setLoading(false);
                setTalent(res.data);
                setCurrentPage(size); // Set the current page
            });
    };

    const debouncedDispatch = useCallback(
        debounce((searchValue: any) => {
            talentJobSearch(searchValue);
        }, 600),
        [] // Dependencies
    );

    const getEmployerSavedSearches = () => {
        const accessToken = getAccessToken();
        axios
            .get(`${api}/talent/get_saved_search/`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
            .then((res: any) => {
                setSavedSearchString(res?.data?.results?.map((elem: any) => elem));
            });
    };

    useEffect(() => {
        getEmployerSavedSearches();
    }, []);

    useEffect(() => {
        isSearchSaved(
            savedSearchObj,
            savedSearchString?.map((elem: any) => elem)
        );
    }, [savedSearchObj]);

    const saveSearch = async () => {
        try {
            await axios.post(
                `${api}/talent/save/search/`,
                {
                    location: workLocation.length === 0 ? "" : workLocation,
                    skills: Skill.length === 0 ? "" : Skill,
                    job_type: JobType.length === 0 ? "" : JobType,
                    search: searchString,
                },
                {
                    headers: {
                        Authorization: `Bearer ${getAccessToken()}`,
                    },
                }
            );

            getEmployerSavedSearches();
            setSearchString("");
            message.success("Search is saved");
        } catch (error) {
            message.error(error.response.data);
        }
    };

    const unSaveSearch = async (search: string) => {
        try {
            await axios.delete(`${api}/talent/unsave/search/${search}/`, {
                headers: {
                    Authorization: `Bearer ${getAccessToken()}`,
                },
            });
            message.warning("Search is deleted");
        } catch (error) {
        }
    };

    const compareSearches = (obj1: any, obj2: any) => {
        return Object.keys(obj1).every(
            (key) => obj2.hasOwnProperty(key) && obj1[key] === obj2[key]
        );
    };

    const isSearchSaved = (singleObj: any, arrayOfObjs: any) => {
        setSearchSaved(
            arrayOfObjs?.some((obj: any) => compareSearches(singleObj, obj))
        );
    };

    return (
        <AnimatePresence>
            {!loading ? (
                <motion.div
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    className="viewAll__box"
                >
                    <DashboardNavbar addLogo={isMobileView}/>
                    <main className="viewAll">
                        <motion.div className="viewAll__heading">
                            <h1>{currentViewType?.[type]?.title}</h1>
                        </motion.div>
                        {
                            type === "job" && <>

                                <div className="employer-explore-searchbar">
                                    {/* Collapse Component for Mobile View */}
                                    <Collapse className="mobile-collapse">
                                        <Panel header="Filters" key="1">
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    width: "100%",
                                                    height: 95,
                                                }}
                                                className="filterSection"
                                            >
                                                <div
                                                    className="select-heading"
                                                    style={{
                                                        fontSize: "16px",
                                                        fontWeight: "700",
                                                        color: "#1E1E2F",
                                                        marginBottom: "10px",
                                                    }}
                                                >
                                                    {" "}
                                                    Work Location{" "}
                                                </div>
                                                <Select
                                                    optionFilterProp="label"
                                                    allowClear={true}
                                                    showSearch={true}
                                                    onChange={(e: any) => setqueryParams(e, "location")}
                                                    className="select"
                                                    placeholder="All"
                                                    value={workLocation}
                                                >
                                                    {(sharedFields["workLocation"] ?? []).map((ind: any) => (
                                                        <Option
                                                            label={ind.name}
                                                            value={ind.name}
                                                            className="roleType__option"
                                                        >
                                                            {ind.name}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    width: "100%",
                                                    height: 95,
                                                }}
                                                className="filterSection"
                                            >
                                                <div
                                                    className="select-heading"
                                                    style={{
                                                        fontSize: "16px",
                                                        fontWeight: "700",
                                                        color: "#1E1E2F",
                                                        marginBottom: "10px",
                                                    }}
                                                >
                                                    {" "}
                                                    Skills{" "}
                                                </div>
                                                <Select
                                                    optionFilterProp="label"
                                                    allowClear={true}
                                                    showSearch={true}
                                                    onChange={(e: any) => setqueryParams(e, "skills")}
                                                    className="select"
                                                    placeholder="All"
                                                    value={Skill}
                                                >
                                                    {(sharedFields["skill"] ?? []).map((ind: any) => (
                                                        <Option
                                                            label={ind.skill}
                                                            value={ind.skill}
                                                            className="roleType__option"
                                                        >
                                                            {ind.skill}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    width: "100%",
                                                    height: 95,
                                                }}
                                                className="filterSection"
                                            >
                                                <div
                                                    className="select-heading"
                                                    style={{
                                                        fontSize: "16px",
                                                        fontWeight: "700",
                                                        color: "#1E1E2F",
                                                        marginBottom: "10px",
                                                        whiteSpace: "nowrap",
                                                    }}
                                                >
                                                    {" "}
                                                    JobType{" "}
                                                </div>
                                                <Select
                                                    optionFilterProp="label"
                                                    allowClear={true}
                                                    showSearch={true}
                                                    onChange={(e: any) => setqueryParams(e, "job_type")}
                                                    className="select"
                                                    placeholder="All"
                                                    value={JobType}
                                                >
                                                    {(sharedFields["roleType"] ?? []).map((ind: any) => (
                                                        <Option
                                                            label={ind.name}
                                                            value={ind.name}
                                                            className="roleType__option"
                                                        >
                                                            {ind.name}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </div>
                                        </Panel>
                                    </Collapse>

                                    {/* Desktop view */}
                                    <div
                                        className="main__dashboard-container-analytics"
                                        style={{
                                            marginTop: "20px",
                                            paddingBottom: "40px",
                                            borderBottom: "0.5px #999999 solid",
                                            gap: 24,
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                width: "100%",
                                            }}
                                            className="filterSection"
                                        >
                                            <div
                                                className="select-heading"
                                                style={{
                                                    fontSize: "16px",
                                                    fontWeight: "700",
                                                    color: "#1E1E2F",
                                                    marginBottom: "10px",
                                                }}
                                            >
                                                {" "}
                                                Work Location{" "}
                                            </div>
                                            <Select
                                                optionFilterProp="label"
                                                allowClear={true}
                                                showSearch={true}
                                                onChange={(e: any) => setqueryParams(e, "location")}
                                                className="select"
                                                placeholder="All"
                                                value={workLocation}
                                            >
                                                {(sharedFields["workLocation"] ?? []).map((ind: any) => (
                                                    <Option
                                                        label={ind.name}
                                                        value={ind.name}
                                                        className="roleType__option"
                                                    >
                                                        {ind.name}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                width: "100%",
                                            }}
                                            className="filterSection"
                                        >
                                            <div
                                                className="select-heading"
                                                style={{
                                                    fontSize: "16px",
                                                    fontWeight: "700",
                                                    color: "#1E1E2F",
                                                    marginBottom: "10px",
                                                }}
                                            >
                                                {" "}
                                                Skills{" "}
                                            </div>
                                            <Select
                                                optionFilterProp="label"
                                                allowClear={true}
                                                showSearch={true}
                                                onChange={(e: any) => setqueryParams(e, "skills")}
                                                className="select"
                                                placeholder="All"
                                                value={Skill}
                                            >
                                                {(sharedFields["skill"] ?? []).map((ind: any) => (
                                                    <Option
                                                        label={ind.skill}
                                                        value={ind.skill}
                                                        className="roleType__option"
                                                    >
                                                        {ind.skill}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                width: "100%",
                                            }}
                                            className="filterSection"
                                        >
                                            <div
                                                className="select-heading"
                                                style={{
                                                    fontSize: "16px",
                                                    fontWeight: "700",
                                                    color: "#1E1E2F",
                                                    marginBottom: "10px",
                                                    whiteSpace: "nowrap",
                                                }}
                                            >
                                                {" "}
                                                JobType{" "}
                                            </div>
                                            <Select
                                                optionFilterProp="label"
                                                allowClear={true}
                                                showSearch={true}
                                                onChange={(e: any) => setqueryParams(e, "job_type")}
                                                className="select"
                                                placeholder="All"
                                                value={JobType}
                                            >
                                                {(sharedFields["roleType"] ?? []).map((ind: any) => (
                                                    <Option
                                                        label={ind.name}
                                                        value={ind.name}
                                                        className="roleType__option"
                                                    >
                                                        {ind.name}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                width: "100%",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    fontSize: "16px",
                                                    fontWeight: "700",
                                                    color: "#1E1E2F",
                                                    marginBottom: "10px",
                                                }}
                                            >
                                                Search
                                            </div>
                                            <ExploreSearchBar
                                                searchString={searchString}
                                                onChange={(e: any) => {
                                                    setSearchString(e.target.value);
                                                    debouncedDispatch(e.target.value);
                                                }}
                                            />
                                        </div>
                                        <button
                                            onClick={() => clearFilters()}
                                            style={{
                                                outline: "none",
                                                border: "1px solid var(--Text-Primary)",
                                                borderRadius: "24px",
                                                width: "100%",
                                                padding: "10px",
                                                fontSize: "14px",
                                                fontWeight: "700",
                                                backgroundColor: "transparent",
                                                color: "var(--Text-Primary)",
                                                marginTop: "20px",
                                            }}
                                        >
                                            Clear Filters
                                        </button>
                                    </div>
                                </div>

                                <div
                                    className="main__dashboard-container-analytics"
                                    style={{
                                        marginTop: "20px",
                                        paddingBottom: "20px",
                                        borderBottom: "0.5px #999999 solid",
                                    }}
                                >
                                    {/* Search Filters */}

                                    <div style={{display: "flex"}}>
                                        <div
                                            className="employer-searchTags-sec"
                                            style={{
                                                fontWeight: "500",
                                                fontSize: "14px",
                                                lineHeight: "20px",
                                            }}
                                        >
                                            {" "}
                                            Saved Searches:{" "}
                                        </div>

                                        {savedSearchString?.length > 0 ? (
                                            <div
                                                style={{
                                                    marginLeft: "20px",
                                                    display: "flex",
                                                    flexWrap: "wrap",
                                                    maxWidth: "400px",
                                                    gap: "10px",
                                                }}
                                            >
                                                <div className="employer-explore-searchTags">
                                                    {savedSearchString?.map((elem: any) => (
                                                        <Tag
                                                            onClick={() => {
                                                                // Set the search string
                                                                setSearchString(elem.search);
                                                                // Set the filters
                                                                setWorkLocation(elem.work_place_type);
                                                                setSkill(elem.skills);
                                                                setJobType(elem.job_Type);
                                                                // Perform the search
                                                                talentJobSearch(elem.search);
                                                            }}
                                                            closeIcon={<CloseCircleOutlined/>}
                                                            onClose={() => unSaveSearch(elem.search)}
                                                            color={"#EDEEF7"}
                                                            style={{
                                                                width: "fit-content",
                                                                height: "22px",
                                                                borderRadius: "22px",
                                                                color: "#344054",
                                                                fontSize: "10px",
                                                                fontWeight: "400",
                                                                textAlign: "center",
                                                            }}
                                                        >
                                                            {elem.search}
                                                        </Tag>
                                                    ))}
                                                </div>
                                            </div>
                                        ) : (
                                            <span
                                                style={{
                                                    fontSize: "12px",
                                                    marginTop: "3px",
                                                    marginLeft: "5px",
                                                }}
                                            >
                    No saved searches
                  </span>
                                        )}
                                    </div>

                                    {/* Save View Saved Searches */}
                                    <div style={{display: "flex", cursor: "pointer", gap: "16px"}}>
                                        {/* Save this Search */}
                                        <div
                                            style={{
                                                display: "flex",
                                            }}
                                        >
                                            <Bookmark
                                                className={`bookmark ${
                                                    searchSaved ? "bookmarkChecked" : ""
                                                }`}
                                                onClick={saveSearch}
                                            />{" "}
                                            <span
                                                style={{
                                                    marginLeft: "10px",
                                                    paddingRight: "20px",
                                                    fontWeight: 400,
                                                    fontSize: "14px",
                                                }}
                                            >
                    {" "}
                                                Save this Search
                  </span>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                        {searchString && talent?.jobs ? (
                            <div className="viewAll__recommendations-container">
                                <div className="viewAll__recommendations-container-card">
                                    {talent?.jobs?.map((job: any) => (
                                        <RecommendedJob
                                            key={job.id}
                                            job={{...job, style: {marginBottom: "40px"}}}
                                        />
                                    ))}
                                </div>
                            </div>
                        ) : isFilterApplied && talent?.results ? (
                            <div className="viewAll__recommendations-container">
                                <div className="viewAll__recommendations-container-card">
                                    {talent?.results?.map((job: any) => (
                                        <RecommendedJob
                                            key={job.id}
                                            job={{...job, style: {marginBottom: "40px"}}}
                                        />
                                    ))}
                                </div>
                            </div>
                        ) : (
                            currentViewType?.[type]?.component
                        )}
                    </main>
                </motion.div>
            ) : (
                <TailSpin
                    height="160"
                    width="160"
                    color="#FBAD84"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{
                        justifySelf: "center",
                        alignSelf: "center",
                    }}
                    wrapperClass="tail-spin"
                    visible={true}
                />
            )}
            {/*<PartnerResourceFooter/>*/}
        </AnimatePresence>
    );
};

export default ViewAllScreen;
