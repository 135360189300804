import React, { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  Logo,
  AdminProfile,
  Home,
  User,
  Event,
  CareerPath,
  AdminPanel,
  TribajaLogo,
  Recommended,
} from "../../assets/icons";
import "./AdminSideBar.scss";
import { useDispatch, useSelector } from "react-redux";
import { getAdminDashboard } from "../../redux/AdminSlice/AdminSlice";
import { AppDispatch } from "../../redux/store/store";
import { getNameInitials } from "../../utils/getNameInitials";

type SideBarItem = {
  icon: React.ReactElement;
  name: string;
  link: string;
};

const AdminSideBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { dashboard } = useSelector((state: any) => state.admin);
  const { profileInfo } = useSelector((state: any) => state.profile);

  // State to check if the screen width is less than 700px
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 700);

  // Effect to add resize event listener
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 700);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    dispatch(getAdminDashboard());
  }, []);

  // Function to shorten lengthy names
  const shortenName = (name: string) => {
    if (isMobileView && name.length > 10) {
      return name.slice(0, 10) + "...";
    }
    return name;
  };

  const sideBarList: Array<SideBarItem> = [
    {
      icon: <Home />,
      name: "Dashboard",
      link: "/admin/dashboard",
    },
    {
      icon: <CareerPath />,
      name: shortenName("Career Path"),
      link: "/admin/career-path",
    },

    {
      icon: <User />,
      name: shortenName("User Management"),
      link: "/admin/user-management",
    },
    {
      icon: <Event />,
      name: "Events",
      link: "/admin/events",
    },
    {
      icon: <AdminPanel />,
      name: shortenName("Admins Panel"),
      link: "/admin/admins-panel",
    },
    {
      icon: <User />,
      name: shortenName("Partners Management"),
      link: "/admin/partners-management",
    },
    {
      icon: <User />,
      name: shortenName("Coaches Management"),
      link: "/admin/coaches-management",
    },
    {
      icon: <Recommended />,
      name: "Resources",
      link: "/admin/resources",
    },
  ];
  return !isMobileView ? (
    <div className="adminSideBar">
      <TribajaLogo style={{ marginBottom: "53px", marginTop: "45px" }} />
      <div id="avatar">
        {getNameInitials(profileInfo?.first_name, profileInfo?.last_name)}
      </div>
      <div className="adminSideBar__title">
        {profileInfo?.first_name} {profileInfo?.last_name}
      </div>

      <ul>
        {sideBarList.map((_elem) => (
          <li
            className={location.pathname === _elem.link ? "active" : ""}
            onClick={(e: any) => {
              navigate(_elem.link);
            }}
            key={_elem.link}
            id={_elem.link}
          >
            {_elem.icon}
            {_elem.name}
          </li>
        ))}
      </ul>
    </div>
  ) : (
    <>
      {/* Mobile view: bottom navigation bar        */}
      <div className="bottom-nav">
        {sideBarList.map((_elem) => (
          <li
            className={location.pathname === _elem.link ? "active" : ""}
            onClick={(e: any) => {
              navigate(_elem.link);
            }}
            key={_elem.link}
            id={_elem.link}
          >
            {_elem.icon}
            {_elem.name}
          </li>
        ))}
      </div>
    </>
  );
};

export default AdminSideBar;
