import React, { FC, useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

import "./TalentCareerGuide.scss";
import DashboardNavbar from "../../components/DashboardNavbar/DashboardNavbar";

import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store/store";
import { TailSpin } from "react-loader-spinner";
import { getTalentDashboard } from "../../redux/TalentSlice/TalentSlice";

import { ReactComponent as ChatImage } from "../../assets/images/careerGuideImage.svg";
import { Button, message } from "antd";
import CareerGuideChat from "../../components/CareerGuideChat/CareerGuideChat";
import { getAccessToken } from "../../utils/jwtToken";
import axios from "axios";
import api from "../../utils/api";

const TalentCareerGuide: FC = () => {
  // States
  const [loading, setLoading] = useState<boolean>(true);
  const [chat, setChat] = useState(false);
  const [careerChatData, setCareerChatData] = useState([]);

  // Selectors
  const profileInfo = useSelector((state: any) => state.profile.profileInfo);
  const talentDashboard = useSelector((state: any) => state.talent.dashboard);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 700);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 700);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const defaultStyle = {
    padding: "6px 44px",
    fontSize: "14px",
    fontWeight: 600,
    background: "#1E1E2F",
    width: "400px",
    marginTop: careerChatData.length === 0 ? "0" : "1150px",
  };

  const mediaQueryStyle = {
    ...defaultStyle,
    width: windowWidth <= 760 ? "fit-content" : "300px",
    // marginTop: windowWidth > 870 ? '630px' :
    //            windowWidth > 760 && windowWidth <= 870 ? '750px' :
    //            '1150px' //default case
    // // Add other styles you want to change for the media query
  };

  const buttonStyle = windowWidth <= 870 ? mediaQueryStyle : defaultStyle;

  useEffect(() => {
    setLoading(true);

    if (profileInfo?.first_name && talentDashboard?.profile) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    setLoading(true);

    if (profileInfo?.first_name) {
      setLoading(false);
    }
  }, [profileInfo]);

  const handleChatGuidance = () => {
    setChat(true);
    const accessToken = getAccessToken();
    axios
      .get(`${api}/recommend/career_path/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        setChat(false);
        setCareerChatData(response.data);
      })
      .catch((error) => {
        setChat(false);
        message.error(error);
        console.error("Error sending request", error);
      });
  };

  return (
    <AnimatePresence>
      {!loading ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="yourCoaches"
        >
          <DashboardNavbar addLogo={isMobileView} />
          <div className="yourCoaches__heading">My career guide</div>
          <div className="yourCoaches__sub-heading">
            Get your specialized career paths based on your profile.
          </div>
          <div
            className="yourCoaches-recent-activities"
            style={{
              display: "flex",
              flexDirection: "column",
              overflowY: "scroll",
              maxHeight: "600px",
              alignItems: "center",
            }}
          >
            {/* Header First Row */}

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: careerChatData.length === 0 ? "center" : "start",
                height: "100%",
              }}
            >
              <div className="chat-logo">
                <ChatImage />
              </div>
              {careerChatData.length === 0 ? (
                <div className="yourCoaches-find-div">
                  Let's find your career path
                </div>
              ) : (
                <div className="yourCoaches-chatbox">
                  <CareerGuideChat careerChatData={careerChatData} />
                </div>
              )}
            </div>
            <div>
              {" "}
              <Button
                type="primary"
                shape="round"
                size={"large"}
                style={buttonStyle}
                onClick={handleChatGuidance}
              >
                {!chat ? (
                  "Let's go"
                ) : (
                  <TailSpin
                    height="25"
                    width="30"
                    color="#ffffff"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{
                      justifySelf: "center",
                      alignSelf: "center",
                    }}
                    wrapperClass="tail-spin"
                    visible={true}
                  />
                )}
              </Button>
            </div>
          </div>
        </motion.div>
      ) : (
        <TailSpin
          height="160"
          width="160"
          color="#FBAD84"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{
            justifySelf: "center",
            alignSelf: "center",
          }}
          wrapperClass="tail-spin"
          visible={true}
        />
      )}
    </AnimatePresence>
  );
};

export default TalentCareerGuide;
