/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useState, useEffect } from "react";
import {
  ArrowDown,
  BecomeACoach,
  Billing,
  Logout,
  SavedItems,
  TribajaLogo,
} from "../../assets/icons";

import { useDispatch } from "react-redux";

import { ReactComponent as ProfileLogo } from "../../assets/icons/tribajaLogo.svg";
import "./DashboardNavbar.scss";
import { Link, NavLink } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { useSelector } from "react-redux";
import { getNameInitials } from "../../utils/getNameInitials";
import { getAccessToken, handleLogout } from "../../utils/jwtToken";
import { getTalentDashboard } from "../../redux/TalentSlice/TalentSlice";
import { AppDispatch } from "../../redux/store/store";
import { Avatar } from "antd";
import { getEmployerDashboard } from "../../redux/EmployerSlice/EmployerSlice";
import RequestModalTalent from "../RequestModalTalent/RequestModalTalent";
import axios from "axios";
import api from "../../utils/api";
import InitialsAvatar from "../InitialsAvatar/InitialsAvatar";
import {
  getPartnerDashboard,
  getPartnerLogo,
} from "../../redux/PartnerSlice/PartnerSlice";
import {} from "../../redux/ProfileSlice/ProfileSlice";

type Props = {
  addLogo?: boolean;
  customStyles?: object;
  talentProfileLogo?: boolean;
};

const DashboardNavbar: FC<Props> = (props) => {
  const [popup, setPopup] = useState(false);
  const [openRequestCoachModal, setOpenRequestCoachModal] = useState(false);
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 700);

  const [headerImg, setHeaderImg] = useState<any>("");
  const customStyles = props?.customStyles;
  const dispatch = useDispatch<AppDispatch>();

  const profileInfo = useSelector((state: any) => state.profile.profileInfo);
  const talentDashboard = useSelector((state: any) => state.talent.dashboard);
  const employerDashbaord = useSelector(
    (state: any) => state.employer.dashboard
  );
  const partnerDashboard = useSelector((state: any) => state.partner.dashboard);

  const getLink = () => {
    if (profileInfo?.role === "employer") {
      return `/profile/${profileInfo?.role}/${employerDashbaord?.profile?.id}`;
    } else if (profileInfo?.role === "talent") {
      return `/profile/${profileInfo?.role}/${talentDashboard?.profile?.id}`;
    } else {
      return "#";
    }
  };

    // Effect to add resize event listener
    useEffect(() => {
      const handleResize = () => {
        setIsMobileView(window.innerWidth < 700);
      };
  
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

  // useEffect(() => {
  //   const fetchPartnerData = async () => {
  //     if (profileInfo?.role === "partner") {
  //       const dashboard = await dispatch(getPartnerDashboard());
  //       const img = await dispatch(
  //         getPartnerLogo(dashboard?.payload?.profile?.partner)
  //       );
  //       setHeaderImg(img.payload);
  //     }
  //   };

  //   fetchPartnerData();
  // }, [dispatch, profileInfo]);

  useEffect(() => {
    if (
      (profileInfo?.role === "talent" || profileInfo?.role === "coach") &&
      talentDashboard
    ) {
      setHeaderImg(talentDashboard?.profile_image);
    } else if (profileInfo?.role === "employer" && employerDashbaord) {
      setHeaderImg(employerDashbaord?.profile_image);
    } else if (profileInfo?.role === "partner" && partnerDashboard) {
      setHeaderImg(partnerDashboard?.profile_image);
    }
  }, [talentDashboard, employerDashbaord, partnerDashboard]);

  useEffect(() => {
    if (profileInfo?.role === "talent") {
      dispatch(getTalentDashboard());
    } else if (profileInfo?.role === "employer") {
      dispatch(getEmployerDashboard());
    } else if (profileInfo?.role === "partner") {
      dispatch(getPartnerDashboard());
    }
  }, []);

  const navbarLinks: any = {
    talent: [
      {
        icon: <SavedItems />,
        name: "Saved Items",
        link: "/saved-items",
      },
    ],
    employer: [
      {
        icon: <SavedItems />,
        name: "Saved Items",
        link: "/employer-saved-items",
      },
    ],
    partner: [],
    admin: [],
  };

  const handleRequestModal = (e: any) => {
    e.preventDefault();
    setOpenRequestCoachModal((prevState) => !prevState);
  };

  const requestToBecomeCoach = () => {
    setLoader(true);
    axios
      .post(
        `${api}/talent/coach/invitation/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        }
      )
      .then((response) => {
        // setOpenRequestCoachModal(false)
        setIsRequestSent(true);
        setLoader(false);
      })
      .catch((error) => {
        // setOpenRequestCoachModal(false)
        setIsRequestSent(false);
        setLoader(false);
        console.error("Error sending request", error);
      });
  };

  return (
    <>
      <div className="dashboardNavbar" style={customStyles || {}}>
        <TribajaLogo className="logo" />
        {props?.addLogo ? (
          <div className="navbar__col-1">
            <Link to={"/"}>
              <ProfileLogo className="navbar__logo" />
            </Link>
          </div>
        ) : null}
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* <div>
                        <Message/>
                    </div>
                    <div className="notification">
                        <Notification/>
                    </div> */}
          <div
            className="dashboardNavbar__profile"
            onClick={() => setPopup(!popup)}
          >
            <main>
              {headerImg ? (
                <Avatar
                  style={{
                    width: "48px",
                    height: "48px",
                    border: "1px solid",
                  }}
                  src={headerImg}
                />
              ) : (
                <InitialsAvatar
                  name={profileInfo?.first_name + " " + profileInfo?.last_name}
                />
              )}
            </main>
            <ArrowDown style={{ cursor: "pointer" }} />
            <AnimatePresence>
              {popup && (
                <motion.div
                  key="profile-container"
                  transition={{ duration: 0.2 }}
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  className="dashboardNavbar__profile-container"
                >
                  <div className="profile__box">
                    {headerImg ? (
                      <Avatar
                        style={{
                          width: "74px",
                          height: "74px",
                          border: "1px solid",
                          marginTop: "10px",
                        }}
                        src={headerImg}
                      />
                    ) : (
                      <div className="profile__box-avatar">
                        {getNameInitials(
                          profileInfo?.first_name,
                          profileInfo?.last_name
                        )}
                      </div>
                    )}
                    <div className="profile__box-details">
                      {profileInfo?.first_name} {profileInfo?.last_name}
                      {profileInfo.role !== "admin" && (
                        <Link
                          to={
                            profileInfo.role === "partner"
                              ? "/partner-profile"
                              : getLink()
                          }
                        >
                          View Profile
                        </Link>
                      )}
                    </div>
                  </div>
                  <div className="profile__box-list">
                    {talentDashboard?.can_become_coach && (
                      <NavLink
                        to="become-a-coach"
                        onClick={(e) => handleRequestModal(e)}
                      >
                        <div>
                          <BecomeACoach />
                          Become a Coach
                        </div>
                        <ArrowDown />
                      </NavLink>
                    )}
                    {navbarLinks[profileInfo?.role].map((item: any) => (
                      <NavLink key={item.name} to={item.link}>
                        <div>
                          {item.icon}
                          {item.name}
                        </div>
                        <ArrowDown />
                      </NavLink>
                    ))}
                   {isMobileView && (profileInfo?.role==='talent' ||profileInfo?.role === 'coach' || profileInfo?.role ==='employer') && !profileInfo?.is_employer_staff && <NavLink to="/billing">
                    <div>
                     <Billing />
                      Billing
                    </div>
                  </NavLink>}

                    <NavLink to="#" onClick={handleLogout}>
                      <div>
                        <Logout />
                        Logout
                      </div>
                      <ArrowDown />
                    </NavLink>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>

      {openRequestCoachModal && (
        <RequestModalTalent
          requestToBecomeCoach={requestToBecomeCoach}
          isRequestSent={isRequestSent}
          handleRequestModal={handleRequestModal}
          loader={loader}
        />
      )}
    </>
  );
};

export default DashboardNavbar;
