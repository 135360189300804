import React, { useEffect, useState } from "react";
import "./index.module.scss";
import { CheckItemIcon } from "../../assets/icons";
import { useSelector } from "react-redux";

interface Plan {
  id: number;
  inclusion_benchmarking: boolean;
  verified_badge: boolean;
  tribaja_profile: boolean;
  anual_inclusion_awards: boolean;
  ats_integration: boolean;
  pipeline_dashboard: boolean;
  explore_page: boolean;
  social_media_features: boolean;
  slack_channel_feature: boolean;
  workshops_and_events: boolean;
  job_post_count: number;
  type: string;
  price: number;
}

interface Feature {
  key: keyof Plan;
  label: string;
}

const features: any = [
  { key: "access_resources", label: "Access Resources" },
  { key: "apply_jobs", label: "Apply Jobs" },
  { key: "chatbot_access", label: "Chatbot Access" },
  { key: "mentor_matches", label: "Mentor Matches" },
  { key: "recommendations", label: "Recommendations" },
  { key: "searchjobs", label: "Search Jobs" },
];

const TalentPlanFeaturesTable = ({ subscriptionPlan }: any) => {
  const talentDashboard = useSelector((state: any) => state.talent.dashboard);
  const filteredSubscriptionPlan = subscriptionPlan.filter((plan: any) => {
    return plan.id !== 22 || talentDashboard?.subscription_status === "seeker";
  });

  const gridTemplateColumns =
    filteredSubscriptionPlan.length !== 3 ? "repeat(3, 1fr)" : "repeat(4, 1fr)";

  return (
    <div style={{ gridTemplateColumns }} className="plan-features__container">
      <div className="plan-features__header"></div>
      {features.map((feature: any) => (
        <div className="plan-features__row" key={feature.key}>
          <div className="plan-features__row-feature">{feature.label}</div>
          {filteredSubscriptionPlan.map((plan: any) => (
            <div
              className="plan-features__row-plan"
              key={plan.id}
              style={{
                backgroundColor:
                  plan.type === "trailblazer"
                    ? "#BDAFEB"
                    : plan.type === "path"
                    ? "#FDCEB5"
                    : "#feedcc",
              }}
            >
              {plan[feature.key] && feature.key !== "job_post_count" ? (
                <CheckItemIcon />
              ) : feature.key === "job_post_count" && plan.id === 43 ? (
                "Unlimited"
              ) : feature.key === "job_post_count" ? (
                plan?.job_post_count
              ) : (
                ""
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default TalentPlanFeaturesTable;