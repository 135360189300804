import { FC, useEffect, useState } from "react";
import { Select, message } from "antd";
import { FormButton } from "../../../../components";
import { ReactComponent as SuccessTick } from "../../../../assets/icons/successTick.svg";
import "../../../ProfileScreen/Modals/EditProfileModal.scss";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store/store";
import { getEmployerAnnualPlans } from "../../../../redux/EmployerSlice/EmployerSlice";
import axios from "axios";
import api from "../../../../utils/api";
import { getAccessToken } from "../../../../utils/jwtToken";

type Props = {
  onModalClose: () => void;
  employerId: string;
};

const AdminEditSubscriptionModal: FC<Props> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [actionType, setActionType] = useState<string>(""); // New state to track action type

  const [submittedSuccessfully, setSubmittedSuccessfully] =
    useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState<string>("");
  const [plans, setPlans] = useState<{ id: number; type: string }[]>([]);

  const dispatch = useDispatch<AppDispatch>();

 

  const onSubmit = (event: any) => {
    event.preventDefault();
    setLoading(true);
    const accessToken = getAccessToken();
    axios
      .post(
        `${api}/main/admin/upgrade-to-annual/`,
        {
          employer_id: props.employerId,
          plan_id: selectedPlan,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        setUpdateLoading(false);
        setSubmittedSuccessfully(true);
        setActionType("update");
        message.success("Subscription plan updated successfully");
      })
      .catch((error) => {
        setLoading(false);
        message.error("An error occurred. Please try again");
      });
  };
  const onCancel = (subscriptionId: string) => {
    setLoading(true);
    const accessToken = getAccessToken();
    axios
      .post(
        `${api}/main/admin/cancel-annual/`,
        {
          employer_id: props.employerId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        setSubmittedSuccessfully(true);
        setActionType("cancel");
        message.success("Subscription cancelled successfully");
      })
      .catch((error) => {
        setLoading(false);
        message.error("An error occurred. Please try again");
      });
  };
  const CrossSvg = () => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.17188 14.8299L14.8319 9.16992"
        stroke="#344054"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8319 14.8299L9.17188 9.16992"
        stroke="#344054"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
        stroke="#344054"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  useEffect(() => {
    dispatch(getEmployerAnnualPlans()).then((data: any) => {
      const formattedPlans = data.payload.map((plan: any) => ({
        id: plan.id,
        type: formatPlanType(plan.type),
      }));
      setPlans(formattedPlans);
    });
  }, [dispatch]);

  const formatPlanType = (type: string) => {
    return type
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <>
      <div className="overlay">
        <div
          className="modal"
          style={{
            height: "auto",
            maxHeight: "1000px",
            overflowY: "unset",
            maxWidth: "600px",
            width: "100%",
          }}
        >
          {submittedSuccessfully ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  width: "100%",
                  cursor: "pointer",
                }}
                onClick={props.onModalClose}
              >
                <CrossSvg />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "80px 40px",
                }}
              >
                <div>
                  <SuccessTick />
                </div>

                <div
                  style={{
                    fontWeight: 700,
                    fontSize: "40px",
                    color: "#1E1E2F",
                    marginTop: "40px",
                  }}
                >
                  <p style={{ fontWeight: 700, fontSize: "40px" }}>
                    {actionType === "update"
                      ? "Subscription plan updated successfully"
                      : "Subscription cancelled successfully"}
                  </p>
                </div>
              </div>
            </>
          ) : (
            <div className="editProfile__container">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h2
                  style={{
                    textAlign: "left",
                    marginTop: "10px",
                    fontWeight: 700,
                    fontSize: "40px",
                    color: "#1E1E2F",
                  }}
                >
                  Edit Subscription Plan
                </h2>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    props.onModalClose();
                  }}
                >
                  <CrossSvg />
                </div>
              </div>
              <hr
                style={{
                  color: "#999999",
                  marginTop: "40px",
                  marginBottom: "20px",
                }}
              />

              <div className="editProfile__container">
                <form
                  onSubmit={onSubmit}
                  style={{
                    gap: "0px",
                  }}
                >
                  <div className="editProfile__inputContainer">
                    <label
                      className="editProfile__label"
                      style={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                        display: "block",
                        textAlign: "left",
                      }}
                    >
                      Select Plan
                    </label>
                    <Select
                      defaultValue="Select Plan"
                      style={{
                        width: "100%",
                        marginBottom: "20px",
                        borderRadius: "5px",
                        height: "40px",
                        textAlign: "left",
                      }}
                      onChange={(value) => setSelectedPlan(value)}
                    >
                      {plans.length >= 0 &&
                        plans.map((plan) => (
                          <Select.Option key={plan.id} value={plan.id}>
                            {plan.type}
                          </Select.Option>
                        ))}
                    </Select>
                  </div>

                  <FormButton
                    disabled={!selectedPlan}
                    text="Update"
                    loading={Boolean(updateLoading)}
                  />
                </form>
                <button
                  onClick={() => onCancel(selectedPlan)}
                  disabled={loading}
                  style={{
                    padding: "16px",
                    fontSize: "14px",
                    fontWeight: "700",
                    borderRadius: "50px",
                    color: "white",
                    background: "red",
                    marginTop: "8px",
                    border: "none",
                    cursor: "pointer",
                   
                  }}
                >
                  Cancel Subscription
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AdminEditSubscriptionModal;
