import React, { FC, useEffect, useState } from "react";

import { AnimatePresence, motion } from "framer-motion";
import { Select } from "antd";
import { Button, Dropdown, Tag } from "antd";
import type { MenuProps } from "antd";

import { TailSpin } from "react-loader-spinner";

import { ReactComponent as MenuIcon } from "../../../../assets/icons/menuIcon.svg";
import { ReactComponent as EditIcon } from "../../../../assets/icons/editIcon.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/deleteIcon.svg";
import AdminAddModal from "./AdminAddModal";
import AdminDeleteModal from "./AdminDeleteModal";

import "./MainDashboard.scss";
import DashboardNavbar from "../../../../components/DashboardNavbar/DashboardNavbar";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../redux/store/store";
import { deleteStaff, getStaff } from "../../../../redux/AdminSlice/AdminSlice";
import { Delete } from "../../../../assets/icons";

const Option = Select.Option;
const MainDashboard: FC = () => {
  const [loading, setLoading] = useState<boolean>(true);

  // LifeCylce events
  useEffect(() => {}, []);

  const [openModal, setModalOpen] = useState<boolean>(false);
  const [openDelModal, setDelModalOpen] = useState<string>("");

  const [adminProfileMode, setAdminProfileMode] = useState<"ADD_NEW" | "EDIT">(
    "ADD_NEW",
  );

  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 700);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 700);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { staff } = useSelector((state: any) => state.admin);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getStaff()).then(() => setLoading(false));
  }, []);

  const getItems = (id: string): MenuProps["items"] => [
    {
      icon: <Delete />,
      key: "1",
      label: "Delete Admin?",
      onClick: () => {
        setDelModalOpen(id);
      },
    },
  ];

  return (
    <>
      {openModal && (
        <AdminAddModal
          formType={adminProfileMode}
          onModalClose={() => setModalOpen(false)}
        />
      )}
      {Boolean(openDelModal) && (
        <AdminDeleteModal
          onSubmit={() => {
            dispatch(deleteStaff(openDelModal)).then(() => {
              dispatch(getStaff()).then(() => {
                setDelModalOpen("");
              });
            });
          }}
          onModalClose={() => setDelModalOpen("")}
        />
      )}
      <AnimatePresence>
        {!loading ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="main__dashboard-box"
          >
            <DashboardNavbar addLogo={isMobileView} />
            <main className="main__dashboard">
              <motion.div
                key={"dashboard-heading"}
                transition={{ duration: 0.3, delay: 0.3 }}
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                className="main__dashboard-heading"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <h1>Admins Management Panel</h1>
                {!staff?.["message"] && (
                  <Button
                    type="primary"
                    shape="round"
                    size={"large"}
                    className="btn-admin"
                    style={{
                      padding: "6px 44px",
                      fontSize: "14px",
                      fontWeight: 600,
                      background: "#1E1E2F",
                    }}
                    onClick={() => {
                      setAdminProfileMode("ADD_NEW");
                      setModalOpen(true);
                    }}
                  >
                    Add New Admin
                  </Button>
                )}
              </motion.div>
              {!staff?.["message"] ? (
                <motion.section
                  transition={{ duration: 0.3, delay: 0.4 }}
                  initial={{ y: 50, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  className="main__dashboard-container"
                >
                  <div
                    className="main__dashboard-recent-activities"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      overflow: "auto",
                      maxHeight: "600px",
                      // width: "100%",
                    }}
                  >
                    {/* Header First Row */}
                    <div
                      style={{
                        fontSize: "13px",
                        fontWeight: "700",
                        color: "#344054",
                        display: "flex",
                        justifyContent: "space-between",
                        minWidth: 800,
                        borderBottom: "1px solid #aaa",
                        paddingBottom: "8px",
                      }}
                    >
                      <div style={{ minWidth: "148px", maxWidth: "148px" }}>
                        {" "}
                        Admin Name{" "}
                      </div>
                      <div style={{ minWidth: "148px", maxWidth: "148px" }}>
                        {" "}
                        Email{" "}
                      </div>

                      <div
                        style={{
                          minWidth: "148px",
                          maxWidth: "148px",
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        Actions{" "}
                      </div>
                    </div>

                    {staff.length &&
                      staff.map((user: any, index: number) => (
                        <>
                          <div
                            style={{
                              fontSize: "12px",
                              fontWeight: "700",
                              color: "#344054",
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "20px",
                              minWidth: 800,
                              borderBottom: "1px solid #aaa",
                              paddingBottom: "8px",
                            }}
                          >
                            <div
                              style={{
                                minWidth: "148px",
                                maxWidth: "148px",
                                fontWeight: "400",
                                fontSize: "12px",
                                lineHeight: "12.67px",
                                color: "#5937CC",
                              }}
                            >
                              {" "}
                              {user.first_name} {user.last_name}
                            </div>

                            <div
                              style={{
                                minWidth: "148px",
                                maxWidth: "148px",
                                fontWeight: "400",
                                fontSize: "12px",
                                lineHeight: "12.67px",
                              }}
                            >
                              {" "}
                              {user.email}
                            </div>

                            <div
                              style={{
                                minWidth: "148px",
                                maxWidth: "148px",
                                fontWeight: "400",
                                fontSize: "12px",
                                lineHeight: "12.67px",
                                display: "flex",
                                justifyContent: "end",
                              }}
                            >
                              <Dropdown
                                menu={{ items: getItems(user.pk) }}
                                placement="bottomLeft"
                                trigger={["click"]}
                              >
                                <MenuIcon style={{ cursor: "pointer" }} />
                              </Dropdown>
                            </div>
                          </div>

                          {/* {index < staff.length - 1 && (
                          <hr style={{ marginTop: "20px" }} />
                        )} */}
                        </>
                      ))}
                  </div>
                </motion.section>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    marginTop: 16,
                    fontSize: 14,
                  }}
                >
                  <h1>You don't have this access.</h1>
                </div>
              )}
            </main>
          </motion.div>
        ) : (
          <div className="loader-container">
            <TailSpin
              height="160"
              width="160"
              color="#FBAD84"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{
                justifySelf: "center",
                alignSelf: "center",
              }}
              wrapperClass="tail-spin"
              visible={true}
            />
          </div>
        )}
      </AnimatePresence>
    </>
  );
};

export default MainDashboard;
