import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getAccessToken } from "../../utils/jwtToken";
import api from "../../utils/api";

export const getEmployerOnboardingQuestions = createAsyncThunk(
  "employer/getEmployerOnboardingQuestions",
  async (data, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.get(`${api}/employer/onboarding/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const updateEmployerProfile = createAsyncThunk(
  "talent/updateEmployerProfile",
  async (profilePayload: any, { rejectWithValue, dispatch }) => {
    const { userId } = profilePayload;
    try {
      const accessToken = getAccessToken();
      const response = await axios.post(
        `${api}/employer/profile/update/`,
        profilePayload.payload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        },
      );
      // return response.data;
      dispatch(getEmployerPublicProfile(userId));
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const getEmployerLogoPic = createAsyncThunk(
  "talent/getEmployerLogoPic",
  async (id: string | undefined, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.get(
        `${api}/employer/file/logo/?employer_id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const postEmployerOnboardingQuestions = createAsyncThunk(
  "employer/postEmployerOnboardingQuestions",
  async (data: any, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.post(`${api}/employer/onboarding/`, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const getEmployerDashboard = createAsyncThunk(
  "employer/getEmployerDashboard",
  async (_, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.get(`${api}/employer/dashboard/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const getEmployerRecommended = createAsyncThunk(
  "employer/getEmployerRecommended",
  async (_, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.get(`${api}/recommend/employer/talent`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const getEmployerPublicProfile = createAsyncThunk(
  "talent/getEmployerPublicProfile",
  async (id: string | undefined, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.get(
        `${api}/employer/profile/public?employer=${id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const getEmployerExplore = createAsyncThunk(
  "employer/getEmployerExplore",
  async (query: string, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.get(
        `${api}/recommend/search/talent${query}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const getEmployerReviews = createAsyncThunk(
  "employer/getEmployerReviews",
  async (id: string | undefined, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.get(
        `${api}/employer/reviews/?employer=${id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const getEmployerAnnualPlans = createAsyncThunk(
  "employer/getEmployerAnnualPlans",
  async () => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.get(`${api}/main/admin/get_annual_plans/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
    }
  },
);

////////////////// Employer Team Management  //////////////////

// Get Staff
export const getStaff = createAsyncThunk(
  "employer/getStaff",
  async (_, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.get(`${api}/employer/staff/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

// Create Staff (Post Request)
export const createStaff = createAsyncThunk(
  "employer/createStaff",
  async (data: any, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.post(`${api}/employer/staff/`, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

// Create Staff (Put Request)
export const createStaffPut = createAsyncThunk(
  "employer/createStaffPut",
  async (data: any, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.put(`${api}/employer/staff/`, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

// Delete Staff
export const deleteStaff = createAsyncThunk(
  "employer/deleteStaff",
  async (id: string, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.delete(`${api}/employer/staff/`, {
        data: { staff_id: id },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

// Get Job
export const getJob = createAsyncThunk(
  "employer/getJob",
  async (id: string, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.get(`${api}/job/${id}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

// Update Job
export const updateJob = createAsyncThunk(
  "employer/updateJob",
  async (data: any, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.put(
        `${api}/job/manage/?job_id=${data.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

const initialState = {
  value: 0,
  dashboard: null,
  profileInfo: null,
  dashboardRecommended: null,
  reviews: null,
  staff: null,
};

export const employerSlice = createSlice({
  name: "employer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getEmployerOnboardingQuestions.fulfilled,
        (state: any, action: any) => {
          // return action.payload;
        },
      )
      .addCase(
        getEmployerOnboardingQuestions.rejected,
        (state: any, action: any) => {
          // return action.payload;
        },
      );
    builder
      .addCase(
        postEmployerOnboardingQuestions.fulfilled,
        (state: any, action: any) => {
          return action.payload;
        },
      )
      .addCase(
        postEmployerOnboardingQuestions.rejected,
        (state: any, action: any) => {
          return action.payload;
        },
      );
    builder
      .addCase(
        getEmployerPublicProfile.fulfilled,
        (state: any, action: any) => {
          const { payload } = action;
          state.profileInfo = { ...(state.profileInfo ?? {}), ...payload };
        },
      )
      .addCase(getEmployerPublicProfile.rejected, (state: any, action: any) => {
        return action.payload;
      });

    builder
      .addCase(getEmployerDashboard.fulfilled, (state: any, action: any) => {
        state.dashboard = action.payload;
      })
      .addCase(getEmployerDashboard.rejected, (state: any, action: any) => {
        return action.payload;
      });

    builder
      .addCase(getEmployerRecommended.fulfilled, (state: any, action: any) => {
        state.dashboardRecommended = action.payload;
      })
      .addCase(getEmployerRecommended.rejected, (state: any, action: any) => {
        return action.payload;
      });

    builder
      .addCase(getEmployerLogoPic.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        state.profileInfo = { ...(state.profileInfo ?? {}), ...payload };
      })
      .addCase(getEmployerLogoPic.rejected, (state: any, action: any) => {
        state.profileInfo = {
          ...(state.profileInfo ?? {}),
          profilePicError: true,
        };
      });
    builder
      .addCase(getEmployerExplore.fulfilled, (state: any, action: any) => {
        state.dashboardRecommended = action.payload;
      })
      .addCase(getEmployerExplore.rejected, (state: any, action: any) => {
        return action.payload;
      });

    builder
      .addCase(getEmployerReviews.fulfilled, (state: any, action: any) => {
        state.reviews = action.payload;
      })
      .addCase(getEmployerReviews.rejected, (state: any, action: any) => {
        return action.payload;
      });

    builder.addCase(getStaff.fulfilled, (state, action) => {
      state.staff = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
// eslint-disable-next-line no-empty-pattern
export const {} = employerSlice.actions;

export default employerSlice.reducer;
