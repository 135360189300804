import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import { Pie } from "@ant-design/charts";
import { ReactComponent as MenuIcon } from "../../assets/icons/menuIcon.svg";

import { TailSpin } from "react-loader-spinner";

import "./EmployerDashboard.scss";
import DashboardNavbar from "../../components/DashboardNavbar/DashboardNavbar";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  InformationCircleIcon,
  OpenRolesEmployer,
  PendingReviewEmployer,
  TimeToHireEmployer,
  TotalApplicantsEmployer,
} from "../../assets/icons";
import { EmployerAnalyticCard } from "../../components";
import { AppDispatch } from "../../redux/store/store";
import { getEmployerDashboard } from "../../redux/EmployerSlice/EmployerSlice";
import { ReactComponent as EditIcon } from "../../assets/icons/editIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/deleteIcon.svg";
import { Tag, Dropdown, message, Tooltip, Popover } from "antd";
import axios from "axios";
import { getAccessToken } from "../../utils/jwtToken";
import api from "../../utils/api";

const EmployerDashboard: FC = () => {
  // States
  const [loading, setLoading] = useState<boolean>(true);

  // Dispatch
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();
  // Selectors
  const profileInfo = useSelector((state: any) => state.profile.profileInfo);
  const employerDashboard = useSelector(
    (state: any) => state.employer.dashboard,
  );

  const statusColor: any = {
    active: "#31BE00",
    open: "#31BE00",
    paused: "#FFA800",
    closed: "#EE1D52",
  };

  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 700);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 700);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // // LifeCylce events
  useEffect(() => {
    setLoading(true);

    if (profileInfo?.first_name && employerDashboard?.job_statistics) {
      setLoading(false);
    }
  }, [profileInfo, employerDashboard]);


  // useEffect(() => {
  //   dispatch(getEmployerDashboard());
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const prepareStats = (statsKey: string) => {
    let stats =
      employerDashboard?.job_statistics?.applicant_statistics?.[statsKey] || {};
    const totalSum: any = Object.values(stats ?? {}).reduce(
      (total: any, current: any) => {
        return total + current;
      },
      0,
    );
    stats = Object.keys(stats).map((stat) => ({
      type: stat,
      value: stats[stat] || 0,
      percentage: Math.round(((stats[stat] || 0) / (totalSum || 1)) * 100),
    }));
    return stats;
  };

  const gender_statistics = prepareStats("gender_statistics");
  const ethnicity_statistics = prepareStats("ethnicity_statistics");
  const state_statistics = prepareStats("state_statistics");

  const config = {
    appendPadding: 10,
    legend: false as const,
    angleField: "value",
    colorField: "type",
    radius: 0.7,
    innerRadius: 0.78,
    label: {
      type: "outer",
      // offset: "-50%",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 14,
      },
    },
    interactions: [{ type: "element-selected" }, { type: "element-active" }],
    statistic: {
      title: false as const,

      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        formatter: function formatter() {
          return "";
        },
      },
    },
  };
  const jobAnalytics =
    employerDashboard?.job_statistics?.open_roles?.analytics || [];

  const items: any = [
    {
      key: "1",
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <EditIcon style={{ marginRight: "10px" }} /> Edit
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <DeleteIcon style={{ marginRight: "10px" }} /> Delete{" "}
        </div>
      ),
    },
  ];

  const handleDeleteJob = (jobId: string) => {
    setLoading(true);
    const accessToken = getAccessToken();
    axios
      .delete(`${api}/job/manage/?job_id=${jobId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(function (response) {
        message.success("Job successfuly deleted");
        dispatch(getEmployerDashboard());
        setLoading(false);
      })
      .catch(function (error) {
        message.error("Job deletion unsuccesful");
        setLoading(false); // Handle any errors
        console.error("Error:", error);
      });
  };

  const onClickMenuItemHandler = (key: any, jobId: any) => {
    if (key === "1") {
      navigate(`/post/job?edit=${jobId}`);
    }
    if (key === "2") {
      handleDeleteJob(jobId);
    }
  };

  return (
    <AnimatePresence>
      {!loading ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="employer__dashboard-box"
        >
          <DashboardNavbar addLogo={isMobileView} />
          <main className="employer__dashboard">
            <motion.div
              key={"dashboard-heading"}
              transition={{ duration: 0.3, delay: 0.3 }}
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              className="employer__dashboard-heading"
            >
              <h1>Welcome {profileInfo?.first_name}</h1>
              {employerDashboard?.subscription_status !== null ? (
                <button
                  onClick={() => {
                    navigate("/post/job");
                  }}
                >
                  Post Job
                </button>
              ) : (
                <Popover
                  placement="right"
                  content={
                    <p>
                      This is a premium feature, see our plans{" "}
                      <Link to="/billing">here.</Link>
                    </p>
                  }
                  trigger={["hover", "focus"]}
                >
                  <button>Post Job</button>
                </Popover>
              )}
            </motion.div>
            <motion.section
              transition={{ duration: 0.3, delay: 0.4 }}
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              className="employer__dashboard-container"
            >
              <div className="employer__dashboard-container-title">
                My profile
              </div>
              <div className="employer__dashboard-container-analytics">
                <EmployerAnalyticCard
                  icon={<TotalApplicantsEmployer />}
                  title="Total Applicants"
                  content={employerDashboard.job_statistics?.total_applicants}
                  detail=""
                />
                <EmployerAnalyticCard
                  icon={<PendingReviewEmployer />}
                  title="Pending Review"
                  content={employerDashboard.job_statistics?.pending_reviews}
                  detail=""
                />
                <EmployerAnalyticCard
                  icon={<OpenRolesEmployer />}
                  title="Open Roles"
                  content={
                    employerDashboard?.job_statistics?.open_roles.open_roles
                  }
                  detail=""
                />
                <EmployerAnalyticCard
                  icon={<TimeToHireEmployer />}
                  title="Avg. Time to Hire"
                  content={
                    employerDashboard?.job_statistics?.avg_time_to_hire ?? 0
                  }
                  detail=""
                />
              </div>
              <div className="employer__dashboard-container-score">
                <div className="your__score score">
                  <div>
                    <span>
                      Your Score
                      <Tooltip
                        rootClassName="tooltip"
                        placement="top"
                        title={"Your benchmark score"}
                      >
                        <InformationCircleIcon />
                      </Tooltip>
                    </span>
                    <span>
                      {Math.round(employerDashboard.benchmark_score)}%
                    </span>
                  </div>
                  <main>
                    <span
                      style={{
                        left: `${employerDashboard.benchmark_score - 1}%`,
                      }}
                    ></span>
                    <section
                      style={{
                        width: `${employerDashboard.benchmark_score || 0}%`,
                      }}
                    ></section>
                  </main>
                </div>
                {employerDashboard?.subscription_status !== null && (
                  <div className="tribaja__score score">
                    <div>
                      <span>
                        Tribaja Score
                        <Tooltip
                          rootClassName="tooltip"
                          placement="top"
                          title={"Inclusion benchmark score"}
                        >
                          <InformationCircleIcon />
                        </Tooltip>
                      </span>
                      <span>
                        {Math.round(employerDashboard.tribaja_score)}%
                      </span>
                    </div>
                    <main>
                      <span
                        style={{
                          left: `${employerDashboard.tribaja_score - 1}%`,
                        }}
                      ></span>
                      <section
                        style={{
                          width: `${employerDashboard.tribaja_score || 0}%`,
                        }}
                      ></section>
                    </main>
                  </div>
                )}
              </div>
              {employerDashboard?.subscription_status !== null && (
                <>
                  {" "}
                  <div
                    className="employer__dashboard-container-title"
                    style={{ marginTop: "80px" }}
                  >
                    Job Applications stats by:
                  </div>
                  <div className="pie-chart-section">
                    {gender_statistics?.length > 0 && (
                      <div className="pie-chart-box">
                        <div
                          style={{
                            display: "flex",
                            color: "#1E1E2F",
                            fontWeight: 700,
                            fontSize: "16px",
                            paddingLeft: "20px",
                            marginTop: "20px",
                          }}
                        >
                          {" "}
                          Gender{" "}
                        </div>
                        <Pie
                          style={{ height: "300px" }}
                          {...config}
                          data={gender_statistics}
                        />

                        <div className="pie-chart-label-section">
                          <div className="pie-chart-headers">
                            <div
                              style={{
                                maxWidth: "88px",
                                // overflowX: "scroll",
                                minWidth: "88px",
                              }}
                            >
                              Type
                            </div>
                            <div>Number</div>
                            <div>%</div>
                          </div>

                          {gender_statistics?.map((gen: any) => (
                            <div
                              style={{
                                display: "flex",
                                paddingInline: "8px",
                                justifyContent: "space-between",
                                gap: "10px",
                              }}
                            >
                              <div
                                style={{
                                  minWidth: "88px",
                                  overflowX: "auto",
                                  // maxWidth: "88px",
                                  color: "#344054",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                }}
                              >
                                {gen.type}
                              </div>
                              <div
                                style={{
                                  color: "#344054",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  minWidth: "14px",
                                }}
                              >
                                {gen.value}
                              </div>
                              <div
                                style={{
                                  color: "#344054",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  minWidth: "14px",
                                }}
                              >
                                {gen.percentage}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    {ethnicity_statistics?.length > 0 && (
                      <div className="pie-chart-box">
                        <div
                          style={{
                            display: "flex",
                            color: "#1E1E2F",
                            fontWeight: 700,
                            fontSize: "16px",
                            paddingLeft: "20px",
                            marginTop: "20px",
                          }}
                        >
                          {" "}
                          Ethnicity{" "}
                        </div>
                        <Pie
                          style={{ height: "300px" }}
                          {...config}
                          data={ethnicity_statistics}
                        />
                        <div className="pie-chart-label-section">
                          <div className="pie-chart-headers">
                            <div
                              style={{
                                maxWidth: "88px",
                                // overflowX: "scroll",
                                minWidth: "88px",
                              }}
                            >
                              Type
                            </div>
                            <div>Number</div>
                            <div>%</div>
                          </div>

                          {ethnicity_statistics?.map((gen: any) => (
                            <div
                              style={{
                                display: "flex",
                                paddingInline: "8px",
                                justifyContent: "space-between",
                                gap: "10px",
                              }}
                            >
                              <div
                                style={{
                                  minWidth: "88px",
                                  // overflowX: "scroll",
                                  maxWidth: "88px",
                                  color: "#344054",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                }}
                              >
                                {gen.type}
                              </div>
                              <div
                                style={{
                                  color: "#344054",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  minWidth: "14px",
                                }}
                              >
                                {gen.value}
                              </div>
                              <div
                                style={{
                                  color: "#344054",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  minWidth: "14px",
                                }}
                              >
                                {gen.percentage}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    {state_statistics?.length > 0 && (
                      <div className="pie-chart-box">
                        <div
                          style={{
                            display: "flex",
                            color: "#1E1E2F",
                            fontWeight: 700,
                            fontSize: "16px",
                            paddingLeft: "20px",
                            marginTop: "20px",
                          }}
                        >
                          {" "}
                          Geographical Location{" "}
                        </div>
                        <Pie
                          style={{ height: "300px" }}
                          {...config}
                          data={state_statistics}
                        />
                        <div className="pie-chart-label-section">
                          <div className="pie-chart-headers">
                            <div
                              style={{
                                maxWidth: "88px",
                                // overflowX: "scroll",
                                minWidth: "88px",
                              }}
                            >
                              Type
                            </div>
                            <div>Number</div>
                            <div>%</div>
                          </div>

                          {state_statistics?.map((gen: any) => (
                            <div
                              style={{
                                display: "flex",
                                paddingInline: "8px",
                                justifyContent: "space-between",
                                gap: "10px",
                              }}
                            >
                              <div
                                style={{
                                  minWidth: "88px",
                                  // overflowX: "scroll",
                                  maxWidth: "88px",
                                  color: "#344054",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                }}
                              >
                                {gen.type}
                              </div>
                              <div
                                style={{
                                  color: "#344054",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  minWidth: "14px",
                                }}
                              >
                                {gen.value}
                              </div>
                              <div
                                style={{
                                  color: "#344054",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  minWidth: "14px",
                                }}
                              >
                                {gen.percentage}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                  {jobAnalytics?.length >= 0 && (
                    <div className="employer-dashboard-activities">
                      <div
                        className="main__dashboard-recent-activities"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          overflow: "auto",
                          maxHeight: "600px",
                          // marginTop: "80px",
                          // minWidth: '100%',
                        }}
                      >
                        <div
                          style={{
                            fontWeight: 700,
                            fontSize: "16px",
                            marginBottom: "20px",
                          }}
                        >
                          Job Analytics{" "}
                        </div>
                        {/* Header First Row */}
                        <div
                          style={{
                            fontSize: "13px",
                            fontWeight: "700",
                            color: "#344054",
                            display: "flex",
                            justifyContent: "space-between",
                            minWidth: 800,
                            borderBottom: "1px solid #aaa",
                            paddingBottom: "8px",
                          }}
                        >
                          <div style={{ minWidth: "148px", maxWidth: "148px" }}>
                            {" "}
                            Title{" "}
                          </div>
                          <div style={{ minWidth: "148px", maxWidth: "148px" }}>
                            {" "}
                            Location{" "}
                          </div>
                          <div style={{ minWidth: "148px", maxWidth: "148px" }}>
                            {" "}
                            Date Posted{" "}
                          </div>

                          <div style={{ minWidth: "148px", maxWidth: "148px" }}>
                            {" "}
                            Status{" "}
                          </div>

                          <div style={{ minWidth: "164px", maxWidth: "148px" }}>
                            {" "}
                            Applications{" "}
                          </div>
                        </div>

                        {jobAnalytics?.length === 0 && (
                          <div
                            style={{
                              height: "100px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            No jobs posted yet !
                          </div>
                        )}

                        {jobAnalytics.map(
                          (
                            { job, number_of_applicants }: any,
                            index: number,
                          ) => (
                            <>
                              <div
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "700",
                                  color: "#344054",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginTop: "20px",
                                  minWidth: 800,
                                  borderBottom: "1px solid #aaa",
                                  paddingBottom: "8px",
                                }}
                              >
                                <div
                                  style={{
                                    minWidth: "148px",
                                    maxWidth: "148px",
                                    fontWeight: "400",
                                    fontSize: "12px",
                                    lineHeight: "12.67px",
                                    color: "#5937CC",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    job?.id && navigate(`/job/${job?.id}`)
                                  }
                                >
                                  {" "}
                                  {job.title}{" "}
                                </div>
                                <div
                                  style={{
                                    minWidth: "148px",
                                    maxWidth: "148px",
                                    fontWeight: "400",
                                    fontSize: "12px",
                                    lineHeight: "12.67px",
                                  }}
                                >
                                  {" "}
                                  {job.location || "N/A"}
                                </div>
                                <div
                                  style={{
                                    minWidth: "148px",
                                    maxWidth: "148px",
                                    fontWeight: "400",
                                    fontSize: "12px",
                                    lineHeight: "12.67px",
                                  }}
                                >
                                  {" "}
                                  {job.date_posted || "N/A"}{" "}
                                </div>
                                <div
                                  style={{
                                    minWidth: "148px",
                                    maxWidth: "148px",
                                  }}
                                >
                                  {" "}
                                  <Tag
                                    color={
                                      statusColor[job.status?.toLowerCase()]
                                    }
                                    style={{
                                      width: "62px",
                                      height: "22px",
                                      borderRadius: "22px",
                                      color: "#FAF9FF",
                                      fontSize: "10px",
                                      fontWeight: "400",
                                      textAlign: "center",
                                    }}
                                  >
                                    {job.status}{" "}
                                  </Tag>
                                </div>
                                <div style={{ display: "flex" }}>
                                  <div
                                    style={{
                                      minWidth: "148px",
                                      maxWidth: "148px",
                                      fontWeight: "400",
                                      fontSize: "12px",
                                      lineHeight: "12.67px",
                                    }}
                                  >
                                    {" "}
                                    {number_of_applicants || "N/A"}{" "}
                                  </div>
                                  <div>
                                    <Dropdown
                                      menu={{
                                        items,
                                        onClick: ({ key }) =>
                                          onClickMenuItemHandler(key, job?.id),
                                      }}
                                      placement="bottomLeft"
                                      trigger={["click"]}
                                    >
                                      <MenuIcon style={{ cursor: "pointer" }} />
                                    </Dropdown>
                                  </div>
                                </div>
                              </div>

                              {/* {index < jobAnalytics.length - 1 && (
                          <hr style={{ marginTop: "20px" }} />
                        )} */}
                            </>
                          ),
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
            </motion.section>
          </main>
        </motion.div>
      ) : (
        <TailSpin
          height="160"
          width="160"
          color="#FBAD84"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{
            justifySelf: "center",
            alignSelf: "center",
          }}
          wrapperClass="tail-spin"
          visible={true}
        />
      )}
    </AnimatePresence>
  );
};

export default EmployerDashboard;
