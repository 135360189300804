// import React, { FC, useState, useRef } from "react";
// import { Select } from "antd";

// import ModalFormComponents from "./Forms";

// import "./EditProfileModal.scss";

// import type { TabsProps } from "antd";
// import { Tabs } from "antd";
// import { FormButton } from "../../../components";

// const Option = Select.Option;

// const CrossSvg = () => (
//   <svg
//     width="24"
//     height="24"
//     viewBox="0 0 24 24"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <path
//       d="M9.17188 14.8299L14.8319 9.16992"
//       stroke="#344054"
//       stroke-width="1.5"
//       stroke-linecap="round"
//       stroke-linejoin="round"
//     />
//     <path
//       d="M14.8319 14.8299L9.17188 9.16992"
//       stroke="#344054"
//       stroke-width="1.5"
//       stroke-linecap="round"
//       stroke-linejoin="round"
//     />
//     <path
//       d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
//       stroke="#344054"
//       stroke-width="1.5"
//       stroke-linecap="round"
//       stroke-linejoin="round"
//     />
//   </svg>
// );

// interface PasswordHidden {
//   one: boolean;
//   two: boolean;
// }

// type Props = {
//   onModalClose: () => void;
//   formModalType: "EMPLOYEE_FORM" | "TALENT_FORM" | "PARTNER_FORM";
//   handleDeleteAccount?: () => void;
//   modalActiveTabTalent?: string;
//   modalActiveTabEmployer?: string;
//   modalActiveTabPartner?: string;
// };

// const EditProfileModal: FC<Props> = (props) => {
//   const {
//     formModalType,
//     onModalClose,
//     handleDeleteAccount,
//     modalActiveTabTalent,
//     modalActiveTabEmployer,
//     modalActiveTabPartner,
//   } = props;

//   const items: TabsProps["items"] = [
//     {
//       key: "personal-info",
//       label: "Personal Info",
//       children: (
//         <ModalFormComponents.PersonalProfileForm
//           handleDeleteAccount={handleDeleteAccount}
//           onModalClose={onModalClose}
//         />
//       ),
//       style: { fontFamily: "Space Grotesk" },
//     },
//     {
//       key: "exp-edu",
//       label: "Experience & Education",
//       children: (
//         <ModalFormComponents.ExperienceEducationForm
//           onModalClose={onModalClose}
//         />
//       ),
//       style: { fontFamily: "Space Grotesk" },
//     },

//     {
//       key: "links",
//       label: "Links",
//       children: (
//         <ModalFormComponents.ProfileLinks onModalClose={onModalClose} />
//       ),
//       style: { fontFamily: "Space Grotesk" },
//     },
//   ];
//   const itemsPartnerForm: TabsProps["items"] = [
//     {
//       key: "company-info",
//       label: "Company Info",
//       children: (
//         <ModalFormComponents.PartnerCompanyProfile
//           onModalClose={onModalClose}
//         />
//       ),
//       style: { fontFamily: "Space Grotesk" },
//     },
//   ];

//   const itemsEmployerForm: TabsProps["items"] = [
//     {
//       key: "company-info",
//       label: "Company Info",
//       children: (
//         <ModalFormComponents.CompanyProfileForm
//           handleDeleteAccount={handleDeleteAccount}
//           onModalClose={onModalClose}
//         />
//       ),
//       style: { fontFamily: "Space Grotesk" },
//     },
//     {
//       key: "exp-edu",
//       label: "Experience & Education",
//       children: (
//         <ModalFormComponents.CompanyExperienceEducationForm
//           onModalClose={onModalClose}
//         />
//       ),
//       style: { fontFamily: "Space Grotesk" },
//     },

//     {
//       key: "links",
//       label: "Links",
//       children: (
//         <ModalFormComponents.CompanyProfileLinks onModalClose={onModalClose} />
//       ),
//       style: { fontFamily: "Space Grotesk" },
//     },
//   ];

//   const modalRef = useRef<HTMLDivElement>(null);

//   const handleOverlayClick = (
//     event: React.MouseEvent<HTMLDivElement, MouseEvent>
//   ) => {
//     if (event.currentTarget === event.target) {
//       onModalClose();
//     }
//   };

//   return (
//     <div className="overlay" onClick={handleOverlayClick}>
//       <div className="modal" ref={modalRef}>
//         <div className="editProfile__container">
//           <div style={{ display: "flex", justifyContent: "space-between" }}>
//             <h2 style={{ textAlign: "left", marginLeft: "10px" }}>
//               Edit Profile
//             </h2>
//             <div
//               style={{ cursor: "pointer" }}
//               onClick={() => {
//                 props.onModalClose();
//               }}
//             >
//               <CrossSvg />
//             </div>
//           </div>
//           <Tabs
//             id="tabs"
//             tabBarGutter={20}
//             defaultActiveKey={
//               formModalType === "TALENT_FORM"
//                 ? modalActiveTabTalent
//                 : modalActiveTabEmployer
//             }
//             items={formModalType === "TALENT_FORM" ? items : itemsEmployerForm}
//             onChange={() => {}}
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default EditProfileModal;
import React, { FC, useState, useRef } from "react";
import { Select } from "antd";

import ModalFormComponents from "./Forms";

import "./EditProfileModal.scss";

import type { TabsProps } from "antd";
import { Tabs } from "antd";
import { FormButton } from "../../../components";

const Option = Select.Option;

const CrossSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.17188 14.8299L14.8319 9.16992"
      stroke="#344054"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.8319 14.8299L9.17188 9.16992"
      stroke="#344054"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
      stroke="#344054"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

interface PasswordHidden {
  one: boolean;
  two: boolean;
}

type Props = {
  onModalClose: () => void;
  formModalType: "EMPLOYEE_FORM" | "TALENT_FORM" | "PARTNER_FORM";
  handleDeleteAccount?: () => void;
  modalActiveTabTalent?: string;
  modalActiveTabEmployer?: string;
  modalActiveTabPartner?: string;
  setUpdatePartnerProfile?: (data: any) => void;
};

const EditProfileModal: FC<Props> = (props) => {
  const {
    formModalType,
    onModalClose,
    handleDeleteAccount,
    modalActiveTabTalent,
    modalActiveTabEmployer,
    modalActiveTabPartner,
    setUpdatePartnerProfile,
  } = props;

  const items: TabsProps["items"] = [
    {
      key: "personal-info",
      label: "Personal Info",
      children: (
        <ModalFormComponents.PersonalProfileForm
          handleDeleteAccount={handleDeleteAccount}
          onModalClose={onModalClose}
        />
      ),
      style: { fontFamily: "Space Grotesk" },
    },
    {
      key: "exp-edu",
      label: "Experience & Education",
      children: (
        <ModalFormComponents.ExperienceEducationForm
          onModalClose={onModalClose}
        />
      ),
      style: { fontFamily: "Space Grotesk" },
    },
    {
      key: "links",
      label: "Links",
      children: (
        <ModalFormComponents.ProfileLinks onModalClose={onModalClose} />
      ),
      style: { fontFamily: "Space Grotesk" },
    },
  ];

  const itemsPartnerForm: TabsProps["items"] = [
    {
      key: "company-info",
      label: "Partner Info",
      children: (
        <ModalFormComponents.PartnerCompanyProfile
          onModalClose={onModalClose}
          setUpdatePartnerProfile={setUpdatePartnerProfile}
        />
      ),
      style: { fontFamily: "Space Grotesk" },
    },
  ];

  const itemsEmployerForm: TabsProps["items"] = [
    {
      key: "company-info",
      label: "Company Info",
      children: (
        <ModalFormComponents.CompanyProfileForm
          handleDeleteAccount={handleDeleteAccount}
          onModalClose={onModalClose}
        />
      ),
      style: { fontFamily: "Space Grotesk" },
    },
    {
      key: "exp-edu",
      label: "Experience & Education",
      children: (
        <ModalFormComponents.CompanyExperienceEducationForm
          onModalClose={onModalClose}
        />
      ),
      style: { fontFamily: "Space Grotesk" },
    },
    {
      key: "links",
      label: "Links",
      children: (
        <ModalFormComponents.CompanyProfileLinks onModalClose={onModalClose} />
      ),
      style: { fontFamily: "Space Grotesk" },
    },
  ];

  const modalRef = useRef<HTMLDivElement>(null);

  const handleOverlayClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (event.currentTarget === event.target) {
      onModalClose();
    }
  };

  return (
    <div className="overlay" onClick={handleOverlayClick}>
      <div className="modal" ref={modalRef}>
        <div className="editProfile__container">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h2 style={{ textAlign: "left", marginLeft: "10px" }}>
              Edit Profile
            </h2>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.onModalClose();
              }}
            >
              <CrossSvg />
            </div>
          </div>
          <Tabs
            id="tabs"
            tabBarGutter={20}
            defaultActiveKey={
              formModalType === "TALENT_FORM"
                ? modalActiveTabTalent
                : formModalType === "EMPLOYEE_FORM"
                ? modalActiveTabEmployer
                : modalActiveTabPartner
            }
            items={
              formModalType === "TALENT_FORM"
                ? items
                : formModalType === "EMPLOYEE_FORM"
                ? itemsEmployerForm
                : itemsPartnerForm
            }
            onChange={() => {}}
          />
        </div>
      </div>
    </div>
  );
};

export default EditProfileModal;
