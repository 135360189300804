import React, {useEffect} from "react";

import "./RequestModalTalent.scss";
import {BecomeCoach, RequestModal} from '../../assets/icons';
import { CloseSquare } from "../../assets/icons";
import {backdropOverFlowHide} from "../../utils/backdropOverFlowHide";
import {TailSpin} from "react-loader-spinner";


type Props = {
    requestToBecomeCoach: () => void;
    isRequestSent:boolean;
    handleRequestModal: (e:any)=>void;
    loader:boolean
}
const ReasonForRequestModalTalent = (props:Props) => {

    const {requestToBecomeCoach,isRequestSent,handleRequestModal,loader} = props

    useEffect(() => {
        backdropOverFlowHide();
        return () =>{
            document.body.style.overflow = '';
        }
    }, []);
 
  return (
    <div
      onClick={(e) => {
        if (e.currentTarget === e.target) {
         
        }
      }}
      className="requestModalTalent__container"
    >


          {loader?    <TailSpin
              height="160"
              width="160"
              color="#FBAD84"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{
                  justifySelf: "center",
                  alignSelf: "center",
              }}
              wrapperClass="profile-tail-spin"
              visible={true}
          />: (
              <>
                  <div className="requestModalTalent">
              <CloseSquare onClick={handleRequestModal} className="requestModalTalent_closeSquareLogo"/>
              {!isRequestSent?<BecomeCoach/>:<RequestModal/>}
              <h1>{!isRequestSent? "You are eligible to become a coach": "Your Request is on Its Way!"}</h1>
              {isRequestSent && <p>Thank you for your Coach request. We will review your request and email you next step in the next 5 to 7 working days.</p>}
              {!isRequestSent? <button onClick={requestToBecomeCoach}>Submit</button>:<button onClick={handleRequestModal} >Done</button>}
          </div>
          </>)}



    </div>
  );
};

export default ReasonForRequestModalTalent;