import React from "react";
import { FC, ReactNode, CSSProperties } from "react";
import "./Button.scss";

interface ButtonProps {
  children: ReactNode;
  type?: "INVERTED" | "PRIMARY";
  style?: CSSProperties;
  className?: string;
  backgoundColor?: string;
}

const Button: FC<ButtonProps> = ({
  children,
  type = "PRIMARY",
  style,
  className,
  backgoundColor,
}) => {
  const updatedStyle: CSSProperties = { ...style };

  if (backgoundColor) {
    updatedStyle.backgroundColor = backgoundColor;
  }
  return (
    <button
      className={`${type === "PRIMARY" ? "btn-primary" : "btn-inverted"} ${
        className ? className : ""
      }`}
      style={updatedStyle}
    >
      {children}
    </button>
  );
};

export default Button;
