import { Dropdown, MenuProps, Pagination, Select, Tag } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../redux/store/store";
import { Delete } from "../../../../assets/icons";
import { AnimatePresence, motion } from "framer-motion";
import { AdminNavBar } from "../../../../components";
import SearchBar from "../../../../components/DashboardSearchbar/SearchBar";
import { ReactComponent as MenuIcon } from "../../../../assets/icons/menuIcon.svg";
import { TailSpin } from "react-loader-spinner";
import {
  changeCoachStatus,
  deleteCoach,
  getAllCoaches,
  getCoaches,
} from "../../../../redux/AdminSlice/AdminSlice";
import DashboardNavbar from "../../../../components/DashboardNavbar/DashboardNavbar";
import { debounce } from "debounce";

const Option = Select.Option;
const CoachesManagement = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [allCoaches, setAllCoaches] = useState<any>(false);
  const [search, setSearch] = useState("");

  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 700);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 700);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const dispatch = useDispatch<AppDispatch>();

  const { coaches } = useSelector((state: any) => state.admin);

  const tableRef = React.useRef<HTMLDivElement>(null);

  // LifeCylce events
  useEffect(() => {
    dispatch(getCoaches(page)).then(() => {
      setLoading(false);
    });
  }, [page]);

  const getItems = (id: string): MenuProps["items"] => [
    {
      icon: <Delete />,
      key: "1",
      label: "Delete User?",
      onClick: () => {
        dispatch(deleteCoach(id)).then(() => {
          dispatch(getAllCoaches({ page, search }));
        });
      },
    },
  ];

  useEffect(() => {
    if (allCoaches) {
      setPage(1);
      dispatch(getAllCoaches({ page, search })).then(() => {
        setLoading(false);
      });
    } else {
      setPage(1);
      dispatch(getCoaches(1)).then(() => {
        setLoading(false);
      });
    }
  }, [allCoaches]);

  const statusColor: any = {
    accepted: "#31BE00",
    pending: "#FFA800",
    rejected: "#EE1D52",
  };

  const debouncedDispatch = useCallback(
    debounce((searchValue: any) => {
      dispatch(getAllCoaches({ page, search: searchValue }));
    }, 800),
    [dispatch, page], // Dependencies  const debouncedDispatch = useCallback( // Dependencies
  );

  return (
    <AnimatePresence>
      {!loading ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="main__dashboard-box"
        >
          <DashboardNavbar addLogo={isMobileView} />{" "}
          <main className="main__dashboard">
            <motion.div
              key={"dashboard-heading"}
              transition={{ duration: 0.3, delay: 0.3 }}
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              className="main__dashboard-heading"
            >
              <h1>Coaches Management</h1>
            </motion.div>
            <motion.section
              transition={{ duration: 0.3, delay: 0.4 }}
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              className="main__dashboard-container"
            >
              {/* Search Section */}
              <div
                id="main__analytics--admin"
                className="main__dashboard-container-analytics--admin"
              >
                <div
                  className="main__dashboard-container-analytics"
                  style={{ marginTop: "20px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "70%",
                    }}
                    className="searchSection"
                  >
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        color: "#1E1E2F",
                        marginBottom: "10px",
                      }}
                    >
                      Search
                    </div>
                    <SearchBar
                      debouncedDispatch={debouncedDispatch}
                      disabled={!allCoaches}
                      value={search}
                      page={page}
                      setSearch={setSearch}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "20%",
                    }}
                    className="filterSection"
                  >
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        color: "#1E1E2F",
                        marginBottom: "10px",
                      }}
                    >
                      {" "}
                      Filter{" "}
                    </div>
                    <Select
                      optionFilterProp="label"
                      showSearch={true}
                      onChange={(e) =>
                        e === "Pending"
                          ? setAllCoaches(false)
                          : setAllCoaches(true)
                      }
                      className="select"
                      placeholder="Select Status"
                      defaultValue="Pending"
                    >
                      {["Pending", "All"].map((state) => (
                        <Option
                          label={state}
                          value={state}
                          className="roleType__option"
                        >
                          {state}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
              <div className="main__dashboard-recent-activities--admin">
                <div
                  className="main__dashboard-recent-activities"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    overflow: "auto",
                    maxHeight: "600px",
                    scrollBehavior: "smooth",
                  }}
                  ref={tableRef}
                >
                  {/* Header First Row */}
                  <div
                    style={{
                      fontSize: "13px",
                      fontWeight: "700",
                      color: "#344054",
                      display: "flex",
                      justifyContent: "space-between",
                      minWidth: 800,
                      borderBottom: "1px solid #aaa",
                      paddingBottom: "8px",
                    }}
                  >
                    <div style={{ minWidth: "148px", maxWidth: "148px" }}>
                      {" "}
                      Coach Name{" "}
                    </div>
                    <div style={{ minWidth: "148px", maxWidth: "148px" }}>
                      {" "}
                      Email{" "}
                    </div>
                    <div style={{ minWidth: "180px", maxWidth: "180px" }}>
                      {" "}
                      Profession{" "}
                    </div>

                    <div style={{ minWidth: "148px", maxWidth: "148px" }}>
                      {" "}
                      Availablity{" "}
                    </div>

                    <div style={{ minWidth: "164px", maxWidth: "148px" }}>
                      {" "}
                    </div>
                  </div>

                  {/* <hr style={{ marginTop: "8px" }} /> */}

                  {coaches.results.map((user: any, index: number) => (
                    <>
                      <div
                        style={{
                          fontSize: "12px",
                          fontWeight: "700",
                          color: "#344054",
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "20px",
                          minWidth: 800,
                        }}
                      >
                        <div
                          style={{
                            minWidth: "148px",
                            maxWidth: "148px",
                            fontWeight: "400",
                            fontSize: "12px",
                            lineHeight: "12.67px",
                            color: "#5937CC",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          {user?.talent?.name}
                        </div>
                        <div
                          style={{
                            minWidth: "148px",
                            maxWidth: "148px",
                            fontWeight: "400",
                            fontSize: "12px",
                            lineHeight: "12.67px",
                            alignItems: "center",
                            display: "flex",
                            textTransform: "capitalize",
                          }}
                        >
                          {" "}
                          {user?.talent?.email ?? "-"}
                        </div>
                        <div
                          style={{
                            minWidth: "180px",
                            maxWidth: "180px",
                            fontWeight: "400",
                            fontSize: "12px",
                            lineHeight: "12.67px",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          {" "}
                          {user?.talent?.profession ?? "-"}
                        </div>

                        <div
                          style={{
                            minWidth: "148px",
                            maxWidth: "148px",
                            fontWeight: "400",
                            fontSize: "12px",
                            lineHeight: "12.67px",
                            alignItems: "center",
                            textTransform: "capitalize",
                            overflow: "hidden",
                            display: "-webkit-box",
                            lineClamp: 3,
                            boxOrient: "vertical",
                          }}
                        >
                          {" "}
                          {user?.talent?.schedule.length
                            ? user?.talent?.schedule?.join(", ")
                            : "-"}
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              minWidth: "148px",
                              maxWidth: "148px",
                              display: "flex",
                              alignItems: "center",
                              gap: 16,
                              cursor: "pointer",
                            }}
                          >
                            {" "}
                            {!allCoaches ? (
                              <>
                                <Tag
                                  onClick={() =>
                                    dispatch(
                                      changeCoachStatus({
                                        coach_id: user.id,
                                        status: "rejected",
                                      }),
                                    ).then(() => dispatch(getCoaches(page)))
                                  }
                                  color={"#EE1D52"}
                                  style={{
                                    width: "62px",
                                    height: "22px",
                                    borderRadius: "22px",
                                    color: "#FAF9FF",
                                    fontSize: "10px",
                                    fontWeight: "400",
                                    textAlign: "center",
                                    cursor: "pointer",
                                  }}
                                >
                                  Reject
                                </Tag>
                                <Tag
                                  onClick={() =>
                                    dispatch(
                                      changeCoachStatus({
                                        coach_id: user.id,
                                        status: "accepted",
                                      }),
                                    ).then(() => dispatch(getCoaches(page)))
                                  }
                                  color={"#31BE00"}
                                  style={{
                                    width: "62px",
                                    height: "22px",
                                    borderRadius: "22px",
                                    color: "#FAF9FF",
                                    fontSize: "10px",
                                    fontWeight: "400",
                                    textAlign: "center",
                                    cursor: "pointer",
                                  }}
                                >
                                  Approve
                                </Tag>
                              </>
                            ) : (
                              <Tag
                                color={statusColor[user?.status]}
                                style={{
                                  width: "62px",
                                  height: "22px",
                                  borderRadius: "22px",
                                  color: "#FAF9FF",
                                  fontSize: "10px",
                                  fontWeight: "400",
                                  textAlign: "center",
                                  textTransform: "capitalize",
                                }}
                              >
                                {user?.status}
                              </Tag>
                            )}
                          </div>
                          {allCoaches && (
                            <div>
                              <Dropdown
                                menu={{ items: getItems(user.id) }}
                                placement="bottomLeft"
                                trigger={["click"]}
                              >
                                <MenuIcon style={{ cursor: "pointer" }} />
                              </Dropdown>
                            </div>
                          )}
                        </div>
                      </div>

                      {index < coaches.length - 1 && (
                        <hr style={{ marginTop: "20px" }} />
                      )}
                    </>
                  ))}
                </div>
              </div>
              <Pagination
                style={{
                  marginInline: "auto",
                  marginTop: "36px",
                }}
                current={coaches.page}
                total={coaches.count}
                pageSize={coaches.page_size}
                showSizeChanger={false}
                onChange={(e) => {
                  setPage(e);
                  if (tableRef.current) {
                    tableRef.current.scrollTo(0, 0);
                  }
                }}
              />
            </motion.section>
          </main>
        </motion.div>
      ) : (
        <div className="loader-container">
          <TailSpin
            height="160"
            width="160"
            color="#FBAD84"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{
              justifySelf: "center",
              alignSelf: "center",
            }}
            wrapperClass="tail-spin"
            visible={true}
          />
        </div>
      )}
    </AnimatePresence>
  );
};

export default CoachesManagement;
