import React, { FC, useEffect, useState } from "react";
import Slider from "react-slick";

import { AnimatePresence, motion } from "framer-motion";

import DashboardNavbar from "../../components/DashboardNavbar/DashboardNavbar";
import TechEventImage from "../../assets/images/largetTechEvent.png";

import "./EmployerSavedItems.scss";
import { Link } from "react-router-dom";
import { Premium, Bookmark } from "../../assets/icons";
import debounce from "debounce";

import SearchBar from "../../components/ExploreSearchBar/ExploreSearchBar";
import { getTalentSavedItems } from "../../redux/TalentSlice/TalentSlice";
import {
  RecommendedCompanies,
  RecommendedJob,
  ResourceCard,
  CoachCard,
  TalentCard,
} from "../../components";

import { useDispatch, useSelector } from "react-redux";
import { TailSpin } from "react-loader-spinner";
import { Select, Tag, message } from "antd";
import { AppDispatch } from "../../redux/store/store";
import Cookies from "js-cookie";
import axios from "axios";
import api from "../../utils/api";
import { getAccessToken } from "../../utils/jwtToken";

type Search = {
  searchName: string;
  searchFilters: Array<string>;
};

const Option = Select.Option;
const EmployerSavedItems: FC = () => {
  // States

  const dispatch = useDispatch<AppDispatch>();

  // Selectors

  const [savedTalents, setSavedTalent] = useState<[]>([]);
  const [loader, setLoader] = useState(false);

  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 700);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 700);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setLoader(true);
    axios
      .get(`${api}/employer/saved_data/`, {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      })
      .then((response) => {
        setLoader(false);
        setSavedTalent(response.data);
      })
      .catch((error) => {
        setLoader(false);
        console.error("Error sending request", error);
      });
  }, []);

  if (!Cookies.get("role")) {
    return null;
  }

  return (
    <AnimatePresence>
      {!loader ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="talentSaved__box"
        >
          <DashboardNavbar addLogo={isMobileView} />
          <main className="talentSaved">
            <motion.div className="talentSaved__heading">
              <h1>Saved Items</h1>
            </motion.div>
            <div
              className="main__dashboard-container-analytics"
              style={
                {
                  // marginTop: "20px",
                  // paddingBottom: "20px",
                  // borderBottom: "0.5px #999999 solid",
                }
              }
            >
              {/* Search Filters */}

              {/* <div style={{ display: "flex" }}>

                            {/* Save View Saved Searches */}
              <div style={{ display: "flex", cursor: "pointer" }}></div>
            </div>

            <motion.section
              className="talentSaved__container"
              // style={{ marginTop: "40px" }}
            >
              {(savedTalents ?? []).length > 0 ? (
                <div className="talentSaved__recommendations-container">
                  <div className="talentSaved__recommendations-container-title">
                    <div>Saved Talents</div>
                  </div>
                  <div className="talentSaved__recommendations-slider">
                    <Slider
                      dots={true}
                      slidesToShow={Math.min(savedTalents?.length, 3)}
                      slidesToScroll={1}
                      autoplay={true}
                      autoplaySpeed={3000}
                      arrows={false}
                      responsive={[
                        {
                          breakpoint: 1189, // Width less than or equal to 1024px
                          settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            infinite: true,
                            dots: true,
                          },
                        },
                        {
                          breakpoint: 938, // Width less than or equal to 1024px
                          settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            infinite: true,
                            dots: true,
                          },
                        },
                      ]}
                    >
                      {savedTalents?.map((talent: any) => {
                        return <TalentCard talent={talent} />;
                      })}
                    </Slider>
                  </div>
                </div>
              ) : null}
            </motion.section>
          </main>
        </motion.div>
      ) : (
        <TailSpin
          height="160"
          width="160"
          color="#FBAD84"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{
            justifySelf: "center",
            alignSelf: "center",
          }}
          wrapperClass="tail-spin"
          visible={true}
        />
      )}
    </AnimatePresence>
  );
};

export default EmployerSavedItems;
