import React, { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { ResourceCard } from "../../components";

import axios from "axios";
import api from "../../utils/api";
import { getAccessToken } from "../../utils/jwtToken";

import { TailSpin } from "react-loader-spinner";
import { PaginationComp } from "../../components/PaginationComp/PaginationComp";

const Resource = () => {
  const [resource, setResource] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const fetchResources = (page: number) => {
    setLoading(true);
    const accessToken = getAccessToken();
    axios
      .get(`${api}/main/list/resources?page=${page}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(function (response) {
        setLoading(false);
        setResource(response.data);
      })
      .catch(function (error) {
        setLoading(false);
        // Handle any errors
        console.error("Error:", error);
      });
  };

  const handlePagination = (p: any) => {
    fetchResources(p);
  };

  useEffect(() => {
    fetchResources(1);
  }, []);

  return (
    <AnimatePresence>
      {!loading ? (
        <motion.section className="viewAll__container">
          {resource?.results?.length > 0 && (
            <div className="viewAll__recommendations-container">
              <div className="viewAll__recommendations-container-card">
                {resource?.results?.map((resource: any) => {
                  return <ResourceCard resource={resource} />;
                })}
              </div>
            </div>
          )}
          <PaginationComp
            count={resource?.count}
            page={resource?.page}
            onChange={handlePagination}
          />
        </motion.section>
      ) : (
        <TailSpin
          height="160"
          width="160"
          color="#FBAD84"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{
            justifySelf: "center",
            alignSelf: "center",
          }}
          wrapperClass="tail-spin"
          visible={true}
        />
      )}
    </AnimatePresence>
  );
};

export default Resource;
