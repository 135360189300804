import React, { useEffect, useState } from "react";
import { CheckItemIcon, PricingIcon } from "../../assets/icons";
import "./EmployerBillingCard.scss";
import { useNavigate, useNavigation } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import api from "../../utils/api";
import { getAccessToken } from "../../utils/jwtToken";
import { message } from "antd";
import { TailSpin } from "react-loader-spinner";

interface EmployerBillingCardProps {
  backgroundColor?: string;
  planName: string;
  price?: string;
  description: string;
  buttonText: string;
  subscriptionStatus: string | null;
  planId: number;
  subscriptionPlan: any;
  gridColumnStart?: number | string;
}

const EmployerBillingCard: React.FC<EmployerBillingCardProps> = ({
  backgroundColor = "#fff",
  planName,
  price,
  description,
  buttonText,
  subscriptionStatus,
  planId,
  subscriptionPlan,
  gridColumnStart = "auto",
}) => {
  console.log(
    planName,
    price,
    description,
    buttonText,
    subscriptionStatus,
    planId,
    subscriptionPlan
  );
  const navigate = useNavigate();
  const features = [
    { key: "tribaja_profile", label: "Tribaja Profile" },
    { key: "inclusion_benchmarking", label: "Inclusion Benchmarking" },
    { key: "verified_badge", label: "Verified Badge" },
    { key: "anual_inclusion_awards", label: "Annual Inclusion Awards" },
    { key: "ats_integration", label: "ATS Integration" },
    { key: "pipeline_dashboard", label: "Pipeline Dashboard" },
    { key: "explore_page", label: "Explore Page" },
    { key: "social_media_features", label: "Social Media Features" },
    { key: "slack_channel_feature", label: "Slack Channel Feature" },
    { key: "workshops_and_events", label: "Workshops and Events" },
    { key: "job_post_count", label: "Job Post" },
  ];

  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1150);
  const [loader, setLoader] = useState(false);

  const [subscribed, setSubscribed] = useState(false);
  const btnText = subscribed ? "Already Subscribed" : buttonText;
  const buttonClass =
    btnText === "Already Subscribed"
      ? "employerBilling__card-button subscribed"
      : "employerBilling__card-button";

  const isBtnEnabled = btnText === "Already Subscribed" ? true : false;

  const grow = {
    id: 43,
    inclusion_benchmarking: true,
    verified_badge: true,
    tribaja_profile: true,
    anual_inclusion_awards: true,
    ats_integration: true,
    pipeline_dashboard: true,
    explore_page: true,
    social_media_features: true,
    slack_channel_feature: true,
    workshops_and_events: true,
    job_post: false,
    type: "grow",
    price: 499,
  };
  // const router = use();

  const handleStripe = async (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    window.location.href =
      "https://billing.stripe.com/p/login/test_5kA7tMb7c5RD83C5kk";

    // try {
    //   const response = await axios.post(
    //     `${api}/main/stripe/customer_portal/employer/`,
    //     {},
    //     {
    //       headers: {
    //         Authorization: `Bearer ${getAccessToken()}`,
    //       },
    //     }
    //   );

    //   const { url } = response.data;

    //   window.location.href = url;
    // } catch (error) {
    //   message.error("Error accessing Stripe portal");
    // }
  };

  const reversedSubscriptionPlan = subscriptionPlan.sort(
    (a: any, b: any) => a.id - b.id
  );

  const plans = [...reversedSubscriptionPlan, grow];

  useEffect(() => {
    console.log(subscriptionStatus);
    setSubscribed(
      Boolean(
        subscriptionStatus === "grow" ||
          subscriptionStatus === "start" ||
          subscriptionStatus === "scale"
      )
    );
  }, [subscriptionStatus]);

  const renderPointers = (planId: any) => {
    console.log(planId);
    const plan = plans.find((p) => p.id === planId);
    if (!plan) return null;

    return (
      <div>
        {features.map((feature) =>
          plan[feature.key] && feature.key !== "job_post_count" ? (
            <div
              key={feature.key}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                padding: "5px 0",
              }}
            >
              <CheckItemIcon />
              <span>{feature.label}</span>
            </div>
          ) : feature.key === "job_post_count" && plan.id === 43 ? (
            <div
              key={feature.key}
              style={{ display: "flex", alignItems: "center", gap: "10px" }}
            >
              <CheckItemIcon />
              <span>{feature.label}: Unlimited</span>
            </div>
          ) : feature.key === "job_post_count" && plan[feature.key] ? (
            <div
              key={feature.key}
              style={{ display: "flex", alignItems: "center", gap: "10px" }}
            >
              <CheckItemIcon />
              <span>
                {feature.label}: {plan.job_post_count}
              </span>
            </div>
          ) : null
        )}
      </div>
    );
  };

  const handleGetPrice = (id: any) => {
    setLoader(true);
    if (Cookies.get("role") === "employer") {
      axios
        .post(
          `${api}/main/stripe/create_checkout/employer/`,
          {
            subscription_id: id,
          },
          {
            headers: {
              Authorization: `Bearer ${getAccessToken()}`,
            },
          }
        )
        .then((response) => {
          setLoader(false);
          window.location.href = response.data.checkout_session_url;
        })
        .catch((error) => {
          setLoader(false);
          message.error("Error sending request");
          console.error("Error sending request", error);
        });
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 1150);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className="employerBilling__card"
      style={{ backgroundColor, gridColumnStart }}
    >
      <PricingIcon />
      <div className="employerBilling__card-plan-name">{planName}</div>
      {price && (
        <div className="employerBilling__card-pricing">
          ${price} <span>/mo</span>
        </div>
      )}
      <div className="employerBilling__card-description">{description}</div>
      {isMobileView && (
        <div
          style={{
            borderTop: "1px solid rgba(153, 153, 153, 1)",
            width: "100%",
            marginTop: "15px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              gap: "10px",
              margin: "15px 0 15px 0",
            }}
          >
            {renderPointers(planId)}
          </div>
        </div>
      )}
      <button
        disabled={isBtnEnabled}
        className={buttonClass}
        // onClick={() => {
        //   if (buttonText === "Book a Demo") {
        //     window.location.href =
        //       "https://calendly.com/d/4fm-mkb-bg9/book-a-demo";
        //   }
        // }}
        onClick={() => handleGetPrice(planId)}
      >
        {!loader ? (
          btnText
        ) : (
          <TailSpin
            height="25"
            width="30"
            color="#FBAD84"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{
              justifySelf: "center",
              alignSelf: "center",
            }}
            wrapperClass="tail-spin"
            visible={true}
          />
        )}
      </button>
      {subscribed && (
        <a
          href="/"
          style={{
            marginTop: "12px",
            fontSize: "12px",
          }}
          onClick={handleStripe}
        >
          Stripe Portal
        </a>
      )}
    </div>
  );
};

export default EmployerBillingCard;
