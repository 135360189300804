import React from 'react';
import { useNavigate, Navigate } from 'react-router-dom';

interface ProtectedRouteProps {
  subscriptionPlan: string;
  requiredPlan: string[];
  element: JSX.Element;
  isCoach: boolean;
  coachOnly?: boolean;
  isEmployer?:boolean
}


const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
    subscriptionPlan,
    requiredPlan,
    isCoach,
    coachOnly = false, // Default to false if not provided
    element,
    isEmployer
  }) => {
    const hasRequiredPlan = (): boolean => requiredPlan.includes(subscriptionPlan);
    
    let canAccessRoute = false;
  
    if (coachOnly) {
      // For coach-only routes, check if the user is a coach
      canAccessRoute = isCoach;
    } else {
      // For other routes, check the subscription plan
      canAccessRoute = hasRequiredPlan() || isCoach; // Allow access if the user is a coach regardless of the subscription
    }

    // if(isEmployer){
    //   canAccessRoute = true
    // }else{
    //   canAccessRoute = false
    // }
  
    if (!canAccessRoute) {
      // Navigate to dashboard or other page
      return <Navigate to="*" replace />;
    }
  
    return element;
  };

export default ProtectedRoute;