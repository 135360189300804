import { useEffect, useState } from "react";
import "./Payment.scss";

import { Elements } from "@stripe/react-stripe-js";
import StripeCheckoutForm from "../../components/StripeCheckoutForm/StripeCheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import api from "../../utils/api";
import { getAccessToken } from "../../utils/jwtToken";
import { useLocation } from "react-router-dom";
import DashboardNavbar from "../../components/DashboardNavbar/DashboardNavbar";
import { AnimatePresence, motion } from "framer-motion";
import { TailSpin } from "react-loader-spinner";

function PaymentPage() {
  //   const [stripePromise, setStripePromise] = useState(null);
  const location = useLocation();
  const clientSecret = location.state;
  // const [clientSecret, setClientSecret] = useState("");
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY ?? "");

  return (
    <>
      <AnimatePresence>
        {clientSecret ? (
          <motion.section className="payment__container">
            <div className="payment__recommendations-container">
              <div className="payment__recommendations-container-card">
                {clientSecret && stripePromise && (
                  <>
                    <h1>Stripe payment </h1>
                    <Elements stripe={stripePromise} options={{ clientSecret }}>
                      <StripeCheckoutForm />
                    </Elements>
                  </>
                )}
              </div>
            </div>
          </motion.section>
        ) : (
          <TailSpin
            height="160"
            width="160"
            color="#FBAD84"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{
              justifySelf: "center",
              alignSelf: "center",
            }}
            wrapperClass="tail-spin"
            visible={true}
          />
        )}
      </AnimatePresence>
      {/* {clientSecret && stripePromise && (
        <>
          <div className="payment-container" style={{
            display: "flex",
            justifyContent: 'center',
            // alignItems:'center',
            flexDirection: 'column',
            padding: '190px'
          }}>
            <h1>Stripe payment </h1>
            <Elements stripe={stripePromise} options={{ clientSecret }}>
              <StripeCheckoutForm />
            </Elements>
          </div>
        </>
      )} */}
    </>
  );
}

export default PaymentPage;
