import React, { FC, useState } from "react";

import "./StaffCard.scss";
import InitialsAvatar from "../InitialsAvatar/InitialsAvatar";
import { deleteStaff, getStaff } from "../../redux/EmployerSlice/EmployerSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store/store";
import AdminDeleteModal from "../../containers/Admin/AdminMainDash/AdminDashTabsComponents/AdminDeleteModal";
import StaffAddModal from "../../containers/EmployerTeamManagementPage/StaffAddModal";

type Props = {
  staff?: any;
};
const StaffCard: FC<Props> = (props) => {
  const { staff } = props;

  const [openModal, setModalOpen] = useState<boolean>(false);

  const [staffProfileMode, setStaffProfileMode] = useState<"ADD_NEW" | "EDIT">(
    "EDIT",
  );
  const [openDelModal, setDelModalOpen] = useState<string>("");
  const dispatch = useDispatch<AppDispatch>();

  return (
    <>
      {openModal && (
        <StaffAddModal
          formType={staffProfileMode}
          staffId={staff?.staff_account?.id}
          onModalClose={() => setModalOpen(false)}
        />
      )}
      {Boolean(openDelModal) && (
        <AdminDeleteModal
          onSubmit={() => {
            dispatch(deleteStaff(openDelModal)).then(() => {
              dispatch(getStaff()).then(() => {
                setDelModalOpen("");
              });
            });
          }}
          onModalClose={() => setDelModalOpen("")}
        />
      )}

      <div className="staffCard" style={staff?.style || {}}>
        <InitialsAvatar
          name={
            staff?.staff_account?.first_name
              ? staff?.staff_account?.first_name
              : "---"
          }
          customStyles={{ width: "84px", height: "84px", fontSize: "16px" }}
        />

        <div className="name">
          {staff?.staff_account?.first_name +
            " " +
            staff?.staff_account?.last_name || " "}{" "}
          {/* {staff?.staff_account?.last_name || ""}{" "} */}
        </div>
        <div
          className="role"
          style={{
            display: "block",
            whiteSpace: "nowrap",
            width: "200px",
            textOverflow: "ellipsis",
            overflow: "hidden",
            textAlign: "center",
          }}
        >
          {staff?.staff_account?.role || "---"}
        </div>
        <div className="email">{staff?.staff_account?.email}</div>
        <div className="buttons">
          <button
            onClick={() => {
              setStaffProfileMode("EDIT");
              setModalOpen(true);
            }}
          >
            Edit
          </button>
          <button
            className="invert"
            onClick={() => setDelModalOpen(staff?.staff_account?.id)}
          >
            Delete
          </button>
        </div>
      </div>
    </>
  );
};

export default StaffCard;
