import React, { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import CoachCard from "../../components/CoachCard/CoachCard";

import axios from "axios";
import api from "../../utils/api";
import { getAccessToken } from "../../utils/jwtToken";

import { TailSpin } from "react-loader-spinner";
import { PaginationComp } from "../../components/PaginationComp/PaginationComp";

const Coach = () => {
  const [coach, setCoaches] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const fetchCoaches = (page: number) => {
    setLoading(true);
    const accessToken = getAccessToken();
    axios
      .get(`${api}/main/list/coaches?page=${page}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(function (response) {
        setCoaches(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        // Handle any errors
        setLoading(false);
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    fetchCoaches(1);
  }, []);

  const handlePagination = (p: any) => {
    fetchCoaches(p);
  };

  return (
    <AnimatePresence>
      {!loading ? (
        <motion.section className="viewAll__container">
          {coach?.results?.length > 0 && (
            <div className="viewAll__recommendations-container">
              <div className="viewAll__recommendations-container-card">
                {coach?.results?.map((coach: any) => {
                  return (
                    <CoachCard
                      coach={{
                        ...coach,
                        style: { marginBottom: "40px" },
                      }}
                    />
                  );
                })}
              </div>
            </div>
          )}
          <PaginationComp
            count={coach?.count}
            page={coach?.page}
            onChange={handlePagination}
          />
        </motion.section>
      ) : (
        <TailSpin
          height="160"
          width="160"
          color="#FBAD84"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{
            justifySelf: "center",
            alignSelf: "center",
          }}
          wrapperClass="tail-spin"
          visible={true}
        />
      )}
    </AnimatePresence>
  );
};

export default Coach;
