import React, { FC, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getAccessToken } from "../../utils/jwtToken";
import api from "../../utils/api";
import Cookies from "js-cookie";

import { Job } from "../../assets/images";
import {
  Bookmark,
  Clock,
  Global,
  Pin,
  Star,
  Verified,
} from "../../assets/icons";

import {
  getTalentDashboard,
  getTalentDashboardRecommended,
} from "../../redux/TalentSlice/TalentSlice";
import { getTalentSavedItems } from "../../redux/TalentSlice/TalentSlice";


import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store/store";


import "./RecommendedJob.scss";
import InitialsAvatar from "../InitialsAvatar/InitialsAvatar";
type Props = {
  job?: any;
};
const RecommendedJob: FC<Props> = (props) => {
  const { job } = props;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [saveToggle, setSaveToggle] = useState<boolean>(job?.is_saved);

  const dispatch = useDispatch<AppDispatch>();


  const saveJob = async () => {
    setLoading(true);
    setSaveToggle(true);
    try {
      await axios.post(
        `${api}/${Cookies.get("role")}/save/job/${job?.id}/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        }
      );
      if (window.location.pathname === "/recommendations") {
        dispatch(getTalentDashboardRecommended("jobs"));
      } else {
        dispatch(getTalentDashboard());
      }
      setLoading(false);
      // setSaved((prevState) => !prevState);
    } catch {
      setLoading(false);
    }
  };

  const unsaveJob = async () => {
    setLoading(true);
    setSaveToggle(false);
    try {
      await axios.delete(
        `${api}/${Cookies.get("role")}/delete/job/${job?.id}/`,
        {
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        }
      );
      setLoading(false);
      if (window.location.pathname === "/recommendations") {
        dispatch(getTalentDashboardRecommended("jobs"));
      }
       else {
        dispatch(getTalentDashboard());
      }
      // setSaved((prevState) => !prevState);
    } catch {
      setLoading(false);
    }
  };

  return (
    <div className="recommendedJob" style={job?.style || {}}>
      {job?.percentage_job_match && (
        <div className="recommendedJob__match">
          <div className="progress">{job?.percentage_job_match || "82%"}</div>
          Job Match
        </div>
      )}
      <Bookmark
        className={`bookmark ${saveToggle ? "bookmarkChecked" : ""}`}
        // style={{ fill: saveToggle ? "#fdceb5" : "" }}
        onClick={!loading ? (saveToggle ? unsaveJob : saveJob) : () => {}}
      />
      {job?.employer?.images?.profile_image ? (
        <img 
        className="recommendedJob__profile"
        src={job?.employer?.images?.profile_image} 
        alt="jobImage" />
      ) : (
        <InitialsAvatar
          name={
            job?.employer?.company_name
              ? job?.employer?.company_name
              : "John Doe"
          }
          customStyles={{ width: "84px", height: "84px", fontSize: "16px" }}
        />
      )}
      <div className="name">
        {job?.employer?.company_name || "------"}{" "}
        {job?.employer?.is_verified ? <Verified /> : ""}
      </div>

      <div className="rating">
        {job?.employer?.rating
          ? Array.from({ length: job?.employer?.rating }).map((_) => <Star />)
          : null}
      </div>
      <div
        className="title"
        style={{
          display: "block",
          whiteSpace: "nowrap",
          width: "200px",
          textOverflow: "ellipsis",
          overflow: "hidden",
          textAlign: "center",
        }}
      >
        {job?.title || "Software Engineer"}
      </div>
      <div className="line"></div>
      <div className="details">
        <div>
          <Pin />
          <span
            style={{
              display: "block",
              whiteSpace: "nowrap",
              width: "80px",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {" "}
            {job?.location || "-----"}
          </span>
        </div>
        <div>
          <Global />
          <span
            style={{
              display: "block",
              whiteSpace: "nowrap",
              width: "80px",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {typeof job?.job_type === "string"
              ? job?.job_type
              : job?.job_type?.[0] || job?.mode_of_work?.[0] || "----"}
          </span>
        </div>
        <div>
          <Clock />
          <span style={{ maxWidth: "80px" }}>
            {job?.posted_since ||
              (job?.date_applied
                ? moment(job?.date_applied, "DD/MM/YYYY").fromNow()
                : "-- Hours Ago")}
          </span>
        </div>
      </div>

      <div className="buttons">
        {/* <button disabled={!!job?.date_applied}>
          {job?.date_applied ? "Applied" : "Apply"}
        </button> */}
        <button
          className="invert"
          onClick={() =>  job?.id && navigate(`/job/${job?.id}`)}
        >
          View Job
        </button>
      </div>
    </div>
  );
};

export default RecommendedJob;
