import React, { useState } from "react";

import "./StudentCard.scss";
import { Coach } from "../../assets/images";
import {
  Bookmark,
  Experience,
  OpenFor,
  Pin,
  Star,
  Verified,
} from "../../assets/icons";
import axios from "axios";
import { getAccessToken } from "../../utils/jwtToken";
import api from "../../utils/api";
import Cookies from "js-cookie";
import ScheduleMeetingModal from "../ScheduleMeetingModal/ScheduleMeetingModal";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store/store";
import {
  getTalentDashboard,
  getTalentDashboardRecommended,
} from "../../redux/TalentSlice/TalentSlice";
import { TailSpin } from "react-loader-spinner";
import { message } from "antd";
import InitialsAvatar from "../InitialsAvatar/InitialsAvatar";
import RequestConnectModal from "../RequestConnectModal/RequestConnectModal";

import ReasonForDecliningModal from "../ReasonForDecliningModal/ReasonForDecliningModal";

type Props = {
  fetchCoachPanelData?: () => void;
  student?: any;
  isRequest?: boolean;
};
const StudentCard = (props: Props) => {
  const { student, isRequest, fetchCoachPanelData } = props;

  const dispatch = useDispatch<AppDispatch>();

  // Initial Saved Status Not coming from backend
  const [loading, setLoading] = useState<boolean>(false);
  const [saveToggle, setSaveToggle] = useState(student?.is_saved);
  const [reasonText, setReasonText] = useState("");

  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [dataUrl, setDataUrl] = useState("");
  const [scheduleMeetingError, setScheduleMeetingError] = useState(false);
  const [spinner, setSpinner] = useState(false);

  const buttonText =
    window.location.pathname === "/my-coaches" ? "Schedule Meeting" : "Connect";

  const handleViewProfile = () => {
    window.location.href = `/profile/talent/${student?.id}`;
  };

  const saveStudent = async () => {
    setLoading(true);
    setSaveToggle(true);
    try {
      await axios.post(
        `${api}/${Cookies.get("role")}/save/talent/${student?.id}/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        },
      );
      setLoading(false);
      fetchCoachPanelData?.();
    } catch {
      setLoading(false);
    }
  };

  const unsaveStudent = async () => {
    setLoading(true);
    setSaveToggle(false);
    try {
      await axios.delete(
        `${api}/${Cookies.get("role")}/delete/talent/${student?.id}/`,
        {
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        },
      );
      if (
        window.location.pathname === "/recommendations" ||
        window.location.pathname.includes("/view-all/coach")
      ) {
        dispatch(getTalentDashboardRecommended("coaches"));
      } else {
        dispatch(getTalentDashboard());
      }
      setLoading(false);
      fetchCoachPanelData?.();
    } catch {
      setLoading(false);
    }
  };

  const handleAcceptStudentRequest = async (requestId: string) => {
    setSpinner(true);
    try {
      await axios.post(
        `${api}/${Cookies.get("role")}/coach/requests/status_update/`,
        {
          id: requestId,
          status: "accepted",
          reason_for_rejection: "",
        },
        {
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        },
      );
      setSpinner(false);
      fetchCoachPanelData?.();
      message.success("Accepted successfully");
    } catch {
      setSpinner(false);
      message.error("Request is unsuccessful");
    }
  };

  const handleRejectRequest = async (requestId: string) => {
    setSpinner(true);
    if (reasonText) {
      try {
        await axios.post(
          `${api}/${Cookies.get("role")}/coach/requests/status_update/`,
          {
            id: requestId,
            status: "rejected",
            reason_for_rejection: reasonText,
          },
          {
            headers: {
              Authorization: `Bearer ${getAccessToken()}`,
            },
          },
        );
        setLoading(true);
        fetchCoachPanelData?.();
        setSpinner(false);
      } catch {
        message.error("Request is unsuccesful");
        setLoading(false);
        setSpinner(false);
      }
    }
    handleRejectModal();
  };

  const handleRejectModal = () => {
    setOpenRejectModal((prevState) => !prevState);
  };

  return (
    <>
      <div className={"studentCard"} style={student?.style || {}}>
        {!isRequest ? (
          <Bookmark
            className={`bookmark ${saveToggle ? "bookmarkChecked" : ""}`}
            onClick={
              !loading ? (saveToggle ? unsaveStudent : saveStudent) : () => {}
            }
          />
        ) : null}
        <div className="coachCard__details">
          {student?.image ? (
            <img src={student?.image} alt="Jason Doe - Tribja Coach" />
          ) : (
            <InitialsAvatar
              name={student?.name ? student?.name : "---"}
              customStyles={{ width: "62px", height: "62px" }}
            />
          )}
          <div className="coachCard__details-container">
            <div className="name">
              {student?.name ? student?.name : "-------"}
              {/* <Verified /> */}
            </div>
            <p className="profession">
              {student?.profession ? student?.profession : "-------"}
            </p>
          </div>
        </div>
        <div className="coachCard__stats">
          <section>
            <div className="coachCard__stats-label">
              <Pin /> From
            </div>
            <span
              style={{
                display: "block",
                whiteSpace: "nowrap",
                width: "80px",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {student?.location
                ? `${student?.location?.city}, ${student?.location.state}, ${student?.location?.country}`
                : "---------"}
            </span>
          </section>
          <section>
            <div className="coachCard__stats-label">
              <Experience /> Experience
            </div>
            {student?.experience
              ? Math.abs(Math.floor(student?.experience)) + " Years"
              : "------"}
          </section>
          <section>
            <div className="coachCard__stats-label">
              <OpenFor /> Open for
            </div>
            <div
              style={{
                display: "block",
                whiteSpace: "nowrap",
                width: "80px",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {student?.schedule?.map((elem: string) => {
                return <span>{elem} </span>;
              })}
            </div>
          </section>
        </div>
        <div className="coachCard__buttons">
          {!isRequest ? (
            <button onClick={handleViewProfile}>View Profile</button>
          ) : (
            <>
              <button
                onClick={() => handleAcceptStudentRequest(student?.request_id)}
              >
                {!spinner ? (
                  "Accept"
                ) : (
                  <TailSpin
                    height="20"
                    width="20"
                    color="white"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{
                      justifySelf: "center",
                      alignSelf: "center",
                    }}
                    wrapperClass="tail-spin"
                    visible={true}
                  />
                )}
              </button>
              <button onClick={handleRejectModal}>Decline</button>
            </>
          )}
        </div>
        {openRejectModal && (
          <ReasonForDecliningModal
            handleRejectRequest={() => handleRejectRequest(student?.request_id)}
            handleReasonText={(e) => setReasonText(e.target.value)}
            reasonText={reasonText}
            handleRejectModal={handleRejectModal}
            spinner={spinner}
          />
        )}
      </div>
    </>
  );
};

export default StudentCard;
