import React, { FC, useState } from "react";
import NavBar from "../../components/NavBar/NavBar";
import {
  GoogleIcon,
  LinkedInIcon,
  PasswordHide,
  PasswordVisible,
} from "../../assets/icons";
import { Select } from "antd";
import { useForm, SubmitHandler } from "react-hook-form";
import { Footer, FormButton } from "../../components";
import { useDispatch } from "react-redux";
import {
  linkedInAuthLogin,
  registerUser,
  updateProfileState,
} from "../../redux/ProfileSlice/ProfileSlice";
import { AppDispatch } from "../../redux/store/store";
import { useNavigate } from "react-router-dom";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import "./SignUpScreen.scss";
import { useGoogleAuth } from "../../hooks/useGoogleAuth";
import axios from "axios";
import { setJWTToken } from "../../utils/jwtToken";
import { enqueueSnackbar } from "notistack";

const Option = Select.Option;

interface SignUpInputs {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  repeatPassword: string;
  type: string;
}

interface PasswordHidden {
  one: boolean;
  two: boolean;
}

const SignUp: FC = () => {
  const [passwordHidden, setPasswordHidden] = useState<PasswordHidden>({
    one: true,
    two: true,
  });
  const [type, setType] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const { register, handleSubmit } = useForm<SignUpInputs>();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const referral = window.location.search.split("=")[1];

  const handleGoogleAuth = useGoogleAuth();

  const { linkedInLogin } = useLinkedIn({
    clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID || "",
    redirectUri: `${window.location.origin}/linkedin`,
    scope: "openid profile email",
    onSuccess: async (code: any) => {
      dispatch(linkedInAuthLogin(code)).then((res) => {
        if (res.type.includes("rejected")) {
          enqueueSnackbar(res.payload.detail, {
            variant: "error",
          });
        } else {
          setJWTToken(
            res.payload.access,
            res.payload.refresh,
            res.payload.user.role,
            res.payload.user.on_boarding_completed,
          );
          dispatch(
            updateProfileState({
              accessToken: res.payload.access,
              boardingCompleted: res.payload.user.on_boarding_completed,
              userRole: res.payload.user.role,
            }),
          );
          if (!res.payload.user.on_boarding_completed) {
            navigate(`/select-profile`);
          } else {
            navigate(`/dashboard`);
          }
        }
      });
    },
    onError: (error: any) => {},
  });

  const onSubmit: SubmitHandler<SignUpInputs> = (data) => {
    setErrorMessage("");
    setLoading(true);
    if (type) {
      if (data.password === data.repeatPassword) {
        dispatch(registerUser({ ...data, type, affiliateCode: referral })).then(
          (res) => {
            setLoading(false);
            if (res.type.includes("rejected")) {
              const errMessage: any = Object.values(res.payload);
              if (errMessage[0][0]?.length > 2) {
                setErrorMessage(errMessage[0][0]);
              } else {
                setErrorMessage("Something went wrong, please try again.");
              }
            } else {
              navigate(`/verify-otp/${data.email}`);
              // return;
            }
          },
        );
      } else {
        setLoading(false);
        setErrorMessage("Both passwords doesn't match.");
      }
    } else {
      setLoading(false);
      setErrorMessage("Fill all the forms.");
    }
  };

  return (
    <div className="signUp">
      <NavBar />
      <div className="signUp__container">
        <h2>Welcome to Tribaja</h2>
        <p>
          Open Doors to Limitless Tech Networking and Learning Opportunities.
        </p>
        <div className="signUp__container-social">
          <button onClick={handleGoogleAuth}>
            <GoogleIcon />
            Continue with Google
          </button>
          <button onClick={linkedInLogin}>
            <LinkedInIcon />
            Continue with LinkedIn
          </button>
        </div>
        <div className="signUp__container-seperator">
          <span></span>
          OR
          <span></span>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label>
            <span>First Name</span>
            <input required {...register("firstName")} type="text" />
          </label>
          <label>
            <span>Last Name</span>
            <input required {...register("lastName")} type="text" />
          </label>
          <label>
            <span>Email Address</span>
            <input required {...register("email")} type="email" />
          </label>
          <label>
            <span>I want to continue as</span>
            <Select
              optionFilterProp="label"
              showSearch={true}
              onChange={(e: any) => setType(e)}
              className="select"
            >
              <Option
                label="Talent"
                value={"talent"}
                className="roleType__option"
              >
                Talent <span> For tech enthusiasts exploring new opportunities.</span>
              </Option>
              <Option
                label="Employer"
                value={"employer"}
                className="roleType__option"
              >
                Employer{" "}
                <span>
                For companies seeking diverse tech talent to enhance their teams.                </span>
              </Option>
              <Option
                label="Partner"
                value="partner"
                className="roleType__option"
              >
                Partner
                <span>
                For programs looking to increase visibility to diverse audiences.

                </span>
              </Option>
            </Select>
          </label>
          <label>
            <span>
              Create a password
              <span
                onClick={() =>
                  setPasswordHidden({
                    one: !passwordHidden.one,
                    two: passwordHidden.two,
                  })
                }
              >
                {passwordHidden.one ? <PasswordHide /> : <PasswordVisible />}
              </span>
            </span>
            <input
              required
              {...register("password")}
              type={passwordHidden.one ? "password" : "text"}
            />
          </label>
          <label>
            <span>
              Repeat password
              <span
                onClick={() =>
                  setPasswordHidden({
                    one: passwordHidden.one,
                    two: !passwordHidden.two,
                  })
                }
              >
                {passwordHidden.two ? <PasswordHide /> : <PasswordVisible />}
              </span>
            </span>
            <input
              required
              {...register("repeatPassword")}
              type={passwordHidden.two ? "password" : "text"}
            />
          </label>
          {errorMessage && <span className="errorMessage">{errorMessage}</span>}
          <FormButton text="Continue with email" loading={Boolean(loading)} />

          <p className="para">
            By continuing, you agree to the{" "}
            <a href="https://tribaja.co/terms-and-conditions">
              Terms and Conditions
            </a>
            , <a href="https://tribaja.co/privacy-policy">Privacy Policy</a>,
            and Community Standards of Tribaja.
          </p>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default SignUp;
