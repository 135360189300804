import React from "react";
import './PaginationComp.scss';

import { Pagination } from 'antd';

interface Props {
    count?:number,
    page: number,
    onChange: any;
}

export const PaginationComp = (props:Props) => {
    
    const {count, page, onChange } = props

    const [currentPage, setCurrentPage] = React.useState(1);
    const itemsPerPage = 9


const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
//   const itemsToDisplay = items?.slice(startIndex, endIndex);

    return(
        <div className='pagination'>
        {/* <div className={'pagination__leftArrow'}>
                   ← <span>Previous</span>
           </div> */}
        <Pagination className='pagination__pageNumber' current={page}  pageSize={30}   onChange={onChange} total={count} prevIcon={null} nextIcon={null} />
        {/* <div className={'pagination__rightArrow'}>
                 <span>Next</span> → 
           </div> */}
        </div>
    )
}