import React from "react";

import "./ExploreSearchBar.scss";
import { Search } from "../../assets/icons";
type SideBarItem = {
  icon: React.ReactElement;
  name: string;
  link: string;
  searchString: string;
};

const ExploreSearchBar = (props: any) => {
  return (
    <div className="search-container">
      <div className="search-box-container">
        <Search />
        <input
          onChange={props?.onChange}
          type="text"
          className="search-box"
          placeholder="Search popluar in Tribaja"
          value={props.searchString}
        />
      </div>
    </div>
  );
};
export default ExploreSearchBar;
