import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
// import { Pie } from "@ant-design/charts";
import { ReactComponent as MenuIcon } from "../../assets/icons/menuIcon.svg";

import { TailSpin } from "react-loader-spinner";

import "./EmployerATS.scss";
import DashboardNavbar from "../../components/DashboardNavbar/DashboardNavbar";
import { useNavigate, useParams } from "react-router-dom";

import { EmployerAnalyticCard, FormButton } from "../../components";
import { AppDispatch } from "../../redux/store/store";
import { getEmployerDashboard } from "../../redux/EmployerSlice/EmployerSlice";
import { ReactComponent as EditIcon } from "../../assets/icons/editIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/deleteIcon.svg";
import { Tag, Dropdown, message } from "antd";
import PostedJobCard from "../../components/PostedJobCard/PostedJobCard";
import axios from "axios";
import { getAccessToken } from "../../utils/jwtToken";
import api from "../../utils/api";

const EmployerATS: FC = () => {
  // States
  const [loading, setLoading] = useState<boolean>(true);
  const [apiKey, setApiKey] = useState("");

  // Dispatch
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();
  // Selectors
  const profileInfo = useSelector((state: any) => state.profile.profileInfo);
  const employerDashboard = useSelector(
    (state: any) => state.employer.dashboard,
  );

  const [isAtsIntegrated, setIsAtsIntegrated] = useState(
    employerDashboard?.is_ats_integrated,
  );
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 700);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 700);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // LifeCylce events
  useEffect(() => {
    setLoading(true);

    if (profileInfo?.first_name && employerDashboard?.job_statistics) {
      setLoading(false);
    }
  }, [profileInfo, employerDashboard]);

  useEffect(() => {
    dispatch(getEmployerDashboard());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAtsIntegration = () => {
    const employerId = employerDashboard?.profile?.id;
    setLoading(true);
    const accessToken = getAccessToken();
    axios
      .post(
        `${api}/employer/integrate_ats/`,
        {
          api_key: apiKey,
          employer: employerId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      )
      .then(function (response) {
        message.success("ATS integrated successfuly");
        setIsAtsIntegrated(true);
        setLoading(false);
      })
      .catch(function (error) {
        message.error("ATS integration unsuccessful");
        setLoading(false); // Handle any errors
        setIsAtsIntegrated(false);
        console.error("Error:", error);
      });
  };

  const handelSyncJobs = () => {
    setLoading(true);
    const accessToken = getAccessToken();
    axios
      .post(
        `${api}/employer/ats/sync/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      )
      .then(function (response) {
        message.success("Jobs synced successfuly");
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false); // Handle any error
        message.error("Error syncing jobs");
        console.error("Error:", error);
      });
  };

  return (
    <AnimatePresence>
      {!loading ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="employerATS__atsPage-box"
        >
          <DashboardNavbar addLogo={isMobileView} />
          <main className="employerATS__atsPage">
            <motion.div
              key={"dashboard-heading"}
              transition={{ duration: 0.3, delay: 0.3 }}
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              className="employerATS__atsPage-heading"
            >
              <h1>Integrate ATS</h1>
            </motion.div>
            <motion.section
              transition={{ duration: 0.3, delay: 0.4 }}
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              className="employerATS__atsPage-container"
            >
              {!isAtsIntegrated ? (
                <div className="employerATS__atsPage-container-inputs">
                  <h4>ATS Integration</h4>
                  <p>
                    Our ATS integration feature allows you to pull in jobs from
                    your ATS so that you don't have to manually recreate them!
                  </p>
                  <p>
                    <em>
                      NOTE: Greenhouse is the only ATS that we currently
                      support.
                    </em>
                  </p>
                  <input
                    type="text"
                    placeholder="Your_ATS_API_KEY_HERE"
                    value={apiKey}
                    onChange={(e) => setApiKey(e.target.value)}
                  />
                  <FormButton
                    onClick={handleAtsIntegration}
                    loading={loading}
                    text={"Submit"}
                  />
                </div>
              ) : (
                <div className="employerATS__atsPage-container-integrationMessage">
                  <h4>ATS integrated successfuly</h4>
                  <FormButton
                    onClick={handelSyncJobs}
                    loading={loading}
                    text={"Sync Jobs"}
                  />
                </div>
              )}
            </motion.section>
          </main>
        </motion.div>
      ) : (
        <TailSpin
          height="160"
          width="160"
          color="#FBAD84"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{
            justifySelf: "center",
            alignSelf: "center",
          }}
          wrapperClass="tail-spin"
          visible={true}
        />
      )}
    </AnimatePresence>
  );
};

export default EmployerATS;
