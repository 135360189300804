import React, { FC, useEffect, useState } from "react";
import "./RequestForLeaveModal.scss";
import { Deletion } from "../../assets/icons";
import { CloseSquare } from "../../assets/icons";
import { backdropOverFlowHide } from "../../utils/backdropOverFlowHide";
import { Select } from "antd";
import { updateAccountDeleteReason } from "../../redux/ProfileSlice/ProfileSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store/store";
import { scrollToTop } from "../../utils/scrollToTop";

interface RequestForLeaveModalProps {
  handleCancelDelete: () => void;
  handleContinueDelete: () => void;
}

const Option = Select.Option;

const RequestForLeaveModal: FC<RequestForLeaveModalProps> = ({
  handleCancelDelete,
  handleContinueDelete,
}) => {
  const [feedBackInput, setFeedbackInput] = useState("");

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    scrollToTop();
    backdropOverFlowHide();
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  const accountDeletionReasons = [
    {
      label: "I'm concerned about my privacy.",
    },
    {
      label: "The service isn’t relevant to my needs.",
    },
    {
      label: "I'm unsatisfied with the quality of the service.",
    },
    {
      label: "I found a better alternative.",
    },
    {
      label: "The service is too expensive.",
    },
    {
      label: "The service is too difficult to use.",
    },
    {
      label: "I no longer use the service frequently.",
    },
    {
      label: "I'm experiencing technical issues.",
    },
    {
      label: "I'm just taking a break from the service.",
    },
    { label: "I prefer not to say." },
    { label: "Other - Please specify" },
  ];

  return (
    <div
      onClick={(e) => {
        if (e.currentTarget === e.target) {
        }
      }}
      className="requestForLeaveModal__container"
    >
      <div className="requestForLeaveModal">
        <CloseSquare
          onClick={handleCancelDelete}
          className="requestForLeaveModal_closeSquareLogo"
        />
        <Deletion
          style={{
            display: "flex",
            alignSelf: "center",
          }}
        />
        <h1>We are sorry to see you leaving</h1>
        <p>
          We appreciate your feedback. Please let us know the reasons for your
          account deletion. Your insights help us improve our services and
          better serve our community. You can always{" "}
          <span style={{ color: "blue" }}>get help</span> form us and we will
          resolve your issue.{" "}
        </p>
        <form>
          <label>Select reason</label>
          <Select
            optionFilterProp="label"
            showSearch={true}
            onChange={(value) => setFeedbackInput(value)}
            className="select"
            placeholder="Select"
          >
            {accountDeletionReasons?.map((data: any) => (
              <Option
                label={data.label}
                value={data.label}
                className="roleType__option"
              >
                {data.label}
              </Option>
            ))}
          </Select>
          <button
            disabled={feedBackInput === ""}
            onClick={() => {
              dispatch(updateAccountDeleteReason(feedBackInput));
              handleContinueDelete();
            }}
          >
            Continue
          </button>
        </form>
      </div>
    </div>
  );
};
export default RequestForLeaveModal;
