import React from "react";
import "./CareerGuideChat.scss";
import { AnimatePresence, motion } from "framer-motion";

type Props = {
  careerChatData: Array<any>;
};
const CareerGuideChat = (props: Props) => {
  const { careerChatData } = props;

  const renderCareerChat = careerChatData?.map((elem: any) => {
    return (
      <div className={"career-guide"}>
        <h3>{elem.career_name}</h3>
        <h6>{elem.description}</h6>
        <ol>
          {elem.skills_required?.slice(0, 5).map((elem: string) => {
            return <li>{elem}</li>;
          })}
        </ol>
      </div>
    );
  });

  return (
    <div>
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {renderCareerChat}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default CareerGuideChat;
