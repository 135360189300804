import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getAccessToken } from "../../utils/jwtToken";
import api from "../../utils/api";

interface AdminPostEventsProps {
  event_name: string;
  event_link: string;
  event_date: number;
  event_details: string;
}

// Helper function to handle API errors
const handleApiError = (err: any, rejectWithValue: any) => {
  if (!err.response) {
    throw err;
  }
  return rejectWithValue(err.response.data);
};

export const getAdminDashboard = createAsyncThunk(
  "admin/getAdminDashboard",
  async (_, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.get(`${api}/adminpanel/dashboard/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const getCareerPath = createAsyncThunk(
  "admin/getCareerPath",
  async (data: any, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.get(
        `${api}/adminpanel/career_path/?page=${data.page}&title=${data.search}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteCareerPath = createAsyncThunk(
  "admin/deleteCareerPath",
  async (id: any, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.delete(
        `${api}/adminpanel/career_path/${id}/`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const getUserStaff = createAsyncThunk(
  "admin/getUserStaff",
  async (data: any, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.get(
        `${api}/adminpanel/users/?page=${data.page}&name=${data.search}` +
          (data.is_active === "Inactive"
            ? `&is_active=${false}`
            : data.is_active === "Active"
              ? `&is_active=${true}`
              : ``),
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteUserStaff = createAsyncThunk(
  "admin/deleteUserStaff",
  async (id: string, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.delete(`${api}/adminpanel/users/${id}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

// Admin Post Events
export const AdminPostEvents = createAsyncThunk(
  "admin/AdminPostEvents",
  async (data: AdminPostEventsProps, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.post(
        `${api}/adminpanel/register/event/`,
        {
          event_name: data.event_name,
          event_link: data.event_link,
          event_date: data.event_date,
          event_details: data.event_details,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      return response.data;
    } catch (err: any) {
      return handleApiError(err, rejectWithValue);
    }
  },
);

// Get Partners
export const getPartners = createAsyncThunk(
  "admin/getPartners",
  async (page: number, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.get(
        `${api}/adminpanel/partners/?page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

// Get All Partners
export const getAllPartners = createAsyncThunk(
  "admin/getAllPartners",
  async (data: any, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.get(
        `${api}/adminpanel/partners/all/?page=${data.page}&name=${data.search}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

// Change Partner Status
export const changePartnerStatus = createAsyncThunk(
  "admin/changePartnerStatus",
  async (data: any, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.patch(
        `${api}/adminpanel/partners/${data.id}/`,
        {
          verified: data.status,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

// Delete Partner
export const deletePartner = createAsyncThunk(
  "admin/deletePartner",
  async (id: string, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.delete(`${api}/adminpanel/partners/${id}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

// Get Coaches
export const getCoaches = createAsyncThunk(
  "admin/getCoaches",
  async (page: number, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.get(
        `${api}/adminpanel/coaches/?page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

// Get All Coaches
export const getAllCoaches = createAsyncThunk(
  "admin/getAllCoaches",
  async (data: any, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.get(
        `${api}/adminpanel/coaches/all/?page=${data.page}&name=${data.search}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

// Change Coach Status
export const changeCoachStatus = createAsyncThunk(
  "admin/changeCoachStatus",
  async (data: any, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.patch(
        `${api}/adminpanel/coaches/${data.coach_id}/`,
        {
          status: data.status,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

// Delete Coach
export const deleteCoach = createAsyncThunk(
  "admin/deleteCoach",
  async (id: string, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.delete(`${api}/adminpanel/coaches/${id}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

// Get Resources
export const getResources = createAsyncThunk(
  "admin/getResources",
  async (page: number, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.get(
        `${api}/adminpanel/resources/?page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

// Get All Resources
export const getAllResources = createAsyncThunk(
  "admin/getAllResources",
  async (data: any, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.get(
        `${api}/adminpanel/resources/all/?page=${data.page}&title=${data.search}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

// Change Resource Status
export const changeResourceStatus = createAsyncThunk(
  "admin/changeResourceStatus",
  async (data: any, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.patch(
        `${api}/adminpanel/resources/${data.id}/`,
        {
          status: data.status,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

// Get Resource
export const getAdminResource = createAsyncThunk(
  "admin/getAdminResource",
  async (id: any, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.get(`${api}/adminpanel/resources/${id}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

// Delete Resource
export const deleteAdminResource = createAsyncThunk(
  "admin/deleteAdminResource",
  async (id: any, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.delete(
        `${api}/adminpanel/resources/${id}/`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

// Get Staff
export const getStaff = createAsyncThunk(
  "admin/getStaff",
  async (_, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.get(`${api}/adminpanel/manage/staff/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data.data;
    } catch (err: any) {
      return {
        message: err.message,
      };
    }
  },
);

// Create Staff
export const createStaff = createAsyncThunk(
  "admin/createStaff",
  async (data: any, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.post(
        `${api}/adminpanel/manage/staff/`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

// Delete Staff
export const deleteStaff = createAsyncThunk(
  "admin/deleteStaff",
  async (id: string, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.delete(`${api}/adminpanel/manage/staff/`, {
        data: { staff_id: id },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

const initialState = {
  dashboard: null,
  profileInfo: null,
  careerPathway: null,
  userStaff: null,
  partners: null,
  coaches: null,
  resources: null,
  staff: null,
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAdminDashboard.fulfilled, (state, action) => {
      state.dashboard = action.payload;
    });
    builder.addCase(getCareerPath.fulfilled, (state, action) => {
      state.careerPathway = action.payload;
    });
    builder.addCase(getUserStaff.fulfilled, (state, action) => {
      state.userStaff = action.payload;
    });
    builder.addCase(getPartners.fulfilled, (state, action) => {
      state.partners = action.payload;
    });
    builder.addCase(getAllPartners.fulfilled, (state, action) => {
      state.partners = action.payload;
    });
    builder.addCase(getCoaches.fulfilled, (state, action) => {
      state.coaches = action.payload;
    });
    builder.addCase(getAllCoaches.fulfilled, (state, action) => {
      state.coaches = action.payload;
    });
    builder.addCase(getResources.fulfilled, (state, action) => {
      state.resources = action.payload;
    });
    builder.addCase(getAllResources.fulfilled, (state, action) => {
      state.resources = action.payload;
    });
    builder.addCase(getStaff.fulfilled, (state, action) => {
      state.staff = action.payload;
    });

    builder.addCase(
      AdminPostEvents.rejected,
      (state: any, action: PayloadAction<any>) => {
        return action.payload;
      },
    );
  },
});

// Action creators are generated for each case reducer function
// eslint-disable-next-line no-empty-pattern
export const {} = adminSlice.actions;

export default adminSlice.reducer;
